import { memo } from 'react'

import InputText from 'components/common/inputs/Text'
import SearchIcon from 'assets/icons/Search'
import { Container, SubmitBtn } from './Styles'

const Bar = ({ field, onSubmit, placeholder, ...props }) => {
  return (
    <Container className="flex items-center justify-center bg-white pl-3 pr-1 rounded-full border border-grey-100">
      <InputText
        field={field}
        placeholder={placeholder}
        noBorder
        noPadding
        fullWidth
        onEnter={onSubmit}
        inputStyle={{ height: 'auto' }}
        {...props}
      />
      <SubmitBtn onClick={onSubmit}>
        <SearchIcon />
      </SubmitBtn>
    </Container>
  )
}

Bar.whyDidYouRender = true

export default memo(Bar)
