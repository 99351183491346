import { memo } from 'react'

import ArrowIcon from 'assets/icons/arrows/Paginate'
import CalendarIcon from 'assets/icons/Calendar'

interface HeaderProps {
  currentMonth: string
  onNavClick: (value: number) => void
}

const Header = ({ currentMonth, onNavClick }: HeaderProps) => {
  return (
    <div className="font-csc65 text-lg h-8 w-full flex justify-between items-center border-b border-grey-100 pb-4">
      <div className="flex items-center justify-start border-r border-grey-100 pr-6">
        <CalendarIcon className="h-3.5 mr-1" />
        {currentMonth}
      </div>
      <div className="flex items-center justify-center">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => onNavClick(-1)}
          tabIndex={0}
          role="button"
          onKeyDown={() => onNavClick(-1)}
        >
          <ArrowIcon className="mr-1" style={{ transform: 'rotate(180deg)' }} />
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => onNavClick(1)}
          tabIndex={0}
          role="button"
          onKeyDown={() => onNavClick(1)}
        >
          <ArrowIcon className="ml-1" style={undefined} />
        </div>
      </div>
    </div>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
