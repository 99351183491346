import { useEffect, useCallback, useState } from 'react'
import Video from 'twilio-video' // DOCS https://media.twiliocdn.com/sdk/js/video/releases/2.11.0/docs/index.html
import useClock from 'hooks/useClock'
import usePrevious from 'hooks/usePrevious'

export const ROOM_STATUSES = {
  INITIAL: 'initial',
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  FULL: 'full',
}

const getDevicePermissions = async () => {
  const devices = {}
  try {
    await navigator.mediaDevices.getUserMedia({ video: true })
    devices.video = true
  } catch (e) {
    devices.video = false
  }
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true })
    devices.audio = true
  } catch (e) {
    devices.audio = false
  }
  return devices
}

const trackPubsToTracks = trackMap =>
  Array.from(trackMap.values())
    .map(publication => publication.track)
    .filter(track => track !== null)

export default (token, roomName, log = true) => {
  const currentDate = useClock(10000)
  const previousDate = usePrevious(currentDate)
  const [status, setStatus] = useState(ROOM_STATUSES.INITIAL)
  const [participants, setParticipants] = useState([])
  const [tracks, setTracks] = useState({})
  const [room, setRoom] = useState({})
  const [inError, setInError] = useState()

  const onParticipantConnected = useCallback(
    (participant, local) => {
      if (log) console.log('Participant Connected: ', participant, local, participants)
      if (!participants.find(p => p.identity === participant.identity)) {
        const videoTracks = trackPubsToTracks(participant.videoTracks)
        const audioTracks = trackPubsToTracks(participant.audioTracks)

        participant.on('trackSubscribed', track => {
          track.on('disabled', () => {
            setTracks(oldTracks => ({
              ...oldTracks,
              [participant.identity]: {
                ...(oldTracks[participant.identity] || []),
                [track.kind]: null,
              },
            }))
          })
          track.on('enabled', () => {
            setTracks(oldTracks => ({
              ...oldTracks,
              [participant.identity]: {
                ...(oldTracks[participant.identity] || []),
                [track.kind]: track,
              },
            }))
          })

          setTracks(oldTracks => ({
            ...oldTracks,
            [participant.identity]: {
              ...(oldTracks[participant.identity] || []),
              [track.kind]: track,
            },
          }))
        })
        participant.on('trackUnsubscribed', track => {
          setTracks(oldTracks => ({
            ...oldTracks,
            [participant.identity]: {
              ...(oldTracks[participant.identity] || []),
              [track.kind]: null,
            },
          }))
        })

        if (audioTracks || videoTracks) {
          setTracks(otherTracks => ({
            ...otherTracks,
            [participant.identity]: {
              audio: audioTracks[0],
              video: videoTracks[0],
            },
          }))
        }
        if (local !== true) setStatus(ROOM_STATUSES.FULL)
        setParticipants(prevParticipants => [...prevParticipants, participant])
      }
    },
    [log, participants, setTracks]
  )
  const onParticipantDisconnected = useCallback(
    participant => {
      if (log) console.log('Participant Disconnected: ', participant)
      participant.tracks.forEach(publication => {
        if (publication.track) {
          const attachedElements = publication.track.detach()
          attachedElements.forEach(element => element.remove())
        }
      })
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p.identity !== participant.identity)
      )
    },
    [log]
  )
  useEffect(() => {
    if (
      token &&
      roomName &&
      (status === ROOM_STATUSES.INITIAL || status === ROOM_STATUSES.DISCONNECTED) &&
      status !== ROOM_STATUSES.FULL
    ) {
      ;(async () => {
        setStatus(ROOM_STATUSES.CONNECTING)
        try {
          const devices = await getDevicePermissions()
          if (log) console.log('Devices: ', devices)
          if (log) console.log('Existing Room: ', room)
          const newRoom = Object.keys(room).length
            ? room
            : await Video.connect(token, {
                name: roomName,
                ...devices,
              })

          if (log) console.log('Connected to room: ', newRoom)
          onParticipantConnected(newRoom.localParticipant, true)
          newRoom.participants.forEach(p => onParticipantConnected(p))
          newRoom.on('participantConnected', onParticipantConnected)
          newRoom.on('participantReconnected', onParticipantConnected)
          newRoom.on('participantDisconnected', onParticipantDisconnected)
          newRoom.on('disconnected', err => {
            if (log) {
              console.log(
                'Disconnected - Removing Tracks, Participants, Set Status DISCONNECTED and reset Room'
              )
            }
            newRoom.participants.forEach(onParticipantDisconnected)
            newRoom.localParticipant.tracks.forEach(publication => {
              if (publication.track) {
                const attachedElements = publication.track.detach()
                attachedElements.forEach(element => element.remove())
              }
            })
            setTracks({})
            setParticipants([])
            setStatus(ROOM_STATUSES.DISCONNECTED)
            setInError(false)
            setRoom({})
          })
          if (!Object.keys(room).length) setRoom(newRoom)
        } catch (e) {
          console.log('An error occured while setting up the video room: ', e.toString(), e.code)
          if (e.code === 53106) {
            // Room not found
            setInError(true)
          }
        }
      })()
    }
  }, [
    token,
    roomName,
    onParticipantConnected,
    onParticipantDisconnected,
    status,
    log,
    currentDate,
    previousDate,
    tracks,
    participants,
    room,
  ])
  const handleMicChange = useCallback(
    newStatus => {
      room.localParticipant.audioTracks.forEach(publication => {
        if (newStatus === 'on') publication.track.enable()
        else publication.track.disable()
      })
    },
    [room]
  )
  const handleCamChange = useCallback(
    newStatus => {
      room.localParticipant.videoTracks.forEach(publication => {
        if (newStatus === 'on') publication.track.enable()
        else publication.track.disable()
      })
    },
    [room]
  )
  const handleHangUp = useCallback(async () => {
    if (!inError && Object.keys(room).length) {
      await room.disconnect()
    }
    setTracks({})
    setParticipants([])
    setInError(false)
  }, [inError, room])

  return {
    status,
    participants,
    tracks,
    setMic: handleMicChange,
    setCam: handleCamChange,
    hangUp: handleHangUp,
    inError,
  }
}
