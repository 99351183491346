export const usernameUsedInPassword = (email, password) => {
  let finalReturn = false

  const parts = email.split('@')
  parts.forEach(part => {
    const toTest = part.split('.')
    toTest.forEach(str => {
      if (str.length >= 4 && password.toLowerCase().includes(str)) {
        finalReturn = true
      }
    })
  })

  return finalReturn
}
