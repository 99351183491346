import { memo } from 'react'

import Popup from 'components/common/Popup'
import Card from 'components/common/Card'
import { useTranslation } from 'services/Translation'

import EN from './content/en'
import NL from './content/nl'
import Privacy from '../Privacy'
import { Divider } from '../Styles'

const Terms = ({ active, onClose, onlyText, withPrivacy }) => {
  const { lang } = useTranslation()

  return !active ? null : (
    <>
      {onlyText ? (
        lang === 'nl' ? (
          <NL />
        ) : (
          <EN />
        )
      ) : (
        <Popup onOverlayClick={onClose} contentWidth={50}>
          <Card style={{ overflow: 'auto', margin: '32px 0' }}>
            {lang === 'nl' ? <NL /> : <EN />}
            {withPrivacy && (
              <>
                <Divider />
                <Privacy onlyText active />
              </>
            )}
          </Card>
        </Popup>
      )}
    </>
  )
}

Terms.whyDidYouRender = true

export default memo(Terms)
