import styled from 'styled-components'

export const Container = styled(({ size, color, center, ...props }) => <div {...props} />)`
  @keyframes microLoadSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50px;
  background-image: ${({ color }) => `linear-gradient(${color}, white)`};
  animation: microLoadSpin 1s linear infinite;
  ${({ center }) => center && `margin: 0 auto;`}
  &:before {
    content: ' ';
    position: absolute;
    width: ${({ size }) => size - 4}px;
    height: ${({ size }) => size - 4}px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    background-color: white;
  }
`
