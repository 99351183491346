import { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

const Element = ({ name, action, time, link }) => {
  const history = useHistory()

  const handleLink = useCallback(() => {
    history.push(link)
  }, [link, history])

  return (
    <div
      className={`rounded-xl bg-purple-200-o12 pt-6 pb-7 pl-6 pr-7 text-purple-200 flex justify-between ${
        link ? 'hover:shadow-cxl transition' : ''
      }`}
      style={{ marginTop: '1.875rem', fontSize: '0.9375rem', cursor: link ? 'pointer' : 'normal' }}
      onClick={link ? handleLink : undefined}
      tabIndex={0}
      role="button"
      onKeyDown={link ? handleLink : undefined}
    >
      <div>
        {name && (
          <div className="font-csc65 w-52 truncate" style={{ marginBottom: '0.8125rem' }}>
            {name}
          </div>
        )}
        <div className="font-csc45">{action}</div>
      </div>
      <div className="text-xs font-csc55 pl-2 text-right" style={{ minWidth: '5.1rem' }}>
        {`${time}`}
      </div>
      {/*<div className="text-xs font-csc55 pl-2 text-right">{`${time} (${fullTime})`}</div>*/}
    </div>
  )
}

Element.whyDidYouRender = true

export default memo(Element)
