import styled from 'styled-components'

// table overwrite styles
export const Container = styled(({ height, ...props }) => <div {...props} />)`
  overflow-y: auto;
  & > div {
    & > div {
      display: block;
    }
  }
  & [class$='Table'] {
    max-height: 30vh;
    height: auto;
    background-color: #fcfcfc;
  }
  & [class$='TableBody'] {
    min-width: max-content;
    max-height: ${({ height }) => height};
    transition: all ease-out 250ms;
    background-color: #fcfcfc;
    padding-bottom: 2rem;
    margin-top: 0;
  }
  & [class$='TableHeadRow'] {
    border: none;
    height: 0px !important;
    background: transparent;
    & [class$='TableCol'] {
      padding: 0;
      height: 0px !important;
    }
  }
  & [class$='TableRow'] {
    margin-top: 1rem;
    height: 63px;
    background: transparent;
    align-items: flex-start;
    border-bottom: 1px solid #e8e8e8;
    font-family: coresanscr45;
    cursor: pointer;
    width: calc(100% - 115px);
    &:first-child {
      margin-top: 0;
    }
    & [class$='TableCell'] {
      font-size: 0.9375rem;
      width: 100px;
      height: 63px;
      align-items: center;
      justify-content: flex-start;
    }
  }
`
