import { memo, useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import LoaderMini from 'components/common/loaders/mini'
import { useMiscDispatch } from 'services/Misc'
import { setAppointmentSideBar, appointmentSideBarDefault } from 'services/Misc/appointmentSideBar'
import { useTranslation } from 'services/Translation'
import { CS } from 'back-end-api'
import usePrevious from 'hooks/usePrevious'
import { Container, Content } from './Styles'
import SchedulesView from './SchedulesView'
import { VIEW_TYPE } from './Tools'
import Filter from './Filter'
import AppointmentsView from './AppointmentView'
import { TABS } from '../Tools'

const tabsVariants = {
  enter: direction => {
    return {
      x: direction > 0 ? 500 : -500,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 500 : -500,
      opacity: 0,
    }
  },
}

const Main = ({ tab, date }) => {
  const { t, formatDate: format } = useTranslation()
  const miscDispatch = useMiscDispatch()
  const previousTab = usePrevious(tab)
  const [currentView, setCurrentView] = useState(VIEW_TYPE.day)
  const [loading, setLoading] = useState(true)

  const direction =
    Object.keys(TABS).findIndex(e => e === previousTab) >
    Object.keys(TABS).findIndex(e => e === tab)
      ? -1
      : 1

  const { data: registrations, isLoading } = CS.useRegistrationsList()
  const [newRegistrations, setNewRegistrations] = useState([])
  const [appointmentFilter, setAppointmentFilter] = useState(null)
  const [filteredAppointments, setFilteredAppointments] = useState([])

  // Only update appointments when the data have changed.
  // This is required to prevent infinite loop as data is diferent on every render
  useEffect(() => {
    if (
      !isLoading &&
      JSON.stringify(registrations.map(r => r.id)) !==
        JSON.stringify(newRegistrations.map(r => r.id))
    ) {
      setNewRegistrations(registrations)
    }
  }, [registrations, isLoading, newRegistrations])

  useEffect(() => {
    const appointments = CS.getAppointments(CS.getSummaries(newRegistrations)).filter(
      a => a.datetime !== undefined && (!appointmentFilter || a.status === appointmentFilter)
    )
    setFilteredAppointments(appointments)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [t, newRegistrations, format, date, appointmentFilter])

  useEffect(() => {
    setAppointmentSideBar(miscDispatch, appointmentSideBarDefault)
  }, [miscDispatch])

  return (
    <>
      <Content>
        <Filter
          date={date}
          content={tab}
          tabsVariants={tabsVariants}
          direction={direction}
          appointmentFilter={appointmentFilter}
          setAppointmentFilter={setAppointmentFilter}
          onChangeView={setCurrentView}
          view={currentView}
        />
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className="absolute"
            style={{ width: '86%' }}
            custom={direction}
            variants={tabsVariants}
            key={tab}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 200 },
              opacity: { duration: 0.2 },
            }}
          >
            <Container custom={direction}>
              {(() => {
                if (loading) return <LoaderMini />
                switch (tab) {
                  case TABS.APPOINTMENTS:
                    return (
                      <AppointmentsView
                        data={filteredAppointments}
                        currentView={currentView}
                        isLoading={loading}
                        date={date}
                      />
                    )
                  case TABS.SCHEDULES:
                    return <SchedulesView date={date} />
                  default:
                    console.error('Unhandled appointments content tab', tab)
                    return null
                }
              })()}
            </Container>
          </motion.div>
        </AnimatePresence>
      </Content>
    </>
  )
}

Main.whyDidYouRender = true

export default memo(Main)
