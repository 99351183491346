export default ({ className = '', color = '#1c165b' }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="17.104"
    height="10.052"
    viewBox="0 0 17.104 10.052"
  >
    <g id="video-camera" transform="translate(0 -96.512)">
      <g id="Group_461" data-name="Group 461" transform="translate(0 96.512)">
        <g id="Group_460" data-name="Group 460">
          <path
            id="Path_229"
            data-name="Path 229"
            d="M9.676,96.512h-7.8A1.877,1.877,0,0,0,0,98.384v6.308a1.877,1.877,0,0,0,1.872,1.872h7.8a1.877,1.877,0,0,0,1.872-1.872V98.384a1.865,1.865,0,0,0-1.872-1.872Z"
            transform="translate(0 -96.512)"
            fill={color}
          />
        </g>
      </g>
      <g id="Group_463" data-name="Group 463" transform="translate(12.482 97.513)">
        <g id="Group_462" data-name="Group 462">
          <path
            id="Path_230"
            data-name="Path 230"
            d="M344.741,123.878a.954.954,0,0,0-.32.131l-2.92,1.682v4.342l2.938,1.682a1.112,1.112,0,0,0,1.534-.412,1.143,1.143,0,0,0,.15-.561v-5.78a1.127,1.127,0,0,0-1.382-1.085Z"
            transform="translate(-341.501 -123.848)"
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
)
