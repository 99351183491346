import { memo } from 'react'

import { hp } from 'styles/Sizes'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import DefaultBtn from 'components/common/buttons/Default'
import Popup from 'components/common/Popup'
import SecondaryBtn from 'components/common/buttons/Secondary'
import { useTranslation } from 'services/Translation'
import { error as colorError } from 'styles/Colors'
import { Container, ButtonsContainer } from './Styles'

import texts from './Texts'

const ConfirmFinish = ({ visible, onCancel, onConfirm }) => {
  const { t } = useTranslation(texts)
  if (!visible) return null
  return (
    <Popup>
      <Container className=" flex flex-col justify-center items-center p-11 bg-white border border-grey-100">
        <div className="flex justify-center pb-4 border-b border-grey-100 w-full">
          <Typography className="font-csc65" type="title" text={t('title')} />
        </div>
        <Separator size={hp(2.5)} />
        <div className="w-full flex justify-center items-center pb-4 border-b border-grey-100">
          <Typography
            center
            style={{ lineHeight: '22px', width: '80%' }}
            className="font-csc45"
            text={t('text')}
          />
        </div>
        <Separator size={hp(3)} />
        <ButtonsContainer>
          <SecondaryBtn
            style={{ width: '9.375rem' }}
            data-cy="btnFinishConfirm"
            label={t('cancelBtnLabel')}
            onClick={onCancel}
            color={colorError}
            bold
            size="14px"
          />
          <DefaultBtn
            style={{ width: '9.375rem' }}
            reversedColors
            label={t('blckBtnLabel')}
            onClick={onConfirm}
            data-cy="btnFinishConfirm"
          />
        </ButtonsContainer>
      </Container>
    </Popup>
  )
}

ConfirmFinish.whyDidYouRender = true

export default memo(ConfirmFinish)
