import { memo } from 'react'
import { useTranslation } from 'services/Translation'
import { Container } from './Styles'
import texts from './Texts'

const FollowUps = () => {
  const { t } = useTranslation(texts)

  return (
    <Container height="40rem">
      <div className="ml-2 my-3 font-csc45">{t('content')}</div>
      {/*To be implemented in the same time as the rest of the follow up*/}
    </Container>
  )
}

FollowUps.whyDidYouRender = true

export default memo(FollowUps)
