export default ({ className = '', color = '#1c165b' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.082"
    height="15.263"
    viewBox="0 0 13.082 15.263"
    className={className}
  >
    <g id="lock" transform="translate(-4.5 -2.25)">
      <path
        id="Path_353"
        data-name="Path 353"
        d="M15.4,8.791a2.18,2.18,0,0,1,2.18,2.18v4.361a2.18,2.18,0,0,1-2.18,2.18H6.68a2.18,2.18,0,0,1-2.18-2.18V10.972a2.18,2.18,0,0,1,2.18-2.18V6.611a4.361,4.361,0,0,1,8.722,0ZM11.041,3.7a2.907,2.907,0,0,1,2.907,2.907v2.18H8.134V6.611A2.907,2.907,0,0,1,11.041,3.7ZM15.4,10.245H6.68a.727.727,0,0,0-.727.727v4.361a.727.727,0,0,0,.727.727H15.4a.727.727,0,0,0,.727-.727V10.972A.727.727,0,0,0,15.4,10.245Z"
        fill={color}
        fillRule="evenodd"
      />
    </g>
  </svg>
)
