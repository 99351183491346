import { memo } from 'react'

import ErrorCross from 'assets/icons/crosses/Error'
import { Title, Text, Subtext, CrossContainer } from './Styles'

const Typography = ({
  type = '',
  text = '',
  bold = false,
  hidden = false,
  style = {},
  error = false,
  success = false,
  center = false,
  size,
  color = '',
  underline = false,
  italic = false,
  rtl = false,
  ...props
}) => {
  switch (type) {
    case 'title':
      return (
        <Title
          hidden={hidden}
          bold={bold}
          style={style}
          center={center}
          size={size}
          color={color}
          underline={underline}
          italic={italic}
          rtl={rtl}
          {...props}
        >
          {text}
        </Title>
      )
    case 'subtext':
      return (
        <Subtext
          hidden={hidden}
          bold={bold}
          style={style}
          center={center}
          size={size}
          underline={underline}
          italic={italic}
          rtl={rtl}
          {...props}
        >
          {text}
        </Subtext>
      )
    default:
      return (
        <>
          <Text
            hidden={hidden}
            bold={bold}
            style={style}
            error={error}
            success={success}
            center={center}
            size={size}
            color={color}
            underline={underline}
            italic={italic}
            rtl={rtl}
            {...props}
          >
            {error && text && (
              <CrossContainer>
                <ErrorCross />
              </CrossContainer>
            )}
            {text}
          </Text>
        </>
      )
  }
}

Typography.whyDidYouRender = true

export default memo(Typography)
