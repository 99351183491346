export const LINKS = {
  START: {
    EN: 'https://workshop-mia.webflow.io/',
    NL: 'https://workshop-mia.webflow.io/',
  },
  FAQ: {
    EN: 'https://www.medvice.io/en/faq',
    NL: 'https://www.medvice.io/faq',
  },
  SUPPORT: {
    EN: 'https://www.medvice.io/en/contact',
    NL: 'https://www.medvice.io/contact',
  },
}
