import { memo, useCallback, useMemo } from 'react'
import { motion } from 'framer-motion'
import { CS_m } from 'back-end-api'
import { useTranslation } from 'services/Translation'
import { useMiscDispatch } from 'services/Misc'
import { getStatusColor } from 'services/Registrations'
import { setAppointmentSideBar, AppointmentSideBar } from 'services/Misc/appointmentSideBar'
import { hp } from 'styles/Sizes'
import Separator from 'components/common/Separator'
import { Locale } from 'date-fns'
import isSameHour from 'date-fns/isSameHour'
import enUsDF from 'date-fns/locale/en-US'
import nlDF from 'date-fns/locale/nl'
import arDF from 'date-fns/locale/ar-DZ'
import {
  DayLine,
  DayTitle,
  Container,
  DayNumber,
  EventGrid,
  Event,
  Time,
  Text,
  TabLine,
  TopShadow,
} from './Styles'
import EventLabel from './EventLabel'

const topShadowVariants = {
  show: {
    top: '-3rem',
  },
  hide: {
    top: '-9rem',
  },
}

const dateFnsLocales: Record<string, Locale> = {
  en: enUsDF,
  nl: nlDF,
  ar: arDF,
}

const DAYS_IN_WEEK: number = 7

function getWeekStartDate(date: Date): Date {
  const dayOfWeek = date.getDay()
  const offset = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // Makes Monday first day of week
  const startDate = new Date(date)
  startDate.setDate(startDate.getDate() - offset)
  return startDate
}

function getAllDatesOfWeek(date: Date): Date[] {
  const startDate = getWeekStartDate(date)
  return Array.from({ length: 7 }, (_, index) => {
    const currentDate = new Date(startDate)
    currentDate.setDate(startDate.getDate() + index)
    return currentDate
  })
}

const getWeekHoursAppointments = (
  datesOfWeek: Date[],
  scheduledAppointments: CS_m.ModelsAppointment[]
) => {
  const hoursEvents: CS_m.ModelsAppointment[][] = []
  datesOfWeek.forEach(day => {
    for (let hour = 0; hour < 24; hour++) {
      day.setHours(hour)

      const appointmentsInHour = scheduledAppointments?.filter(
        a => a.datetime && isSameHour(new Date(a.datetime), day)
      )

      hoursEvents.push(appointmentsInHour)
    }
  })

  return hoursEvents
}

interface WeekTableProps {
  appointments: CS_m.ModelsAppointment[]
  currDate: Date
}

const WeekTable = ({ appointments, currDate }: WeekTableProps) => {
  const { lang } = useTranslation()
  const miscDispatch = useMiscDispatch()

  const days = useMemo(() => {
    const daysIndexArray = [...Array(DAYS_IN_WEEK).keys()]
    const daysIndexArrayStartOnMonday = [...daysIndexArray.slice(1), daysIndexArray[0]]

    return daysIndexArrayStartOnMonday.map(i =>
      dateFnsLocales[lang]?.localize?.day(i, { width: 'abbreviated' })
    )
  }, [lang])

  const datesOfWeek = getAllDatesOfWeek(currDate)
  const weekHoursAppointments = getWeekHoursAppointments(datesOfWeek, appointments)

  const handlePatientClick = useCallback(
    (patientId: number) => {
      setAppointmentSideBar(miscDispatch, {
        type: AppointmentSideBar.AppointmentDetails,
        appointmentDetailsSideBarProps: { registrationId: patientId },
      })
    },
    [miscDispatch]
  )

  return (
    <Container>
      <TabLine className="border-b border-grey-100">
        <DayLine>
          {days.map(day => (
            <DayTitle key={day}>{`${day?.[0]?.toUpperCase()}${day.substring(1)}`}</DayTitle>
          ))}
        </DayLine>
        <DayLine>
          {datesOfWeek.map(day => (
            <DayNumber key={day.getDate()}>{day.getDate()}</DayNumber>
          ))}
        </DayLine>
        <TopShadow className="">
          <motion.div
            animate="show"
            variants={topShadowVariants}
            initial="hide"
            className="absolute h-6 -mt-px -top-12 bg-transparent left-2/4 transition"
            style={{
              boxShadow: 'rgb(47 76 203 / 10%) 0px 30px 30px 30px',
              transform: 'translate(-50%)',
              borderRadius: '100%',
              width: '55%',
            }}
          />
        </TopShadow>
      </TabLine>

      <Separator size={hp(2)} />

      <EventGrid>
        {Array(24)
          .fill(' ')
          .map((_, i) => (
            <Time key={i}>
              <Text>{`${i}:00`}</Text>
            </Time>
          ))}
        {weekHoursAppointments?.map((hourAppointments, i) => (
          <Event key={i}>
            {hourAppointments?.splice(0, 3)?.map((a, iHour) => (
              <EventLabel
                color={getStatusColor(a.summary!.registration!)}
                name={`${a.summary?.registration?.first_name} ${a.summary?.registration?.last_name}`}
                age={a.summary?.registration?.age || 0}
                reason={a?.summary?.complaint?.name || 'Medical question'}
                time={a.datetime ? new Date(a.datetime) : new Date()}
                appointmentId={a.id}
                onClick={() => {
                  handlePatientClick(a.registration_id)
                }}
                colapsed={iHour >= 2}
              />
            ))}
          </Event>
        ))}
      </EventGrid>
    </Container>
  )
}

WeekTable.whyDidYouRender = true

export default memo(WeekTable)
