import { memo, useCallback, useState } from 'react'
import { Formik, Field, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { GS } from 'back-end-api'
import { hp } from 'styles/Sizes'
import Separator from 'components/common/Separator'
import TextInput from 'components/common/inputs/Text'
import DefaultBtn from 'components/common/buttons/Default'
import Typography from 'components/common/Typography'
import { login, logout, Claims } from 'services/Authentication'
import { useTranslation } from 'services/Translation'
import jwtDecode from 'jwt-decode'
import styled from 'styled-components'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
`

export const initialValues = {
  username: '',
  password: '',
}

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    usernameLabel: getText('User Name', 'Auth_Login_Form'),
    passwordLabel: getText('Password', 'Auth_Login_Form'),
    btnLabel: getText('Log In', 'Auth_Login_Form'),
    errorLabel: getText('Wrong Credentials', 'Auth_Login_Form'),
    errorInvalidRole: getText('Invalid role', 'Auth_Login_Form'),
    emailMatchError: getText('Invalid email', 'Auth_Login_Form'),
    emailRequiredError: getText('The username is required', 'Auth_Login_Form'),
    passwordRequiredError: getText('The password is required', 'Auth_Login_Form'),
    tooManyRequests: getText(
      'Too many requests sent, please retry in 30 seconds',
      'Auth_Login_Form'
    ),
  }[key])

interface LoginFormProps {
  onLoggedIn: (resp: {
    mfaPendingToken: string | undefined
    partialTarget: string | undefined
  }) => void
  error: string
}

const LoginForm = ({ onLoggedIn, error: propsError }: LoginFormProps) => {
  const { t, lang } = useTranslation(texts)
  const [error, setError] = useState('')

  const schema = Yup.object().shape({
    username: Yup.string()
      .required(t('emailRequiredError'))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Okta email
        t('emailMatchError')
      ),
    password: Yup.string().required(t('passwordRequiredError')),
  })

  const handleSubmit = useCallback(
    async (values: any, { resetForm }: any) => {
      const res = await login({
        email: values.username,
        password: values.password,
        language_tag: lang,
      })

      if (res?.error) {
        const errorMsg = res.error?.status === 429 ? t('tooManyRequests') : t('errorLabel')
        setError(t(errorMsg))
        return
      }

      const claims = jwtDecode<Claims>(res.data!.access_token)

      if (claims.role !== GS.Role.Doctor) {
        await logout({ access_token: res.data!.access_token ?? '' })
        setError(t('errorInvalidRole'))
        return
      }

      const resp = {
        mfaPendingToken: res.data!.access_token,
        partialTarget: res.data!.anonymized_otp_destination,
      }

      setError('')
      onLoggedIn(resp)
      resetForm(initialValues)
    },
    [t, onLoggedIn, lang]
  )

  return (
    <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, errors, submitCount, submitForm }) => {
        return (
          <Form>
            <Field
              data-cy="email"
              name="username"
              type="email"
              label={t('usernameLabel')}
              component={TextInput}
              fullWidth
              autoComplete="chrome-off"
            />
            <Separator size={hp(1)} />
            {!!(submitCount && errors?.username) && (
              <Typography text={errors.username} error style={undefined} size={undefined} />
            )}
            <Separator size={hp(3)} />
            <Field
              data-cy="password"
              name="password"
              type="password"
              label={t('passwordLabel')}
              component={TextInput}
              fullWidth
              autoComplete="chrome-off"
              onEnter={submitForm}
            />
            <Separator size={hp(1)} />
            {!!(submitCount && errors?.password) && (
              <Typography text={errors.password} error style={undefined} size={undefined} />
            )}
            <Typography
              text={error}
              center
              error
              data-cy="loginError"
              style={undefined}
              size={undefined}
            />
            <Separator size={hp(3.7) - 25} />
            {propsError && (
              <>
                <Typography
                  text={propsError}
                  center
                  error
                  data-cy="login2FAError"
                  style={undefined}
                  size={undefined}
                />
                <Separator size={hp(3.7) - 25} />
              </>
            )}
            <DefaultBtn
              data-cy="submitBtn"
              style={{ width: '50%' }}
              label={t('btnLabel')}
              disabled={isSubmitting}
              type="submit"
              nextIcon={undefined}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

LoginForm.whyDidYouRender = true

export default memo(LoginForm)
