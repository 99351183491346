import styled from 'styled-components'

export const Container = styled(({ small, ...props }) => <div {...props} />)`
  position: fixed;
  top: 0;
  left: 0;
  ${'' /* left: ${({ small }) => (small ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED)}px; */}
  height: 100vh;
  width: 100vw;
  ${'' /* width: calc(100vw - ${({ small }) => (small ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED)}px); */}
  transition: all 300ms ease-in;
  z-index: 30;
`
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
`
export const Content = styled(({ noEvents, ...props }) => <div {...props} />)`
  z-index: 6;
  cursor: default;
  pointer-events: ${({ noEvents }) => (noEvents ? 'none' : 'all')};
  position: relative;
  height: 100%;
  width: ${({ width }) => `${width}%`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`
