import styled from 'styled-components'
import { motion } from 'framer-motion'

import { primary, transparency } from 'styles/Colors'

export const Container = styled(({ clickable, columnWidth, ...props }) => <div {...props} />)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${({ columnWidth }) => columnWidth};
  pointer-events: ${({ clickable }) => (clickable ? 'all' : 'none')};
  overflow: hidden;
`
export const Overlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.6);
`
export const Inner = styled(motion.div)`
  position: relative;
  background-color: white;
  box-shadow: 3px 6px 24px 0 ${primary + transparency[10]};
`
