export default ({ className, color = '#1c165b' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.286"
    height="18"
    viewBox="0 0 10.286 18"
    className={className}
  >
    <path
      id="thermometer-quarter"
      d="M6.428,13.5A1.921,1.921,0,0,1,4.5,15.428,1.921,1.921,0,0,1,2.571,13.5a1.941,1.941,0,0,1,1.285-1.818v-1.4H5.142v1.4a1.9,1.9,0,0,1,.934.713,1.885,1.885,0,0,1,.352,1.1Zm1.286,0a3.152,3.152,0,0,0-.341-1.446,3.2,3.2,0,0,0-.944-1.125V3.214a1.86,1.86,0,0,0-.562-1.366A1.86,1.86,0,0,0,4.5,1.286a1.86,1.86,0,0,0-1.366.562,1.86,1.86,0,0,0-.562,1.366v7.714a3.2,3.2,0,0,0-.944,1.125A3.152,3.152,0,0,0,1.286,13.5a3.1,3.1,0,0,0,.939,2.275,3.1,3.1,0,0,0,2.275.939,3.1,3.1,0,0,0,2.275-.939A3.1,3.1,0,0,0,7.714,13.5ZM9,13.5a4.333,4.333,0,0,1-1.321,3.179A4.336,4.336,0,0,1,4.5,18a4.332,4.332,0,0,1-3.179-1.321,4.487,4.487,0,0,1-.036-6.323V3.214A3.1,3.1,0,0,1,2.224.939,3.1,3.1,0,0,1,4.5,0,3.1,3.1,0,0,1,6.775.939a3.1,3.1,0,0,1,.939,2.275v7.141A4.342,4.342,0,0,1,9,13.5Zm1.285-5.785V9H8.357V7.714h1.929Zm0-2.572V6.428H8.357V5.143h1.929Zm0-2.571V3.857H8.357V2.571h1.929Z"
      fill={color}
    />
  </svg>
)
