import { memo, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { hp } from 'styles/Sizes'
import Typography from 'components/common/Typography'
import DefaultBtn from 'components/common/buttons/Default'
import useTranslation from 'services/Translation/useTranslation'
import TextInput from 'components/common/inputs/Text'

import Separator from 'components/common/Separator'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    title: getText('Two factor authentication', 'Auth_Login_2FA_Form'),
    subtitle1: getText('We sent a code to ', 'Auth_Login_2FA_Form'),
    subtitle2: getText('. Please enter it below.', 'Auth_Login_2FA_Form'),
    codeLabel: getText('Code', 'Auth_Login_2FA_Form'),
    errorReq: getText('Code is required.', 'Auth_Login_2FA_Form'),
    errorTypeLength: getText('Code must be 5 numbers', 'Auth_Login_2FA_Form'),
    btnLabel: getText('Continue', 'Auth_Login_2FA_Form'),
  }[key])

export const initialValues = {
  code: '',
}

interface CodeFormProps {
  onSubmit: (code: string) => Promise<void>
  partialTarget: string
  error: string
  loading: boolean
}

const CodeForm = ({ onSubmit, partialTarget, error: propsError, loading }: CodeFormProps) => {
  const { t } = useTranslation(texts)

  const schema = Yup.object().shape({
    code: Yup.string().required(t('errorReq')).length(5, t('errorTypeLength')),
  })

  const handleSubmit = useCallback(
    async (values: any) => {
      await onSubmit(values.code)
    },
    [onSubmit]
  )

  return (
    <>
      <Typography text={t('title')} type="title" style={undefined} size={undefined} />
      <Separator size={hp(1)} />
      <Typography
        text={`${t('subtitle1')} ${partialTarget} ${t('subtitle2')}`}
        style={undefined}
        size={undefined}
      />
      <Separator size={hp(2)} />
      <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, errors, submitCount, submitForm }) => (
          <Form>
            <Field
              data-cy="code"
              name="code"
              label={t('codeLabel')}
              component={TextInput}
              fullWidth
              onEnter={submitForm}
              autoFocus
            />
            <Separator size={hp(1)} />
            {!!(submitCount && errors?.code) && (
              <Typography text={errors.code} error style={undefined} size={undefined} />
            )}
            <Separator size={hp(1)} />
            {propsError && (
              <>
                <Typography
                  text={propsError}
                  center
                  error
                  data-cy="error2FA"
                  style={undefined}
                  size={undefined}
                />
                <Separator size={hp(3.7) - 25} />
              </>
            )}
            <DefaultBtn
              label={t('btnLabel')}
              withNext
              type="submit"
              reversedColors
              disabled={isSubmitting || loading}
              style={{ margin: 'auto' }}
              nextIcon={undefined}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

CodeForm.whyDidYouRender = true

export default memo(CodeForm)
