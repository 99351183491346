import { memo } from 'react'

import Arrow from 'assets/icons/arrows/Default'
import Cross from 'assets/icons/crosses/Close'
import { Container } from './Styles'

const Back = ({ onClick, style, orientation = 'left', useCross }) => {
  let transform = ''
  switch (orientation) {
    case 'left':
      transform = 'rotate(180deg)'
      break
    case 'top':
      transform = 'rotate(-90deg)'
      break
    case 'bottom':
      transform = 'rotate(90deg)'
      break
  }

  return (
    <Container style={style} onClick={onClick}>
      {useCross ? <Cross /> : <Arrow style={{ transform }} />}
    </Container>
  )
}

Back.whyDidYouRender = true

export default memo(Back)
