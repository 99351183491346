import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled(
  ({ top, left, paddingVetical, paddingHorizontal, arrowPos, innerRef, ...props }) => (
    <motion.div {...props} ref={innerRef} />
  )
)`
  top: ${({ top }) => top + 24}px;
  left: ${({ left }) => left}px;
  border: 1px solid #e8e8e8;
  opacity: 0;
  padding-top: ${({ paddingVetical }) => paddingVetical || '2.25rem'};
  padding-bottom: ${({ paddingVetical }) => paddingVetical || '2.25rem'};
  padding-left: ${({ paddingHorizontal }) => paddingHorizontal || '1.5rem'};
  padding-right: ${({ paddingHorizontal }) => paddingHorizontal || '1.5rem'};
  ${({ arrowPos }) => {
    let ret = ''
    switch (arrowPos) {
      case 'center':
        ret = 'transform: translateX(-50%);'
        break
      case 'left':
        ret = 'transform: translateX(-26px);'
        break
      case 'right':
        ret = 'transform: translateX(calc(-100% + 26px));'
        break
    }
    return ret
  }}

  &:after,
  &:before {
    //transform: rotate(180deg);
    bottom: 100%;
    ${({ arrowPos }) => (arrowPos === 'center' ? 'left: 50%;' : '')}
    border: solid transparent;
    ${({ arrowPos }) => arrowPos && "content: '';"}
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: transparent;
    border-bottom-color: #fcfcfc;
    border-width: 10px;
    margin-left: -10px;
    ${({ arrowPos }) => {
      let ret = ''
      switch (arrowPos) {
        case 'left':
          ret = 'left: 16px;'
          break
        case 'right':
          ret = 'right: 16px;'
          break
      }
      return ret
    }}
  }
  &:before {
    border-bottom-color: #e8e8e8;
    border-width: 11px;
    margin-left: -11px;
    ${({ arrowPos }) => {
      let ret = ''
      switch (arrowPos) {
        case 'left':
          ret = 'left: 15px;'
          break
        case 'right':
          ret = 'right: 15px;'
          break
      }
      return ret
    }}
  }
`
