import styled from 'styled-components'

import { hp } from 'styles/Sizes'
import { primary, transparency } from 'styles/Colors'

export const Container = styled(({ disabled, color, background, mini, ...props }) => (
  // eslint-disable-next-line react/button-has-type
  <button {...props} />
))`
  border: none;
  border-radius: 50px;
  padding: ${({ mini }) => (mini ? '0 22px' : '0 44px')};
  height: ${({ mini }) => (mini ? hp(2.7) : hp(4.2))}px;
  // padding: ${({ mini }) => (mini ? `0 ${22 * 0.8}px` : `0 ${44 * 0.8}px`)};
  // height: ${({ mini }) => (mini ? hp(2.7) * 0.8 : hp(4.2) * 0.8)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  box-shadow: 1.5px 3px 9px 0 ${primary + transparency[20]};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  white-space: nowrap;
  &:hover {
    background-color: ${({ background }) => background + transparency[70]};
  }
`
export const PrevIcon = styled.div`
  margin-right: ${18 * 0.8}px;
  transform: scale(0.8);
`
export const NextIcon = styled.div`
  margin-left: ${17 * 0.8}%;
  margin-top: 2px;
  transform: scale(0.8);
`
export const Label = styled(({ mini, bold, size, ...props }) => <div {...props} />)`
  font-size: ${({ mini, size }) => (mini ? `${12 * 0.8}px` : size)};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-family: 'coresanscr65';
`
