import { memo, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import differenceInYears from 'date-fns/differenceInYears'

import { purple, green } from 'styles/Colors'
import Typography from 'components/common/Typography'
import AgeIcon from 'assets/icons/AgeIcon'
import PhoneIcon from 'assets/icons/PhoneIcon'
import IdIcon from 'assets/icons/Id'
import Arrow from 'assets/icons/arrows/Nav'
import { useTranslation } from 'services/Translation'

import { ROUTES } from 'Constants'
import Female from './assets/Female'
import Male from './assets/Male'
import Row from './Row'
import { Container, Header, Line, IconContainer, Expand } from './Styles'
import { containerVariants } from './Animations'
import texts from './Texts'

const Element = ({ patient }) => {
  const history = useHistory()
  const { formatDate: format, t } = useTranslation(texts)
  const [isHovered, setHovered] = useState(false)
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(3)

  const dateOfBirth = useMemo(() => new Date(patient.dob), [patient?.dob])
  const age = useMemo(() => differenceInYears(new Date(), dateOfBirth), [dateOfBirth])

  const toggleOpen = useCallback(() => setOpen(v => !v), [])
  const handleRedirect = useCallback(
    id => e => {
      e.stopPropagation()

      history.push(`${ROUTES.PATIENT}${id}`)
    },
    [history]
  )
  const handleExpand = useCallback(e => {
    e.stopPropagation()
    setLoaded(v => v + 3)
  }, [])
  const handleClose = useCallback(e => {
    e.stopPropagation()
    setLoaded(3)
    setOpen(false)
  }, [])

  return (
    <Container
      className="mx-16 transition border border-grey-100 relative overflow-hidden bg-white mt-7 cursor-pointer h-32"
      onClick={open ? handleClose : toggleOpen}
      animate={open ? 'open' : 'initial'}
      variants={containerVariants}
      active={open || isHovered}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Header>
        <Typography
          text={`${patient.lastname} ${patient.firstname}`}
          style={{
            marginRight: 12,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            lineHeight: '17px',
          }}
        />
        {patient?.gender !== 'Male' ? (
          <Female style={{ padding: 4 }} />
        ) : (
          <Male style={{ padding: 3 }} />
        )}
      </Header>
      <Line>
        <IconContainer color={green}>
          <AgeIcon />
        </IconContainer>
        <Typography
          style={{ marginLeft: 12, flex: 1, lineHeight: '14px' }}
          size={12}
          text={`${format(dateOfBirth, 'dd - MM - yyyy')
            .split(' ')
            .map((e, i) => (i === 1 ? `${e?.[0].toUpperCase()}${e?.substring(1)}` : e))
            .join(' ')} ( ${age} ${t('yearsMin')} )`}
        />
      </Line>
      <Line>
        <IconContainer color={purple}>
          <IdIcon />
        </IconContainer>
        <Typography
          text={patient.ssn}
          size={12}
          style={{ marginLeft: 12, flex: 1, lineHeight: '14px' }}
        />
      </Line>
      <Line>
        <IconContainer color={green}>
          <PhoneIcon />
        </IconContainer>
        <Typography
          style={{ marginLeft: 12, flex: 1, lineHeight: '14px' }}
          size={12}
          text={patient?.phone}
        />
      </Line>
      {patient?.summaries?.map(
        (s, i) => i < loaded && <Row summary={s} onClick={handleRedirect(s.patientId)} />
      )}
      {patient?.summaries?.length > loaded ? (
        <Expand onClick={handleExpand}>
          <Arrow style={{ transform: 'rotate(-90deg)' }} />
        </Expand>
      ) : (
        <Expand onClick={handleClose}>
          <Arrow style={{ transform: 'rotate(90deg)' }} />
        </Expand>
      )}
    </Container>
  )
}

Element.whyDidYouRender = true

export default memo(Element)
