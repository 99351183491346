import { memo } from 'react'

import { useLocation } from 'react-router-dom'

import { ROUTES } from 'Constants'
import Footer from './Footer'
import Header from './Header'
import { Container, Content } from './Styles'

const AuthLayout = ({ children }) => {
  const location = useLocation()

  return (
    <Container>
      <Header noHelp={location.pathname !== ROUTES.LOGIN()} />
      <Content>{children}</Content>
      <Footer />
    </Container>
  )
}

AuthLayout.whyDidYouRender = true

export default memo(AuthLayout)
