import { memo, CSSProperties, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'

import Card from 'components/common/Card'
import MaleIcon from 'assets/icons/gender/Male'
import FemaleIcon from 'assets/icons/gender/Female'
import { useTranslation } from 'services/Translation'
import VideoIcon from 'assets/icons/Video'
import { getStatusColor } from 'services/Registrations'
import { CS_m } from 'back-end-api'
import { ROUTES } from 'Constants'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    id: getText('ID', 'Sidebar_Appointment_Element'),
    ssnUndefined: getText('N/A', 'Sidebar_Appointment_Element'),
  }[key])

interface ElementProps {
  appointment: CS_m.ModelsAppointment
  style: CSSProperties
}

const Element = ({ appointment, style }: ElementProps) => {
  const history = useHistory()
  const { t } = useTranslation(texts)
  const statusColor = getStatusColor(appointment.summary!.registration!)

  const goToRegistration = useCallback(() => {
    if (appointment.summary?.registration?.id) {
      history.push(`${ROUTES.PATIENT}${appointment.summary?.registration?.id}`)
    }
  }, [history, appointment])

  return (
    <Card
      withMark
      markColor="#6C5BFF"
      className="mt-7 p-3.5 grid grid-rows-2 justify-items-center items-center hover:shadow-cxl transition"
      onClick={goToRegistration}
      style={{
        height: '4.5rem',
        gridTemplateAreas: '"time name name" "time id type"',
        gridTemplateColumns: '6.25rem repeat(2, 1fr)',
        ...style,
      }}
      innerRef={undefined}
      animate={undefined}
      variants={undefined}
      initial={undefined}
    >
      <div
        className="text-purple-200 h-full border-r pr-5 justify-center border-grey-100 font-csc65 items-center flex justify-self-stretch"
        style={{ gridArea: 'time', fontSize: '0.9375rem' }}
      >
        {appointment.datetime ? format(new Date(appointment.datetime), 'h:mm a') : null}
      </div>
      <div
        className="text-blue-900 font-csc65 w-full flex items-center pl-5"
        style={{ gridArea: 'name', fontSize: '0.9375rem' }}
      >
        <div className="truncate">{`${appointment.summary?.registration?.first_name || ''} ${
          appointment.summary?.registration?.last_name || ''
        }`}</div>
        {appointment.summary?.registration?.gender === 'male' ? (
          <MaleIcon className="ml-2.5" />
        ) : (
          <FemaleIcon className="ml-2.5 -mt-1" />
        )}
      </div>
      <div
        className="text-blue-900 pl-5 self-end justify-self-start w-full border-r border-grey-100"
        style={{ gridArea: 'id', fontSize: '0.625rem' }}
      >
        <div className="font-csc65 mr-2 inline-block text-xs">{t('id')}</div>
        <div className="font-csc45 inline-block text-xs">
          {appointment.summary?.registration?.social_security_number || t('ssnUndefined')}
        </div>
      </div>
      <div
        className="flex items-center self-end justify-self-end pr-1"
        style={{ gridArea: 'type' }}
      >
        <div
          style={{ fontSize: '0.625rem', color: statusColor }}
          className="font-csc45 flex justify-center items-center"
        >
          <VideoIcon className="mr-2.5" color={statusColor} />
          {`Video ${appointment?.status}`}
        </div>
      </div>
    </Card>
  )
}

Element.whyDidYouRender = true

export default memo(Element)
