import { memo, useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import LogoIcon from 'assets/icons/logo/Snake'

const LoaderMini = ({ center = false, style = {} }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisible(value => !value)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={visible ? { opacity: 1 } : { opacity: 0 }}
      transition={{
        duration: 1,
      }}
      style={
        center
          ? {
              margin: 'auto',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...style,
            }
          : style
      }
    >
      <LogoIcon />
    </motion.div>
  )
}

LoaderMini.whyDidYouRender = true

export default memo(LoaderMini)
