import { memo, useState, useCallback } from 'react'
import styled from 'styled-components'

import { hp, wp } from 'styles/Sizes'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import DefaultBtn from 'components/common/buttons/Default'
import BackBtn from 'components/common/buttons/Back'
import { useTranslation } from 'services/Translation'

import EmailForm from 'components/auth/reset/EmailForm'
import PasswordForm from 'components/auth/reset/PasswordForm'
import OTPForm from 'components/auth/reset/OTPForm'
import PasswordPopup from 'components/auth/reset/PasswordPopup'

import { ROUTES } from 'Constants'
import { STEPS, Subtitles } from './Tools'
import texts from './Texts'

export const GradientBar = styled.div`
  height: 1px;
  width: ${wp(2.9)}px;
  background-image: linear-gradient(to right, #2e4ce6 1%, #ff488d 99%);
  object-fit: contain;
  border-radius: 0.3px;
  margin: 0;
`

const Reset = ({ history }) => {
  const { t } = useTranslation(texts)
  const [currentStep, setCurrentStep] = useState(STEPS.EMAIL)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const handleStepChange = useCallback(
    newStep => () => {
      if (newStep) setCurrentStep(newStep)
      else history.replace(ROUTES.LOGIN())
    },
    [history]
  )
  const handlePasswordError = useCallback(error => {
    setCurrentStep(STEPS.PASSWORD)
    setPassword('')
    setPasswordError(error)
  }, [])

  return (
    <>
      <Typography text={t('title')} type="title" />
      <Separator size={hp(1.3)} />
      <GradientBar />
      <Separator size={hp(2.3)} />
      {currentStep !== STEPS.OTP ? (
        <Typography text={t(Subtitles[currentStep])} />
      ) : (
        <>
          <Typography style={{ display: 'inline-block' }} text={t(Subtitles[currentStep][0])} />{' '}
          <Typography text={email} bold /> <Typography text={t(Subtitles[currentStep][1])} />
        </>
      )}
      <Separator size={hp(6.5)} />
      {currentStep === STEPS.EMAIL && (
        <EmailForm onStepChange={handleStepChange(STEPS.PASSWORD)} setEmail={setEmail} />
      )}
      {currentStep === STEPS.PASSWORD && (
        <>
          <BackBtn
            style={{
              position: 'fixed',
              top: '50%',
              transform: 'translate(0, -50%)',
              left: wp(5.7),
            }}
            onClick={handleStepChange(STEPS.EMAIL)}
          />
          <PasswordForm
            onStepChange={handleStepChange(STEPS.OTP)}
            setPassword={setPassword}
            email={email}
            extraError={passwordError}
            resetExtraError={() => setPasswordError('')}
          />
          <PasswordPopup />
        </>
      )}
      {currentStep === STEPS.OTP && (
        <OTPForm
          onStepChange={handleStepChange(STEPS.FINAL)}
          password={password}
          username={email}
          onPasswordError={handlePasswordError}
        />
      )}
      {currentStep === STEPS.FINAL && (
        <DefaultBtn
          reversedColors
          label={t('btnLabel')}
          onClick={handleStepChange()}
          style={{ width: '50%', margin: 'auto' }}
        />
      )}
    </>
  )
}

Reset.whyDidYouRender = true

export default memo(Reset)
