import { memo, useState, useCallback, useEffect } from 'react'

import { CS, CS_m } from 'back-end-api'
import useChat from 'hooks/twilio/useChat'
import useVideo, { ROOM_STATUSES } from 'hooks/twilio/useVideo'
import { useMiscDispatch } from 'services/Misc'
import {
  setHangUpAppointment,
  useAudioVisualsState,
  setAppointmentPickedUp,
  setAppointmentSnoozed,
} from 'services/Misc/audioVisuals'

import ReadyAVCall from './ReadyAVCall'
import VideoComp from './VideoComp'
import InfoBox from './InfoBox'
import { Container, VideoGridContainer } from './Styles'
import { containerVariants, infoColumnVariants } from './Animations'

const AVConsult = () => {
  const miscDispatch = useMiscDispatch()
  const audioVisualState = useAudioVisualsState()

  const { data, isValidating } = CS.useRegistrationsList({
    without_old_closed_summaries: 'true',
  })

  const [newAppointmentReady, setNewAppointmentReady] = useState<
    CS_m.ModelsAppointment | undefined
  >(undefined)

  const [videoToken, setVideoToken] = useState('')
  const [videoRoomName, setVideoRoomName] = useState('')

  const { messageList, typing, onTyping, close, sendMessage } = useChat(videoToken, videoRoomName)
  const { tracks, status, setMic, setCam, hangUp, inError } = useVideo(videoToken, videoRoomName)
  const [microphoneActive, setMicrophoneActive] = useState(true)
  const [videoActive, setVideoActive] = useState(true)
  const [generalLoading, setGeneralLoading] = useState(false)
  const [size, setSize] = useState({
    small: false,
    medium: true,
    large: false,
  })
  const [showInfo, setShowInfo] = useState(false)
  const [sidebarContent, setSidebarContent] = useState('')

  const onPickup = useCallback(() => {
    if (!newAppointmentReady) {
      return
    }
    setAppointmentPickedUp(miscDispatch, audioVisualState, newAppointmentReady)
    setNewAppointmentReady(undefined)
  }, [miscDispatch, audioVisualState, newAppointmentReady])

  const onSnooze = useCallback(() => {
    if (!newAppointmentReady) {
      return
    }
    setAppointmentSnoozed(miscDispatch, audioVisualState, newAppointmentReady)
    setNewAppointmentReady(undefined)
  }, [miscDispatch, audioVisualState, newAppointmentReady])

  const handleResize = useCallback((newValue: string) => {
    switch (newValue) {
      case 'small':
        return setSize({ large: false, medium: false, small: true })
      case 'medium':
        return setSize({ large: false, medium: true, small: false })
      case 'large':
        return setSize({ large: true, medium: false, small: false })
    }
    return true
  }, [])

  const handleShowInfo = useCallback(() => setShowInfo(v => !v), [])

  const handleHangUp = useCallback(async () => {
    setMicrophoneActive(true)
    setVideoActive(true)
    setVideoToken('')
    setVideoRoomName('')
    setNewAppointmentReady(undefined)
    setHangUpAppointment(miscDispatch, audioVisualState)

    await CS.updateAppointment({
      id: audioVisualState.pickedUpAppointmentId ?? 0,
      status: CS_m.EndpointsUpdateAppointmentRequestStatus.done,
    })

    await hangUp()
    await close()
  }, [audioVisualState, miscDispatch, close, hangUp])

  useEffect(() => {
    if (status === ROOM_STATUSES.DISCONNECTED) {
      handleHangUp()
    }
  }, [status, handleHangUp])

  const animate = useCallback(() => {
    if (size.medium) {
      return 'visible'
    }

    if (size.small) {
      return 'small'
    }

    return 'large'
  }, [size])

  useEffect(() => {
    if (!isValidating && !audioVisualState.pickedUpAppointmentId) {
      const appointmentReady = CS.getAppointments(CS.getSummaries(data ?? []))
        .filter(a => a.status === CS_m.ModelsAppointmentStatus.ready)
        .find(a => !audioVisualState.appointmentSnoozedIds.some(snoozedId => a.id === snoozedId))

      setNewAppointmentReady(appointmentReady)
    }
  }, [data, isValidating, audioVisualState, miscDispatch])

  useEffect(() => {
    if (audioVisualState.pickedUpAppointmentId && !generalLoading && videoToken === '') {
      setGeneralLoading(true)
      CS.joinAppointmentVideo({ id: audioVisualState.pickedUpAppointmentId }).then(res => {
        if (res.data) {
          setVideoToken(res.data.token)
          setVideoRoomName(res.data.video_room_id)
        }
        setGeneralLoading(false)
      })
    }
  }, [audioVisualState, videoToken, generalLoading])

  useEffect(() => {
    if (inError) {
      console.error('Error in video call')
      handleHangUp()
    }
  }, [inError, handleHangUp])

  if (audioVisualState.pickedUpAppointmentId) {
    return (
      <Container initial="hidden" animate={animate()} variants={containerVariants}>
        <VideoGridContainer showInfo={showInfo} large={size.large} small={size.small}>
          <VideoComp
            status={status}
            tracks={tracks}
            setMic={setMic}
            setCam={setCam}
            hangUp={handleHangUp}
            microphoneActive={microphoneActive}
            setMicrophoneActive={setMicrophoneActive}
            videoActive={videoActive}
            setVideoActive={setVideoActive}
            openMessages={handleShowInfo}
            setSidebarContent={setSidebarContent}
            handleResize={handleResize}
            small={size.small}
            medium={size.medium}
            large={size.large}
            showInfo={showInfo}
            sidebarContent={sidebarContent}
          />
          <InfoBox
            small={size.small}
            large={size.large}
            onCloseInfo={handleShowInfo}
            initial="hidden"
            animate={showInfo && (size.large || size.medium) ? 'visible' : 'hidden'}
            variants={infoColumnVariants}
            sidebarContent={sidebarContent}
            setSidebarContent={setSidebarContent}
            showInfo={showInfo}
            list={messageList.items}
            typing={typing}
            onSubmit={sendMessage}
            onKeyPress={onTyping}
          />
        </VideoGridContainer>
      </Container>
    )
  }

  if (newAppointmentReady) {
    return (
      <Container initial="hidden" animate={animate()} variants={containerVariants}>
        <VideoGridContainer showInfo={showInfo} large={size.large} small={size.small}>
          <ReadyAVCall appointment={newAppointmentReady} onPickup={onPickup} onSnooze={onSnooze} />
        </VideoGridContainer>
      </Container>
    )
  }

  return <></>
}

AVConsult.whyDidYouRender = true

export default memo(AVConsult)
