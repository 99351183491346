import isBefore from 'date-fns/isBefore'

import removeUndefined from 'utils/removeUndefined'
import { findBodyPartParent } from 'components/common/widgets/Dummy/Dummy.tools'
import { getStatus } from '../Tools'

export const getPatientFromSummary = data => {
  const newPatient = {
    id: data.registration?.id,
    dob: data.registration?.date_of_birth,
    firstname: data.registration?.first_name,
    lastname: data.registration?.last_name,
    gender: data.registration?.gender,
    email: data.registration?.email,
    phone: data.registration?.mobile_phone,
    height: data.registration?.height,
    weight: data.registration?.weight,
    username: data.registration?.username,
    ssn: data.registration?.social_security_number,
    summaries: [data.id],
  }
  removeUndefined(newPatient)
  return newPatient
}

const formatAnswers = answers => {
  if (!answers?.length) return []
  const multipleAnswers = answers.filter(
    ({
      question: {
        template,
        content: { multi },
      },
    }) => (template === 'Buttons' && multi) || template === 'Localization'
  )
  multipleAnswers.forEach(answer => {
    if (answer.response?.startsWith('[')) {
      const newResponse = JSON.parse(answer.response).join(', ')
      const aIdx = answers.findIndex(({ id }) => id === answer.id)
      // eslint-disable-next-line no-param-reassign
      answers[aIdx].response = newResponse
    }
  })
  answers.sort((a, b) => (a.id < b.id ? -1 : 1))
  return answers
}

export const formatSummary = (data, account) => {
  if (!data) return {}
  // eslint-disable-next-line no-param-reassign
  data.appointment = data?.appointments?.length
    ? data?.appointments.reduce(
        (acc, newVal) => (acc?.created_at > newVal?.created_at ? acc : newVal),
        data?.appointments[0]
      )
    : data?.appointment
  // eslint-disable-next-line no-nested-ternary
  const lastUpdate = data?.appointment?.updated_at
    ? isBefore(new Date(data?.updated_at), new Date(data?.appointment?.updated_at))
      ? data?.appointment?.updated_at
      : data?.updated_at
    : data?.updated_at
  const { answers } = data
  const questions = []
  answers?.forEach(a => questions.push(a.question))

  const localizationAnswers = answers
    ? answers?.filter(({ question: { template } }) => template === 'Localization')
    : []
  const bodyParts = []

  if (localizationAnswers && localizationAnswers.length) {
    // eslint-disable-next-line no-unused-expressions
    localizationAnswers?.forEach(({ response, question }) => {
      if (response && response.charAt(0) === '[') {
        // Has multiple answers
        const bodyPartsByParent = []
        const responseArray = JSON.parse(response)
        responseArray.forEach(res => {
          const parent = findBodyPartParent(res).name
          if (!bodyPartsByParent[parent]) bodyPartsByParent[parent] = [res]
          else bodyPartsByParent[parent].push(res)
        })
        Object.keys(bodyPartsByParent).forEach(bodyPart => {
          bodyParts.push({
            name: question.text,
            parts: bodyPartsByParent[bodyPart],
          })
        })
      } else if (response) bodyParts.push({ name: question.text, parts: response })
    })
  }
  const summaryAdvice = {
    date: data.advice?.created_at_datetime,
    authorId: data.advice?.author_account_id,
    supervisor: data.advice?.doctor_supervised_account_id,
    read: data.advice?.is_read,
    text: data.advice?.text,
  }

  removeUndefined(summaryAdvice)

  const out = {
    id: data.id,
    accountId: account?.account_id || data.registration?.account_id,
    patientId: data.registration?.id || account?.id,
    registrationId: data.registration?.id,
    appointmentId: data.appointment_id || data.appointment?.id,
    appointmentStatus: data.appointment_status || data?.appointment?.status,
    appointmentIsImmediate: data.is_immediate || data?.appointment?.is_immediate || false,
    initialComplaint: data.complaint?.name,
    name: data.complaint?.name,
    product: data.product,
    statusTime: lastUpdate,
    completeComplaint: {
      originLanguage: data.sumup?.origin_language || data.complaint?.language_tag,
      translations: data.sumup?.translations || { [data.complaint?.language_tag]: '' },
    },
    complaintLang: data.complaint?.language_tag,
    // eslint-disable-next-line camelcase
    complaintId: data.answers?.[0]?.complaint_id,
    otherComplaints: data.complaints || [],
    request: {
      originLanguage: data.patient_comment?.origin_language || data.complaint?.language_tag,
      translations: data.patient_comment?.translations || {
        [data.complaint?.language_tag]: '',
      },
    },
    redFlags: data.red_flags || [],
    bodyParts,
    status: data.status,
    jobStatus: data.job_status,
    diagnosis: {
      diseases: data.diagnosis?.diseases,
      comment: data.diagnosis?.comment,
    },
    doctorEvaluation: data.doctor_evaluation,
    notes: data.assistant_notes || [],
    advice: summaryAdvice,
    answers: formatAnswers(answers),
    questions,
  }
  out.formattedStatus = getStatus(out)
  removeUndefined(out)
  return out
}
