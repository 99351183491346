import styled from 'styled-components'

export const Container = styled(({ type, size, innerRef, ...props }) => (
  <div {...props} ref={innerRef} />
))`
  ${({ type, size }) =>
    type === 'vertical'
      ? `
    height: ${size}px;
    width: 100%;
  `
      : `
    width: ${size}px;
    height: 100%;
  `}
`
