/* eslint-disable no-nested-ternary */

import styled from 'styled-components'

import { error, greyTertiary } from 'styles/Colors'

export const ButtonsContainer = styled.div`
  position: ${({ large }) => large && 'absolute'};
  bottom: ${({ large }) => large && 0};
  left: ${({ large }) => large && '50%'};
  transform: ${({ large }) => large && 'translateX(-50%)'};
  width: 20vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  z-index: 10;
`

export const MinimizeBtn = styled(({ disabled, ...props }) => <div {...props} />)`
  position: absolute;
  right: 0;
  width: auto;
  font-family: 'coresanscr65';
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const SmallBtn = styled(({ disabled, ...props }) => <div {...props} />)`
  background-color: ${greyTertiary};
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  `
      : ''}
`

export const CallBtn = styled(({ ...props }) => <div {...props} />)`
  background-color: ${error};
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`
