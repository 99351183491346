import { formatSummary } from 'services/Summaries/formatter'
import { formatAppointment } from 'services/Appointments/formatter'
import { formatPatient } from 'services/Patients/formatter'

export const formatRegistrations = (rawPatient, fromLive = false) => {
  const patients = []
  const summaries = []
  const appointments = []
  const oldAppointments = []

  if (!rawPatient?.length) return { patients, summaries, appointments, oldAppointments }

  rawPatient.forEach(patient => {
    if (patient?.summary) {
      if (patient?.summary?.appointment) {
        appointments.push(formatAppointment(patient?.summary?.appointment))
      }
      summaries.push(formatSummary(patient?.summary, patient))
    }
    if (patient?.summaries && patient?.summaries?.length) {
      patient.summaries.forEach(s => {
        if (s.appointment) appointments.push(formatAppointment(s.appointment))
        if (s.appointments) {
          s.appointments.forEach(appoint => oldAppointments.push(formatAppointment(appoint)))
        }
        summaries.push(formatSummary(s, patient))
      })
    }
    patients.push(fromLive ? { fromLive: true, ...formatPatient(patient) } : formatPatient(patient))
  })

  return { patients, summaries, appointments, oldAppointments }
}
