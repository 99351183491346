import { memo, useCallback, useState, useRef } from 'react'
import VideoDoctorIcon from 'assets/icons/VideoDoctor'
import DoubleTick from 'assets/icons/DoubleTick'
import Bell from 'assets/icons/Bell'
import ClinicIcon from 'assets/icons/ClinicDoctor'
import { CS, CS_m } from 'back-end-api'
import { useTranslation } from 'services/Translation'
import UnderlineBtn from 'components/common/buttons/Underline'
import { purple, primary, green } from 'styles/Colors'
import FloatingCard from 'components/common/Card/Floating'
import OptionButton from 'components/common/buttons/Option'
import { usePatientRouteParams } from 'components/patient/Parameters'
import AdviceForm from './AdviceForm'
import AppointmentSchedule from './AppointmentSchedule'
import Urgent from './Urgent'
import Referral from './Referral'
import Modal from './Modal'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    avTooltip: getText('AV Call', 'Patient_Details_Actions'),
    adviceTooltip: getText('Advice', 'Patient_Details_Actions'),
    referralTooltip: getText('Referral', 'Patient_Details_Actions'),
  }[key])

function getOffset(el: any) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  }
}

enum SideBar {
  advice,
  scheduleAv,
  directAv,
  referral,
}

const Actions = () => {
  const { registration, summary, appointment } = usePatientRouteParams()

  const { t } = useTranslation(texts)
  const AvButtonRef = useRef<any>(null)
  const [AvFloatingCard, setAvFloatingCard] = useState(false)
  const [sideBar, setSideBar] = useState<SideBar | null>(null)

  const setSidebar = useCallback(
    (activeSideBar: SideBar | null) => () => {
      if (activeSideBar !== null) {
        setAvFloatingCard(false)
      }
      setSideBar(activeSideBar)
    },
    [setSideBar, setAvFloatingCard]
  )

  return (
    <>
      <div className="flex items-center justify-left">
        {summary?.product === CS_m.ModelsSummaryProduct.videoCall && (
          <div className="flex items-center justify-center align-center cursor-pointer pr-12 border-r border-grey-100">
            <UnderlineBtn
              disabled={CS.isSummaryStatusFinal(summary) || appointment !== null}
              innerRef={AvButtonRef}
              withNext
              bold
              key="avButton"
              onClick={() => {
                setAvFloatingCard(v => !v)
              }}
              previousIcon={
                !appointment ? (
                  <VideoDoctorIcon className={undefined} />
                ) : (
                  <DoubleTick color={purple} />
                )
              }
              label={t('avTooltip')}
              color={purple}
              nextIcon={undefined}
              style={undefined}
            />
            <FloatingCard
              style={{ padding: '10px 20px' }}
              active={AvFloatingCard}
              top={
                AvButtonRef.current &&
                getOffset(AvButtonRef.current).top + AvButtonRef.current.offsetHeight - 15
              }
              left={
                AvButtonRef.current &&
                getOffset(AvButtonRef.current).left + AvButtonRef.current.offsetWidth
              }
              arrowPos="right"
              setActive={() => {
                setAvFloatingCard(false)
              }}
              paddingHorizontal={undefined}
              paddingVetical={undefined}
            >
              <OptionButton
                onClick={setSidebar(SideBar.directAv)}
                label="Direct AV Call"
                previousIcon={undefined}
                nextIcon={undefined}
                style={undefined}
              />
              <OptionButton
                onClick={setSidebar(SideBar.scheduleAv)}
                label="Schedule AV Call"
                previousIcon={undefined}
                nextIcon={undefined}
                style={undefined}
              />
            </FloatingCard>
          </div>
        )}

        <div className="flex items-center justify-center align-center cursor-pointer px-12 border-r border-grey-100">
          <UnderlineBtn
            disabled={CS.isSummaryStatusFinal(summary) && summary?.advice?.text === undefined}
            withNext
            bold
            key="advice"
            onClick={setSidebar(SideBar.advice)}
            previousIcon={
              !summary?.advice?.text ? (
                <ClinicIcon className={undefined} />
              ) : (
                <DoubleTick color={primary} />
              )
            }
            label={t('adviceTooltip')}
            color={primary}
            innerRef={undefined}
            nextIcon={undefined}
            style={undefined}
          />
        </div>

        <div className="flex items-center justify-center align-center cursor-pointer px-12 border-r border-grey-100">
          <UnderlineBtn
            disabled={CS.isSummaryStatusFinal(summary) && summary?.referral?.url === undefined}
            withNext
            bold
            key="referral"
            onClick={setSidebar(SideBar.referral)}
            previousIcon={
              !summary?.referral?.url ? <Bell color={green} /> : <DoubleTick color={green} />
            }
            label={t('referralTooltip')}
            color={green}
            innerRef={undefined}
            nextIcon={undefined}
            style={undefined}
          />
        </div>
      </div>

      <Modal visible={sideBar !== null}>
        {(() => {
          switch (sideBar) {
            case SideBar.advice:
              return <AdviceForm onClose={setSidebar(null)} registration={registration} />
            case SideBar.referral:
              return <Referral onClose={setSidebar(null)} />
            case SideBar.scheduleAv:
              return <AppointmentSchedule summaryId={summary?.id} onClose={setSidebar(null)} />
            case SideBar.directAv:
              return <Urgent summaryId={summary?.id} onClose={setSidebar(null)} />
            default:
              console.error('Unhandled Diagnosis actions sideBar', sideBar)
              return null
          }
        })()}
      </Modal>
    </>
  )
}

Actions.whyDidYouRender = true

export default memo(Actions)
