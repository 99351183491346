import { memo } from 'react'
import Typography from 'components/common/Typography'
import { useTranslation } from 'services/Translation'
import { textPrimary } from 'styles/Colors'
import Status from '../../Summary/Appointment/Status'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    title: getText('Ongoing actions', 'Patient_Details_Actions'),
    subtitle: getText('You have selected this below :', 'Patient_Details_Actions'),
  }[key])

const PendingRequest = () => {
  const { t } = useTranslation(texts)

  return (
    <div className="h-96 overflow-y-auto">
      <div className="font-csc65 flex flex-col text-lg py-3 mb-3">
        {t('title')}
        <Typography
          className="mt-6 font-csc45"
          text={t('subtitle')}
          color={textPrimary}
          size={15}
          style={undefined}
        />
        <div className="py-6 w-full border-b border-grey-100">
          <Status />
        </div>
      </div>
    </div>
  )
}

PendingRequest.whyDidYouRender = true

export default memo(PendingRequest)
