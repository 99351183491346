import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import InputMask from 'react-input-mask'
import { motion } from 'framer-motion'

import { textPlaceholder, primary, transparency } from 'styles/Colors'
import { hp, wp } from 'styles/Sizes'

export const Container = styled(({ noPadding, withCard, ...props }) => <div {...props} />)`
  height: auto;
  width: 100%;
  padding-top: ${({ noPadding }) => (noPadding ? '0' : `${20 * 0.8}px`)};
  position: relative;
  border-radius: 15px;
  box-shadow: ${({ withCard }) =>
    withCard ? `3px 6px 9px 0px ${primary + transparency[18]}` : 'none'};
`
export const InputContainer = styled(({ noBorder, noPadding, multiline, ...props }) => (
  <div {...props} />
))`
  height: ${({ multiline }) => (multiline ? 'auto' : `${(hp(4.6) + 4) * 0.8}px`)};
  width: 100%;
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : `1px solid ${textPlaceholder}`)};
  padding: ${({ noPadding }) => (noPadding ? '0' : `0 ${wp(0.9) * 0.8}px`)};
  display: flex;
`
export const Input = styled(
  ({ fullWidth, multiline, innerRef, rtl, withMask, placeholderStyle, ...props }) =>
    multiline ? (
      <TextareaAutosize async {...props} ref={innerRef} dir={rtl ? 'rtl' : 'ltr'} />
    ) : withMask ? (
      <InputMask {...props} inputRef={innerRef} dir={rtl ? 'rtl' : 'ltr'} />
    ) : (
      <input {...props} ref={innerRef} dir={rtl ? 'rtl' : 'ltr'} />
    )
)`
  background: transparent;
  resize: none;
  border: none;
  height: ${({ multiline }) => (multiline ? 'unset' : `${hp(4.6) * 0.8}px`)};
  width: 100%;
  font-size: 0.937rems;
  line-height: 1.25rem;
  &::placeholder {
    color: ${textPlaceholder};
    font-size: ${({ placeholderStyle }) =>
      placeholderStyle ? placeholderStyle.fontSize : '0.937rem'};
    line-height: 1.25rem;
  }
`
export const EyeContainer = styled.div`
  margin-left: ${6 * 0.8}px;
  height: ${hp(4.6) * 0.8}px;
  width: ${hp(4.6) * 0.8}px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Label = styled(motion.div)`
  position: absolute;
  left: ${wp(0.9) * 0.8}px;
  pointer-events: none;
`
