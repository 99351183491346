import ReactDOM from 'react-dom'
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components'
import './styles/tailwind.css'
import App from './App'

ReactDOM.render(
  <FluentProvider theme={teamsLightTheme}>
    <App />,
  </FluentProvider>,
  document.getElementById('root')
)
