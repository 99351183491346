export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.055"
    height="19.548"
    viewBox="0 0 20.055 19.548"
  >
    <g id="Group_899" data-name="Group 899" transform="translate(-1262 -870.729)">
      <path
        id="Path_323"
        data-name="Path 323"
        d="M975-18750v-4.965h5.443"
        transform="translate(288 19626.895)"
        fill="none"
        stroke="#1c165b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_326"
        data-name="Path 326"
        d="M0,4.965V0H5.443"
        transform="translate(1281.055 871.729) rotate(90)"
        fill="none"
        stroke="#1c165b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_324"
        data-name="Path 324"
        d="M0,4.965V0H5.443"
        transform="translate(1263.24 889.277) rotate(-90)"
        fill="none"
        stroke="#1c165b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_325"
        data-name="Path 325"
        d="M0,4.965V0H5.443"
        transform="translate(1281.055 889.277) rotate(180)"
        fill="none"
        stroke="#1c165b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)
