import { memo, useState } from 'react'
import { motion } from 'framer-motion'

import Unfold from 'assets/icons/Unfold'
import ArrowIcon from 'assets/icons/arrows/Small'
import { useTranslation } from 'services/Translation'

import differenceInYears from 'date-fns/differenceInYears'
import Field from './Field'
import texts from './Texts'

interface HeaderProps {
  patient: any
}

const Header = ({ patient }: HeaderProps) => {
  const { t, formatDate: format } = useTranslation(texts)
  const [open, setOpen] = useState(false)

  if (!patient) {
    return null
  }

  return (
    <>
      <motion.div
        className="w-full bg-background z-3 overflow-hidden"
        animate={{
          left: 0,
          top: 0,
          height: 0,
          paddingBottom: open ? 236 + 92 : 138 + 92,
        }}
        transition={{
          height: { ease: 'easeOut', duration: 0.25 },
          top: { ease: 'easeOut', duration: 0.25 },
        }}
        style={{ zIndex: 2 }}
      >
        <div
          className="relative w-full bg-background z-1 flex items-stretch justify-between text-blue-900 "
          style={{ zIndex: 1, paddingLeft: '7.5rem', paddingRight: '7.5rem' }}
        >
          <div className="border-r border-grey-100 flex flex-col flex-1 font-csc45">
            <div
              className="font-csc65 text-lg border-b border-grey-100 pb-3 mb-3"
              style={{ marginRight: '4.625rem' }}
            >
              {t('details')}
            </div>
            <Field value={`${patient?.firstname || ''} ${patient?.lastname || ''}`} />
            <Field value={patient?.gender === 'male' ? t('male') : t('female')} />
            <Field
              value={`${format(new Date(patient?.dob), 'dd - MM - yyyy')} (${differenceInYears(
                new Date(),
                new Date(patient?.dob)
              )} ${t('years')})`}
            />
            <Field value={patient?.ssn} />
            <Field value={patient?.phone} />
            <Field value={patient?.email} />
          </div>
          <motion.div
            className="w-full absolute left-0 bg-background z-3"
            animate={{ height: open ? 66 : 77, bottom: open ? -67 : 0 }}
            transition={{
              height: { ease: 'easeOut', duration: 0.25 },
              bottom: { ease: 'easeOut', duration: 0.25 },
            }}
          />
        </div>
      </motion.div>
      <div
        className="relative w-full cursor-pointer"
        onClick={() => setOpen(v => !v)}
        tabIndex={0}
        role="button"
        onKeyDown={() => setOpen(v => !v)}
      >
        <Unfold
          style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%) rotate(180deg)',
            left: '50%',
            top: 0,
            zIndex: 1,
          }}
          className={undefined}
        />
        <ArrowIcon
          style={{
            position: 'absolute',
            transform: `translate(-50%) ${open ? 'rotate(180deg)' : 'rotate(0deg)'}`,
            transition: 'all 250ms linear',
            left: '50%',
            top: 4,
            zIndex: 1,
          }}
          color="#1C165B"
        />
        <div
          className="absolute h-6 -mt-px bottom-12 bg-transparent left-2/4 transition"
          style={{
            boxShadow: 'rgb(47 76 203 / 10%) 0px 30px 30px 30px',
            transform: 'translate(-50%)',
            borderRadius: '100%',
            width: '55%',
            zIndex: -1,
          }}
        />
      </div>
    </>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
