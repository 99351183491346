import { getAll, create, deleteTimeOff, getUnavailabilities } from './queries'
import { TimeOffProvider, useTimeOffDispatch, useTimeOffState } from './context'
import { formatTimeOff } from './formatter'

export {
  TimeOffProvider,
  useTimeOffState,
  useTimeOffDispatch,
  getAll,
  create,
  deleteTimeOff,
  getUnavailabilities,
  formatTimeOff,
}

export const wholeDayDuration_ns = 24 * 60 * 60 * 1000000000
