export default getText => key =>
  ({
    title: getText(
      'Please choose an ICPC code to finish the consultation',
      'Patient_Details_Tabs_Diagnosis_Selector'
    ),
    searchInputPlaceholder: getText(
      'Search an ICPC code here',
      'Patient_Details_Tabs_Diagnosis_Selector'
    ),
    addBtn: getText('Add', 'Patient_Details_Tabs_Diagnosis_Selector'),
    twiceIcpcs: getText(
      'Cannot have twice the same code',
      'Patient_Details_Tabs_Diagnosis_Selector'
    ),
  }[key])
