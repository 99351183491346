import { textPlaceholder, textPrimary } from 'styles/Colors'
import { hp } from 'styles/Sizes'

export const labelVariants = {
  small: {
    top: 0,
    fontSize: 14 * 0.8,
    color: textPrimary,
    opacity: 1,
  },
  large: {
    fontSize: 18 * 0.8,
    color: textPlaceholder,
    top: hp(3) * 0.8,
    opacity: 0.8,
  },
}
