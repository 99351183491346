/* eslint-disable no-undef */
import { useState, useEffect } from 'react'

export default () => {
  const [tabAlreadyOpen, setTabAlreadyOpen] = useState(false)

  useEffect(() => {
    localStorage.openpages = Date.now()
    const onLocalStorageEvent = ({ key }) => {
      if (key === 'openpages') {
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now()
      }
      if (key === 'page_available') {
        setTabAlreadyOpen(true)
      }
    }
    window.addEventListener('storage', onLocalStorageEvent, false)
  }, [])
  return tabAlreadyOpen
}
