import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'services/Translation'
import InputText from 'components/common/inputs/Text'
import { error } from 'styles/Colors'
import Typography from 'components/common/Typography'
import styled from 'styled-components'
import { usePatientRouteParams } from 'components/patient/Parameters'
import { CS, downloadPicture } from 'back-end-api'
import LoaderMini from 'components/common/loaders/mini'

import Appointment from './Appointment'

const Container = styled.div`
  grid-area: text;
  position: relative;
  overflow: auto;
`

const FormContainer = styled.div``

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    title: getText('Topic', 'Patient_Details_Tabs_Summary_Form'),
    emergencyTitle: getText('Potential Emergency', 'Patient_Details_Tabs_Summary_Form'),
    topicLabel: getText('Initial Topic : ', 'Patient_Details_Tabs_Summary_Form'),
    requestLabel: getText('Patient Requests', 'Patient_Details_Tabs_Summary_Form'),
    editBtnOn: getText('Done', 'Patient_Details_Tabs_Summary_Form'),
    editBtnOff: getText('Edit', 'Patient_Details_Tabs_Summary_Form'),
    copyBtnOn: getText('Copied!', 'Patient_Details_Tabs_Summary_Form'),
    copyBtnOff: getText('Copy summary', 'Patient_Details_Tabs_Summary_Form'),
    rawInfo: getText('Raw information', 'Patient_Details_Tabs_Summary_Form'),
    oneSkipped: getText('skipped question', 'Patient_Details_Tabs_Summary_Form'),
    multiSkipped: getText('skipped questions', 'Patient_Details_Tabs_Summary_Form'),
    redFlags: getText('Red Flags', 'Patient_Details_Tabs_Summary_Form'),
    otherComplaints: getText('Other Complaints', 'Patient_Details_Tabs_Summary_Form'),
  }[key])

const Summary = () => {
  const { t, lang } = useTranslation(texts)
  const { summary } = usePatientRouteParams()
  const [picture, setPicture] = useState<string | null>(null)
  const [loadingPicture, setLoadingPicture] = useState<boolean>(false)

  useEffect(() => {
    const fetchAndDecodeImage = async () => {
      if (summary?.picture_url) {
        setLoadingPicture(true)
        const image = await downloadPicture(summary.picture_url)
        setPicture(image)
        setLoadingPicture(false)
      }
    }

    fetchAndDecodeImage()
  }, [summary?.picture_url])

  const translatedSumup = CS.getSummarySumupTranslateIfNone(summary, lang) ?? ''

  const [editing, setEditing] = useState(false)
  const [updatedSumup, setUpdatedSumup] = useState(translatedSumup)

  const onEdit = useCallback(async () => {
    if (editing && updatedSumup !== summary?.sumup) {
      await CS.updateSummary({ id: summary!.id, sumup: { text: updatedSumup, language_tag: lang } })
    }
    setEditing(!editing)
  }, [editing, lang, summary, updatedSumup])

  const updateSumup = useCallback(({ target: { value } }: any) => {
    setUpdatedSumup(value)
  }, [])

  if (!summary) return <LoaderMini center />

  return (
    <Container className="h-96 overflow-y-auto">
      <Appointment onEdit={onEdit} editing={editing} />
      <FormContainer>
        <div className="font-csc65 flex flex-col text-lg py-3 mb-3">
          {t('title')}
          <InputText
            className="mt-3 text-blue-500"
            field={{
              value: summary.complaint?.name,
            }}
            noBorder
            noPadding
            containerStyle={{ flex: 1 }}
            readOnly
            inputStyle={undefined}
            style={undefined}
            label={undefined}
            inputRef={undefined}
            placeholderStyle={undefined}
          />
        </div>
        <div className="font-csc45 pb-3 mb-3 leading-7">
          <InputText
            field={{
              onChange: updateSumup,
              value: updatedSumup ?? '',
            }}
            inputStyle={{ lineHeight: '25px', fontSize: '15px' }}
            multiline
            noBorder
            noPadding
            readOnly={!editing}
            data-cy="sumupInput"
            style={undefined}
            label={undefined}
            inputRef={undefined}
            containerStyle={undefined}
            placeholderStyle={undefined}
          />
        </div>
        <div className="flex flex-col font-csc65 text-lg pb-3 mb-3">
          {t('redFlags')}
          <Typography
            className="mt-3 font-csc45"
            text={summary.red_flags?.join(', ')}
            color={error}
            size={15}
          />
        </div>
        {summary.patient_comment && (
          <div className="font-csc65 text-lg pb-3 mb-3">
            {t('otherComplaints')}
            <InputText
              className="font-csc45 text-xs pb-3 mb-3 leading-7"
              field={{
                value: CS.getSummaryPatientCommentTranslateIfNone(summary, lang),
              }}
              noBorder
              noPadding
              inputStyle={{ lineHeight: '25px', fontSize: '15px' }}
              multiline
              readOnly
              rows={1}
              style={undefined}
              label={undefined}
              inputRef={undefined}
              containerStyle={undefined}
              placeholderStyle={undefined}
            />
          </div>
        )}
        {picture ? (
          <img src={picture} alt="" width="300" height="400" />
        ) : loadingPicture ? (
          <LoaderMini center />
        ) : null}
      </FormContainer>
    </Container>
  )
}

Summary.whyDidYouRender = true

export default memo(Summary)
