import { forwardRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { primary } from 'styles/Colors'

export const Container = styled(
  forwardRef(({ withMark, clickable, padding, markColor, isAnimated, ...props }, ref) =>
    isAnimated ? <motion.div {...props} ref={ref} /> : <div {...props} ref={ref} />
  )
)`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'normal')};
  pointer-events: all;
  ${({ withMark, markColor }) =>
    withMark
      ? `
    &:before {
      position: absolute;
      content: ' ';
      top: 0;
      left: 0.875rem;
      width: 5rem;
      height: 3px;
      background-color: ${markColor || primary};
      border-radius: 3px;
    }
  `
      : ''}
`
export const Title = styled.div`
  font-weight: bold;
  font-size: ${18 * 0.8}px;
  margin-bottom: ${8 * 0.8}%;
`
