import styled from 'styled-components'

export const NextIcon = styled.div`
  margin-left: ${17 * 0.8}%;
  margin-top: 2px;
  transform: scale(0.8);
`
export const Label = styled(({ mini, size, ...props }) => <div {...props} />)`
  font-size: ${({ mini, size }) => (mini ? `${12 * 0.8}px` : size)};
`
