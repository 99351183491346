import { memo } from 'react'

import { CS } from 'back-end-api'
import { useTranslation } from 'services/Translation'
import Typography from 'components/common/Typography'
import GenderIcon from 'assets/icons/gender/Gender'
import ThermometerIcon from 'assets/icons/Thermometer'
import IdIcon from 'assets/icons/Id'
import { error } from 'styles/Colors'
import { useAudioVisualsState } from 'services/Misc/audioVisuals'

const PatientInfo = () => {
  const { t } = useTranslation()
  const audioVisualState = useAudioVisualsState()

  const appointment = CS.useAppointment(audioVisualState?.pickedUpAppointmentId ?? 0)
  const summary = appointment?.summary
  const registration = summary?.registration

  return (
    <>
      <div className="flex flex-col py-3">
        <div className="flex justify-start items-center py-3">
          <IdIcon className="mr-2.5" />
          <Typography
            style={{ minHeight: '14px' }}
            size={14}
            className="font-csc45 pr-3"
            text={`${registration?.first_name} ${registration?.last_name}`}
          />
        </div>
        <div className="flex justify-start items-center py-3">
          <IdIcon className="mr-2.5" />
          <Typography
            style={{ minHeight: '14px' }}
            size={14}
            className="font-csc45 pr-3"
            text={registration?.email}
          />
        </div>
        <div className="flex justify-start items-center py-3">
          <IdIcon className="mr-2.5" />
          <Typography
            style={{ minHeight: '14px' }}
            size={14}
            className="font-csc45 pr-3"
            text={registration?.mobile_phone}
          />
        </div>
        <div className="flex justify-start items-center py-3">
          <GenderIcon gender={registration?.gender} size={14} style={undefined} />
          <Typography
            style={{ minHeight: '14px' }}
            size={14}
            className="font-csc45 pr-3 ml-2.5"
            text={registration?.gender}
          />
        </div>
        <div className="flex justify-start items-center py-3">
          <ThermometerIcon className="mr-2.5" />
          <Typography
            style={{ minHeight: '14px' }}
            size={14}
            className="font-csc45 pr-3"
            text={registration?.date_of_birth}
          />
        </div>
        <div className="flex justify-star items-center py-3">
          <IdIcon className="mr-2.5" />
          <Typography
            style={{ minHeight: '14px' }}
            size={14}
            className="font-csc45 pr-3"
            text={registration?.id?.toString()}
          />
        </div>
      </div>
      <div className="py-3 border-b border-grey-100 font-csc65 text-lg w-full">
        {t('infoTitle2')}
      </div>
      <div className="flex flex-col justify-start items-start py-6">
        <Typography
          style={{ minHeight: '14px' }}
          size={15}
          bold
          className="font-csc65 pb-6"
          text="Topic"
        />
        <Typography
          style={{ minHeight: '14px' }}
          size={15}
          color={error}
          className="font-csc45"
          text={summary?.complaint?.name ?? 'Medical question'}
        />
      </div>
      <div className="flex flex-col justify-start items-start pb-6">
        <Typography
          style={{ minHeight: '14px' }}
          size={15}
          bold
          className="font-csc65 pb-6"
          text="Red Flag"
        />
        <Typography
          style={{ minHeight: '14px' }}
          size={15}
          className="font-csc45"
          text={(summary?.red_flags?.length ?? 0) > 0 ? summary!.red_flags!.join(', ') : 'None'}
        />
      </div>
    </>
  )
}

PatientInfo.whyDidYouRender = true

export default memo(PatientInfo)
