import { memo, useCallback, useMemo } from 'react'
import isSameDay from 'date-fns/isSameDay'

import { useTranslation } from 'services/Translation'
import PaginateIcon from 'assets/icons/arrows/Paginate'

import texts from './Texts'

const Header = ({ date, setDate }) => {
  const { formatDate } = useTranslation(texts)
  const isToday = useMemo(() => isSameDay(date, new Date()), [date])

  const changeDay = useCallback(
    direction => () => {
      setDate(direction)
    },
    [setDate]
  )

  return (
    <div className="font-csc65 text-blue-900 text-xs px-16 h-16 bg-background z-20 relative flex items-center justify-start">
      <div className="mr-6" style={{ fontSize: '15px' }}>
        {formatDate(date, 'MMMM yyyy')}
      </div>
      <div className="flex items-center border-l border-grey-100 h-4">
        <div
          className={`bg-background-white transition p-2 rounded-full mx-5 ${
            !isToday ? 'hover:bg-grey-100 cursor-pointer' : ''
          }`}
          onClick={changeDay(-1)}
          tabIndex={0}
          role="button"
          onKeyDown={changeDay(-1)}
        >
          <PaginateIcon
            color={isToday ? '#A4A4A4' : undefined}
            style={{ transform: 'rotate(180deg)' }}
          />
        </div>
        <div
          className="hover:bg-grey-100 bg-background-white transition p-2 rounded-full cursor-pointer"
          onClick={changeDay(1)}
          tabIndex={0}
          role="button"
          onKeyDown={changeDay(1)}
        >
          <PaginateIcon />
        </div>
      </div>
    </div>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
