import { memo } from 'react'

import { useTranslation } from 'services/Translation'
import DoubleTickIcon from 'assets/icons/DoubleTick'
import useClock from 'hooks/useClock'
import { formatUpdates } from 'components/home/Sidebar/LiveUpdates/List/Tools'
import List from 'components/common/List'
import Element from 'components/layouts/DashboardLayout/Sidebar/Notifications/Card/Element'
import texts from './Texts'

const Notifications = ({ data }) => {
  const { t, formatDate: format } = useTranslation(texts)
  const currentDate = useClock()
  const formattedUpdates = formatUpdates(data, currentDate, t, format)

  return (
    <div className="flex flex-col mt-8 pt-8">
      <div className="flex justify-between items-center h-12 px-16">
        <div className="font-csc65 text-lg text-blue-900">{t('title')}</div>
        <div className="flex items-center font-csc55 text-xs text-purple-200 cursor-pointer">
          <DoubleTickIcon className="mr-5" />
          {t('markAsRead')}
        </div>
      </div>
      <List
        containerClassName="flex-1"
        className="px-16"
        initialBotShadow={!!formattedUpdates && formattedUpdates?.length > 3}
      >
        {!!formattedUpdates?.length &&
          [...formattedUpdates]?.map((el, idx) => (
            <Element
              key={idx}
              type={el.type}
              link={el.link}
              name={el.name}
              action={el.action}
              time={el.time}
              fullTime={el.fullTime}
            />
          ))}
      </List>
    </div>
  )
}

Notifications.whyDidYouRender = true

export default memo(Notifications)
