import { useEffect, useState } from 'react'

export default (timeout = 60000) => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date())
    }, timeout)
    return () => clearInterval(intervalId)
  }, [timeout])

  return currentDate
}
