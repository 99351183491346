import { memo } from 'react'

import { Container } from './Styles'

const Card = ({
  style = {},
  children,
  innerRef,
  withMark,
  markColor,
  padding = 0,
  onClick = () => {},
  isAnimated = false,
  animate,
  variants,
  initial,
  className,
  ...props
}) => {
  return (
    <Container
      className={`rounded-xl relative bg-background-white border border-grey-100 ${className}`}
      style={style}
      ref={innerRef}
      withMark={withMark}
      markColor={markColor}
      padding={padding}
      onClick={onClick}
      clickable={!!onClick}
      isAnimated={isAnimated}
      animate={animate}
      variants={variants}
      initial={initial}
      {...props}
    >
      {children}
    </Container>
  )
}

Card.whyDidYouRender = true

export default memo(Card)
