import { memo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'services/Translation'
import LoaderView from 'components/common/loaders/LoaderView'
import { ModalContainer, ModalSucceeded } from '../Common'
import Select from './Select'
import Confirm from './Confirm'
import { TimeWindow, Step } from './Types'

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 80%;
  padding-bottom: 24px;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

interface AppointmentScheduleProps {
  onClose: () => void
  summaryId: number | undefined
}

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    btnLabelClose: getText('Close', 'Patient_Details_Modal_Scheduled'),
    succeededLabel: getText('Appointment request sent', 'Patient_Details_Modal_Scheduled'),
  }[key])

const AppointmentSchedule = ({ onClose, summaryId }: AppointmentScheduleProps) => {
  const { t } = useTranslation(texts)
  const [step, setStep] = useState<Step>(Step.TimeWindowSelection)
  const [notes, setNotes] = useState<string>('')
  const [duration, setDuration] = useState<number>(10)
  const [validTimeWindow, setValidTimeWindow] = useState<TimeWindow>({
    start: null,
    end: null,
  })

  switch (step) {
    case Step.InProgress:
      return <LoaderView onClose={onClose} />
    case Step.Success:
      return (
        <ModalSucceeded
          label={t('succeededLabel')}
          containerProps={{ label: t('btnLabelClose'), onClose }}
        />
      )
    case Step.TimeWindowSelection:
      return (
        <ModalContainer onClose={onClose} label={t('btnLabelClose')}>
          <Select
            notes={notes}
            setNotes={setNotes}
            validTimeWindow={validTimeWindow}
            setValidTimeWindow={setValidTimeWindow}
            duration={duration}
            setDuration={setDuration}
            setStep={setStep}
          />
        </ModalContainer>
      )
    case Step.AppointmentCreation:
      return (
        <ModalContainer onClose={onClose} label={t('btnLabelClose')}>
          <Confirm
            notes={notes}
            validTimeWindow={validTimeWindow}
            duration={duration}
            summaryId={summaryId}
            setStep={setStep}
          />
        </ModalContainer>
      )
    default:
      console.error('Unhandled Appointment schedule step: ', step)
      return null
  }
}

AppointmentSchedule.whyDidYouRender = true

export default memo(AppointmentSchedule)
