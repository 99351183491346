export const imageVariants = {
  enter: direction => ({
    x: direction > 0 ? 500 : -500,
    opacity: 0,
    y: '-50%',
  }),
  center: {
    zIndex: 0,
    x: 0,
    opacity: 1,
    y: '-50%',
  },
  exit: direction => ({
    zIndex: 0,
    x: direction < 0 ? 500 : -500,
    opacity: 0,
    y: '-50%',
  }),
}
