import { motion } from 'framer-motion'
import styled from 'styled-components'

import { hp } from 'styles/Sizes'
import { primary, transparency, textPrimary, purple } from 'styles/Colors'

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
`

export const SearchContainer = styled.div`
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //padding: 1px 4px 1px 16px;
  //background-color: white;
  //border-radius: 50px;
`
export const SubmitBtn = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  &:hover {
    opacity: 0.8;
  }
`
export const Input = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  width: 100%;
  border-radius: 50px;
  box-shadow: 3px 6px 18px ${primary + transparency[10]};
  background-color: white;
  padding: 0 7% 0 0;
`
export const Icon = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6%;
  padding-left: 7%;
`
export const CodesList = styled(motion.div)`
  position: relative;
  max-height: ${hp(20)}px;
  background-color: white;
  box-shadow: 3px 6px 18px 0 ${primary + transparency[10]};
  border-radius: 15px;
  overflow: auto;
  padding: 10px;
`
export const CodeElem = styled(({ selected, ...props }) => <div {...props} />)`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  ${({ selected }) =>
    selected &&
    `
    background-color: ${purple + transparency[10]};
  `}
  &:hover {
    background-color: ${purple + transparency[10]};
  }
`
export const CodeLabel = styled.div`
  font-family: coresanscr45;
  font-size: 14px;
  color: ${textPrimary};
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`
