import { useEffect } from 'react'
import { diff } from 'deep-object-diff'
import { useHistory } from 'react-router-dom'

import { useMiscState, useMiscDispatch } from 'services/Misc'
import { getClaims } from 'services/Authentication'
import { usePatientsState } from 'services/Patients'
import { useSummariesState } from 'services/Summaries'
import { useAppointmentsState } from 'services/Appointments'
import { useTranslation } from 'services/Translation'
import usePrevious from 'hooks/usePrevious'

import { ROUTES, STATUS } from 'Constants'
import texts from './Texts'

export const useRefresh = ({ setActive, setTitle, setBody, setPatientUrl, resetNotif }) => {
  const history = useHistory()
  const { t } = useTranslation(texts)
  const { list: patients } = usePatientsState()
  const { list: summaries } = useSummariesState()
  const { list: appointments } = useAppointmentsState()
  const claims = getClaims()
  const { notifSent } = useMiscState()
  const miscDispatch = useMiscDispatch()
  const previousSummaries = usePrevious(summaries)

  // Disable notif when going to the patient url
  useEffect(
    () =>
      history.listen(location => {
        if (
          location.pathname.includes('patient') &&
          notifSent &&
          !!Object.keys(notifSent).find(n => n === location.pathname.split('/')[3])
        ) {
          resetNotif()
        }
      }),
    [resetNotif, notifSent, history]
  )

  // Appointments
  useEffect(() => {
    appointments.forEach(({ id, status }) => {
      if (status === 'ready') {
        const patient = patients.find(
          p => p.id === summaries.find(s => s.appointmentId === id)?.patientId
        )
        const notifId = `${patient?.id}-${id}`
        if (patient && !notifSent?.[notifId]) {
          setTitle(t('patientReadyAVTitle'))
          setBody(
            `${t('patientReadyAVBody1')} ${patient.firstname} ${patient.lastname} ${t(
              'patientReadyAVBody2'
            )}`
          )
          setPatientUrl(`${ROUTES.PATIENT}${patient.id}`)
          setActive(true)
          miscDispatch({
            type: 'notifSent',
            payload: notifId,
          })
        }
      }
    })
  }, [
    patients,
    summaries,
    appointments,
    setTitle,
    setBody,
    setPatientUrl,
    t,
    claims,
    setActive,
    miscDispatch,
    notifSent,
  ])

  // New summary
  useEffect(() => {
    if (!!previousSummaries && Object.keys(diff(summaries, previousSummaries)).length) {
      summaries.forEach(s => {
        const patient = patients.find(p => p.id === s.patientId)
        if (s.status === STATUS.submitted.value) {
          const notifId = `${patient?.id}`

          if (patient && !notifSent?.[notifId]) {
            setTitle(t('patientNewSummaryTitle'))
            setBody(
              `${t('patientNewSummaryBody1')} ${patient.firstname} ${patient.lastname} ${t(
                'patientNewSummaryBody2'
              )} ${s.name}`
            )
            setPatientUrl(`${ROUTES.PATIENT}${patient.id}`)
            setActive(true)
            miscDispatch({
              type: 'notifSent',
              payload: notifId,
            })
          }
        }
      })
    }
  }, [
    summaries,
    previousSummaries,
    patients,
    setTitle,
    setPatientUrl,
    setBody,
    setActive,
    miscDispatch,
    claims,
  ])
}
