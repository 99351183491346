export const typingVariants = {
  initial: {
    opacity: 0,
  },
  active: {
    opacity: 1,
  },
}

export const extraBtnVariants = {
  initial: {
    rotate: '90deg',
  },
  open: {
    rotate: '270deg',
  },
}

export const barVariants = {
  initial: {
    height: 50,
    borderRadius: 50,
  },
  open: {
    height: 100,
    borderRadius: 15,
  },
}
