import { primary } from 'styles/Colors'
import { wp } from 'styles/Sizes'

export const barWidthPercent = 25
// const barWidthPercent = 19.5;

export const iconVariants = {
  initial: {
    right: wp(22),
    background: 'white',
    borderRadius: '50px',
    boxShadow: `0 0 0 0 ${primary}`,
    transition: {
      duration: 1,
      // boxShadow: {
      //   type: 'linear',
      // },
    },
  },
  hovered: {
    right: wp(22),
    background: 'white',
    borderRadius: '50px',
    boxShadow: `1.5px 3px 14px 0 rgba(46,76,230,0.2)`,
    transition: {
      duration: 0.3,
      // boxShadow: {
      //   type: 'linear',
      // },
    },
  },
}
export const containerVariants = {
  hover: { scale: 1.04 },
  tap: { scale: 0.95 },

  active: {
    color: 'white',
    backgroundColor: '#1C165B',
  },
  inactive: {
    color: '#1C165B',
    backgroundColor: 'white',
    // transition: {
    //   type: 'linear',
    // },
    // width: wp(barWidthPercent),
    // transition: {
    //   when: 'beforeChildren',
    //   staggerChildren: 0.2,
    // },
  },
}
