import { primary } from 'styles/Colors'

export default ({ style, color = primary }) => (
  <svg width="17.265" height="17.478" viewBox="0 0 17.265 17.478" style={style}>
    <g transform="translate(-.045 -.055)">
      <g transform="translate(.045 .055)">
        <path
          id="Path_2792"
          d="M8.678 17.532a8.653 8.653 0 0 1-8.391-6.7A8.777 8.777 0 0 1 4.77 1a8.557 8.557 0 0 1 10.481 2.126 8.825 8.825 0 0 1 .4 10.816.471.471 0 0 1-.663.1.485.485 0 0 1-.1-.672 7.845 7.845 0 0 0-1.215-10.479 7.61 7.61 0 0 0-10.421.4 7.848 7.848 0 0 0-.421 10.55 7.613 7.613 0 0 0 10.354 1.251.471.471 0 0 1 .663.1.485.485 0 0 1-.1.672 8.5 8.5 0 0 1-5.068 1.662z"
          fill={color}
          data-name="Path 2792"
          transform="translate(-.045 -.055)"
        />
        <path
          id="Path_2793"
          d="M91.688 60.6a2.7 2.7 0 0 1 1.472-2.72 2.049 2.049 0 0 0 1.116-1.766 1.748 1.748 0 0 0-1.849-1.756 1.807 1.807 0 0 0-1.849 1.482.465.465 0 0 1-.453.378.459.459 0 0 1-.453-.55 2.672 2.672 0 0 1 2.752-2.09 2.553 2.553 0 0 1 2.74 2.577 2.6 2.6 0 0 1-1.4 2.294 2.2 2.2 0 0 0-1.193 2.151.265.265 0 0 1-.265.264h-.354a.264.264 0 0 1-.264-.264z"
          fill={color}
          data-name="Path 2793"
          transform="translate(-83.584 -49.868)"
        />
        <path
          id="Path_2794"
          d="M91.372 60h-.356a.332.332 0 0 1-.331-.331 2.768 2.768 0 0 1 1.5-2.777 1.985 1.985 0 0 0 1.085-1.7 1.683 1.683 0 0 0-1.78-1.692 1.744 1.744 0 0 0-1.783 1.424.535.535 0 0 1-.518.432.527.527 0 0 1-.412-.194.518.518 0 0 1-.1-.436 2.737 2.737 0 0 1 2.818-2.142 2.61 2.61 0 0 1 2.805 2.64 2.66 2.66 0 0 1-1.431 2.35 2.122 2.122 0 0 0-1.169 2.092.332.332 0 0 1-.33.332zm-.553-.331a.194.194 0 0 0 .194.194h.357a.194.194 0 0 0 .194-.194 2.259 2.259 0 0 1 1.221-2.205 2.532 2.532 0 0 0 1.369-2.238 2.482 2.482 0 0 0-2.673-2.509 2.6 2.6 0 0 0-2.681 2.032.384.384 0 0 0 .077.324.394.394 0 0 0 .693-.176 1.877 1.877 0 0 1 1.918-1.535 1.812 1.812 0 0 1 1.912 1.824A2.109 2.109 0 0 1 92.256 57a2.627 2.627 0 0 0-1.437 2.662z"
          fill={color}
          data-name="Path 2794"
          transform="translate(-82.647 -48.937)"
        />
        <path
          id="Path_2795"
          d="M117.05 180.266a.636.636 0 1 1 .635.636.636.636 0 0 1-.635-.636z"
          fill={color}
          data-name="Path 2795"
          transform="translate(-109.111 -167.286)"
        />
        <path
          id="Path_2796"
          d="M116.743 180.075a.7.7 0 1 1 .5-.207.7.7 0 0 1-.5.207zm0-1.271a.568.568 0 1 0 .568.568.568.568 0 0 0-.568-.568z"
          fill={color}
          data-name="Path 2796"
          transform="translate(-108.169 -166.389)"
        />
      </g>
    </g>
  </svg>
)
