/* eslint-disable */
import { createGlobalStyle } from 'styled-components'

import { textPrimary } from 'styles/Colors'
import BasierCircleEot from '../assets/fonts/BasierCircle/basiercircle-regular-webfont.eot'
import BasierCircleTtf from '../assets/fonts/BasierCircle/basiercircle-regular-webfont.ttf'
import BasierCircleWoff from '../assets/fonts/BasierCircle/basiercircle-regular-webfont.woff'
import BasierCircleWoff2 from '../assets/fonts/BasierCircle/basiercircle-regular-webfont.woff2'
import fonts from './fonts'

export default createGlobalStyle`
  /************  FONT STYLE  *************/
 @font-face {
        font-family: 'BasierCircle';
        src: local('BasierCircle'), local('BasierCircle'),
        url(${BasierCircleTtf}) format('ttf'),
        url(${BasierCircleEot}) format('eot'),
        url(${BasierCircleWoff2}) format('woff2'),
        url(${BasierCircleWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
  }
  ${fonts}

  * {
    box-sizing: border-box;
    outline: none;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

  h1 {
    margin: 0 0 8px;
    color: #000;
  }

  ::placeholder {
    color: lighten(#566973, 20%);
  }
  
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FCFCFC;

    color: ${textPrimary};
    font-family: 'BasierCircle', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  }

  .root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .errorScreen {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  /************  REMOVE ARROWS FOR NUMBER INPUTS  *************/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /************  SCROLLBAR STYLE  *************/
  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 50px;
  }
  
  // Animate scrollbar?
  //@media screen and (-webkit-min-device-pixel-ratio:0) {
  //  .scrollContainer {
  //    background-color: rgba(0,0,0,0);
  //    -webkit-background-clip: text;
  //    /* uncomment following line to see the trick in actions */
  //    /* -webkit-text-fill-color: transparent; */
  //    transition: background-color .4s;
  //  }
  //  .scrollContainer:hover {
  //    background-color: rgba(0,0,0,0.18);
  //  }
  //  .scrollContainer::-webkit-scrollbar {
  //    width: 8px;
  //    height: 8px;
  //  }
  //  .scrollContainer::-webkit-scrollbar-track {
  //    display: none;
  //  }
  //  .scrollContainer::-webkit-scrollbar-thumb {
  //    border-radius: 10px;
  //    background-color: inherit;
  //  }
  //}
`
