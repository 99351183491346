import styled from 'styled-components'
import { greySecondary } from 'styles/Colors'

import { hp } from 'styles/Sizes'

export const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px 170px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 1px;
  grid-template-rows: repeat(3, 60px);
  grid-template-areas:
    'beforeButton beforeWeek beforeSelect beforeCalendar'
    'afterButton afterWeek afterSelect afterCalendar'
    'atButton atWeek atSelect atCalendar';
`
export const GridContainer = styled(({ gridArea, justifyContent, paddingRight, ...props }) => (
  <div {...props} />
))`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  padding-right: ${({ paddingRight }) => (paddingRight ? `${paddingRight}px` : `${hp(1.7)}px`)};
  padding-top: ${hp(1.7)}px;
  padding-bottom: ${hp(1.7)}px;
  align-items: center;
  border-bottom: ${`1px solid ${greySecondary}`};
`

export const FooterSchedule = styled.div`
  width: 100%;
  padding-bottom: 24px;
  z-index: 0;
`
