import { memo, useCallback, useMemo, useState } from 'react'

import { hp } from 'styles/Sizes'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import SearchIcon from 'assets/icons/Search'
import TextInput from 'components/common/inputs/Text'
import { useTranslation } from 'services/Translation'
import { Container, Icon, CodeElem, CodeLabel, CodesList } from './Styles'
import texts from './Texts'

const Icpcs = ({ icpcs, codes, setCodes, disabled }) => {
  const { t } = useTranslation(texts)
  const [searchValue, setSearchValue] = useState('')
  const [selectedCode, setSelectedCode] = useState(null)
  const [icpcError, setIcpcError] = useState('')
  const visibleList = useMemo(() => {
    if (searchValue.length > 1 && icpcs?.length) {
      const filteredList = icpcs.filter(
        el =>
          el.name.toUpperCase().includes(searchValue.toUpperCase()) ||
          el.code.toUpperCase().includes(searchValue.toUpperCase())
      )
      return filteredList.length ? filteredList : []
    }
    return []
  }, [searchValue, icpcs])

  const onSearchChange = useCallback(({ target: { value: newValue } }) => {
    setSearchValue(newValue)
  }, [])

  const handleCodeAdd = useCallback(
    newCode => e => {
      if (codes.find(c => c?.id === newCode.id)) {
        setIcpcError(t('twiceIcpcs'))
        return
      }
      setIcpcError('')
      setSelectedCode(newCode)
      const newCodes = [...codes]
      newCodes.push(newCode)
      setCodes(newCodes)
      setSearchValue('')
      setSelectedCode(null)
    },
    [codes, selectedCode, setCodes, t]
  )

  return (
    <Container>
      <div className="w-full flex items-center justify-center bg-white pl-3 pr-1 rounded-full border border-grey-100">
        <TextInput
          field={{
            value: searchValue,
            onChange: onSearchChange,
          }}
          noBorder
          noPadding
          inputStyle={{ height: 45 }}
          style={{ height: 45, width: 'auto' }}
          placeholder={t('searchInputPlaceholder')}
          data-cy="icpcInput"
          readOnly={disabled}
        />
        <Icon>
          <SearchIcon />
        </Icon>
      </div>
      <Separator size={hp(1.5)} />
      <CodesList
        className="w-full"
        animate={
          visibleList?.length
            ? {
                height: 170,
                opacity: 1,
              }
            : {
                height: 0,
                opacity: 0,
              }
        }
      >
        {visibleList?.map(el => (
          <CodeElem
            key={el.id}
            onClick={handleCodeAdd(el)}
            selected={el.id === selectedCode?.id}
            data-cy={`icpcElem_${el.id}`}
          >
            <CodeLabel>
              {el.code}
              <Separator type="horizontal" size={20} />
              {el.name}
            </CodeLabel>
          </CodeElem>
        ))}
      </CodesList>
      {icpcError && <Typography error text={icpcError} />}
    </Container>
  )
}

Icpcs.whyDidYouRender = true

export default memo(Icpcs)
