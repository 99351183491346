import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { getAccessToken } from 'services/Authentication'
import asyncForEach from 'utils/asyncForEach.ts'
import { getInvite } from 'services/LiveUpdates/queries'
import { getSummary } from 'services/Summaries'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import { getOne as getOneAppointment } from 'services/Appointments'
import { useRegistrations } from 'services/Registrations'

import { fetchFunc, multiple } from '../Tools'
import { formatLiveUpdate, LIVE_UPDATES_TYPES } from './formatter'

export const useLiveUpdates = ({ alertOpen, from } = {}, log = false) => {
  const token = getAccessToken()
  const {
    data: { patients, summaries, appointments },
  } = useRegistrations()

  const [finalList, setFinalList] = useState([])
  const [loading, setLoading] = useState(false)

  const { data, error } = useSWR(
    {
      url: `${process.env.REACT_APP_CLINIC_SERVICE_URL}/ListOwnNotifications`,
      args: {
        token,
        params: { from_datetime: from || undefined },
        log,
      },
    },
    fetchFunc,
    { refreshInterval: 50000 }
  )
  const formattedList = multiple(formatLiveUpdate, data || [])
  const isLoading = !error && !data

  // Fill FinalList
  useEffect(() => {
    if (
      patients?.length &&
      formattedList?.length &&
      !loading &&
      formattedList?.length !== finalList?.length
    ) {
      // Only set a loader if list is empty
      if (!finalList.length) setLoading(true)
      ;(async () => {
        const out = []
        await asyncForEach(formattedList, async update => {
          const alreadyFilledUpdate = finalList.find(e => e.id === update.id)
          if (alreadyFilledUpdate) out.push(alreadyFilledUpdate)
          else {
            // Update is new
            const finalUpdate = { id: update.id, createdAt: update.createdAt, type: update.type }

            switch (update.type) {
              case LIVE_UPDATES_TYPES.PATIENT_INVITED: {
                if (!update.inviteInfos) {
                  const res = await getInvite({ token }, update.inviteId)
                  if (res) {
                    finalUpdate.inviteInfos = res
                    out.push(finalUpdate)
                  }
                } else {
                  finalUpdate.inviteInfos = update.inviteInfos
                  out.push(finalUpdate)
                }
                break
              }

              case LIVE_UPDATES_TYPES.PATIENT_REGISTERED: {
                // Add patient from id, if there needs more infos ask for them to the backend
                const foundPatient = patients?.find(p => p.id === update.patientId)
                finalUpdate.patient = { ...(foundPatient || update.author) }
                out.push(finalUpdate)
                break
              }

              case LIVE_UPDATES_TYPES.SUMMARY_CREATED:
              case LIVE_UPDATES_TYPES.SUMMARY_CANCELLED:
              case LIVE_UPDATES_TYPES.ASSISTANT_NOTES_UPDATED: {
                const foundSummary = summaries?.find(s => s.id === update.summaryId)
                if (!foundSummary) {
                  // get summary & patient
                  const [newSummary, newPatient] =
                    (await getSummary({ token, isNew: true }, update.summaryId)) || []
                  if (newSummary && newPatient) {
                    finalUpdate.summary = newSummary
                    finalUpdate.patient = newPatient
                    out.push(finalUpdate)
                  }
                } else {
                  const foundPatient = patients.find(p => p.id === foundSummary.patientId)
                  if (foundPatient) {
                    finalUpdate.summary = foundSummary
                    finalUpdate.patient = foundPatient
                    out.push(finalUpdate)
                  }
                }
                break
              }

              case LIVE_UPDATES_TYPES.READY_FOR_APPOINTMENT:
              case LIVE_UPDATES_TYPES.SCHEDULED_APPOINTMENT_NOW:
              case LIVE_UPDATES_TYPES.SCHEDULED_APPOINTMENT_WITHIN_30_MIN:
              case LIVE_UPDATES_TYPES.APPOINTMENT_ACCEPTED:
              case LIVE_UPDATES_TYPES.APPOINTMENT_CANCELLED: {
                const foundAppointment = appointments?.find(a => a.id === update.appointmentId)
                const delay =
                  foundAppointment &&
                  differenceInSeconds(
                    new Date(foundAppointment?.updatedAt),
                    new Date(update.createdAt)
                  )
                if (foundAppointment && delay > -2) {
                  const foundSummary = summaries.find(s => s.id === foundAppointment.summaryId)
                  if (foundSummary) {
                    const foundPatient = patients.find(p => p.id === foundSummary.patientId)
                    if (foundPatient) {
                      finalUpdate.appointment = foundAppointment
                      finalUpdate.summary = foundSummary
                      finalUpdate.patient = foundPatient
                      out.push(finalUpdate)
                    }
                  } else {
                    // get summary & patient
                    const [newSummary, newPatient] =
                      (await getSummary({ token, isNew: true }, foundAppointment.summaryId)) || []
                    finalUpdate.appointment = foundAppointment
                    finalUpdate.summary = newSummary
                    finalUpdate.patient = newPatient
                    out.push(finalUpdate)
                  }
                } else {
                  // Go get it
                  const newAppointment = await getOneAppointment({ token }, update.appointmentId)
                  const [newSummary, newPatient] =
                    (await getSummary({ token, isNew: true }, newAppointment.summaryId)) || []
                  finalUpdate.appointment = newAppointment
                  finalUpdate.summary = newSummary
                  finalUpdate.patient = newPatient
                  out.push(finalUpdate)
                }
                break
              }
            }
          }
        })
        setFinalList(out.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)))
        setLoading(false)
      })()
    }
    // FinalList should not be in this list. Otherwise it will cause an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertOpen, finalList?.length, formattedList, patients, summaries, appointments, loading])

  return {
    data: finalList || [],
    isLoading: isLoading || loading,
    isError: typeof data === 'number' ? data : error,
  }
}
