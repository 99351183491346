import { memo, useState, useCallback } from 'react'
import Month from 'components/common/Month'
import { useTranslation } from 'services/Translation'
import Header from './Header'

interface CalendarCardProps {
  field: {
    value: Date | null
    onChange: (date: Date) => void
  }
  events: any
  appointmentDuration?: number
  disableBefore: Date
}

const CalendarCard = ({
  field: { value, onChange },
  events,
  appointmentDuration,
  disableBefore,
}: CalendarCardProps) => {
  const { formatDate: format } = useTranslation()
  const currDate = new Date()
  const [monthView, setMonthView] = useState({
    month: value?.getMonth() || currDate.getMonth(),
    year: value?.getMonth() || currDate.getFullYear(),
  })
  const handleDateChange = useCallback(
    (newDate: Date) => {
      onChange(newDate)
    },
    [onChange]
  )
  const handleNavClick = useCallback(
    (direction: number) => {
      const newMonth = monthView.month + direction
      setMonthView({
        month: newMonth === 12 ? 0 : newMonth === -1 ? 11 : newMonth,
        year:
          newMonth === 12
            ? monthView.year + 1
            : newMonth === -1
            ? monthView.year - 1
            : monthView.year,
      })
    },
    [monthView]
  )

  return (
    <div style={{ width: '20rem', height: '15rem' }} className="px-4">
      <Header
        onNavClick={handleNavClick}
        currentMonth={format(new Date(monthView.year, monthView.month), 'MMMM yyyy')}
      />
      <div className="flex">
        <Month
          className="pt-8"
          currDate={currDate}
          monthView={monthView}
          events={events}
          selectedDate={value}
          mini
          appointmentDuration={appointmentDuration}
          onDateChange={handleDateChange}
          disableBefore={disableBefore}
          calendarType={undefined}
        />
      </div>
    </div>
  )
}

CalendarCard.defaultProps = {
  appointmentDuration: 0,
}

CalendarCard.whyDidYouRender = true

export default memo(CalendarCard)
