import { useCallback, memo, useState } from 'react'
import { GS } from 'back-end-api'

import { hp } from 'styles/Sizes'
import DefaultBtn from 'components/common/buttons/Default'
import Separator from 'components/common/Separator'
import Typography from 'components/common/Typography'
import { useTranslation } from 'services/Translation'

import ReactCodeInput from 'react-code-input'
import { Container, inputStyle, inputStyleInvalid } from './Styles'
import texts from './Texts'

const OTP_SIZE = 5

const OTPForm = ({ username, password, onStepChange, onPasswordError }) => {
  const { t } = useTranslation(texts)
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleOtpChange = useCallback(
    value => {
      if (value.length === OTP_SIZE) setOtp(value)
      else if (otp) setOtp('')
    },
    [otp]
  )
  const handleSubmit = useCallback(async () => {
    setLoading(true)
    const res = await GS.resetPassword({
      one_time_password_destination: username,
      one_time_password: parseInt(otp, 10),
      new_password: password,
    })
    if (res?.error) {
      switch (res.error?.status) {
        case 404:
          setError(t('otpExpired'))
          break
        case 500:
          onPasswordError(t('passwordAlreadyUsed'))
          break
        default:
          setError(t('error'))
      }
    } else {
      setError('')
      onStepChange()
    }
    setLoading(false)
  }, [onPasswordError, onStepChange, otp, password, t, username])

  return (
    <Container>
      <ReactCodeInput
        type="number"
        fields={OTP_SIZE}
        inputStyle={inputStyle}
        inputStyleInvalid={inputStyleInvalid}
        name="OTP"
        onChange={handleOtpChange}
        isValid={!error}
      />
      <Separator size={hp(1)} />
      <Typography text={error} hidden={!error} error center />
      <Separator size={hp(5.5)} />
      <DefaultBtn
        disabled={!otp || loading}
        label={t('btnLabel')}
        withNext
        onClick={handleSubmit}
        reversedColors
        style={{ width: '50%', margin: 'auto' }}
      />
    </Container>
  )
}

OTPForm.whyDidYouRender = true

export default memo(OTPForm)
