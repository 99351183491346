import styled from 'styled-components'

export const Container = styled(({ noPadding, fullWidth, ...props }) => <div {...props} />)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  height: ${({ noPadding }) => (noPadding ? 'fit-content' : 'auto')};
  padding: ${({ noPadding }) => (noPadding ? '0' : '20px 0')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
`
