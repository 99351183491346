export default getText => key =>
  ({
    title: getText('Reset password', 'Auth_Reset'),
    emailSubtitle: getText('Please write down your email address first', 'Auth_Reset'),
    passwordSubtitle: getText('Now, please write down your new password', 'Auth_Reset'),
    otpSubtitle1: getText('Please enter the 5 - digit OTP we sent to', 'Auth_Reset'),
    otpSubtitle2: getText('to complete the registration.', 'Auth_Reset'),
    finalSubtitle: getText('Your new password has been set.', 'Auth_Reset'),
    btnLabel: getText('Done', 'Auth_Reset'),
  }[key])
