import { motion } from 'framer-motion'
import styled from 'styled-components'

import { primary, transparency } from 'styles/Colors'
import { wp, hp } from 'styles/Sizes'

export const Container = styled(motion.div)`
  position: absolute;
  top: ${hp(5.6)}px;
  right: ${wp(5.7)}px;
  border-radius: 50px;
  background-color: white;
  width: 200px;
  //overflow: hidden;
  box-shadow: 1.5px 3px 9px 0 ${primary + transparency[20]};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px 0 20px 35px;
  z-index: 3;
`
export const Label = styled(motion.div)`
  position: absolute;
  color: ${primary};
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`
export const LinkContainer = styled(({ innerRef, ...props }) => (
  <motion.div {...props} ref={innerRef} />
))``
