/* eslint-disable */

import {
  TitleH2,
  TitleH3,
  TitleH4,
  Paragraph,
  Strong,
  List,
  Element,
  Link,
  Info,
} from '../../Styles'

export default () => (
  <>
    <TitleH2>MIA Suite Terms</TitleH2>
  </>
)
