import { memo } from 'react'

import { useTranslation } from 'services/Translation'

import { TYPES } from '../Tools'
import Chip from './Chip'
import texts from './Texts'

const TypeSelector = ({ onChange, currentType }) => {
  const { t } = useTranslation(texts)

  return (
    <>
      <div className="font-csc45 text-sm mt-10 mb-7 text-grey-200">{t('title')}</div>
      <div className="pb-4 border-b border-grey-100">
        <Chip
          label={t('lastname')}
          onClick={onChange(TYPES.LASTNAME)}
          active={currentType === TYPES.LASTNAME}
        />
        <Chip
          label={t('firstname')}
          onClick={onChange(TYPES.FIRSTNAME)}
          active={currentType === TYPES.FIRSTNAME}
        />
        <Chip
          label={t('date')}
          onClick={onChange(TYPES.DATE)}
          active={currentType === TYPES.DATE}
        />
        <Chip label={t('ssn')} onClick={onChange(TYPES.SSN)} active={currentType === TYPES.SSN} />
      </div>
    </>
  )
}

TypeSelector.whyDidYouRender = true

export default memo(TypeSelector)
