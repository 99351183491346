import { memo } from 'react'
import CrossDelete from 'assets/icons/crosses/Error'
import { purple } from 'styles/Colors'
import { DeleteBtn } from './Styles'
import { Icpc } from '../../Types'

interface SelectedDiseasesProps {
  codes: Icpc[]
  onDelete: (idx: number) => void
  disabled: boolean
}

const SelectedDiseases = ({ codes, onDelete, disabled }: SelectedDiseasesProps) => {
  return (
    <div className="w-2/3 h-fit flex flex-wrap items-start justify-start">
      {codes.map((code, idx) => (
        <div
          key={code.id}
          style={{ fontSize: '15px' }}
          className="flex rounded-full mx-3 my-2 px-3 font-csc65 text-purple-200 w-auto bg-purple-200-o12"
        >
          <span className="mr-1">{code.code}</span>
          {code.name}
          <DeleteBtn
            onClick={() => onDelete(idx)}
            disabled={disabled}
            data-cy={`btnDeleteIcpc_${code.id}`}
          >
            {!disabled && <CrossDelete color={purple} />}
          </DeleteBtn>
        </div>
      ))}
    </div>
  )
}

SelectedDiseases.whyDidYouRender = true

export default memo(SelectedDiseases)
