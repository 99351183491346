import { memo } from 'react'

import { Container } from './Styles'

const CheckBox = ({ field: { onChange, value }, ...props }) => {
  return <Container selected={!!value} onClick={onChange} {...props} />
}

CheckBox.whyDidYouRender = true

export default memo(CheckBox)
