export default ({ color = '#1C165B' }) => (
  <svg width="16.575" height="19.891" viewBox="0 0 16.575 19.891">
    <g transform="translate(-42.675)">
      <g transform="translate(42.675)">
        <path
          d="M59.192,16.778l-1.423-2.372a7.046,7.046,0,0,1-1-3.625V8.7A5.809,5.809,0,0,0,52.62,3.145V1.658a1.658,1.658,0,0,0-3.315,0V3.145A5.809,5.809,0,0,0,45.161,8.7V10.78a7.05,7.05,0,0,1-1,3.624l-1.423,2.372a.415.415,0,0,0,.355.628H58.836a.414.414,0,0,0,.356-.627Z"
          transform="translate(-42.675)"
          fill={color}
        />
      </g>
      <g transform="translate(48.353 18.234)">
        <path
          d="M188.815,469.333a2.883,2.883,0,0,0,5.22,0Z"
          transform="translate(-188.815 -469.333)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)
