import { memo } from 'react'

import { hp } from 'styles/Sizes'
import Typography from 'components/common/Typography/Component'
import DefaultBtn from 'components/common/buttons/Default/Component'
import Separator from 'components/common/Separator/Component'
import { useTranslation } from 'services/Translation'
import { Container } from './Styles'
import texts from './Texts'

const OtherTabOpen = () => {
  const { t } = useTranslation(texts)
  return (
    <Container>
      <Typography text={t('title1')} type="title" />
      <Separator size={hp(3)} />
      <Typography text={t('title2')} type="title" />
      <Separator size={hp(3)} />
      <Typography text={t('title3')} type="title" />
      <Separator size={hp(4)} />
      <DefaultBtn label={t('btnLabel')} onClick={() => window.location.reload(false)} />
    </Container>
  )
}

OtherTabOpen.whyDidYouRender = true

export default memo(OtherTabOpen)
