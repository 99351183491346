import { memo, useState, useCallback } from 'react'

import Card from 'components/common/Card'
import CloseBtn from 'components/common/buttons/Close'
import Typography from 'components/common/Typography'
import { useTranslation } from 'services/Translation'

import { closeBtnStyle, cardStyle } from './Styles'
import texts from './Texts'

const PasswordPopup = () => {
  const { t } = useTranslation(texts)
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => setOpen(false), [])

  if (!open) return null
  return (
    <Card style={cardStyle}>
      <CloseBtn style={closeBtnStyle} onClick={handleClose} />
      <Typography text={`${t('text1')} `} />
      <Typography text={t('text2')} bold />
      <Typography text={` ${t('text3')} `} />
      <Typography text={t('text4')} bold />
      <Typography text={` ${t('text5')} `} />
      <Typography text={t('text6')} bold />
    </Card>
  )
}

PasswordPopup.whyDidYouRender = true

export default memo(PasswordPopup)
