import { memo, useCallback, useState, useRef } from 'react'

import { textPrimary } from 'styles/Colors'
import ArrowIcon from 'assets/icons/arrows/Small'
import UnderlineBtn from 'components/common/buttons/Underline'
import FloatingCard from 'components/common/Card/Floating'
import OptionButton from 'components/common/buttons/Option'

import { Container } from './Styles'

const FloatingSelectInput = ({
  field: { value, onChange },
  options,
  label,
  size,
  bold = true,
  noPadding = false,
  fullWidth = false,
  'data-cy': dataCy,
  style,
}) => {
  const innerRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(v => !v)
  }, [])
  const handleElemClick = useCallback(
    idx => () => {
      onChange(idx)
      handleOpen()
    },
    [handleOpen, onChange]
  )

  return (
    <>
      <Container style={style} data-cy={dataCy} noPadding={noPadding} fullWidth={fullWidth}>
        <UnderlineBtn
          innerRef={innerRef}
          nextIcon={<ArrowIcon color={textPrimary} />}
          bold={bold}
          size={size}
          onClick={handleOpen}
          label={label || options[value]}
        />
      </Container>
      <FloatingCard
        style={{ padding: '10px 20px', position: 'absolute' }}
        active={open}
        setActive={handleOpen}
        top={
          innerRef &&
          innerRef.current &&
          innerRef.current.offsetTop + innerRef.current.offsetHeight - 15
        }
        left={
          innerRef && innerRef.current && innerRef.current.offsetLeft + innerRef.current.offsetWidth
        }
        arrowPos="right"
      >
        {options.map((el, i) => (
          <OptionButton
            selected={value === i}
            data-cy={`${dataCy}_${i}`}
            size={size}
            bold={bold}
            onClick={handleElemClick(i)}
            label={el}
          />
        ))}
      </FloatingCard>
    </>
  )
}

FloatingSelectInput.whyDidYouRender = true

export default memo(FloatingSelectInput)
