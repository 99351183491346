import { memo } from 'react'

import { hp } from 'styles/Sizes'
import useClock from 'hooks/useClock'
import { useTranslation } from 'services/Translation'
import List from 'components/common/List'

import Element from './Element'
import { formatUpdates } from './Tools'
import texts from './Texts'

const UpdatesList = ({ updates, expanded }) => {
  const { t, formatDate: format } = useTranslation(texts)
  const currentDate = useClock()
  const formattedUpdates = formatUpdates(updates, currentDate, t, format)

  return (
    <List
      className="px-16 pb-8"
      height={expanded ? hp(80) : hp(34)}
      initialBotShadow={!!formattedUpdates && formattedUpdates?.length > 1}
      topExtraSafe
    >
      {[...formattedUpdates].map((el, idx) => (
        <Element
          key={idx}
          // key={el.id}
          type={el.type}
          link={el.link}
          name={el.name}
          action={el.action}
          time={el.time}
          fullTime={el.fullTime}
        />
      ))}
    </List>
  )
}

UpdatesList.whyDidYouRender = true

export default memo(UpdatesList)
