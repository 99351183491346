export default (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    title: getText('Appointments Details', 'Encounters_Sidebar'),
    closeBtnLabel: getText('Close', 'Encounters_Sidebar'),
    audioVisual: getText('AV Consult', 'Encounters_Sidebar'),
    detailView: getText('View Details', 'Encounters_Sidebar'),
    historyDetails: getText('Encounter details', 'Encounters_Sidebar'),
    dateAndTime: getText('Encounter Date & Time', 'Encounters_Sidebar'),
    actionType: getText('Type of Product', 'Encounters_Sidebar'),
    appointmentStatus: getText('Status of the summary', 'Encounters_Sidebar'),
    problemDescription: getText('Problem description', 'Encounters_Sidebar'),
    topic: getText('Topic', 'Encounters_Sidebar'),
    summary: getText('Original MIA Summary', 'Encounters_Sidebar'),
    followUpQuestion: getText('Follow up patient questions', 'Encounters_Sidebar'),
    labelViewSummary: getText('View MIA Summary', 'Encounters_Sidebar'),
    seeMore: getText('See more...', 'Encounters_Sidebar'),
    seeLess: getText('See less', 'Encounters_Sidebar'),
  }[key])
