import { memo } from 'react'
import { textPrimary } from 'styles/Colors'
import { useTranslation } from 'services/Translation'
import DefaultBtn from 'components/common/buttons/Default/Component'
import DefaultCross from 'assets/icons/crosses/Default'
import AdviceSentImg from 'assets/images/AdviceSent'
import { hp } from 'styles/Sizes'
import Typography from 'components/common/Typography'
import SuccessImg from 'assets/images/Success'
import OvalLoader from 'components/common/loaders/oval/Component'
import Separator from 'components/common/Separator'
import { Container } from './Styles'
import texts from './Texts'

const LoaderView = ({ loading = true, onClose }) => {
  const { t } = useTranslation(texts)
  const ModalContainer = ({ children }) => (
    <>
      <DefaultBtn
        mini
        label={t('btnLabelClose')}
        onClick={onClose}
        className="absolute top-40 left-0"
        style={{ transform: 'translate(-50%, -50%)', zIndex: 99 }}
        nextIcon={<DefaultCross style={{ width: 12, height: 'auto' }} />}
        background={textPrimary}
        color="white"
      />
      <Container>{children}</Container>
    </>
  )

  return (
    <ModalContainer>
      {loading ? (
        <>
          <AdviceSentImg />
          <Separator size={hp(3)} />
          <div className="flex flex-col items-center justify-center">
            <Typography
              type="title"
              className="font-csc65"
              center
              text={t('processing')}
              color={textPrimary}
              size={15}
            />
            <OvalLoader center />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-center">
            <SuccessImg />
            <Separator size={hp(3)} />
            <Typography
              type="title"
              className="font-csc65"
              center
              text={t('btnLabelSent')}
              color={textPrimary}
              size={20}
            />
          </div>
        </>
      )}
    </ModalContainer>
  )
}

LoaderView.whyDidYouRender = true

export default memo(LoaderView)
