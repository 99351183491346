import { textPrimary } from 'styles/Colors'

export default ({ className = '', color = textPrimary }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="22"
    height="4"
    viewBox="0 0 22 4"
  >
    <g fill="#1c165b" stroke={color} strokeWidth="1">
      <circle cx="2" cy="2" r="2" stroke="none" />
      <circle cx="2" cy="2" r="1.5" fill="none" />
    </g>
    <g transform="translate(9)" fill="#1c165b" stroke={color} strokeWidth="1">
      <circle cx="2" cy="2" r="2" stroke="none" />
      <circle cx="2" cy="2" r="1.5" fill="none" />
    </g>
    <g transform="translate(18)" fill="#1c165b" stroke={color} strokeWidth="1">
      <circle cx="2" cy="2" r="2" stroke="none" />
      <circle cx="2" cy="2" r="1.5" fill="none" />
    </g>
  </svg>
)
