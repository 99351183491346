import { primary } from 'styles/Colors'

export default ({ color = primary }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.564"
    height="14.313"
    viewBox="0 0 21.564 14.313"
  >
    <path
      d="M30.648,93.147a13.781,13.781,0,0,1-8.941-3.61.6.6,0,0,1,.766-.915c3.7,3.092,7.521,4.047,11.351,2.838a12.455,12.455,0,0,0,4.734-2.795.6.6,0,1,1,.858.828,13.469,13.469,0,0,1-5.183,3.088,11.72,11.72,0,0,1-3.585.565Z"
      transform="translate(-19.662 -80.949)"
      fill={color}
    />
    <path
      d="M20.952,5.363a.6.6,0,0,1-.388-.144C16.221,1.484,11.622.336,6.9,1.8A15.994,15.994,0,0,0,1,5.191a.6.6,0,1,1-.839-.85A16.916,16.916,0,0,1,6.5.68,13.979,13.979,0,0,1,13.269.22a17.137,17.137,0,0,1,8.068,4.092.6.6,0,0,1-.386,1.051Z"
      transform="translate(0.016 0.002)"
      fill={color}
    />
    <path
      d="M16.742,99.161a.6.6,0,0,1-.5-.912l1.25-2a.6.6,0,1,1,1.01.633l-1.249,2A.6.6,0,0,1,16.742,99.161Z"
      transform="translate(-14.77 -87.788)"
      fill={color}
    />
    <path
      d="M68.367,127.083a.6.6,0,0,1-.569-.774l.625-2a.6.6,0,0,1,1.138.358l-.625,2A.6.6,0,0,1,68.367,127.083Z"
      transform="translate(-61.998 -113.345)"
      fill={color}
    />
    <path
      d="M120,133.828a.6.6,0,0,1-.6-.6v-2a.6.6,0,0,1,1.192,0v2A.6.6,0,0,1,120,133.828Z"
      transform="translate(-109.231 -119.515)"
      fill={color}
    />
    <path
      d="M164.9,128.576a.6.6,0,0,1-.568-.418l-.624-2a.6.6,0,1,1,1.138-.356l.625,2a.6.6,0,0,1-.57.774Z"
      transform="translate(-149.739 -114.711)"
      fill={color}
    />
    <path
      d="M209.737,102.161a.6.6,0,0,1-.506-.279l-1.249-2a.6.6,0,1,1,1.01-.633l1.25,2a.6.6,0,0,1-.5.912Z"
      transform="translate(-190.179 -90.532)"
      fill={color}
    />
  </svg>
)
