import styled from 'styled-components'
import { background } from 'styles/Colors'
import { wp } from 'styles/Sizes'

export const Container = styled.div`
  background-color: ${background};
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
`
export const Content = styled.div`
  position: relative;
  width: ${wp(24.4)}px;
  margin: auto;
`
