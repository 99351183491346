import { memo } from 'react'

import { useTranslation } from 'services/Translation'
import texts from './Texts'

const Header = () => {
  const { t } = useTranslation(texts)

  return (
    <>
      <div
        className="flex items-center pb-6 font-csc65 text-lg text-blue-900 border-r border-grey-100"
        style={{ paddingTop: '3.375rem', paddingLeft: '7.5rem', paddingRight: '2.8125rem' }}
      >
        {t('title')}
      </div>
      <div
        className="h-px bg-grey-100 mb-12"
        style={{ marginLeft: '7.5rem', marginRight: '7.5rem' }}
      />
    </>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
