import { error } from 'styles/Colors'

export default ({ color = error }) => (
  <svg width="12" height="12" viewBox="0 0 12 12">
    <g transform="translate(-162 -64)">
      <circle cx="6" cy="6" r="6" transform="translate(162 64)" fill={color} />
      <line
        y1="4.909"
        x2="4.909"
        transform="translate(165.545 67.545)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <line
        y1="4.909"
        x2="4.909"
        transform="translate(170.455 67.545) rotate(90)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
)
