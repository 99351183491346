/* eslint-disable */

import {
  TitleH2,
  TitleH3,
  TitleH4,
  Paragraph,
  Strong,
  List,
  Element,
  Link,
  Info,
} from '../../Styles'

export default () => (
  <>
    <TitleH2>ALGEMENE VOORWAARDEN – MIA Dashboard</TitleH2>
    <Paragraph>
      Dit zijn de algemene voorwaarden (“<Strong>“Algemene Voorwaarden</Strong>") van Medvice
      Digital Health B.V. (hierna te noemen “Medvice”. "wij", "ons" of "onze"), een onderneming met
      adres Sint Janssingel 92, 5211 DA, 's-Hertogenbosch. Medvice is ingeschreven bij de Kamer van
      Koophandel onder nummer 73850691. Met het gebruiken of bezoeken van de (mobiele) applicaties,
      websites of andere diensten van Medvice (hierna gezamenlijk te noemen de “Dienst") stemt u in
      dat u gebonden bent aan deze Algemene Voorwaarden. De Dienst is in eigendom en in beheer van
      Medvice. Deze Algemene Voorwaarden hebben betrekking op uw rechten en plichten. Indien u niet
      gebonden wilt zijn aan deze Algemene Voorwaarden, gebruikt of bezoekt u de Dienst niet.
    </Paragraph>
    <TitleH4>Registratie en accountbeveiliging</TitleH4>
    <List>
      <Element numbered>U moet minimaal 18 jaar oud zijn om de Dienst te gebruiken.</Element>
      <Element numbered>
        U bent verantwoordelijk voor alle activiteiten die plaatsvinden via uw account. U gaat ermee
        akkoord dat u uw account, gebruikersnaam of andere account-gerelateerde rechten niet zal
        verkopen, overdragen of uitlenen. Met uitzondering van personen of bedrijven die
        uitdrukkelijk geautoriseerd zijn om accounts aan te maken namens hun werknemers of cliënten,
        verbiedt Medvice het aanmaken van een account voor iemand anders dan uzelf.
      </Element>
      <Element numbered>
        U verklaart dat alle informatie die u verstrekt of verstrekt heeft tijdens het aanmaken van
        een account juist, accuraat, volledig en actueel is en u gaat akkoord dat u uw
        accountsgegevens dient bij te werken om de juistheid en accuraatheid te behouden.
      </Element>
      <Element numbered>
        U gaat akkoord dat u de inloggegevens van andere Medvice gebruikers niet zal gebruiken,
        verzamelen of achterhalen.
      </Element>
      <Element numbered>
        Voor het gebruik van de Dienst, dient de gebruiker BIG-geregistreerd te zijn of onder
        bevoegdheid te werken van een BIG-geregistreerde gebruiker.
      </Element>
      <Element numbered>
        Voor het gebruik van de Dienst, dient de gebruiker als zorgaanbieder geregistreerd te zijn.
        Hiervoor vraagt Medvice de gebruiker om een verplichte AGB- of vergelijkbare code.
      </Element>
      <Element numbered>
        U zal niet proberen een andere gebruiker te beperken in het gebruik van de Dienst en u mag
        het schenden van deze Algemene Voorwaarden of andere voorwaarden van Medvice niet
        aanmoedigen of vergemakkelijken.
      </Element>
      <Element numbered>
        U bent verantwoordelijk voor het bewaren en het geheimhouden van uw wachtwoord en codes
        gerelateerd aan twee-factor-authenticatie.
      </Element>
      <Element numbered>
        U bent verantwoordelijk voor het voorkomen dat onbevoegden oneigenlijk gebruik maken van uw
        smartphone, smarttablet of computer tijdens het gebruik van de Dienst.
      </Element>
      <Element numbered>
        . U zal via de Dienst niet op ongeoorloofde wijze een account aanmaken, met inbegrip van
        maar niet beperkt tot, het gebruik van een apparaat, script, bot, spider, crawler of
        scraper.
      </Element>
    </List>
    <TitleH4>Veiligheid</TitleH4>
    <List>
      <Element numbered>
        U mag de Dienst niet gebruiken voor illegale of ongeautoriseerde doeleinden. U gaat akkoord
        met alle wetten, regels en voorschriften die van toepassing zijn op uw gebruik van de
        Dienst, met inbegrip van maar niet beperkt tot wetgeving inzake liet auteursrecht.
      </Element>
      <Element numbered>
        De Dienst mag u alleen gebruiken binnen de kaders van een bestaande of nog te starten
        behandelovereenkomst (WGBO).
      </Element>
      <Element numbered>
        Het is niet toegestaan om de Dienst of de servers en/of netwerken die zijn verbonden met de
        Dienst te belemmeren of te verstoren, bijvoorbeeld door wormen, virussen, spyware, malware
        of andere destructieve of ontwrichtende code te verzenden. U mag geen reverse-engineering
        handelingen verrichten. U mag geen inhoud of code injecteren of anderszins aanpassingen
        doorvoeren in, of ingrijpen in de manier waarop, een Medvice pagina wordt weergegeven in de
        browser of native omgeving van een gebruiker of apparaat.
      </Element>
      {/********************* PAGE 2 ********************/}
      <Element numbered>
        U mag de Dienst niet veranderen, wijzigen, aanpassen of een andere website veranderen,
        wijzigen of aanpassen om zo ten onrechte te impliceren dat deze wordt geassocieerd met de
        Dienst of Medvice.
      </Element>
    </List>
    <TitleH4>Basisvoorwaarden</TitleH4>
    <List>
      <Element numbered>
        Wij behouden ons het recht voor om de Dienst of uw toegang, tot de Dienst om welke reden dan
        ook, zonder voorafgaande kennisgeving, op elk moment en zonder aansprakelijkheid jegens u
        aan te passen of te beëindigen.
      </Element>
      <Element numbered>
        Wij behouden ons het recht voor om het gebruiksrecht van een gebruikersnaam om welke reden
        dan ook te laten vervallen.
      </Element>
      <Element numbered>
        Bij beëindiging komen alle licenties en andere rechten die op grond van deze Algemene
        Voorwaarden aan u zijn verleend, onmiddellijk te vervallen.
      </Element>
      <Element numbered>
        Wij behouden ons het recht voor om de toegang tot de Dienst te weigeren voor elke
        willekeurige persoon op elk gewenst moment en om welke reden dan ook.
      </Element>
      <Element numbered>
        U gaat akkoord dat u verantwoordelijk bent voor alle datakosten die u maakt door het gebruik
        van de Dienst.
      </Element>
      <Element numbered>
        Wij verbieden ‘crawling’, ‘scraping’, ‘caching’ of op een andere wijze toegang verkrijgen
        tot de Dienst via geautomatiseerde middelen (behalve als gevolg van de standaard zoekmachine
        protocollen of technologieën gebruikt door een zoekmachine met uitdrukkelijke toestemming
        van Medvice).
      </Element>
    </List>
    <TitleH4>Intellectuele eigendomsrechten</TitleH4>
    <List>
      <Element numbered>
        Medvice verleent aan u hierbij een niet-overdraagbare licentie om de Dienst te gebruiken.
      </Element>
      <Element numbered>
        Het is niet toegestaan de broncode van de Dienst te wijzigen, er afgeleide werken van te
        maken, te decompileren of anderszins te verkrijgen, behalve wanneer dit uitdrukkelijk is
        toegestaan volgens een open-bronlicentie of wanneer we u uitdrukkelijk schriftelijke
        toestemming hebben gegeven. Elke poging om dit te doen, is een schending van de rechten van
        Medvice.
      </Element>
      <Element numbered>
        De Dienst bevat inhoud die in eigendom of in licentie is bij Medvice (“
        <Strong>Medvice Inhoud</Strong>"). Medvice Inhoud wordt beschermd door intellectuele
        eigendomsrechten, zoals auteursrechten en merkenrechten. Medvice heeft en behoudt alle
        rechten ten aanzien van de Medvice Inhoud. Het is niet toegestaan om enige kennisgeving
        inzake auteursrechten, handelsmerken of andere intellectuele eigendomsrechten die zijn
        opgenomen in of bij de Medvice Inhoud te verwijderen, aan te passen of te verhullen. Evenmin
        is het toegestaan om Medvice Inhoud te reproduceren, te wijzigen, aan te passen, uit te
        voeren, weer te geven, te publiceren, te verspreiden, over te brengen, uit te zenden, te
        verkopen, in licentie te verlenen of op enigerlei andere wijze te exploiteren, noch er
        afgeleide werken van te maken.
      </Element>
      <Element numbered>
        De Medvice naam en logo zijn handelsmerken van Medvice, en mogen niet geheel of gedeeltelijk
        worden gekopieerd, geïmiteerd of gebruikt worden zonder voorafgaande schriftelijke
        toestemming van Medvice. Daarnaast mogen alle koppen, aangepaste afbeeldingen,
        knoppictogrammen en scripts servicemerken, handelsmerken en of beeldmerken van Medvice
        zonder voorafgaande schriftelijke toestemming van Medvice, niet worden gekopieerd,
        geïmiteerd of gebruikt.
      </Element>
    </List>
    <TitleH4>Derden</TitleH4>
    <List>
      <Element numbered>
        De Dienst of communicatie die u ontvangt van de Dienst, kunnen links naar (mobiele)
        applicaties, websites of functies van derden bevatten. De Dienst kan ook de inhoud van
        derden bevatten die wij niet goedkeuren of aanbevelen. Medvice controleert geen web-diensten
        of andere inhoud van deze derden. U erkent en aanvaardt uitdrukkelijk dat Medvice op geen
        enkele wijze verantwoordelijk of aansprakelijk is voor dergelijke diensten of functies van
        derden. Wij raden u aan de voorwaarden en het privacy beleid van deze derden te lezen
      </Element>
      {/********************* PAGE 3 ********************/}
      <Element numbered>
        U kunt geheel naar eigen inzicht en risico gebruik maken van toepassingen die de Dienst of
        uw profiel op de Dienst verbinden met diensten van derden ("<Strong>Applicaties</Strong>"),
        waarna dergelijke Applicaties interactie kunnen hebben met, verbinding kunnen maken met of
        informatie kunnen verzamelen van en/of uitwisselen met uw profiel op de Dienst. Met het
        gebruik van zulke Applicaties, erkent u en gaat u akkoord met het volgende: (i) als u een
        Applicatie gebruikt om uw informatie te delen, stemt u ermee in dat informatie over uw
        profiel op de Dienst wordt gedeeld; (ii) het gebruik van een Applicatie kan leiden tot
        persoonlijk identificeerbare informatie die openbaar gemaakt kan worden, zelfs als Medvice
        deze informatie niet zelf heeft voorzien; en (iii) het gebruik van een Applicatie is naar
        eigen keuze en risico, en u vrijwaart de Medvice Partijen (zoals hieronder gedefinieerd)
        voor de activiteiten die verwant zijn aan de Applicaties.
      </Element>
    </List>
    <TitleH4>Afwijzing van garanties</TitleH4>
    <List>
      <Element numbered>
        De Dienst, inclusief Medvice Inhoud, wordt geleverd op een "zoals deze is”, “zoals deze
        beschikbaar is” en "met alle aanwezige gebreken" basis. Voor zover toegestaan door de wet,
        geeft noch Medvice noch haar bestuurders, werknemers, managers, officers, partners, filialen
        of agenten (gezamenlijk de
        <Strong>"Medvice Partijen"</Strong>) enige garantie of goedkeuring van welke aard dan ook,
        expliciet of impliciet, met betrekking tot: (a) de Dienst; (b) de Medvice Inhoud; (c)
        Gebruikers Inhoud; of (d) de veiligheid in verband met de overdracht van informatie naar
        Medvice of via de Dienst. Bovendien wijzen de Medvice Partijen hierbij alle expliciete en
        impliciete garanties af, met inbegrip van, maar niet beperkt tot garanties van
        verkoopbaarheid, geschiktheid voor een bepaald doel, niet-inbreukmakendheid, eigendom,
        maatwerk, handel, rustig genot, systeemintegratie en afwezigheid van computervirussen
      </Element>
      <Element numbered>
        U erkent dat uw gebruik van de Dienst geheel op eigen risico is. De Medvice Partijen
        garanderen niet dat uw gebruik van de Dienst bij wet is toegestaan in een bepaald
        jurisdictie en wijzen dergelijke garanties dan ook expliciet af. In sommige jurisdicties is
        het uitsluiten van impliciete of andere garanties beperkt of niet toegestaan: dat betekent
        dat de bovenstaande afwijzing in uw jurisdictie mogelijk niet op u en de Algemene
        Voorwaarden van toepassing is.
      </Element>
      <Element numbered>
        Hoewel het de intentie is van Medvice dat de Dienst zoveel mogelijk beschikbaar moet zijn,
        kunnen er momenten zijn dat de Dienst kan worden onderbroken door onderhoudswerkzaamheden,
        updates of upgrades, noodreparaties (“hotfixes”) of uitgevallen telecommunicatieverbindingen
        en/of apparatuur
      </Element>
      <Element numbered>
        Door de Dienst te gebruiken, verklaart en garandeert u dat uw activiteiten bij wet zijn
        toegestaan in elke jurisdictie waar u de Dienst gebruikt.
      </Element>
    </List>
    <TitleH4>Beperking van aansprakelijkheid</TitleH4>
    <List>
      <Element numbered>
        Voor zover niet verboden door de wet, in geen geval zullen de Medvice Partijen aansprakelijk
        zijn voor verlies of schade van welke aard dan ook (inclusief, zonder beperking, voor
        directe, indirecte, economische, voorbeeldige, speciale, straffende, incidentele,
        gevolgschade of schade die direct of indirecte te maken hebben met):
        <List>
          <Element alphabetic>de Dienst;</Element>
          <Element alphabetic>de Medvice Inhoud;</Element>
          {/********************* PAGE 4 ********************/}
          <Element alphabetic>Gebruikers Inhoud;</Element>
          <Element alphabetic>
            uw gebruik van, het onvermogen om te gebruiken, of de prestaties van de Dienst;
          </Element>
          <Element alphabetic>
            alle genomen maatregelen in verband met een onderzoek door Medvice Partijen of
            overheidsinstellingen met betrekking tot uw gebruik of gebruik van derden, van de
            Dienst.
          </Element>
          <Element alphabetic>
            alle genomen maatregelen in verband met auteursrecht of andere eigenaren van
            intellectueel eigendom;
          </Element>
          <Element alphabetic>eventuele fouten of omissies in de werking van de Dienst;</Element>
          <Element alphabetic>
            enige schade aan een de computer, mobiele apparaat of andere apparatuur van een
            gebruiker, waaronder schade als gevolg van een beveiligingsbreuk of van virussen,
            programmeerfouten, sabotage, fraude, fouten, nalatigheden, onderbrekingen, defecten,
            vertraging in de exploitatie of transmissie, computer- of netwerkuitval of enige andere
            (technische) storing, met inbegrip van, maar niet beperkt tot schade door winstderving,
            verlies van goodwill, gegevensverlies, werkonderbrekingen, onnauwkeurigheid van
            resultaten of computerstoringen, zelfs als deze te voorzien was/waren of zelfs als de
            Medvice Partijen in kennis zijn gesteld of hadden moeten weten van de mogelijkheid van
            dergelijke schade, ongeacht of deze voortvloeit uit een overeenkomst, nalatigheid,
            risicoaansprakelijkheid of onrechtmatige daad (al dan niet geheel of gedeeltelijk als
            gevolg van nalatigheid, overmacht, een telecommunicatiestoring of ontvreemding of
            vernietiging van de Dienst).
          </Element>
        </List>
      </Element>
      <Element numbered>
        In geen geval zullen de Medvice Partijen aansprakelijk zijn voor u of iemand anders zijn
        verlies, schade of letsel, met inbegrip van maar niet beperkt tot de dood of persoonlijk
        letsel. In sommige jurisdicties is uitsluiting of beperking van incidentele schade of
        gevolgschade niet toegestaan, dus mogelijk geldt de bovenstaande beperking of uitsluiting
        niet voor u. In geen geval zal de Medvice Partijen totale aansprakelijkheid jegens u voor
        alle schade, verliezen of oorzaken hoger dan het bedrag van €250 liggen.
      </Element>
      <Element numbered>
        U gaat ermee akkoord dat in het geval u of iemand anders door u, enige schade of verlies
        lijdt of enig letsel oploopt als gevolg van het handelen of nalaten van Medvice, de
        eventuele schade niet onherstelbaar is en niet voldoende is om u recht te geven om een
        gerechtelijk verbod te eisen op de exploitatie van een website, service eigendom, product of
        andere inhoud die eigendom is of onder zeggenschap staat van de Medvice Partijen en dat u
        niet het recht hebt om de ontwikkeling productie, distributie, reclame, weergave of
        exploitatie van een website eigendom product, Dienst of enige andere inhoud die eigendom is
        van of onder zeggenschap staat van de Medvice Partijen te verbieden of tegen te houden.
      </Element>
      <Element numbered>
        Medvice is niet verantwoordelijk voor de acties, inhoud, informatie of gegevens van derden
        en u vrijwaart ons, onze bestuurders, functionarissen, werknemers en agenten voor alle
        vorderingen en schade die op enigerlei voortvloeien uit een vordering tegen dergelijke
        derden.
      </Element>
      <Element numbered>
        U gaat ermee akkoord dat elke claim die u heeft en die voortvloeit uit de relatie met
        Medvice, binnen een jaar nadat de claim is ontstaan, moet worden ingediend. Anders is uw
        claim verjaard.
      </Element>
    </List>
    <TitleH4>Beperking van aansprakelijkheid</TitleH4>
    <Paragraph>
      U gaat akkoord (op verzoek van Medvice) om de Medvice Partijen te verdedigen en te vrijwaren
      van en tegen alle claims, aansprakelijkheden, schade, verliezen, onkosten inclusief de
      vergoedingen voor advocaten die voortvloeien uit of in een van de volgende (onder meer als
      gevolg van uw directe activiteiten op of via de Dienst of die namens u):
    </Paragraph>
    <List>
      <Element alphabetic>Uw toegang tot het gebruik van de Dienst;</Element>
      <Element alphabetic>
        Uw schending of vermeende schending van deze Algemene Voorwaarden;
      </Element>
      <Element alphabetic>
        Uw schending van enig recht van derden, inclusief enig recht van intellectueel eigendom,
        publiciteit, vertrouwelijkheid, eigendom of privacy recht;
      </Element>
      {/********************* PAGE 5 ********************/}
      <Element alphabetic>
        Uw schending van wetten, regels, voorschriften, codes, statuten, verordeningen, of
        voorschriften van een overheids- en semioverheidsinstanties waaronder alle regelgevende,
        administratieve en wetgevende instanties; of
      </Element>
      <Element alphabetic>Een onjuiste voorstelling door u.</Element>
    </List>
    <Paragraph>
      U zult volledig meewerken in de verdediging van een claim zoals Medvice van u vereist. In
      geval van enige kwestie waarin schadeloosstelling van u wordt gevraagd, behoudt Medvice zich
      hen recht van exclusieve verdediging van en controle over de zaak voor, en zal u in geen geval
      een vordering afwikkelen zonder voorafgaande schriftelijke toestemming van Medvice.
    </Paragraph>
    <TitleH4>Beperking van aansprakelijkheid</TitleH4>
    <Paragraph>
      Indien een bepaling van deze Algemene Voorwaarden nietig dan wel onverbindend mocht blijken te
      zijn, blijft u gebonden aan de overige bepalingen van deze Algemene Voorwaarden. Medvice zal
      de nietige dan wel onverbindende bepaling(en) vervangen door een bepaling die wel verbindend
      is en waarvan de strekking zoveel mogelijk dezelfde is als die van de ie vervangen
      bepaling(en), rekening houdend met het oogmerk van deze Algemene Voorwaarden.
    </Paragraph>
    <TitleH4>Geen rechtsverwerking</TitleH4>
    <Paragraph>
      Afstand van recht door u of Medvice kan slechts geschieden door een daartoe strekkende
      schriftelijke kennisgeving. Indien u of Medvice haar rechten uit hoofde van deze Algemene
      Voorwaarden niet uitoefent of het uitoefenen daarvan uitstelt, kan dit niet worden beschouwd
      als afstand van dat recht, of van enig ander recht uit hoofde van deze Algemene Voorwaarden.
    </Paragraph>
    <TitleH4>Geen rechtsverwerking</TitleH4>
    <List>
      <Element numbered>
        De informatie die binnen de Dienst wordt verschaft, is niet bedoeld voor distributie aan of
        gebruik door natuurlijke personen of rechtspersonen in jurisdicties of landen waar een
        dergelijke distributie of gebruik in strijd zou zijn met de wet- of regelgeving of waar het
        een vereiste is dat Medvice zich in het betreffende jurisdictie of land registreert. Wij
        behouden ons het recht voor om de beschikbaarheid van de Dienst of enig onder deel van de
        Dienst te allen tijde en naar eigen goeddunken te beperken tot personen, geografische
        gebieden of Jurisdicties, alsmede het aanbod van enig programma of product dat dan wel enige
        inhoud, Dienst of functie die Medvice biedt, te beperken.
      </Element>
      <Element numbered>
        Het is verboden om software van de Dienst te downloaden, exporteren of her-exporteren: (a)
        naar (of naar een staatsburger of inwoner van) een land waartegen de Europese Unie of de
        Verenigde Staten een handelsembargo heeft ingesteld of (b) naar personen op de lijst van
        “Specially Designated Nationals' van het Amerikaanse ministerie van Financiën of de “Table
        of Deny Orders’ van het Amerikaanse ministerie van Handel of vergelijkbare lijst van de
        Europese Unie. Door software die is gerelateerd aan de Dienst te downloaden, verklaart en
        garandeert u dat u geen staatsburger of inwoner bent van een dergelijk land en niet voorkomt
        op een dergelijke lijst.
      </Element>
    </List>
    <TitleH4>Wijzigingen</TitleH4>
    <List>
      <Element numbered>
        Wij hebben het recht om deze Algemene Voorwaarden van tijd tot tijd naar eigen goeddunken te
        wijzigen en bij te werken ("<Strong>Bijgewerkte Voorwaarden</Strong>").
      </Element>
      <Element numbered>
        Tenzij wij om juridische of administratieve redenen wijzigingen doorvoeren, zullen wij een
        redelijke termijn in acht nemen om in kennis te stellen voordat de Bijgewerkte Voorwaarden
        van kracht worden. U gaat ermee akkoord dat wij u in kennis stellen van de Bijgewerkte
        Voorwaarden door ze via de Dienst te publiceren en dat u na de ingangsdatum van de
        Bijgewerkte Voorwaarden door de Dienst te gebruiken (of door een redelijkerwijs door ons te
        bepalen handeling te verrichten) u uw akkoord verklaart met de Bijgewerkte Voorwaarden. U
        dient deze Algemene Voorwaarden en eventuele Bijgewerkte Voorwaarden derhalve door te lezen
        voordat u de Dienst gebruikt.
      </Element>
      {/********************* PAGE 6 ********************/}
      <Element numbered>
        De Bijgewerkte Voorwaarden treden in werking vanaf het moment van publicatie of vanaf een
        latere datum die kan worden aangegeven in de Bijgewerkte Voorwaarden. Deze voorwaarden
        zullen vanaf dat moment van toepassing zijn op uw gebruik van de Dienst. Deze Algemene
        Voorwaarden gelden tevens voor alle geschillen die ontstaan voorafgaand aan de ingangsdatum
        van de Bijgewerkte Voorwaarden.
      </Element>
      <Element numbered>
        De Algemene Voorwaarden zijn ook van toepassing op upgrades, bugfixes, hotfixes en updates
        van de Dienst welke de Dienst vervangen en/of aanvullen, tenzij deze upgrade of update
        gepaard gaat met Bijgewerkte Voorwaarden. In dat geval zijn de Bijgewerkte Voorwaarden van
        toepassing.
      </Element>
      <Element numbered>
        Door het gebruik van de Dienst gaat u ermee akkoord dat de Dienst zo nu en dan upgrades,
        bugfixes, hotfixes en updates, ondergaat, zodat Medvice de Dienst kan verbeteren, uitbreiden
        en verder ontwikkelen.
      </Element>
    </List>
    <TitleH4>Toepasselijk recht en forumkeuze</TitleH4>
    <List>
      <Element numbered>
        Deze Algemene Voorwaarden en alle niet-contractuele rechten en verplichtingen daaruit
        voortvloeiende, worden in alle opzichten beheerst door het Nederlands recht.
      </Element>
      <Element numbered>
        Alle geschillen tussen u en Medvice, welke mochten ontstaan naar aanleiding van de Algemene
        Voorwaarden, dan wel van overeenkomsten die daarvan het gevolg zijn, zullen in eerste
        instantie worden beslecht door de bevoegde rechter van Rechtbank Amsterdam.
      </Element>
      <Element numbered>
        De toepassing van het Weens Koopverdrag wordt uitdrukkelijk uitgesloten.
      </Element>
    </List>
    <TitleH4>Contact</TitleH4>
    <Paragraph>
      Wanneer u vragen heeft over deze Algemene Voorwaarden, kunt u contact opnemen met Medvice door
      een e-mail te sturen naar <Strong>info@medvice.io</Strong>.
    </Paragraph>
  </>
)
