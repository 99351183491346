import { background } from 'styles/Colors'

export default ({ className, color = background }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.688"
    height="11.819"
    viewBox="0 0 14.688 11.819"
    className={className}
  >
    <path
      id="Path_1513"
      data-name="Path 1513"
      d="M-17697.9,3756.637l3.395,3.257,7.057-8.2"
      transform="translate(17700.02 -3749.575)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
)
