import { memo, useMemo, useState, useRef, useCallback, Dispatch, SetStateAction } from 'react'
import isBefore from 'date-fns/isBefore'
import sameDay from 'date-fns/isSameDay'
import { getDayStart, getDayEnd, buildTimeSlots } from 'components/common/Month/Tools'
import { useTranslation } from 'services/Translation'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import CheckBox from 'components/common/inputs/CheckBox'
import TextInput from 'components/common/inputs/Text'
import UnderlineBtn from 'components/common/buttons/Underline'
import ArrowIcon from 'assets/icons/arrows/Small'
import FloatingCard from 'components/common/Card/Floating'
import usePoll, { alreadyStartedLoops } from 'hooks/usePoll'
import { getAccessTokenAndClaims } from 'services/Authentication'
import subDays from 'date-fns/subDays'
import { textPrimary, error } from 'styles/Colors'
import { ROUTES } from 'Constants'
import { hp } from 'styles/Sizes'
import FloatingSelectInput from 'components/common/inputs/SelectFloating'
import DefaultBtn from 'components/common/buttons/Default/Component'
import { useTimeOffState } from 'services/TimeOff'
import {
  useAppointmentsDispatch,
  useAppointmentsState,
  getAll as getAppointments,
} from 'services/Appointments'
import { Step, TimeWindow } from '../Types'
import { TimeGrid, GridContainer, FooterSchedule } from './Styles'
import CalendarCard from './CalendarCard'

const REFRESH_DOCTOR_APPOINTMENT = parseInt(
  process.env.REACT_APP_REFRESH_DOCTOR_APPOINTMENT || '5000',
  10
)

const durationOptions = [5, 10, 15, 20, 25, 30]

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    title: getText('Scheduled AV request', 'Patient_Details_Modal_Select_Form'),
    durationLabel: getText('Appointment duration :', 'Patient_Details_Modal_Select_Form'),
    windowLabel: getText('Appointment window :', 'Patient_Details_Modal_Select_Form'),
    afterWindow: getText('After :', 'Patient_Details_Modal_Select_Form'),
    beforeWindow: getText('Before :', 'Patient_Details_Modal_Select_Form'),
    atWindow: getText('At :', 'Patient_Details_Modal_Select_Form'),
    notesLabel: getText(
      'Please type if any note for the patient',
      'Patient_Details_Modal_Select_Form'
    ),
    notesPlaceholder: getText('Write here', 'Patient_Details_Modal_Select_Form'),
    selectCalendar: getText('Select a date', 'Patient_Details_Modal_Select_Form'),
    textAV: getText(
      'If you send out a scheduled request, he or she will receive a choice menu to pick a slot according to your availability schedule.',
      'Patient_Details_Modal_Select_Form'
    ),
    subtext: getText(
      'Please make sure your availability schedule is properly planned.',
      'Patient_Details_Modal_Select_Form'
    ),
    dateErr: getText(
      'No slots available for the selected window',
      'Patient_Details_Modal_Select_Form'
    ),
    dateWarn1: getText('Warning: only', 'Patient_Details_Modal_Select_Form'),
    dateWarn2: getText('slots left in the select window', 'Patient_Details_Modal_Select_Form'),
    btnLabelNext: getText('Next', 'Patient_Details_Modal_Select_Form'),
  }[key])

interface CalendarFloatingCardProps {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
  calendarRef: any
  value: Date | null
  onChange: (date: Date) => void
  events: any
  appointmentDuration: number
  disableBefore: Date
}

function CalendarFloatingCard({
  active,
  setActive,
  calendarRef,
  value,
  onChange,
  events,
  appointmentDuration,
  disableBefore,
}: CalendarFloatingCardProps) {
  return (
    <FloatingCard
      active={active}
      setActive={setActive}
      top={
        calendarRef &&
        calendarRef.current &&
        calendarRef.current.offsetTop + calendarRef.current.offsetHeight
      }
      left={
        calendarRef &&
        calendarRef.current &&
        calendarRef.current.offsetLeft + calendarRef.current.offsetWidth
      }
      arrowPos="right"
      paddingHorizontal={undefined}
      paddingVetical={undefined}
      style={undefined}
    >
      <CalendarCard
        field={{
          value,
          onChange,
        }}
        events={events}
        appointmentDuration={appointmentDuration}
        disableBefore={disableBefore}
      />
    </FloatingCard>
  )
}

interface SectionProps {
  fieldName: string
  value: Date | null
  resetValue: () => void
  calendarRef: any
  handleCalendarOpen: () => void
}

function Section({ fieldName, value, resetValue, calendarRef, handleCalendarOpen }: SectionProps) {
  const { t, formatDate: format } = useTranslation(texts)
  return (
    <>
      <GridContainer gridArea={`${fieldName}Button`}>
        <CheckBox
          field={{
            onChange: resetValue,
            value: value != null,
          }}
          style={{ marginRight: 10 }}
        />
        <Typography
          type="title"
          className="font-csc45"
          text={t(`${fieldName}Window`)}
          size={15}
          style={undefined}
        />
      </GridContainer>
      <GridContainer gridArea={`${fieldName}Week`} justifyContent="flex-end">
        <Typography
          type="title"
          text="1"
          color={textPrimary}
          size={18}
          style={{
            width: 18,
            color: textPrimary,
            alignSelf: 'center',
            margin: '0 auto',
            textDecoration: 'underline',
          }}
        />
      </GridContainer>
      <GridContainer gridArea={`${fieldName}Select`} justifyContent="flex-end" paddingRight={35}>
        <FloatingSelectInput
          field={{
            onChange: () => {},
            value: 0,
          }}
          size="0.75rem"
          noPadding
          options={['Week']}
          data-cy={`select${fieldName}`}
          label={undefined}
          style={undefined}
        />
      </GridContainer>
      <GridContainer gridArea={`${fieldName}Calendar`} justifyContent="flex-end">
        <div className="w-full border-l border-grey-100 flex justify-end">
          <UnderlineBtn
            innerRef={calendarRef}
            nextIcon={<ArrowIcon color={textPrimary} style={undefined} />}
            bold
            size="0.75rem"
            onClick={handleCalendarOpen}
            label={value ? format(value, 'dd MMMM, yyyy') : t('selectCalendar')}
            previousIcon={undefined}
            style={undefined}
          />
        </div>
      </GridContainer>
    </>
  )
}

interface SelectProps {
  notes: string
  setNotes: Dispatch<SetStateAction<string>>
  validTimeWindow: TimeWindow
  setValidTimeWindow: Dispatch<SetStateAction<TimeWindow>>
  duration: number
  setDuration: Dispatch<SetStateAction<number>>
  setStep: Dispatch<SetStateAction<Step>>
}

enum SelectionType {
  before,
  after,
  at,
}

const Select = ({
  notes,
  setNotes,
  validTimeWindow,
  setValidTimeWindow,
  duration,
  setDuration,
  setStep,
}: SelectProps) => {
  const { t } = useTranslation(texts)
  const beforeCalendarRef = useRef(null)
  const afterCalendarRef = useRef(null)
  const atCalendarRef = useRef(null)
  const { token, claims } = getAccessTokenAndClaims()

  const [dateError, setDateError] = useState<string | null>(null)
  const [dateWarn, setDateWarn] = useState<string | null>(null)

  const [calendarOpen, setCalendarOpen] = useState<SelectionType | null>(null)

  const handleBeforeCalendarOpen = useCallback(
    () => setCalendarOpen(s => (s === SelectionType.before ? null : SelectionType.before)),
    []
  )
  const handleAfterCalendarOpen = useCallback(
    () => setCalendarOpen(s => (s === SelectionType.after ? null : SelectionType.after)),
    []
  )
  const handleAtCalendarOpen = useCallback(
    () => setCalendarOpen(s => (s === SelectionType.at ? null : SelectionType.at)),
    []
  )

  const handleNotes = useCallback(
    (newVal: string) => {
      setNotes(newVal)
    },
    [setNotes]
  )
  const handleDuration = useCallback(
    (selectedDurationId: number) => {
      setDuration(durationOptions[selectedDurationId])
    },
    [setDuration]
  )

  const appointmentsDispatch = useAppointmentsDispatch()
  const appointmentsInterval = useCallback(async () => {
    const alreadyStarted = alreadyStartedLoops as any
    if (alreadyStarted.patientsDetailsAppointmentInterval) {
      await getAppointments({ token }, { staffId: null, appointmentsDispatch })
    }
  }, [appointmentsDispatch, token])

  usePoll(appointmentsInterval, REFRESH_DOCTOR_APPOINTMENT, {
    contextName: 'patientsDetailsAppointmentInterval',
    isRunning: Boolean(token && window.location.pathname.startsWith(ROUTES.PATIENT)),
  })

  const { list: appointments } = useAppointmentsState()
  const { list: rawTimeOffs } = useTimeOffState()
  const timeOffs = useMemo(() => (claims?.sub ? rawTimeOffs?.[claims.sub] : null) || [], [
    rawTimeOffs,
    claims,
  ])
  const events = useMemo(() => [...timeOffs, ...appointments], [timeOffs, appointments])

  const handleConfirmAppointment = useCallback(() => {
    const finalTimeSlots = buildTimeSlots(validTimeWindow, events, duration)
    const slotsLength = finalTimeSlots.reduce((acc, curr) => acc + curr?.slots?.length || 0, 0)
    if (slotsLength === 0) {
      setDateError(t('dateErr'))
      return
    }
    if (!dateWarn && slotsLength <= 5) {
      setDateWarn(`${t('dateWarn1')} ${slotsLength} ${t('dateWarn2')}`)
      return
    }

    setStep(Step.AppointmentCreation)
  }, [setStep, validTimeWindow, events, duration, t, dateWarn])

  const setTimeStart = useCallback(
    (start: Date) => {
      if (validTimeWindow.end && isBefore(validTimeWindow.end, start)) {
        return
      }

      setValidTimeWindow((w: TimeWindow) => ({
        ...w,
        start: getDayStart(start),
      }))
    },
    [validTimeWindow, setValidTimeWindow]
  )

  const setTimeEnd = useCallback(
    (end: Date) => {
      if (validTimeWindow.start && isBefore(end, validTimeWindow.start)) {
        return
      }

      setValidTimeWindow((w: TimeWindow) => ({
        ...w,
        end: getDayEnd(end),
      }))
    },
    [validTimeWindow, setValidTimeWindow]
  )

  const setTimeAt = useCallback(
    (at: Date) => {
      setTimeStart(at)
      setTimeEnd(at)
    },
    [setTimeStart, setTimeEnd]
  )

  return (
    <>
      <div className="flex flex-col text-lg py-3 mb-3">
        <Typography
          type="title"
          className="font-csc65"
          text={t('title')}
          color={textPrimary}
          size={18}
          style={undefined}
        />
        <Separator size={hp(3)} />
        <Typography
          className="font-csc45 text-medium"
          text={t('textAV')}
          color={textPrimary}
          size={15}
          style={undefined}
        />
        <Separator size={hp(3)} />
        <Typography
          type="title"
          italic
          className="font-csc65"
          text={t('subtext')}
          color={textPrimary}
          size={15}
          style={undefined}
        />
        <Separator size={hp(3.5)} />
        <div className="flex items-center">
          <Typography
            type="title"
            className="font-csc65 mr-6"
            text={t('durationLabel')}
            size={15}
            style={undefined}
          />
          <FloatingSelectInput
            field={{
              onChange: handleDuration,
              value: durationOptions.findIndex(e => e === duration),
            }}
            size="0.75rem"
            options={durationOptions.map(value => `${value} minutes`)}
            label={undefined}
            style={undefined}
            data-cy={undefined}
          />
        </div>
        <Separator size={hp(3)} />
        <Typography
          type="title"
          className="font-csc65"
          text={t('windowLabel')}
          size={15}
          style={undefined}
        />
        <TimeGrid>
          <Section
            fieldName="before"
            value={
              validTimeWindow.start &&
              validTimeWindow.end &&
              sameDay(validTimeWindow.start, validTimeWindow.end)
                ? null
                : validTimeWindow.end
            }
            resetValue={() => {
              setValidTimeWindow((w: TimeWindow) => ({ ...w, end: null }))
            }}
            calendarRef={beforeCalendarRef}
            handleCalendarOpen={handleBeforeCalendarOpen}
          />
          <Section
            fieldName="after"
            value={
              validTimeWindow.start &&
              validTimeWindow.end &&
              sameDay(validTimeWindow.start, validTimeWindow.end)
                ? null
                : validTimeWindow.start
            }
            resetValue={() => {
              setValidTimeWindow((w: TimeWindow) => ({ ...w, start: null }))
            }}
            calendarRef={afterCalendarRef}
            handleCalendarOpen={handleAfterCalendarOpen}
          />
          <Section
            fieldName="at"
            value={
              validTimeWindow.start &&
              validTimeWindow.end &&
              sameDay(validTimeWindow.start, validTimeWindow.end)
                ? validTimeWindow.start
                : null
            }
            resetValue={() => {
              setValidTimeWindow({ start: null, end: null })
            }}
            calendarRef={atCalendarRef}
            handleCalendarOpen={handleAtCalendarOpen}
          />
        </TimeGrid>
        <Separator size={hp(3)} />
        <Typography
          type="title"
          className="font-csc65"
          text={t('notesLabel')}
          size={15}
          style={undefined}
        />
        <Separator size={hp(1.7)} />
        <TextInput
          field={{
            onChange: ({ target: { value } }: any) => handleNotes(value),
            value: notes,
          }}
          noBorder
          multiline
          placeholder={t('notesPlaceholder')}
          noPadding
          name={undefined}
          style={undefined}
          label={undefined}
          inputRef={undefined}
          inputStyle={undefined}
          containerStyle={undefined}
          placeholderStyle={undefined}
          data-cy={undefined}
        />

        <CalendarFloatingCard
          active={calendarOpen === SelectionType.before}
          setActive={handleBeforeCalendarOpen}
          calendarRef={beforeCalendarRef}
          value={validTimeWindow.end}
          onChange={setTimeEnd}
          events={events}
          appointmentDuration={duration}
          disableBefore={
            validTimeWindow.start ? new Date(validTimeWindow.start) : subDays(new Date(), 1)
          }
        />
        <CalendarFloatingCard
          active={calendarOpen === SelectionType.after}
          setActive={handleAfterCalendarOpen}
          calendarRef={afterCalendarRef}
          value={validTimeWindow.start}
          onChange={setTimeStart}
          events={events}
          appointmentDuration={duration}
          disableBefore={subDays(new Date(), 1)}
        />
        <CalendarFloatingCard
          active={calendarOpen === SelectionType.at}
          setActive={handleAtCalendarOpen}
          calendarRef={atCalendarRef}
          value={validTimeWindow.start}
          onChange={setTimeAt}
          events={events}
          appointmentDuration={duration}
          disableBefore={subDays(new Date(), 1)}
        />
      </div>
      <Separator size={hp(5)} />
      <FooterSchedule>
        {dateError !== null && (
          <Typography
            italic
            className="font-csc45"
            center
            text={dateError}
            color={error}
            size={undefined}
            style={undefined}
          />
        )}
        {dateWarn !== null && (
          <Typography
            italic
            className="font-csc45"
            center
            text={dateWarn}
            color={error}
            size={undefined}
            style={undefined}
          />
        )}
        <div className="w-full flex justify-center align-center border-t border-grey-100 pt-12">
          <DefaultBtn
            label={t('btnLabelNext')}
            onClick={handleConfirmAppointment}
            type="submit"
            className="font-csc65"
            reversedColors
            color={textPrimary}
            style={{ width: '9.375rem' }}
            disabled={!(validTimeWindow.start || validTimeWindow.end)}
            nextIcon={undefined}
          />
        </div>
      </FooterSchedule>
    </>
  )
}

Select.whyDidYouRender = true

export default memo(Select)
