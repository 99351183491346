import { primary } from 'styles/Colors'

export default ({ style, color = primary }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="17.638"
    height="14.088"
    viewBox="0 0 17.638 14.088"
  >
    <g transform="translate(1 1.414)">
      <path
        d="M870.472,583.933H885.7l-5.55-5.55"
        transform="translate(-870.472 -578.383)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M886.129,584.175l-5.329,5.71"
        transform="translate(-870.904 -578.625)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)
