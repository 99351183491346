import { memo, useCallback, useState } from 'react'

import { hp } from 'styles/Sizes'
import { textPrimary } from 'styles/Colors'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import DefaultBtn from 'components/common/buttons/Default'
import TextInput from 'components/common/inputs/Text'
import LoaderView from 'components/common/loaders/LoaderView'
import Link from 'components/common/Link'
import { usePatientRouteParams } from 'components/patient/Parameters'
import { CS } from 'back-end-api'
import { useTranslation } from 'services/Translation'
import { ModalContainer, ModalFailed, ModalSucceeded } from '../Common'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    succeededLabel: getText('Referral created', 'Patient_Details_Actions_Referral'),
    btnLabelClose: getText('Close', 'Patient_Details_Actions_Referral'),
    referralInputLabel: getText('Please type your referral', 'Patient_Details_Actions_Referral'),
    textPlaceholder: getText('Write referral here', 'Patient_Details_Actions_Referral'),
    btnReferralGeneration: getText('Generate referral', 'Patient_Details_Actions_Referral'),
    existingReferralTitle: getText('Given referral', 'Patient_Details_Actions_Referral'),
    openReferralLabel: getText('Open referral', 'Patient_Details_Actions_Referral'),
  }[key])

enum Status {
  Pending,
  InProgress,
  Succeeded,
  Failed,
  Exist,
}

interface ReferralProps {
  onClose: () => void
}

const Referral = ({ onClose }: ReferralProps) => {
  const { t, lang } = useTranslation(texts)
  const [error, setError] = useState<string>('')
  const [text, setText] = useState('')
  const { summary } = usePatientRouteParams()

  const [status, setStatus] = useState(summary?.referral?.url ? Status.Exist : Status.Pending)

  const openLink = useCallback((to: string) => {
    window.open(to)
  }, [])

  const submit = useCallback(async () => {
    setStatus(Status.InProgress)

    const resCreateReferral = await CS.setSummaryReferral({
      id: summary?.id ?? 0,
      language_tag: lang,
      text,
    })

    if (resCreateReferral.error) {
      switch (resCreateReferral.error.status) {
        case 400:
          setError('Internal error')
          break
        case 500:
          setError('Server error')
          break
        default:
          setError(resCreateReferral.error?.message ?? 'Unknown error')
      }

      setStatus(Status.Failed)
      return
    }

    setStatus(Status.Succeeded)
  }, [summary, lang, text])

  switch (status) {
    case Status.InProgress:
      return <LoaderView onClose={onClose} />
    case Status.Failed:
      return (
        <ModalFailed errorMsg={error} containerProps={{ label: t('btnLabelClose'), onClose }} />
      )
    case Status.Succeeded:
      return (
        <ModalSucceeded
          label={t('succeededLabel')}
          containerProps={{ label: t('btnLabelClose'), onClose }}
        />
      )
    case Status.Pending:
      return (
        <ModalContainer onClose={onClose} label={t('btnLabelClose')}>
          <Typography
            type="title"
            className="font-csc65"
            text={t('referralInputLabel')}
            color={textPrimary}
            size={18}
            style={undefined}
          />
          <Separator size={hp(3)} />
          <TextInput
            field={{
              onChange: ({ target: { value } }: any) => setText(value),
              value: text,
            }}
            noBorder
            multiline
            placeholder={t('textPlaceholder')}
            noPadding
            data-cy="inputBefore"
            style={{
              height: 500,
              overflow: 'auto',
            }}
            label={undefined}
            inputRef={undefined}
            inputStyle={undefined}
            containerStyle={undefined}
            placeholderStyle={undefined}
          />
          <Separator size={hp(10.2)} />
          <div className="w-full flex justify-center align-center border-t border-grey-100 pt-12">
            <DefaultBtn
              label={t('btnReferralGeneration')}
              onClick={submit}
              type="submit"
              className="font-csc65"
              reversedColors
              color={textPrimary}
              style={{ width: '9.375rem' }}
              data-cy="btnSubmit"
              nextIcon={undefined}
              disabled={text === ''}
            />
          </div>
        </ModalContainer>
      )
    case Status.Exist:
      return (
        <ModalContainer onClose={onClose} label={t('btnLabelClose')}>
          <Typography
            type="title"
            className="font-csc65"
            text={t('existingReferralTitle')}
            color={textPrimary}
            size={18}
            style={undefined}
          />
          <Separator size={hp(3)} />
          <Link
            underlineOnHover
            to={summary?.referral?.url ?? ''}
            onClick={() => openLink(summary?.referral?.url ?? '')}
            target="_blank"
            label={t('openReferralLabel')}
          />
        </ModalContainer>
      )

    default:
      console.error('Unhandled status: ', status)
      return null
  }
}

Referral.whyDidYouRender = true

export default memo(Referral)
