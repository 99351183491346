import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`
export const Content = styled.div`
  position: relative;
  width: 100%;
  padding-left: 6.5rem;
  padding-right: 5rem;
`
