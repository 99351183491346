/* eslint-disable */
// order matters when rotating
import { BODYPARTS_DATA, ORIENTATION } from './constants'
import frontData from './views/frontData'
import leftData from './views/leftData'
import backData from './views/backData'
import rightData from './views/rightData'

export const VIEWS = {
  [ORIENTATION.FRONT]: frontData,
  [ORIENTATION.LEFT]: leftData,
  [ORIENTATION.BACK]: backData,
  [ORIENTATION.RIGHT]: rightData,
}

export const getBodyPartOrientation = bodypartName => {
  return BODYPARTS_DATA[bodypartName].orientation
}

/**
 * Find bodypart in the data provided
 * @param {func} testFn
 * @param {*} partData
 * Note: This function is recursive because we need to get in zoomable areas of the body.
 */
const findBodyPart = (testFn, partData) => {
  if (testFn(partData)) return partData
  if (partData.areas) {
    for (const area of partData.areas) {
      const result = findBodyPart(testFn, area)
      if (result) {
        // return as soon as possible
        return result
      }
    }
  }
}

export const findBodyPartByName = name => {
  return findBodyPart(part => part.name === name, VIEWS[getBodyPartOrientation(name)])
}

export const findBodyPartParent = bodypartName => {
  const orientation = getBodyPartOrientation(bodypartName)
  return findBodyPart(
    ({ areas }) => areas && areas.some(({ name }) => name === bodypartName),
    VIEWS[orientation]
  )
}
