export default ({ style, color = '#a4a4a4' }) => (
  <svg width="8.25" height="5.125" viewBox="0 0 8.25 5.125" style={style}>
    <path
      d="M-14936.295-21763.586l2.711,2.711-2.711,2.711"
      transform="translate(-21756.75 14937.709) rotate(90)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)
