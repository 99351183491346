import { memo } from 'react'

import { textPrimary } from 'styles/Colors'
import SmallArrowIcon from 'assets/icons/arrows/Select'

import { NextIcon, Label, Container } from './Styles'

const UnderlineBtn = ({
  label = '',
  onClick = () => {},
  color = textPrimary,
  bold = false,
  withNext = false,
  italic = false,
  size = '0.9375rem',
  disabled = false,
  className = '',
  innerRef,
  previousIcon,
  nextIcon,
  style,
  ...props
}) => {
  return (
    <Container
      onClick={disabled ? undefined : onClick}
      style={style}
      disabled={disabled}
      innerRef={innerRef}
      type="button"
      className={`${
        disabled && 'opacity-30'
      } flex items-center justify-center font-csc65 text-medium ${className}`}
      {...props}
    >
      {previousIcon && <div className="mr-3">{previousIcon}</div>}
      <Label className="font-csc45" size={size} color={color} bold={bold} italic={italic}>
        {label}
      </Label>
      {withNext && (
        <NextIcon className="mr-3">
          <SmallArrowIcon style={{ transform: 'rotate(-90deg)' }} className="ml-3" />
        </NextIcon>
      )}
      {nextIcon && <div className="pl-6">{nextIcon}</div>}
    </Container>
  )
}

UnderlineBtn.whyDidYouRender = true

export default memo(UnderlineBtn)
