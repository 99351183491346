import { memo, CSSProperties, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Card from 'components/common/Card'
import MaleIcon from 'assets/icons/gender/Male'
import FemaleIcon from 'assets/icons/gender/Female'
import { useTranslation } from 'services/Translation'
import { CS, CS_m } from 'back-end-api'
import { ROUTES } from 'Constants'
import { getStatusText, getStatusColor, useStatusTranslation } from 'services/Registrations'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    medicalQuestion: getText('Medical question', 'Sidebar_Encounter_Element'),
  }[key])

interface ElementProps {
  registration: CS_m.ModelsRegistration
  style: CSSProperties
}

const Element = ({ registration, style }: ElementProps) => {
  const history = useHistory()
  const { formatDate, t } = useTranslation(texts)
  const tStatus = useStatusTranslation()

  const goToRegistration = useCallback(() => {
    history.push(`${ROUTES.PATIENT}${registration.id}`)
  }, [history, registration])

  return (
    <Card
      withMark
      markColor="#6C5BFF"
      className="mt-7 p-1.5 grid grid-rows-2 justify-items-center items-center hover:shadow-cxl transition"
      onClick={goToRegistration}
      style={{
        height: '4.5rem',
        gridTemplateAreas: '"time name name" "time id type"',
        gridTemplateColumns: '6.25rem repeat(2, 1fr)',
        ...style,
      }}
      innerRef={undefined}
      animate={undefined}
      variants={undefined}
      initial={undefined}
    >
      <div
        className="text-purple-200 h-full border-r pr-3 justify-center border-grey-100 font-csc65 items-center flex justify-self-stretch"
        style={{ gridArea: 'time', fontSize: '0.800rem' }}
      >
        {formatDate(new Date(registration.created_at), 'd MMMM')}
        <br />
        {formatDate(new Date(registration.created_at), 'h:mm a')}
      </div>

      <div
        className="text-blue-900 font-csc65 w-full flex items-center pl-5"
        style={{ gridArea: 'name', fontSize: '0.800rem' }}
      >
        <div className="truncate">{`${registration.first_name || ''} ${
          registration.last_name || ''
        }`}</div>
        {registration.gender === 'male' ? (
          <MaleIcon className="ml-2.5" />
        ) : (
          <FemaleIcon className="ml-2.5 -mt-1" />
        )}
      </div>

      <div
        className="text-blue-900 pl-3 self-end justify-self-start w-full border-r border-grey-100"
        style={{ gridArea: 'id', fontSize: '0.625rem' }}
      >
        <div className="font-csc45 inline-block text-xs">
          {CS.getSummary(registration)?.complaint?.name || t('medicalQuestion')}
        </div>
      </div>

      <div
        className="flex items-center self-end justify-self-end pr-1"
        style={{ gridArea: 'type' }}
      >
        <div
          style={{ fontSize: '0.625rem', color: getStatusColor(registration), lineHeight: 1.2 }}
          className="font-csc45 flex justify-center items-center"
        >
          {getStatusText(registration, tStatus)}
        </div>
      </div>
    </Card>
  )
}

Element.whyDidYouRender = true

export default memo(Element)
