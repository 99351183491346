import { memo } from 'react'
import { textPrimary, placeholderTextColor } from 'styles/Colors'

import { Oval } from 'react-loader-spinner'

const OvalLoader = ({ center, style = {} }) => {
  return (
    <div>
      <Oval
        style={
          center
            ? {
                margin: 'auto',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...style,
              }
            : style
        }
        ariaLabel="loading-indicator"
        height={50}
        width={50}
        strokeWidth={5}
        color={textPrimary}
        secondaryColor={placeholderTextColor}
      />
    </div>
  )
}

OvalLoader.whyDidYouRender = true

export default memo(OvalLoader)
