import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ImageContainer = styled(motion.div)`
  height: 400px;
  width: 70%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
`
