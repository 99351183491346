import { primary } from 'styles/Colors'

export default ({ color = primary }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.403"
    height="14.657"
    viewBox="0 0 21.403 14.657"
  >
    <g transform="translate(0.004 -0.002)">
      <path
        d="M109.181,97.36a1.7,1.7,0,0,1-1.7-1.7,1.7,1.7,0,1,0,1.7,1.7Z"
        transform="translate(-96.848 -87.571)"
        fill={color}
      />
      <path
        d="M15.427,63.644a.592.592,0,0,1-.456-.969A13.322,13.322,0,0,1,23.71,58.2c3.434-.188,6.812,1.3,10.036,4.428a.592.592,0,1,1-.824.85c-2.977-2.887-6.056-4.264-9.148-4.1a12.252,12.252,0,0,0-7.892,4.049.592.592,0,0,1-.455.217Z"
        transform="translate(-13.593 -53.264)"
        fill={color}
      />
      <path
        d="M24.436,114.208c-3.238,0-6.417-1.492-9.462-4.444a.592.592,0,1,1,.824-.85c2.977,2.886,6.055,4.264,9.148,4.1a12.25,12.25,0,0,0,7.891-4.049.594.594,0,1,1,.916.755,13.322,13.322,0,0,1-8.738,4.476Q24.722,114.208,24.436,114.208Z"
        transform="translate(-13.555 -99.551)"
        fill={color}
      />
      <path
        d="M73.045,71.058a4.726,4.726,0,1,1,4.876-4.724A4.807,4.807,0,0,1,73.045,71.058Zm0-8.264a3.543,3.543,0,1,0,3.692,3.54A3.622,3.622,0,0,0,73.045,62.794Z"
        transform="translate(-62.418 -56.4)"
        fill={color}
      />
      <path
        d="M20.957,5.326a.592.592,0,0,1-.386-.143C16.243,1.462,11.662.326,6.957,1.8A15.8,15.8,0,0,0,1.145,5.151a.592.592,0,0,1-.834-.84A16.8,16.8,0,0,1,6.6.675,13.882,13.882,0,0,1,13.327.218,17.017,17.017,0,0,1,21.34,4.282a.592.592,0,0,1-.383,1.043Z"
        transform="translate(-0.149 0.002)"
        fill={color}
      />
    </g>
  </svg>
)
