import styled from 'styled-components'

import { link } from 'styles/Colors'
import { hp, wp } from 'styles/Sizes'

export const Container = styled.div`
  width: 100%;
  height: ${hp(9.7)}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 ${wp(5.7)}px;
  position: relative;
`
export const Title = styled.div`
  color: ${link};
  font-size: 17px;
  line-height: 8px;
  text-decoration: underline;
  margin-bottom: 5px;
`
export const Version = styled.div`
  display: inline-block;
  margin-left: ${wp(7.8)}px;
  text-decoration: none;
`
