import { memo, useCallback } from 'react'
import DataTable from 'react-data-table-component'
import { CS_m } from 'back-end-api'
import LoaderMini from 'components/common/loaders/mini'
import { useTranslation } from 'services/Translation'
import { useMiscDispatch } from 'services/Misc'
import { setAppointmentSideBar, AppointmentSideBar } from 'services/Misc/appointmentSideBar'
import { greySecondary, greyPrimary } from 'styles/Colors'
import { getStatusColor } from 'services/Registrations'
import { Container, TimeCell, TypeCell } from './Styles'

const ClickableCell = ({ children, onClick }: any) => (
  <div role="presentation" onClick={onClick} onKeyDown={onClick}>
    {children}
  </div>
)

const columns = (
  t: (key: string) => string,
  format: (date: Date, pattern: string) => string,
  typeTable: string,
  handleRowClicked: (row: CS_m.ModelsAppointment) => void
) => [
  {
    name: typeTable,
    cell: (a: CS_m.ModelsAppointment) => (
      <ClickableCell
        onClick={() => {
          handleRowClicked(a)
        }}
      >
        <TimeCell
          color={getStatusColor(a.summary!.registration!)}
          className="w-full py-2.5  text-xl font-csc45"
        >
          <div className="flex items-center pr-9 w-full h-12 text-xl font-csc45 border-r border-grey-100">
            {a.datetime ? format(new Date(a.datetime), 'h:mm a') : '-'}
          </div>
        </TimeCell>
      </ClickableCell>
    ),
    width: '150px',
    conditionalCellStyles: [
      {
        when: (a: CS_m.ModelsAppointment) =>
          a.summary?.status === CS_m.ModelsSummaryStatus.consulted ||
          a.summary?.status === CS_m.ModelsSummaryStatus.cancelled,
        style: {
          color: `${greyPrimary} !important`,
          borderColor: `${greySecondary} !important`,
        },
      },
    ],
  },
  {
    name: typeTable === 'Mornings' ? '00:00 AM to 12:00 AM' : '00:00 PM to 12:00 PM',
    cell: (a: CS_m.ModelsAppointment) => (
      <ClickableCell
        onClick={() => {
          handleRowClicked(a)
        }}
      >
        <div className="w-full font-csc65 border-r border-grey-100 py-2.5">
          {a.summary?.registration?.first_name} {a.summary?.registration?.last_name}
        </div>
      </ClickableCell>
    ),
    minWidth: '200px',
  },
  {
    name: '',
    cell: (a: CS_m.ModelsAppointment) => (
      <ClickableCell
        onClick={() => {
          handleRowClicked(a)
        }}
      >
        <div className="flex flex-col pl-1.5 hover:text-purple-200">
          <div style={{ fontSize: '15px' }} className="font-csc65 text-grey-200">
            {t('age')}
          </div>
          <div style={{ fontSize: '15px' }} className="pt-1 font-csc45">
            {a.summary?.registration?.age}
          </div>
        </div>
      </ClickableCell>
    ),
    center: false,
    width: '80px',
  },
  {
    name: '',
    cell: (a: CS_m.ModelsAppointment) => (
      <ClickableCell
        onClick={() => {
          handleRowClicked(a)
        }}
      >
        <div className="flex flex-col pl-1.5">
          <div style={{ fontSize: '15px' }} className="font-csc65 text-grey-200">
            {t('sex')}
          </div>
          <div style={{ fontSize: '15px' }} className="pt-1 font-csc45">
            {t(a.summary?.registration?.gender || 'male')}
          </div>
        </div>
      </ClickableCell>
    ),
    width: '80px',
  },
  {
    name: '',
    cell: (a: CS_m.ModelsAppointment) => (
      <ClickableCell
        onClick={() => {
          handleRowClicked(a)
        }}
      >
        <div className="flex flex-col pl-1.5">
          <div style={{ fontSize: '15px' }} className="font-csc65 text-grey-200">
            {t('reason')}
          </div>
          <div style={{ fontSize: '15px' }} className="pt-1 font-csc45">
            {a?.summary?.complaint?.name || 'Medical question'}
          </div>
        </div>
      </ClickableCell>
    ),
    width: '615px',
  },
  {
    name: '',
    cell: (a: CS_m.ModelsAppointment) => (
      <ClickableCell
        onClick={() => {
          handleRowClicked(a)
        }}
      >
        <TypeCell
          color={getStatusColor(a.summary!.registration!)}
          status={a.status}
          className="font-csc65 pl-14 pr-0 py-9 flex items-center border-l border-grey-100"
        />
      </ClickableCell>
    ),
  },
]

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    age: getText('Age', 'Patient_List_Table'),
    sex: getText('Sex', 'Patient_List_Table'),
    reason: getText('Reason', 'Patient_List_Table'),
    male: getText('Male', 'Patient_List_Table'),
    female: getText('Female', 'Patient_List_Table'),
    empty: getText('There are no records to display', 'Patient_List_Table'),
  }[key])

interface TableProps {
  appointments: CS_m.ModelsAppointment[]
  loading: boolean
  date: Date
}

const Table = ({ appointments, loading, date }: TableProps) => {
  const miscDispatch = useMiscDispatch()
  const { t, formatDate } = useTranslation(texts)

  const filtered = appointments
    ?.filter(
      a =>
        a.datetime &&
        new Date(a.datetime).getDate() === new Date(date).getDate() &&
        new Date(a.datetime).getMonth() === new Date(date).getMonth()
    )
    .sort((a, b) => {
      if (a.datetime && b.datetime) {
        return new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
      }
      return 0
    })

  const morningAppointments = filtered?.filter(a => {
    return a.datetime && new Date(a.datetime).getHours() <= 12
  })

  const afternoonAppointments = filtered?.filter(a => {
    return a.datetime && new Date(a.datetime).getHours() > 12
  })

  const handleRowClicked = useCallback(
    (appointment: CS_m.ModelsAppointment) => {
      setAppointmentSideBar(miscDispatch, {
        type: AppointmentSideBar.AppointmentDetails,
        appointmentDetailsSideBarProps: { registrationId: appointment.registration_id },
      })
    },
    [miscDispatch]
  )

  return (
    <Container height="40rem">
      <DataTable
        noDataComponent={t('empty')}
        progressPending={loading}
        progressComponent={<LoaderMini />}
        columns={columns(t, formatDate, 'Mornings', handleRowClicked)}
        data={morningAppointments}
        noHeader
        fixedHeader
        fixedHeaderScrollHeight="unset"
        dense
        onRowClicked={handleRowClicked}
      />
      <DataTable
        noDataComponent={t('empty')}
        progressPending={loading}
        progressComponent={<LoaderMini />}
        columns={columns(t, formatDate, 'Afternoons', handleRowClicked)}
        data={afternoonAppointments}
        noHeader
        fixedHeader
        fixedHeaderScrollHeight="unset"
        dense
        onRowClicked={handleRowClicked}
      />
    </Container>
  )
}

Table.whyDidYouRender = true

export default memo(Table)
