export const footerVariants = {
  initial: {
    height: 0,
  },
  open: {
    height: '6rem',
  },
}

export const shadowVariants = {
  show: {
    top: '-3rem',
  },
  hide: {
    top: '-9rem',
  },
}
