import { memo } from 'react'
import { getClaims } from 'services/Authentication'
import { Container } from './Styles'
import Tabs from './Tabs'

const Header = ({ setTab, tab, date }) => {
  const claims = getClaims()

  return (
    <Container>
      <Tabs setTab={setTab} tab={tab} role={claims?.role} date={date} />
    </Container>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
