import styled from 'styled-components'

import { greyPrimary } from 'styles/Colors'

export const TitleH2 = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 32px;
`
export const TitleH3 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 24px;
`
export const TitleH4 = styled.div`
  font-size: 20px;
  margin-top: 18px;
  margin-bottom: 18px;
`
export const Paragraph = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
`
export const Strong = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
`
export const List = styled.div`
  padding-left: 24px;
  counter-reset: number;
`
export const Element = styled(({ numbered, alphabetic, ...props }) => <div {...props} />)`
  position: relative;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 14px;
  counter-increment: number;
  &::before {
    content: ${({ numbered, alphabetic }) =>
      numbered
        ? "counter(number, decimal)'.'"
        : alphabetic
        ? "counter(number, lower-alpha)'.'"
        : '•'};
    position: absolute;
    top: 0;
    left: 0;
  }
`
export const Link = styled.a`
  font-size: 14px;
`
export const Info = styled.div``
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${greyPrimary};
  margin: 40px 0 32px 0;
`
