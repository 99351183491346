import { memo, Fragment } from 'react'
import styled from 'styled-components'

import { error, primary } from 'styles/Colors'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'

export const Container = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface ArrayCellProps {
  data: string[]
}

const ArrayCell = ({ data }: ArrayCellProps) => {
  return (
    <Container data-tag="___react-data-table-allow-propagation___">
      {!data.length ? (
        <Typography
          data-tag="___react-data-table-allow-propagation___"
          text="-"
          size={14 * 0.8}
          color={primary}
          className="pl-6"
          style={undefined}
        />
      ) : (
        data.slice(0, 3).map((el, idx) => {
          return (
            <Fragment key={idx}>
              {!!idx && (
                <Separator size={8 * 0.8} data-tag="___react-data-table-allow-propagation___" />
              )}
              <Typography
                data-tag="___react-data-table-allow-propagation___"
                text={data.length >= 4 && idx === 2 ? '...' : el}
                size={14 * 0.8}
                color={error}
                center
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              />
            </Fragment>
          )
        })
      )}
    </Container>
  )
}

ArrayCell.whyDidYouRender = true

export default memo(ArrayCell)
