/* eslint-disable no-nested-ternary */
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import subDays from 'date-fns/subDays'
import isBefore from 'date-fns/isBefore'
import setDate from 'date-fns/setDate'
import isSameDay from 'date-fns/isSameDay'
import addDays from 'date-fns/addDays'
import { CS_m } from 'back-end-api'
import addWeeks from 'date-fns/addWeeks'
import addMonths from 'date-fns/addMonths'
import addYears from 'date-fns/addYears'
import getDate from 'date-fns/getDate'
import getDayOfYear from 'date-fns/getDayOfYear'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import addMinutes from 'date-fns/addMinutes'
import min from 'date-fns/min'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import isToday from 'date-fns/isToday'
import isAfter from 'date-fns/isAfter'

export const staticDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]
export const mondayFirstDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const getNumberList = (year, month, days, disableBefore, format) => {
  // Setting the first day of current month
  const firstDayOfMonth = new Date(year, month, 1)

  // Checking the name of the first day of the month
  const daysToGetMonday = days.findIndex(el => el === format(firstDayOfMonth, 'E'))
  // Setting the last monday depending of the first day of current month
  const firstDayOfWeek = daysToGetMonday
    ? subDays(firstDayOfMonth, daysToGetMonday)
    : firstDayOfMonth
  // Getting the number of the last day of the previous month
  // Except if firstDayOfWeek === firstDayOfMonth

  const lastDayOfPrevMonthNb = daysToGetMonday ? lastDayOfMonth(firstDayOfWeek).getDate() : null
  const lastDayOfCurrMonthNb = lastDayOfMonth(firstDayOfMonth).getDate()
  const firstDayOfWeekNb = firstDayOfWeek.getDate()

  const daysToRemove = lastDayOfPrevMonthNb ? lastDayOfPrevMonthNb - firstDayOfWeekNb : -1
  let finishedPreviousMonth = !lastDayOfPrevMonthNb
  let finishedCurrentMonth = false
  const out = []

  for (let i = 0; i < 42; i++) {
    if (firstDayOfWeekNb + i > lastDayOfPrevMonthNb) finishedPreviousMonth = true
    if (i - daysToRemove > lastDayOfCurrMonthNb) finishedCurrentMonth = true

    const currentDate = new Date(
      year,
      month,
      !finishedPreviousMonth
        ? firstDayOfWeekNb + i
        : finishedCurrentMonth
        ? i - daysToRemove - lastDayOfCurrMonthNb
        : i - daysToRemove
    )

    const isBeforeDate = disableBefore && isBefore(currentDate, disableBefore)

    out.push({
      disabled: isBeforeDate || !finishedPreviousMonth || finishedCurrentMonth,
      value: !finishedPreviousMonth
        ? firstDayOfWeekNb + i
        : finishedCurrentMonth
        ? i - daysToRemove - lastDayOfCurrMonthNb
        : i - daysToRemove,
    })
  }
  return out
}

export const getNumberWeekList = (year, month, days, format) => {
  const firstDayOfMonth = new Date(year, month, 1)
  const today = new Date().getDate()

  // Checking the name of the first day of the month
  const daysToGetMonday = days.findIndex(el => el === format(firstDayOfMonth, 'E'))
  // Setting the last monday depending of the first day of current month
  const firstDayOfWeek = daysToGetMonday
    ? subDays(firstDayOfMonth, daysToGetMonday)
    : firstDayOfMonth
  // Getting the number of the last day of the previous month
  // Except if firstDayOfWeek === firstDayOfMonth

  const lastDayOfPrevMonthNb = daysToGetMonday ? lastDayOfMonth(firstDayOfWeek).getDate() : null
  const firstDayOfWeekNb = firstDayOfWeek.getDate()

  const daysToRemove = lastDayOfPrevMonthNb ? lastDayOfPrevMonthNb - firstDayOfWeekNb : -1
  let finishedPreviousMonth = !lastDayOfPrevMonthNb
  const out = []

  for (let i = today; i < today + 7; i++) {
    if (firstDayOfWeekNb + i > lastDayOfPrevMonthNb) finishedPreviousMonth = true
    out.push({
      disabled: false,
      // disabled: i === 5 || i === 6,
      value: !finishedPreviousMonth
        ? new Date(setDate(firstDayOfWeek, firstDayOfWeekNb + i)).getDate()
        : new Date(setDate(firstDayOfMonth, i - daysToRemove + 1)).getDate(),
    })
  }
  return out
}

export const isDayAffectedByEvent = (day, { time: startDay, period, frequency, until }) => {
  // Calculate every events one by one until we are today, or we pass today
  let testingDay = new Date(startDay)
  const dayDate = new Date(day)
  const untilDay = until ? new Date(until) : null
  while (
    (isBefore(testingDay, dayDate) && (!until || isBefore(testingDay, untilDay))) ||
    isSameDay(testingDay, dayDate)
  ) {
    if (isSameDay(testingDay, dayDate)) return true
    switch (frequency) {
      case CS_m.IntervalRepeatFrequency.daily: {
        testingDay = addDays(testingDay, period)
        break
      }
      case CS_m.IntervalRepeatFrequency.weekly: {
        testingDay = addWeeks(testingDay, period)
        break
      }
      case CS_m.IntervalRepeatFrequency.monthly: {
        testingDay = addMonths(testingDay, period)
        break
      }
      case CS_m.IntervalRepeatFrequency.yearly: {
        testingDay = addYears(testingDay, period)
        break
      }
      default:
        return false
    }
  }
  return false
}

const getTimeBasedOnDay = (day, time) => {
  const finalTime = new Date(day)
  finalTime.setHours(time.getHours())
  finalTime.setMinutes(time.getMinutes())
  return finalTime
}

export const getTimeOffsForDay = (day, events) => {
  const out = []
  const repeatable = events?.filter(
    e =>
      e.repeat?.frequency &&
      isBefore(new Date(e.time), day) &&
      (!e.repeat?.until || isBefore(day, new Date(e.repeat.until)))
  )

  repeatable?.forEach(({ repeat, time, duration, ...props }) => {
    const { frequency, period, until } = repeat || {}
    const testingDay = new Date(time)
    // If frequency + period fall on that day, add duration & time to out.
    switch (frequency) {
      case CS_m.IntervalRepeatFrequency.daily: {
        if (period === 1 || isDayAffectedByEvent(day, { time, period, frequency, until })) {
          out.push({
            time: getTimeBasedOnDay(day, testingDay),
            duration,
            repeat,
            ...props,
          })
        }

        break
      }
      case CS_m.IntervalRepeatFrequency.weekly: {
        // If this is the same weekDay as today, we calculate if it falls on today. Otherwise do nothing.
        // Example: every week on friday cannot happens a monday.
        if (
          testingDay.getDay() === day.getDay() &&
          isDayAffectedByEvent(day, { time, period, frequency, until })
        ) {
          out.push({
            time: getTimeBasedOnDay(day, testingDay),
            duration,
            repeat,
            ...props,
          })
        }
        break
      }
      case CS_m.IntervalRepeatFrequency.monthly: {
        // Only check if this is the same month day
        if (
          getDate(day) === getDate(time) &&
          isDayAffectedByEvent(day, { time, period, frequency, until })
        ) {
          out.push({
            time: getTimeBasedOnDay(day, testingDay),
            duration,
            repeat,
            ...props,
          })
        }
        break
      }
      case CS_m.IntervalRepeatFrequency.yearly: {
        // Only check if this is the same day of year
        if (
          getDayOfYear(day) === getDayOfYear(time) &&
          isDayAffectedByEvent(day, { time, frequency, period, until })
        ) {
          out.push({
            time: getTimeBasedOnDay(day, testingDay),
            duration,
            repeat,
            ...props,
          })
        }
        break
      }
    }
  })
  const singleTimeOffs = events?.filter(e => isSameDay(new Date(e.time), day) && !e.patientId)
  return [...out, ...singleTimeOffs]
}

export const getDayStart = day => {
  const startingDay = new Date(day)
  startingDay.setHours(0)
  startingDay.setMinutes(0)
  startingDay.setSeconds(0)
  startingDay.setMilliseconds(0)
  return startingDay
}

export const getDayEnd = day => {
  const endingDay = new Date(day)
  endingDay.setHours(23)
  endingDay.setMinutes(59)
  endingDay.setSeconds(59)
  endingDay.setMilliseconds(0)
  return endingDay
}

export const getAvailableTimes = (events, currentDate) => {
  const currentDayStart = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  )
  const currentDayEnd = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1
  )

  const sortedEvents = events.sort((a, b) => new Date(a.time) - new Date(b.time))
  const availableSlots = []

  let availableSlotStartTime = currentDayStart

  sortedEvents.forEach(event => {
    const eventTime = new Date(event.time)
    const eventDuration = event.duration
    const eventEndTime = new Date(eventTime.getTime() + eventDuration)

    if (eventTime > availableSlotStartTime) {
      const availableSlotDuration = eventTime.getTime() - availableSlotStartTime.getTime()
      availableSlots.push({ time: availableSlotStartTime, duration: availableSlotDuration })
    }

    if (eventEndTime > availableSlotStartTime) {
      availableSlotStartTime = eventEndTime
    }
  })

  if (availableSlotStartTime < currentDayEnd) {
    const availableSlotDuration = currentDayEnd.getTime() - availableSlotStartTime.getTime()
    availableSlots.push({ time: availableSlotStartTime, duration: availableSlotDuration })
  }

  return availableSlots
}

const MAX_MINUTES = 10
const MAX_MINUTES_MS = MAX_MINUTES * 60000

export const splitAvailableTimes = slots => {
  const result = slots.flatMap(slot => {
    const { time, duration } = slot
    const numSubSlots = Math.max(Math.ceil(duration / MAX_MINUTES_MS), 1)
    const subSlotDuration = Math.ceil(duration / numSubSlots)

    return Array.from({ length: numSubSlots }, (_, index) => {
      const subSlotTime = new Date(time.getTime() + index * subSlotDuration)
      const subSlotDurationActual = Math.min(subSlotDuration, duration - index * subSlotDuration)
      return { time: subSlotTime, duration: subSlotDurationActual }
    })
  })

  return result
}

export const extractPreviousSlots = days => {
  const now = new Date()
  const out = []

  days.forEach(day => {
    if (isToday(day.date)) {
      const currentDay = { ...day, slots: [] }
      day.slots.forEach(({ time, ...slot }) => {
        if (isAfter(time, now)) currentDay.slots.push({ time, ...slot })
      })
      if (currentDay.slots?.length) out.push(currentDay)
    } else out.push(day)
  })
  return out
}

export const buildTimeSlots = (validDateTimeWindow, timeOffs, durationMinutes) => {
  const out = []
  let intervalDays = []
  const now = new Date()
  const validEnd = validDateTimeWindow?.end ? new Date(validDateTimeWindow.end) : null
  const validBegin = validDateTimeWindow?.start ? new Date(validDateTimeWindow.start) : null

  if (validEnd && validBegin) {
    if (isSameDay(validEnd, validBegin)) intervalDays.push(new Date(validEnd))
    else {
      const maxDay = min([addDays(validBegin, 7), validEnd])
      intervalDays = eachDayOfInterval({
        start: validBegin,
        end: new Date(maxDay),
      })
    }
  } else if (validBegin) {
    // load only 7 days
    intervalDays = eachDayOfInterval({
      start: validBegin,
      end: addDays(validBegin, 7),
    })
  } else if (validEnd) {
    const maxDay = min([addDays(now, 7), validEnd])
    intervalDays = eachDayOfInterval({ start: now, end: maxDay })
  } else {
    // nothing specific, loading the next 7 days
    intervalDays = eachDayOfInterval({
      start: now,
      end: addDays(now, 7),
    })
  }

  intervalDays.forEach(day => {
    const beginTime = getDayStart(day)
    const endTime = getDayEnd(day)
    const openedMinutes = differenceInMinutes(endTime, beginTime)

    const dayToPush = {
      id: `${getDayOfYear(day)} - ${day.getFullYear()}`,
      date: day,
      slots: [],
    }
    const singleEventsOfTheDay = timeOffs.filter(
      e => !e.repeat?.frequency && isSameDay(new Date(e.time), day)
    )
    const timeOffsOfTheDay = getTimeOffsForDay(day, timeOffs)
    const eventsOfTheDay = [...singleEventsOfTheDay, ...timeOffsOfTheDay].sort((a, b) =>
      isBefore(new Date(a.time), new Date(b.time)) ? -1 : 1
    )

    if (!eventsOfTheDay.length) {
      // No appointments in the day, no time off neither. Full time free during opening hours
      // Except for today.

      const timeSlotsNumber = Math.floor(openedMinutes / durationMinutes)
      for (let i = 0; i < timeSlotsNumber; i++) {
        const slotStartTime = addMinutes(beginTime, i * durationMinutes)
        const slotEndTime = addMinutes(slotStartTime, durationMinutes)
        if (
          (slotEndTime.getTime() === endTime.getTime() || isBefore(slotEndTime, endTime)) &&
          isBefore(now, slotStartTime)
        ) {
          dayToPush.slots.push({
            id: i,
            time: addMinutes(beginTime, i * durationMinutes),
          })
        }
      }
      if (dayToPush.slots.length) out.push(dayToPush)
    } else {
      const availableTimes = getAvailableTimes(eventsOfTheDay, day)
      dayToPush.slots = splitAvailableTimes(availableTimes)
      if (dayToPush.slots?.length) out.push(dayToPush)
    }
  })
  return extractPreviousSlots(out)
}
