import { memo, useState, useCallback } from 'react'
import { useTranslation } from 'services/Translation'
import Typography from 'components/common/Typography'
import LoaderView from 'components/common/loaders/LoaderView'
import TextInputComposed from 'components/common/inputs/Text/Composed'
import Separator from 'components/common/Separator'
import { textPrimary } from 'styles/Colors'
import { CS, CS_m } from 'back-end-api'
import { hp } from 'styles/Sizes'
import { ModalContainer, ModalSucceeded, ModalFailed } from '../Common'
import { AdviceStatus } from './common'
import WriteAdvice from './WriteAdvice'
import ConfirmAdvice from './ConfirmAdvice'
import CreateAdviceTemplate from './CreateAdviceTemplate'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    btnLabelClose: getText('Close', 'Patient_Details_Modal_Advice'),
    btnLabelSent: getText('Advice sent!', 'Patient_Details_Modal_Advice'),
    existingAdviceTitle: getText('Given advice', 'Patient_Details_Modal_Advice'),
  }[key])

const TEMPLATES_PLACEHOLDERS = [
  {
    value: '[patient-firstname]',
    content: 'first_name',
  },
  {
    value: '[patient-lastname]',
    content: 'last_name',
  },
  {
    value: '[patient-age]',
    content: 'age',
  },
  {
    value: '[patient-birthday]',
    content: 'date_of_birth',
  },
  {
    value: '[patient-gender]',
    content: 'gender',
  },
]

interface AdviceFormProps {
  onClose: () => void
  registration: CS_m.ModelsRegistration | null
}

const AdviceForm = ({ onClose, registration }: AdviceFormProps) => {
  const { t } = useTranslation(texts)
  const [advice, setAdvice] = useState('')
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState<AdviceStatus>(
    CS.getSummary(registration)?.advice?.text ? AdviceStatus.Review : AdviceStatus.WriteAdvice
  )

  const formatTemplateText = useCallback(
    (rawText: string) => {
      if (!registration) return rawText
      if (!rawText) return ''
      let finalText = `${rawText}`
      TEMPLATES_PLACEHOLDERS.forEach(p => {
        finalText = finalText.replace(
          p.value,
          (registration[p.content as keyof CS_m.ModelsRegistration] as string | null) ?? ''
        )
      })
      return finalText || ''
    },
    [registration]
  )

  switch (status) {
    case AdviceStatus.Error:
      return (
        <ModalFailed
          errorMsg={`Couldn't send advice`}
          containerProps={{ label: t('btnLabelClose'), onClose }}
        />
      )
    case AdviceStatus.Success:
      return (
        <ModalSucceeded
          label={t('btnLabelSent')}
          containerProps={{ label: t('btnLabelClose'), onClose }}
        />
      )
    case AdviceStatus.Loading:
      return <LoaderView onClose={onClose} />
    case AdviceStatus.WriteAdvice:
      return (
        <WriteAdvice
          onClose={onClose}
          formatTemplateText={formatTemplateText}
          setStatus={setStatus}
          advice={advice}
          setAdvice={setAdvice}
          title={title}
          setTitle={setTitle}
        />
      )
    case AdviceStatus.CreateAdviceTemplate:
      return (
        <CreateAdviceTemplate
          onClose={onClose}
          setStatus={setStatus}
          formatTemplateText={formatTemplateText}
          advice={advice}
          setAdvice={setAdvice}
          title={title}
          setTitle={setTitle}
        />
      )
    case AdviceStatus.ConfirmAdvice:
      return (
        <ConfirmAdvice
          onClose={onClose}
          summary={CS.getSummary(registration)}
          setStatus={setStatus}
          advice={advice}
          title={title}
        />
      )
    case AdviceStatus.Review:
      return (
        <ModalContainer onClose={onClose} label={t('btnLabelClose')}>
          <Typography
            type="title"
            className="font-csc65"
            text={t('existingAdviceTitle')}
            color={textPrimary}
            size={18}
            style={undefined}
          />
          <Separator size={hp(3)} />
          <TextInputComposed
            height={550}
            readOnly
            hideToolbar
            noPadding
            field={{
              value: CS.getSummary(registration)?.advice?.text,
            }}
          />
        </ModalContainer>
      )
    default:
      console.error('Unhandled Set advice status:', status)
      return null
  }
}

AdviceForm.whyDidYouRender = true

export default memo(AdviceForm)
