export default getText => key =>
  ({
    timeAgo: getText('ago', 'Layout_Alerts_List'),
    justNow: getText('Just now', 'Layout_Alerts_List'),

    minute: getText('min', 'Layout_Alerts_List'),
    minutes: getText('mins', 'Layout_Alerts_List'),
    minuteMin: getText('min', 'Layout_Alerts_List'),
    minuteShort: getText('m', 'Layout_Alerts_List'),
    hourShort: getText('h', 'Layout_Alerts_List'),

    invited: getText('has been invited to use MIA.', 'Layout_Alerts_List'),
    registered: getText('has registered to your clinic.', 'Layout_Alerts_List'),
    newPatient: getText('A new patient', 'Layout_Alerts_List'),
    submitted: getText('has just submitted a questionnaire about', 'Layout_Alerts_List'),
    notes: getText('The assistant has added notes for', 'Layout_Alerts_List'),
    arrived: getText(
      'has just arrived at your clinic and will take place in the waiting room.',
      'Layout_Alerts_List'
    ),
    ready1: getText('is now ready to begin the video appointment', 'Layout_Alerts_List'),
    ready2: getText('at', 'Layout_Alerts_List'),
    willArrive: getText('is expected to arrive at your clinic shortly.', 'Layout_Alerts_List'),
    willStart1: getText('Your videocall-appointment with', 'Layout_Alerts_List'),
    willStart2: getText('is about to start.', 'Layout_Alerts_List'),
    willArrive30: getText(
      'is expected to arrive at your clinic in 30 minutes.',
      'Layout_Alerts_List'
    ),
    willStart301: getText('Your videocall-appointment with', 'Layout_Alerts_List'),
    willStart302: getText('is due to start in 30 minutes.', 'Layout_Alerts_List'),
    accepted1: getText('accepted your appointment request for', 'Layout_Alerts_List'),
    accepted2: getText('at', 'Layout_Alerts_List'),
    cancelled1: getText('The appointment for', 'Layout_Alerts_List'),
    cancelled2: getText('at', 'Layout_Alerts_List'),
    cancelled3: getText('has been cancelled.', 'Layout_Alerts_List'),
  }[key])
