import { memo, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import Card from 'components/common/Card'
import { useTranslation } from 'services/Translation'

import English from './assets/English'
import Netherlands from './assets/Netherlands'

export const LangIcon = styled.div`
  cursor: pointer;
  transform: scale(0.8);
`
export const cardStyles = {
  position: 'absolute',
  padding: '20px 0',
  width: 116 * 0.8,
  height: 146 * 0.8,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}

interface LangProps {
  opened: boolean
  setClose: () => void
  top: number
  left: number
}

const Lang = ({ opened, setClose, top, left }: LangProps) => {
  const { setLang } = useTranslation()
  const cardRef = useRef<any>(null)

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (cardRef.current && !cardRef.current.contains(e.target)) {
        setClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [cardRef, setClose])

  const handleLangChange = useCallback(
    (newLang: string) => () => {
      setClose()
      setLang(newLang)
    },
    [setClose, setLang]
  )

  if (!opened) return null

  return (
    <Card
      innerRef={cardRef}
      style={{
        ...cardStyles,
        top,
        left: left || 286 * 0.8,
        zIndex: 50,
      }}
      withMark={undefined}
      markColor={undefined}
      animate={undefined}
      variants={undefined}
      initial={undefined}
      className={undefined}
    >
      <LangIcon onClick={handleLangChange('en')} data-cy="btnLanguageEn">
        <English />
      </LangIcon>
      <LangIcon onClick={handleLangChange('nl')} data-cy="btnLanguageNl">
        <Netherlands />
      </LangIcon>
    </Card>
  )
}

Lang.whyDidYouRender = true

export default memo(Lang)
