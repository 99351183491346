import { hp } from 'styles/Sizes'

export const containerVariants = {
  closed: {
    height: hp(4.2),
    transition: {
      when: 'afterChildren',
    },
  },
  open: {
    height: hp(16.5),
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
}
export const linkVariants = {
  closed: {
    opacity: 0,
    y: -10,
  },
  open: {
    opacity: 1,
    y: 0,
  },
}
export const labelVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}
