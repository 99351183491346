import { memo, useCallback } from 'react'

import { useLiveUpdates } from 'services/LiveUpdates/hooks'
import LoaderMini from 'components/common/loaders/mini'
import Header from './Header'
import List from './List'

const LiveUpdates = ({ expanded, setExpanded }) => {
  const { data, isLoading } = useLiveUpdates()

  const handleExpand = useCallback(() => {
    setExpanded(v => !v)
  }, [setExpanded])

  return (
    <div className="bg-background relative z-30 mt-2">
      <Header onExpand={handleExpand} expanded={expanded} canExpand={data?.length > 1} />
      {isLoading ? (
        <div className="flex items-center justify-center mt-6">
          <LoaderMini center />
        </div>
      ) : (
        <List updates={data} expanded={expanded} />
      )}
    </div>
  )
}

LiveUpdates.whyDidYouRender = true

export default memo(LiveUpdates)
