import removeUndefined from 'utils/removeUndefined'

export const formatTimeOff = time => {
  const repeat = {
    frequency: time.interval?.repeat?.frequency,
    period: time.interval?.repeat?.period,
    // eslint-disable-next-line camelcase
    until: time.interval?.repeat?.until_datetime,
  }
  removeUndefined(repeat, true)
  const finalTimeOff = {
    id: time.id,
    doctorId: time.doctor_account_id,
    time: time.interval.datetime,
    duration: Math.round(time.interval.duration_ns / 1000000),
    repeat: Object.keys(repeat).length ? repeat : undefined,
  }
  removeUndefined(finalTimeOff)

  return finalTimeOff
}

export const reverseFormatTimeOff = data => {
  const time = data.time instanceof Date ? data.time : new Date(data.time)
  const until = data.repeat?.until instanceof Date ? data.repeat?.until : undefined
  const repeat = {
    frequency: data.repeat?.frequency,
    period: data.repeat?.period ? parseInt(data.repeat?.period, 10) : undefined,
    until_datetime: until,
  }
  removeUndefined(repeat)
  const finalTimeOff = {
    interval: {
      datetime: time,
      duration_ns: data.duration * 1000000,
      repeat: Object.keys(repeat).length ? repeat : undefined,
    },
  }
  removeUndefined(finalTimeOff)
  return finalTimeOff
}

export const formatUnavs = data => {
  const out = []
  data.forEach(unav => {
    const repeat = {
      frequency: unav.repeat.frequency,
      period: unav.repeat.period,
      until: unav.repeat.until_datetime,
    }
    removeUndefined(repeat, true)
    const finalUnav = {
      time: unav.datetime,
      duration: Math.round(unav.duration_ns / 1000000),
      repeat: Object.keys(repeat).length ? repeat : undefined,
    }
    removeUndefined(finalUnav)
    out.push(finalUnav)
  })
  return out
}
