import axios from 'axios'
import { diff } from 'deep-object-diff'

import { primary } from 'styles/Colors'

import { MAP_APPOINTMENTS, MAP_JOB_STATUS, MAP_STATUS_COLOR, STATUS } from 'Constants'
import { logNetworkError } from './Sentry'

export const getFromLocalOrDefault = (state, key) => {
  const item = localStorage.getItem(key)
  return item ? JSON.parse(item) : state
}

export const getFromSessionOrDefault = (state, key) => {
  const item = sessionStorage.getItem(key)
  return item ? JSON.parse(item) : state
}

export const saveToLocal = (state, key) => {
  const value = JSON.stringify(state)
  localStorage.setItem(key, value)
  console.log(`New ${key} saved localy: `, value)
}

export const saveToSession = (state, key) => {
  const value = JSON.stringify(state)
  sessionStorage.setItem(key, value)
  console.log(`New ${key} saved to session: `, value)
}

export const removeFromLocal = key => {
  localStorage.removeItem(key)
  console.log('Removed localy: ', key)
}

export const removeFromSession = key => {
  sessionStorage.removeItem(key)
  console.log('Removed from session: ', key)
}

export const clearSession = () => {
  sessionStorage.clear()
  console.log('Cleared session')
}

const getHeaders = (token = false) => {
  const result = {
    Accept: 'application/json',
    'X-Request-ID': Math.floor(Math.random() * 1000000),
  }
  if (token) result.Authorization = `Bearer ${token}`
  return result
}
export const Net = {
  get: async (url, token, params = {}, log = false, extensiveError = false) => {
    const headers = getHeaders(token)
    try {
      const query = {
        method: 'get',
        url,
        headers,
        ...(params ? { params } : {}),
      }
      if (log) console.log('GET Query: ', query)
      const res = await axios(query)
      if (log) console.log('GET Res: ', res)
      if (res.status.toString().startsWith('2')) return res.data

      logNetworkError(res)

      return extensiveError ? res : res.status
    } catch (e) {
      logNetworkError(e)

      console.error(
        `Error GET ${url}\nToken: ${token}\nParams: ${JSON.stringify(params)}\nCode : ${
          e.response?.status
        } - ${JSON.stringify(e.response?.data)}`
      )
      return extensiveError ? e : e.response?.status
    }
  },
  async post(url, token, data, log = false, extensiveError = false) {
    const headers = { ...getHeaders(token), 'Content-Type': 'application/json' }
    try {
      const query = {
        method: 'post',
        url,
        headers,
        data,
      }
      if (log) console.log('POST Query: ', query)
      const res = await axios(query)
      if (log) console.log('POST Res: ', res)
      if (res.status.toString().startsWith('2')) return res.data

      logNetworkError(res)

      return extensiveError ? res : res.status
      // return res.status === 200 || res.status === 201 ? res.data : res.status;
    } catch (e) {
      logNetworkError(e)

      console.error(
        `Error POST ${url}\nToken: ${token}\nData: ${JSON.stringify(data)}\nCode : ${
          e.response?.status
        } - ${JSON.stringify(e.response?.data)}`
      )
      return extensiveError ? e : e.response?.status
    }
  },
  async patch(url, token, data, log = false, extensiveError = false) {
    const headers = { ...getHeaders(token), 'Content-Type': 'application/json' }
    try {
      const query = {
        method: 'patch',
        url,
        headers,
        data,
      }
      if (log) console.log('PATCH Query: ', query)
      const res = await axios(query)
      if (log) console.log('PATCH Res: ', res)
      if (res.status.toString().startsWith('2')) return res.data

      logNetworkError(res)

      return extensiveError ? res : res.status
      // return res.status === 200 ? res.data : res.status;
    } catch (e) {
      logNetworkError(e)

      console.error(
        `Error PATCH ${url}\nToken: ${token}\nData: ${JSON.stringify(data)}\nCode : ${
          e.response?.status
        } - ${JSON.stringify(e.response?.data)}`
      )
      return extensiveError ? e : e.response?.status
    }
  },
  async delete(url, token, data, log = false, extensiveError = false) {
    const headers = getHeaders(token)
    try {
      const query = {
        method: 'delete',
        url,
        data,
        headers,
      }
      if (log) console.log('DELETE Query: ', query)
      const res = await axios(query)
      if (log) console.log('DELETE Res: ', res)
      if (res.status.toString().startsWith('2')) return res.data

      logNetworkError(res)

      return extensiveError ? res : res.status
      // return res.status === 200 ? res.data : res.status;
    } catch (e) {
      logNetworkError(e)

      console.error(
        `Error DELETE ${url}\nToken: ${token}\nCode : ${e.response?.status} - ${JSON.stringify(
          e.response?.data
        )}`
      )
      return extensiveError ? e : e.response?.status
    }
  },
}

export const getNewElemsOrNothing = (oldStuff, newStuff, compareKey = 'id', log = false) => {
  if (!oldStuff || !oldStuff.length) {
    if (log) console.log('Old Stuff is empty, returning new stuff: ', oldStuff, newStuff)
    return newStuff
  }

  const out = []
  let hasNew = false

  newStuff.forEach(newElem => {
    const foundStuff = oldStuff.find(el => el[compareKey] === newElem[compareKey])
    if (foundStuff) {
      const stuffDiff = diff(foundStuff, newElem)
      if (log) console.log('Old elem in new array: ', stuffDiff, foundStuff, oldStuff, newStuff)
      if (Object.keys(stuffDiff).length) {
        const finalStuff = {
          ...foundStuff,
          ...newElem,
        }
        out.push(finalStuff)
        hasNew = true
      } else {
        out.push(foundStuff)
      }
    } else {
      out.push(newElem)
      hasNew = true
    }
  })
  return hasNew ? out : []
}

export const getUniquesByIds = (oldData, newData, log) => {
  const oldDataIds = oldData.map(e => e.id)
  const newDataIds = newData.map(e => e.id)
  const outIds = [...new Set([...oldDataIds, ...newDataIds])]
  const out = []

  if (log) console.log('Out Ids: ', outIds, oldData, newData)
  outIds.forEach(id => {
    out.push(newData.find(e => e.id === id) || oldData.find(e => e.id === id))
  })
  return out
}

export const multiple = (callback, data, ...params) => {
  if (!data || !data.length) return []
  const out = []
  data.forEach(e => out.push(callback(e, ...params)))
  return out
}

export const getStatus = summary => {
  if (!summary || !Object.keys(summary).length) return ''
  const { status, appointmentStatus, appointmentIsImmediate, jobStatus } = summary
  let newStatus = {
    label: status ? STATUS[status].label : '',
    color: status ? MAP_STATUS_COLOR[status] : primary,
  }
  if (status !== 'consulted' && appointmentStatus) {
    newStatus = {
      label:
        MAP_APPOINTMENTS[appointmentStatus][appointmentIsImmediate ? 'immediate' : 'scheduled'],
      color: MAP_STATUS_COLOR[appointmentStatus],
    }
  } else if (!status && jobStatus === MAP_JOB_STATUS.PICKED.value) {
    newStatus = {
      label: MAP_JOB_STATUS.PICKED.label,
      color: MAP_JOB_STATUS.PICKED.color,
    }
  }
  return newStatus
}

export const getUpdatedList = (oldList, newList) => {
  const out = []

  oldList.forEach(oldEl => {
    const newAIndex = newList.findIndex(a => a.id === oldEl.id)
    if (~newAIndex) {
      // Old element is found in new list, adding it
      out.push(newList[newAIndex])
      // Removing that element from the new list
      newList.splice(newAIndex, 1)
      // Else pushing the old element
    } else out.push(oldEl)
  })
  // Pushing the new elements that are not in the old list.
  newList.forEach(a => out.push(a))
  return out
}

export const fetchFunc = async ({ url, args: { token, params, log } }) => {
  return await Net.get(url, token, params, log)
}
