import removeUndefined from 'utils/removeUndefined'

import { formatSummary } from 'services/Summaries/formatter'
import { formatAppointment } from 'services/Appointments/formatter'

export const formatPatient = data => {
  const out = {
    id: data.id,
    age: data.age,
    dob: data.date_of_birth,
    deletedAt: data.deleted_at,
    email: data.email,
    firstname: data.first_name,
    lastname: data.last_name,
    gender: data.gender,
    height: data.height,
    weight: data.weight,
    phone: data.mobile_phone,
    status: data.status,
    jobStatus: data?.job?.status,
    jobStatusTime: data?.job?.updated_at,
    jobName: data?.job?.complaint?.name || '',
    encodedUsername: data?.encoded_username || data?.external_user_id,
    ssn: data?.social_security_number,
    userConsent: data?.user_consent,
    doctorId: data?.doctor_account_id,
  }
  removeUndefined(out)
  return out
}

export const formatDiseases = data => {
  return data
}

export const formatAll = (rawPatient, fromLive = false) => {
  const patients = []
  const summaries = []
  const appointments = []

  if (!rawPatient?.length) return [patients, summaries, appointments]
  rawPatient.forEach(patient => {
    if (patient?.summary) {
      if (patient?.summary?.appointment) {
        appointments.push(formatAppointment(patient?.summary?.appointment))
      }
      summaries.push(formatSummary(patient?.summary, patient))
    }
    if (patient?.summaries && patient?.summaries?.length) {
      patient.summaries.forEach(s => {
        if (s.appointment) appointments.push(formatAppointment(s.appointment))
        summaries.push(formatSummary(s, patient))
      })
    }
    patients.push(fromLive ? { fromLive: true, ...formatPatient(patient) } : formatPatient(patient))
  })
  return [patients, summaries, appointments]
}
