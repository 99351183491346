import styled from 'styled-components'
import { CS_m } from 'back-end-api'
import { primary, transparency, textPrimary, purple, background } from 'styles/Colors'

export const TimeCell = styled.div`
  position: relative;
  height: 100%;
  color: ${({ color }) => color};
  :after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 30%;
    transform: translateX(-50%);
    background-color: ${({ color }) => color};
    height: 2px;
    width: 80px;
  }
`

export const TypeCell = styled(({ status, color, ...props }) => <div {...props} />)`
  position: relative;
  height: 100%;
  width: 100%;
  :after {
    content: '✔';
    content: ${({ status }) =>
      // eslint-disable-next-line no-nested-ternary
      status === CS_m.ModelsAppointmentStatus.accepted
        ? '✔'
        : CS_m.ModelsAppointmentStatus.cancelled
        ? 'x'
        : '🕐'};
    color: white;
    font-size: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: -43px;
    transform: translateX(-50%);
    background-color: ${({ color }) => color};
    height: 73px;
    width: 20px;
  }
`

export const Container = styled(({ height, ...props }) => <div {...props} />)`
  overflow-y: auto;
  & > div {
    & > div {
      display: block;
    }
  }
  & [class$='Table'] {
    max-height: 100%;
    height: auto;
    background-color: ${background};
  }
  & [class$='TableBody'] {
    min-width: max-content;
    max-height: ${({ height }) => height};
    transition: all ease-out 250ms;
    background-color: ${background};
    padding-bottom: 2rem;
    margin-top: 1rem;
  }
  & [class$='TableHeadRow'] {
    border: none;
    background-color: ${background};
    height: 22px;
    & [class$='TableCol'] {
      padding: 0;
      &:first-child {
        position: relative;
        text-align: left;
        background-color: ${primary + transparency[10]};
        border-radius: 20px 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
          content: ' ';
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);
          width: 1.5px;
          height: 15px;
          background-color: ${primary};
        }
        & > div {
          padding-left: 2rem;
          justify-content: flex-start;
        }
      }
      &:nth-of-type(2) {
        & > div {
          width: 60%;
          color: ${textPrimary};
          background-color: ${primary + transparency[10]};
          border-radius: 0 20px 20px 0;
          font-family: coresanscr45;
        }
      }
      & > div {
        color: ${primary};
        font-family: coresanscr85;
        font-size: 0.8125rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;
        padding-left: 0.75rem;
        & > span {
          margin-left: 8px;
          color: ${primary + transparency[70]};
        }
      }
    }
  }
  & [class$='TableRow'] {
    border-radius: 12px;
    margin-top: 2rem;
    min-height: 73px;
    align-items: flex-start;
    border: 1px solid #e8e8e8;
    font-family: coresanscr45;
    cursor: pointer;
    width: 100%;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      box-shadow: 3px 6px 24px 0 ${primary + transparency[10]};
      color: ${purple} !important;
      & [class$='text-grey-200'] {
        color: ${purple} !important;
      }
      & [class$='border-grey-100'] {
        border-color: ${purple} !important;
      }
    }
    & [class$='TableCell'] {
      font-size: 0.9375rem;
      width: 70px;
      height: 73px;
      align-items: center;
      justify-content: flex-start;
    }
  }
`
