import { Net } from '../Tools'
import { formatInvite } from './formatter'

export const getInvite = async ({ token }, inviteId) => {
  const res = await Net.get(`${process.env.REACT_APP_CLINIC_SERVICE_URL}/GetInvite`, token, {
    id: inviteId,
  })
  if (typeof res === 'number') return false
  return formatInvite(res)
}
