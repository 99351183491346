import { multiple, Net } from '../Tools'
import { formatPatient, formatAll, formatDiseases } from './formatter'

export const searchPatient = async (
  { token },
  searchValue,
  { patientsDispatch, summariesDispatch, appointmentsDispatch },
  log = true
) => {
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/ListRegistrations`,
    token,
    {
      ...(searchValue?.ssn ? { patient_ssn: searchValue?.ssn } : {}),
      ...(searchValue?.date
        ? { patient_dob: searchValue?.date.split('-').reverse().join('-') }
        : {}),
      ...(searchValue?.firstname ? { patient_firstname: searchValue?.firstname } : {}),
      ...(searchValue?.lastname ? { patient_lastname: searchValue?.lastname } : {}),
    }
  )
  if (log) console.log('searchPatient Res:', res, typeof res)
  if (typeof res === 'number') {
    return null
  }
  console.log('RES: ', res)
  const [patients, summaries, appointments] = formatAll(res, true)
  if (log) console.log('Formatted patients; summaries:', patients, summaries, appointments)
  // eslint-disable-next-line no-unused-expressions
  patientsDispatch?.({
    type: 'addBatch',
    payload: patients,
    log,
  })
  // eslint-disable-next-line no-unused-expressions
  summariesDispatch?.({
    type: 'addBatch',
    payload: summaries,
    log,
  })
  // eslint-disable-next-line no-unused-expressions
  appointmentsDispatch?.({
    type: 'addBatch',
    payload: appointments,
    log,
  })
  return formatAll(res)
}

export const getOne = async ({ token }, patientId, { patientsDispatch }, log = false) => {
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/GetRegistration`,
    token,
    { id: patientId },
    log
  )
  if (log) console.log('GetOnePatient Res: ', res)
  if (typeof res === 'number') {
    return null
  }
  const formattedPatient = formatPatient(res)
  if (log) console.log('Formatted Patient: ', formattedPatient)
  // eslint-disable-next-line no-unused-expressions
  patientsDispatch?.({
    type: 'addOne',
    payload: formattedPatient,
    log,
  })
  return true
}

export const getDiseaseIdentifiers = async (
  { token },
  { system = 'icpc', lang },
  patientsDispatch,
  log = false
) => {
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/ListDiseaseIdentifiers`,
    token,
    { classification_system: system, language_tag: lang },
    log
  )
  if (typeof res === 'number') return false
  const formattedDiseases = multiple(formatDiseases, res)
  // eslint-disable-next-line no-unused-expressions
  patientsDispatch?.({
    type: 'setDiseases',
    payload: formattedDiseases,
    log,
  })
  return true
}

export const advice = async ({ token }, summaryId, adviceText, log = false) => {
  const res = await Net.post(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/SetSummaryAdvice`,
    token,
    {
      advice: adviceText,
      id: summaryId,
    },
    log
  )
  if (typeof res === 'number') return false
  return true
}

export const consult = async ({ token }, summaryId, log = false) => {
  const res = await Net.post(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/summary/consult`,
    token,
    { id: summaryId },
    log
  )
  console.log('Consult Res', res)
  if (typeof res === 'number') return false
  return true
}
