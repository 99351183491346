import { memo } from 'react'

import { CS, CS_m } from 'back-end-api'
import { ownRegistration } from 'services/Registrations'
import { hp } from 'styles/Sizes'
import ExpandBtn from 'components/common/buttons/Expand'
import List from 'components/common/List'
import Element from './Element'

function isVideoCallProductWithPendingOrAcceptedAppointment(registration: CS_m.ModelsRegistration) {
  const summary = CS.getSummary(registration)
  const appointment = CS.getAppointment(summary)
  return (
    (summary?.product === CS_m.ModelsSummaryProduct.videoCall &&
      appointment?.status === CS_m.ModelsAppointmentStatus.pending) ||
    appointment?.status === CS_m.ModelsAppointmentStatus.accepted
  )
}

interface EncountersProps {
  onExpand: () => void
  expanded: boolean
}

const Encounters = ({ onExpand, expanded }: EncountersProps) => {
  const registrations = CS.useRegistrationsList({
    without_old_closed_summaries: 'true',
  }).data?.filter(
    (r: CS_m.ModelsRegistration) =>
      ownRegistration(r) &&
      !isVideoCallProductWithPendingOrAcceptedAppointment(r) &&
      !CS.isSummaryStatusFinal(CS.getSummary(r))
  )

  return (
    <div className="relative w-full h-full">
      <div className="relative flex items-center justify-end px-16 h-16">
        <ExpandBtn expanded={expanded} onExpand={onExpand} canExpand style={undefined} />
      </div>
      <List
        className="px-16 pb-8"
        containerClassName={undefined}
        height={expanded ? hp(72) : hp(35)}
      >
        {registrations?.map((r: CS_m.ModelsRegistration, idx: number) => (
          <Element key={r.id} style={!idx ? { marginTop: 0 } : {}} registration={r} />
        ))}
      </List>
    </div>
  )
}

Encounters.whyDidYouRender = true

export default memo(Encounters)
