import styled from 'styled-components'
import { wp } from 'styles/Sizes'

export const Container = styled.div`
  border-radius: 18px;
  box-shadow: 1.5px 4.5px 12px 0 rgba(46, 76, 230, 0.15);
  width: ${wp(30)};
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`
