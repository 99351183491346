import styled from 'styled-components'

import { primary, transparency } from 'styles/Colors'

// table overwrite styles
export const Container = styled(({ height, ...props }) => <div {...props} />)`
  overflow-y: auto;
  & > div {
    & > div {
      display: block;
    }
  }
  & [class$='Table'] {
    max-height: 30vh;
    height: auto;
    background-color: #fcfcfc;
  }
  & [class$='TableBody'] {
    min-width: max-content;
    max-height: ${({ height }) => height};
    transition: all ease-out 250ms;
    background-color: #fcfcfc;
    padding-bottom: 2rem;
    margin-top: 0;
  }
  & [class$='TableHeadRow'] {
    border: none;
    min-height: 17px;
    background-color: #fcfcfc;
    & [class$='TableCol'] {
      padding: 0;
      &:last-child {
        text-align: left;
        padding-right: 1rem;
        & > div {
          padding-left: 0;
          justify-content: flex-end;
        }
      }
      & > div {
        color: #1c165b;
        font-family: coresanscr85;
        font-size: 0.8125rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;
        padding-left: 0.75rem;
        & > span {
          margin-left: 8px;
          color: ${primary + transparency[70]};
        }
      }
    }
  }
  & [class$='TableRow'] {
    margin-top: 1rem;
    height: 63px;
    background: transparent;
    align-items: flex-start;
    border-bottom: 1px solid #e8e8e8;
    font-family: coresanscr45;
    cursor: pointer;
    width: calc(100% - 115px);
    &:first-child {
      margin-top: 0.5rem;
    }
    & [class$='TableCell'] {
      font-size: 0.9375rem;
      width: 100px;
      height: 63px;
      align-items: center;
      justify-content: flex-start;
    }
  }
`
