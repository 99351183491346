import { memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { textPrimary } from 'styles/Colors'
import Typography from 'components/common/Typography'
import DefaultBtn from 'components/common/buttons/Default/Component'
import UnderlineBtn from 'components/common/buttons/Underline'
import { ROUTES } from 'Constants'
import { useTranslation } from 'services/Translation'
import { useStatusTranslation, getStatusText } from 'services/Registrations'
import { CS } from 'back-end-api'
import {
  usePatientSideBarState,
  setPatientSideBar,
  patientSideBarDefault,
} from 'services/Misc/patientSideBar'
import { useMiscDispatch } from 'services/Misc'
import DefaultCross from 'assets/icons/crosses/Default'
import { Container } from './Styles'
import { contentVariants } from './Animations'
import texts from './Texts'

const PastEncounter = () => {
  const { lang, t, formatDate } = useTranslation(texts)
  const tStatus = useStatusTranslation()
  const history = useHistory()
  const [seeMore, setSeeMore] = useState(false)
  const patientSideBarProps = usePatientSideBarState()
  const miscDispatch = useMiscDispatch()

  const res = CS.useRegistration({
    id: patientSideBarProps.pastEncounterProps?.selectedRegistrationId.toString() ?? '0',
  })

  const handleSwitchRegistration = useCallback(() => {
    history.push(
      `${ROUTES.PATIENT}${patientSideBarProps.pastEncounterProps?.selectedRegistrationId}`
    )
  }, [patientSideBarProps, history])

  const handleSeeMoreSummary = useCallback(() => {
    setSeeMore(v => !v)
  }, [])

  const onClose = useCallback(() => {
    setPatientSideBar(miscDispatch, patientSideBarDefault)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!res.data) return null

  const summary = CS.getSummary(res.data)

  if (!summary) return null

  const summaryType = CS.getSummaryType(summary)

  return (
    <Container variants={contentVariants}>
      <div className="px-16 pt-4 overflow-y-auto h-full">
        <DefaultBtn
          mini
          label={t('closeBtnLabel')}
          onClick={onClose}
          className="absolute top-9 left-0"
          style={{ transform: 'translate(-50%, -50%)' }}
          nextIcon={<DefaultCross style={{ width: 12, height: 'auto' }} />}
          background={textPrimary}
          color="white"
          reversedColors={undefined}
          withNext={undefined}
          type={undefined}
        />
        <div className="h-12 w-full flex items-center justify-between relative border-b border-grey-100">
          <Typography
            size={18}
            className="font-csc65 pb-3"
            text={t('historyDetails')}
            style={undefined}
          />
        </div>
        <div className="flex flex-col">
          <Typography
            size={15}
            className="font-csc65 pt-6"
            text={t('dateAndTime')}
            style={undefined}
          />
          <div className="font-csc45 text-xl pt-6">
            {formatDate(new Date(res.data.created_at), 'd MMMM, yyyy')}
          </div>
          <Typography
            size={15}
            className="font-csc65 pt-6"
            text={t('actionType')}
            style={undefined}
          />
          <div className="font-csc45 text-xl pt-6 text-purple-200">{summaryType}</div>

          <Typography
            size={15}
            className="font-csc65 pt-6"
            text={t('appointmentStatus')}
            style={undefined}
          />
          <div className="font-csc45 text-xl pt-6">{getStatusText(res.data, tStatus)}</div>
        </div>
        <div className="mt-12 h-12 w-full flex items-center justify-between relative">
          <Typography
            size={18}
            className="font-csc65 pb-3"
            text={t('problemDescription')}
            style={undefined}
          />
        </div>
        <div className="flex flex-col">
          {summaryType === CS.SummaryType.CompleteConsultation && (
            <>
              <Typography
                size={15}
                className="font-csc65 pt-6"
                text={t('topic')}
                style={undefined}
              />
              <div className="font-csc45 text-medium pt-6 text-red-300">
                {summary.complaint?.name || '-'}
              </div>
              <Typography
                size={15}
                className="font-csc65 pt-6"
                text={t('summary')}
                style={undefined}
              />
              <div
                className={`font-csc45 duration-200 transition-all text-medium pt-6 ${
                  seeMore ? 'line-clamp-none' : 'line-clamp-3'
                }`}
              >
                {CS.getSummarySumupTranslateIfNone(summary, lang) ?? '-'}
              </div>
              <UnderlineBtn
                className="mt-3"
                style={{ justifyContent: 'start' }}
                bold
                label={t(seeMore ? 'seeLess' : 'seeMore')}
                onClick={handleSeeMoreSummary}
                innerRef={undefined}
                previousIcon={undefined}
                nextIcon={undefined}
              />
            </>
          )}
          <Typography
            size={15}
            className="font-csc65 pt-6"
            text={t('followUpQuestion')}
            style={undefined}
          />
          <div className="font-csc45 text-medium pt-6">
            {CS.getTranslation(summary.patient_comment, lang) || 'None'}
          </div>
          <DefaultBtn
            label={t('labelViewSummary')}
            type="submit"
            className="w-3/5 font-csc65 mt-9 mb-6"
            reversedColors
            withNext
            color={textPrimary}
            onClick={() => handleSwitchRegistration()}
            innerRef={undefined}
            bold={undefined}
            previousIcon={undefined}
            style={undefined}
            nextIcon={undefined}
          />
        </div>
      </div>
    </Container>
  )
}

PastEncounter.whyDidYouRender = true

export default memo(PastEncounter)
