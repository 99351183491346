import { useCallback } from 'react'
import Female from './Female'
import Male from './Male'

const GenderIcon = ({ style, gender = 'female', size = 10 }) => {
  const switchGender = useCallback(value => {
    switch (value) {
      case 'female':
        return <Female style={style} size={size} />
      case 'male':
        return <Male style={style} size={size} />
      default:
        return <Female style={style} size={size} />
    }
  }, [])

  return <>{switchGender(gender)}</>
}

export default GenderIcon
