import { memo } from 'react'
import useTabAlreadyOpen from 'hooks/useTabAlreadyOpen'
import ScheduleContent from 'components/appointments/Content'
import HomeContent from 'components/home/Content'
import PatientContent from 'components/patient/Content'
import { AnimatePresence } from 'framer-motion'
import { ROUTES } from 'Constants'
import styled from 'styled-components'
import { background } from 'styles/Colors'
import { useLocation } from 'react-router-dom'

import OtherTabOpen from './OtherTabOpen'
import TopBar from './TopBar'
import Sidebar from './Sidebar'
import AVConsult from './AVConsult'
import Notification from './Notification'

export const Container = styled.div`
  background-color: ${background};
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
`

const Dashboard = () => {
  const otherTabOpen = useTabAlreadyOpen()
  const location = useLocation()

  if (otherTabOpen) {
    return (
      <Container>
        <OtherTabOpen />
      </Container>
    )
  }
  return (
    <>
      <div className="w-screen h-screen overflow-hidden flex ">
        <div className="w-3/4 h-full">
          <TopBar />
          {/* @ts-ignore */}
          <AnimatePresence>
            {location.pathname === ROUTES.HOME && <HomeContent />}
            {location.pathname === ROUTES.SCHEDULE && <ScheduleContent />}
            {location.pathname.startsWith(ROUTES.PATIENT) && <PatientContent />}
          </AnimatePresence>
        </div>
        <div className="w-1/4 h-full border-l border-grey-100">
          <Sidebar />
        </div>
      </div>
      <AVConsult />
      <Notification />
    </>
  )
}

Dashboard.whyDidYouRender = true

export default memo(Dashboard)
