/* eslint-disable */

import {
  TitleH2,
  TitleH3,
  TitleH4,
  Paragraph,
  Strong,
  List,
  Element,
  Link,
  Info,
} from '../../Styles'

export default () => (
  <>
    <TitleH2>MIA Suite Privacy Policy</TitleH2>
    <Paragraph>
      Dit is de Privacy Policy van Medvice Enterprises B.V. (hierna te noemen “Het Bedrijf”, “WIJ”,
      “ONS” of “ONZE”), een onderneming met adres Molengraaffsingel 12, 2629JD, Delft, kantoor
      B3.1.15, Nederland. Het Bedrijf is ingeschreven bij de Kamer van Koophandel onder nummer
      75265958. Deze Privacy Policy legt uit hoe Wij gegevens verzamelen, gebruiken, delen en
      beschermen in relatie tot Onze Dienstverlening. Wij verzamelen deze gegevens wanneer u
      gebruikt maakt van Onze Diensten. Wij verwerken Persoonsgegevens op een manier die in
      overeenstemming is met de Algemene Verordening Gegevensbescherming (de “AVG”), de op de AVG
      gebaseerde uitvoeringswetgeving en de andere op dit moment geldende privacywetgeving.
    </Paragraph>
    <Paragraph>
      Onze Privacy Policy is van Toepassing op iedereen die gebruik maakt van Onze Diensten. Door
      gebruik te maken van Onze Diensten, begrijpt u en gaat u akkoord met het verzamelen en
      gebruiken van informatie in overeenstemming met dit privacy beleid.
    </Paragraph>
    <Paragraph>
      Ook verzamelen Wij beperkte gegevens die via Onze websites verzameld worden. Zie hiervoor ook
      de Privacy Policy voor Onze website.
    </Paragraph>
    <TitleH3>Definities</TitleH3>
    <Paragraph>Voor de doeleinden van dit privacy beleid:</Paragraph>
    <Paragraph>
      <Strong>U</Strong> verwijst naar de persoon die toegang heeft tot of gebruik maakt van de
      Service, of het Bedrijf, of een andere rechtspersoon namens wie een dergelijke persoon toegang
      heeft tot de Service of deze gebruikt, indien van Toepassing.
    </Paragraph>
    <Paragraph>
      Onder de AVG (Algemene Verordening Gegevensbescherming), kan “U” of “u” worden aangeduid als
      de Betrokkene of als de Gebruiker, omdat u de persoon bent die de Service gebruikt.
    </Paragraph>
    <Paragraph>
      <Strong>Bedrijf</Strong> (in deze overeenkomst "het Bedrijf", "Wij", "Ons" of "Onze" genoemd)
      verwijst naar Medvice Enterprises B.V., Molengraaffsingel 12, 2629JD, Delft. In het kader van
      de AVG is het Bedrijf de Gegevensbeheerder.
    </Paragraph>
    {/********************* PAGE 2 ********************/}
    <Paragraph>
      <Strong>Toepassing</Strong> betekent het softwareprogramma dat door het Bedrijf wordt
      geleverd, door u gedownload op elk elektronisch Apparaat, genaamd de MIA-suite bestaande uit
      de MIA API, de MIA mobiele app voor patiënten, de MIA in-clinic wachtkamerzuil, het MIA
      Dashboard voor zorgprofessionals de MIA Editor.
    </Paragraph>
    <Paragraph>
      <Strong>Gelieerde onderneming</Strong> betekent een entiteit die zeggenschap heeft over, onder
      zeggenschap staat van of onder gemeenschappelijke zeggenschap staat met een partij, waarbij
      "zeggenschap" betekent eigendom van 50% of meer van de aandelen, het aandelenbelang of andere
      stemgerechtigde effecten voor de verkiezing van bestuurders of andere beheer autoriteiten.
    </Paragraph>
    <Paragraph>
      <Strong>Account</Strong> betekent een uniek Account dat voor u is aangemaakt om toegang te
      krijgen tot Onze Service of delen van Onze Service.
    </Paragraph>
    <Paragraph>
      <Strong>Service</Strong> verwijst naar de bestaande uit de MIA API, de MIA mobiele app voor
      patiënten, de MIA in-clinic wachtkamerzuil, het MIA Dashboard voor zorgprofessionals de MIA
      Editor.
    </Paragraph>
    <Paragraph>
      <Strong>Land</Strong> verwijst naar: Nederland
    </Paragraph>
    <Paragraph>
      <Strong>MIA Suite</Strong> (in deze overeenkomst "Dienst", "Product", "Service" of
      "Toepassing" genoemd) verwijst naar de MIA Suite cloud applicaties bestaande uit de MIA API,
      de MIA mobiele app voor patiënten, de MIA in- clinic wachtkamerzuil, het MIA Dashboard voor
      zorgprofessionals de MIA Editor.
    </Paragraph>
    <Paragraph>
      <Strong>Serviceprovider</Strong> betekent elke natuurlijke of rechtspersoon die de gegevens
      namens het Bedrijf verwerkt. Het verwijst naar externe bedrijven of personen die in Dienst
      zijn van het Bedrijf om de Service te vergemakkelijken, om de Service namens het Bedrijf te
      leveren, om Services met betrekking tot de Service uit te voeren of om het Bedrijf te helpen
      analyseren hoe de Service wordt gebruikt. In het kader van de AVG worden Serviceproviders
      beschouwd als gegevensverwerkers.
    </Paragraph>
    <Paragraph>
      <Strong>Persoonsgegevens</Strong> zijn alle informatie die betrekking heeft op een
      geïdentificeerde of identificeerbare persoon. Voor de doeleinden van de AVG betekent
      Persoonsgegevens alle informatie met betrekking tot u, zoals een naam, een
      identificatienummer, locatiegegevens, online identificatie of een of meer factoren die
      specifiek zijn voor de fysieke, fysiologische, genetische, mentale, economische, culturele of
      sociale identiteit.
    </Paragraph>
    <Paragraph>
      <Strong>Apparaat</Strong> kent elk Apparaat dat toegang heeft tot de Dienst, zoals een
      computer, een mobiele telefoon of een digitale tablet.
    </Paragraph>
    <Paragraph>
      <Strong>Gebruiksgegevens</Strong> verwijzen naar automatisch verzamelde gegevens, hetzij
      gegenereerd door het gebruik van de Service of uit de Service- infrastructuur zelf
      (bijvoorbeeld de duur van een paginabezoek).
    </Paragraph>
    {/********************* PAGE 3 ********************/}
    <Paragraph>
      <Strong>Gegevensbeheerder</Strong>verwijst voor de doeleinden van de AVG (Algemene Verordening
      Gegevensbescherming) naar het Bedrijf als de rechtspersoon die alleen of samen met anderen de
      doeleinden en middelen voor de verwerking van Persoonsgegevens bepaalt
    </Paragraph>
    {/********************* PAGE 4 ********************/}
    <TitleH3>Wat voor gegevens verzamelen Wij?</TitleH3>
    <Paragraph>
      Uw Persoonsgegevens verzamelen Wij bij gebruik van Onze Diensten op de volgende manier:
    </Paragraph>
    <TitleH4>Lid 1: Niet medische Persoonsgegevens</TitleH4>
    <Paragraph>
      Tijdens het gebruik van Onze Service kunnen We u vragen om ons bepaalde persoonlijk
      identificeerbare informatie te verstrekken die kan worden gebruikt om contact met u op te
      nemen of u te identificeren. Persoonlijk identificeerbare informatie kan omvatten, maar is
      niet beperkt tot:
    </Paragraph>
    <List>
      <Element>Uw volledige naam.</Element>
      <Element>Uw e-mailadres.</Element>
      <Element>Uw telefoonnummer.</Element>
      <Element>
        Uw unieke persoonsnummer. Dit kan uw BIG nummer zijn of een gelieerde AGB-code voor
        zorgverleners, of BSN nummer voor patiënten.
      </Element>
      <Element>Uw geboortedatum.</Element>
      <Element>Uw geslacht (bij geboorte).</Element>
    </List>
    <TitleH4>Lid 2: Informatie verzameld tijdens het gebruik van de Toepassing</TitleH4>
    <Paragraph>
      Tijdens het gebruik van Onze Toepassing kunnen We, om de functies van Onze Toepassing te
      bieden, met uw voorafgaande toestemming verzamelen:
    </Paragraph>
    <Element>Informatie over uw locatie</Element>
    <Paragraph>
      We gebruiken deze informatie om functies van Onze Service aan te bieden en om Onze Service te
      verbeteren en aan te passen. De informatie kan worden geüpload naar de server van een
      Serviceprovider of kan eenvoudig op uw Apparaat worden opgeslagen. U kunt de toegang tot deze
      informatie op elk moment in- of uitschakelen via de instellingen van uw Apparaat.
    </Paragraph>
    <TitleH4>Lid 3: Medische Persoonsgegevens</TitleH4>
    <Paragraph>
      Bij het gebruiken van de Toepassing, zullen bij u patiënten een aantal medische vragen worden
      gesteld om uw lichamelijke klacht beter in kaart te brengen. Het Bedrijf verwerkt en vergaart
      tijdens dit proces daarom de volgende medische Persoonsgegevens:
    </Paragraph>
    {/********************* PAGE 5 ********************/}
    <Element>
      Medische informatie over de gegeven gezondheidsklacht. Herleiding tot identiteit met deze
      medische gegevens, zonder toegang tot andere (zoals beschreven bij Lid 1) Persoonsgegevens is
      vrijwel onmogelijk.
    </Element>
    <TitleH4>Lid 4: Gebruiksgegevens</TitleH4>
    <Paragraph>
      Gebruiksgegevens worden automatisch verzameld bij het gebruik van de Toepassing.
      Gebruiksgegevens kunnen informatie bevatten zoals het IP-adres van uw Apparaat (bijv.
      IP-adres), browsertype, browserversie, de pagina's van Onze Service die u bezoekt, de tijd en
      datum van uw bezoek, de tijd die op die pagina's is doorgebracht, uniek Apparaat
      identificatiemiddelen en andere diagnostische gegevens.
    </Paragraph>
    <Paragraph>
      Wanneer u toegang krijgt tot de Service via of via een mobiel Apparaat, kunnen We bepaalde
      informatie automatisch verzamelen, inclusief, maar niet beperkt tot, het type mobiel Apparaat
      dat u gebruikt, de unieke ID van uw mobiele Apparaat, het IP-adres van uw mobiele Apparaat, uw
      mobiele Apparaat besturingssysteem, het type mobiele internetbrowser dat u gebruikt, unieke
      Apparaat-ID's en andere diagnostische gegevens.
    </Paragraph>
    <Paragraph>
      We kunnen ook informatie verzamelen die uw browser verzendt wanneer u Onze Service bezoekt of
      wanneer u toegang krijgt tot de Service via of via een mobiel Apparaat.
    </Paragraph>
    <TitleH3>Doeleinden verwerking gegevens</TitleH3>
    <Paragraph>Wij kunnen uw Persoonsgegevens gebruiken voor de volgende doeleinden:</Paragraph>
    <Element>
      <Strong>Om de Service te leveren en te onderhouden</Strong>, inclusief om het gebruik van de
      Toepassing te monitoren.
    </Element>
    <Element>
      <Strong>Om uw Account te beheren</Strong>: om uw registratie als gebruiker van de Toepassing
      te beheren. De Persoonsgegevens die u verstrekt, kunnen u toegang geven tot verschillende
      functionaliteiten van de Service die voor u als geregistreerde gebruiker beschikbaar zijn.
    </Element>
    <Element>
      <Strong>Om contact met u op te nemen</Strong>: om contact met u op te nemen via e-mail,
      telefoon, sms of andere gelijkwaardige vormen van elektronische communicatie, zoals
      pushmeldingen van een mobiele Toepassing met betrekking tot updates of informatieve
      communicatie met betrekking tot de functionaliteiten, producten of gecontracteerde Diensten,
      inclusief de beveiligingsupdates, indien nodig of redelijk voor hun implementatie.
    </Element>
    <Element>
      <Strong>Om uw verzoeken te beheren</Strong>: om uw verzoeken in te zien en te beheren.
    </Element>
    <Paragraph>We kunnen uw persoonlijke informatie delen in de volgende situaties:</Paragraph>
    <Element>
      <Strong>Met Serviceproviders</Strong>: We kunnen uw Persoonsgegevens delen met
      Serviceproviders om het gebruik van Onze Service te controleren en te analyseren, om contact
      met u op te nemen.
    </Element>
    <Element>
      <Strong>Voor bedrijfsoverdrachten</Strong>: We kunnen uw Persoonsgegevens delen of overdragen
      in verband met of tijdens onderhandelingen over een fusie, verkoop van bedrijfsactiva,
      financiering of overname van het Bedrijf of een deel ervan aan een ander bedrijf.
    </Element>
    <Element>
      <Strong>Met partners</Strong>: We kunnen uw informatie delen met Onze partners, in welk geval
      We van deze partners verlangen dat ze dit privacy-beleid naleven. Gelieerde ondernemingen zijn
      onder meer Ons moederbedrijf en alle andere dochterondernemingen, joint venture-partners of
      andere bedrijven waarover Wij zeggenschap hebben of die gezamenlijk onder Ons beheer staan.
    </Element>
    {/********************* PAGE 5 ********************/}
    <Element>
      <Strong>Met aangesloten huisartsenpraktijken, huisartsenposten en poliklinieken</Strong>: We
      kunnen de volgende Persoonsgegevens delen met een gezondheidswerker van een aangesloten
      praktijk / kliniek voor identiteitsverificatie:
    </Element>
    <List>
      <Paragraph>Volledige naam.</Paragraph>
      <Paragraph>Uw telefoonnummer.</Paragraph>
      <Paragraph>
        Uw unieke persoonsnummer. Dit kan uw BIG nummer zijn of een gelieerde AGB-code.
      </Paragraph>
      <Paragraph>Uw geboortedatum.</Paragraph>
    </List>
    <Paragraph>
      Het delen van deze Persoonsgegevens gebeurt alleen in overeenstemming met een ondertekende
      gegevensverwerkingsovereenkomst tussen de aangesloten praktijk / kliniek en Ons.
    </Paragraph>
    <Paragraph>
      Alle informatie die wordt verzameld gebruikt het Bedrijf om de Service, accurater en
      betrouwbaarder te maken, om zo de kwaliteit van de geleverde Diensten te waarborgen.
    </Paragraph>
    <Paragraph>
      Alle medische Persoonsgegevens en niet medische Persoonsgegevens kunnen alléén worden
      verkregen en opgeslagen na uw geïnformeerde toestemming vooraf. Toestemming geven gebeurt
      digitaal binnen de omgeving van de Toepassing, waarbij wordt verwezen naar deze Privacy
      Policy.
    </Paragraph>
    <TitleH3>Hoe wordt deze informatie gedeeld?</TitleH3>
    <Paragraph>
      Wij zullen uw non-geanonimiseerde Persoonsgegevens niet verhuren of verkopen aan derden,
      tenzij u daarvoor toestemming geeft of om te voldoen aan een wettelijke verplichting.
    </Paragraph>
    <TitleH3>Wettelijk verzoek en voorkoming schade</TitleH3>
    <Paragraph>
      Op grond van een wettelijk verzoek zijn Wij gerechtigd om toegang te krijgen tot uw informatie
      en dit te bewaren en/of te delen in antwoord op een wettelijk verzoek. Wij zijn ook gerechtigd
      om uw informatie te bewaren en/of te delen wanneer Wij geloven dat dit noodzakelijk is voor
      het opsporen, voorkomen en aankaarten van fraude of andere illegale activiteiten en om Ons, u
      en anderen te beschermen. Informatie die Wij ontvangen over u kan worden geopend, bewerkt en
      bewaard voor een langere periode wanneer dit noodzakelijk is vanwege een juridisch verzoek of
      verplichting, een onderzoek met betrekking tot Onze voorwaarden, beleid of om anderszins
      schade te voorkomen.
    </Paragraph>
    <TitleH3>Beveiliging en veiligheid</TitleH3>
    <Paragraph>
      Het Bedrijf heeft passende technische en organisatorische maatregelen genomen om uw gegevens
      te beveiligen tegen enige vorm van onrechtmatige verwerking.
    </Paragraph>
    <TitleH3>Bewaartermijn en anonimisatie</TitleH3>
    <Paragraph>
      In overeenstemming met de AVG en de overige relevante wetgeving, bewaart het Bedrijf
      Persoonsgegevens niet langer dan noodzakelijk is voor de verwezenlijking van de doeleinden
      waarvoor zij verzameld of verwerkt worden.
    </Paragraph>
    <Paragraph>
      Door het gebruik van Onze Diensten laat u en de patiënt voor een bepaalde tijd gegevens bij
      Ons achter. Dit komt tot stand door het verkrijgen van een dossier na gebruik van de
      Toepassing of door het aanmaken van een account voor zorgverleners via het MIA Dashboard.
    </Paragraph>
    <Paragraph>
      Voor patiënten geldt:{' '}
      <Link href="https://www.medvice.io/en/miasuiteprivacypolicy" target="_blank">
        www.medvice.io/en/miasuiteprivacypolicy
      </Link>
    </Paragraph>
    {/********************* PAGE 8 ********************/}
    <Paragraph>
      Voor zorgverleners geldt: De Toepassing slaat alle de bij Lid 1 genoemde gegevens op gedurende
      de gebruiksduur van De Toepassing welke bepaald wordt a.d.h.v. een afgenomen MIA Suite
      licentieovereenkomst. Beëindiging van deze licentieovereenkomst, zal leiden tot het per direct
      verwijderen van alle de bij Lid 1 genoemde gegevens.
    </Paragraph>
    <Paragraph>
      Als u aanvullende informatie wil over de bewaartermijn van specifieke Persoonsgegevens, kunt u
      een email sturen naar info@medvice.io.
    </Paragraph>
    <TitleH3>Overdracht van Persoonsgegevens en medische Persoonsgegevens:</TitleH3>
    <Paragraph>
      Alle informatie, inclusief Persoonsgegevens en medische Persoonsgegevens, wordt verwerkt in de
      bedrijfsvestigingen van het Bedrijf of op andere plaatsen waar de bij de verwerking betrokken
      partijen zich bevinden. Dit betekent dat deze informatie kan worden overgedragen aan - en
      wordt onderhouden op - computers buiten uw staat, provincie, land of andere overheidsregio
      waar de wetgeving inzake gegevensbescherming kan verschillen van die van uw jurisdictie. Voor
      EU- gebruikers zal de overdracht van persoonlijke en medische gegevens de EU-grenzen niet
      overschrijden als ze niet voldoen aan de EU- gegevensopslag- en AVG-richtlijnen. Uw instemming
      met dit privacy beleid gevolgd door uw indiening van dergelijke informatie betekent uw
      instemming met die overdracht. Het Bedrijf zal alle redelijkerwijs noodzakelijke stappen
      ondernemen om ervoor te zorgen dat uw en patiëntgegevens veilig en in overeenstemming met dit
      privacy beleid worden behandeld en dat er geen overdracht van uw Persoonsgegevens en medische
      gegevens plaatsvindt naar een organisatie of een land, tenzij er adequate controles zijn,
      waaronder de beveiliging van uw gegevens en andere persoonlijke informatie.
    </Paragraph>
    <TitleH3>Delen van uw Persoonsgegevens</TitleH3>
    <TitleH4>Zakelijke transacties</TitleH4>
    <Paragraph>
      Als het Bedrijf betrokken is bij een fusie, overname of verkoop van activa, kunnen uw
      Persoonsgegevens en geanonimiseerde medische patiëntgegevens worden overgedragen. We zullen
      dit melden voordat uw Persoonsgegevens worden overgedragen en worden onderworpen aan een ander
      privacy beleid.
    </Paragraph>
    <TitleH4>Politie</TitleH4>
    {/********************* PAGE 9 ********************/}
    <Paragraph>
      Onder bepaalde omstandigheden kan het Bedrijf worden vereist om uw Persoonsgegevens bekend te
      maken als dit wettelijk verplicht is of als reactie op geldige verzoeken van
      overheidsinstanties (bijvoorbeeld een rechtbank of een overheidsinstantie).
    </Paragraph>
    <TitleH4>Andere wettelijke vereisten</TitleH4>
    <Paragraph>
      Het Bedrijf kan uw Persoonsgegevens vrijgeven in de overtuiging dat dergelijke actie
      noodzakelijk is om:
    </Paragraph>
    <Element>Voldoen aan een wettelijke verplichting</Element>
    <Element>Bescherm en verdedig de rechten of eigendommen van het Bedrijf.</Element>
    <Element>Voorkomen of onderzoeken van mogelijke misstanden in verband met de Dienst</Element>
    <Element>
      Bescherm de persoonlijke veiligheid van gebruikers van de Dienst of het publiek
    </Element>
    <Element>Beschermen tegen wettelijke aansprakelijkheid</Element>
    <TitleH3>Beveiliging van uw Persoonsgegevens</TitleH3>
    <Paragraph>
      De beveiliging van uw persoonlijke en medische persoons- gegevens is belangrijk voor Ons, maar
      onthoud dat geen enkele verzendmethode via internet of elektronische opslag 100% veilig is.
      Hoewel We ernaar streven om commercieel aanvaardbare middelen te gebruiken om uw
      Persoonsgegevens te beschermen, kunnen We de absolute veiligheid ervan niet garanderen.
    </Paragraph>
    <TitleH3>Gedetailleerde informatie over de verwerking van uw Persoonsgegevens</TitleH3>
    <Paragraph>
      Serviceproviders hebben alleen toegang tot uw Persoonsgegevens om hun taken namens Ons uit te
      voeren en zijn verplicht deze niet openbaar te maken of voor enig ander doel te gebruiken.
    </Paragraph>
    <TitleH4>Analytics</TitleH4>
    {/********************* PAGE 10 ********************/}
    <Paragraph>
      We kunnen externe Serviceproviders gebruiken om het gebruik van Onze Service te monitoren en
      te analyseren.
    </Paragraph>
    <TitleH4>Google Analytics</TitleH4>
    <Paragraph>
      Google Analytics is een webanalyse-Service die wordt aangeboden door Google en die
      websiteverkeer volgt en rapporteert. Google gebruikt de verzamelde gegevens om het gebruik van
      Onze Service te volgen en te controleren. Deze gegevens worden gedeeld met andere
      Google-Services. Google kan de verzamelde gegevens gebruiken om de advertenties van zijn eigen
      advertentienetwerk te contextualiseren en personaliseren. U kunt zich afmelden voor bepaalde
      Google Analytics-functies via de instellingen van uw mobiele Apparaat, zoals de
      advertentie-instellingen van uw Apparaat of door de instructies van Google te volgen in hun
      privacy beleid: https://policies.google.com/privacy. Ga voor meer informatie over de privacy
      praktijken van Google naar de webpagina Privacy en voorwaarden van Google:
      https://policies.google.com/privacy
    </Paragraph>
    <TitleH3>Gebruik, prestaties en diversen</TitleH3>
    <Paragraph>
      We kunnen externe Serviceproviders gebruiken om Onze Service te verbeteren.
    </Paragraph>
    <TitleH4>Okta</TitleH4>
    <Paragraph>
      Hun privacy beleid kan worden bekeken op{' '}
      <Link href="https://www.okta.com/" target="_blank">
        https://www.okta.com/
      </Link>
    </Paragraph>
    <TitleH4>Twilio Platform</TitleH4>
    <Paragraph>
      Hun privacy beleid kan worden bekeken op{' '}
      <Link href="https://www.twilio.com/legal/privacy" target="_blank">
        https://www.twilio.com/legal/privacy
      </Link>
    </Paragraph>
    <TitleH4>IBM Cloud Platform</TitleH4>
    <Paragraph>
      Hun privacy beleid kan worden bekeken op{' '}
      <Link href="https://www.ibm.com/cloud" target="_blank">
        https://www.ibm.com/cloud
      </Link>
    </Paragraph>
    <TitleH3>AVG-privacy</TitleH3>
    <TitleH4>Juridische basis voor het verwerken van Persoonsgegevens onder GDPR</TitleH4>
    <Paragraph>
      We kunnen (gevoelige) Persoonsgegevens verwerken onder de volgende voorwaarden:
    </Paragraph>
    <Element>
      <Strong>Toestemming</Strong>: u hebt uw toestemming gegeven voor het verwerken van
      Persoonsgegevens voor één of meer specifieke doeleinden.
    </Element>
    {/********************* PAGE 11 ********************/}
    <Element>
      <Strong>Wettelijke verplichtingen</Strong>
      het verwerken van Persoonsgegevens is noodzakelijk om te voldoen aan een wettelijke
      verplichting waaraan het Bedrijf is onderworpen.
    </Element>
    <Element>
      <Strong>Vitale belangen</Strong>: het verwerken van Persoonsgegevens is noodzakelijk om uw
      vitale belangen of van een andere natuurlijke persoon te beschermen.
    </Element>
    <Element>
      <Strong>Publieke belangen</Strong>: Verwerking van Persoonsgegevens heeft betrekking op een
      taak die wordt uitgevoerd in het algemeen belang of in de uitoefening van het openbaar gezag
      dat aan het Bedrijf is toegekend.
    </Element>
    <Element>
      <Strong>Gerechtvaardigde belangen</Strong>: het verwerken van Persoonsgegevens is noodzakelijk
      voor de doeleinden van de legitieme belangen die door het Bedrijf worden nagestreefd
    </Element>
    <Paragraph>
      In ieder geval helpt het Bedrijf graag om de specifieke rechtsgrondslag die van toepassing is
      op de verwerking te verduidelijken, en in het bijzonder of het verstrekken van
      Persoonsgegevens een wettelijke of contractuele vereiste is, of een vereiste die nodig is om
      een contract aan te gaan.
    </Paragraph>
    <TitleH3>Uw rechten onder de AVG</TitleH3>
    <Paragraph>
      Het Bedrijf verbindt zich ertoe de vertrouwelijkheid van uw Persoonsgegevens te respecteren en
      te garanderen dat u uw rechten kunt uitoefenen.
    </Paragraph>
    <Paragraph>
      U hebt het recht onder dit privacy beleid, en volgens de wet als u zich binnen de EU bevindt,
      om:
    </Paragraph>
    <Element>
      <Strong>Toegang vragen tot uw Persoonsgegevens.</Strong>Het recht op toegang, update of
      verwijdering van de informatie die We over u hebben. Wanneer dit mogelijk is gemaakt, kunt u
      rechtstreeks toegang krijgen tot, bijwerken van of verzoeken om verwijdering van uw
      Persoonsgegevens in het gedeelte Uw Accountinstellingen. Als u deze acties niet zelf kunt
      uitvoeren, neem dan contact met Ons op om u te helpen. Dit stelt u ook in staat om een kopie
      te ontvangen van de Persoonsgegevens die We over u bewaren.
    </Element>
    <Element>
      <Strong>Verzoek om correctie van de Persoonsgegevens die We over u bewaren.</Strong>U hebt het
      recht om eventuele onvolledige of onjuiste informatie die We over u bewaren, te laten
      corrigeren.
    </Element>
    <Element>
      <Strong>Bezwaar maken tegen de verwerking van uw Persoonsgegevens.</Strong>Dit recht bestaat
      wanneer We vertrouwen op een legitiem belang als de wettelijke basis voor Onze verwerking en
      er is iets met uw specifieke situatie, waardoor u bezwaar wil maken tegen Onze verwerking van
      uw Persoonsgegevens op deze grond. U hebt ook het recht om bezwaar aan te tekenen wanneer Wij
      uw Persoonsgegevens verwerken voor marketingdoeleinden.
    </Element>
    {/********************* PAGE 12 ********************/}
    <Element>
      <Strong>Verzoek om verwijdering van uw Persoonsgegevens.</Strong>U hebt het recht om Ons te
      vragen Persoonsgegevens te verwijderen als er geen goede reden voor Ons is om deze verder te
      verwerken.
    </Element>
    <Element>
      <Strong>Verzoek om de overdracht van uw Persoonsgegevens.</Strong>We zullen u of een door u
      gekozen derde partij, uw Persoonsgegevens verstrekken in een gestructureerd, algemeen
      gebruikt, machine-leesbaar formaat. Houd er rekening mee dat dit recht alleen van Toepassing
      is op geautomatiseerde informatie waarvoor u Ons aanvankelijk toestemming hebt gegeven om te
      gebruiken of waar We de informatie hebben gebruikt om een contract met u uit te voeren.
    </Element>
    <Element>
      <Strong>Uw toestemming intrekken.</Strong>U heeft het recht om uw toestemming voor het gebruik
      van uw Persoonsgegevens in te trekken. Als u uw toestemming intrekt, kunnen We u mogelijk geen
      toegang verlenen tot bepaalde specifieke functionaliteiten van de Service.
    </Element>
    <Element>
      <Strong>Uitoefening van uw GDPR-gegevensbeschermingsrechten.</Strong>U kunt uw rechten op
      toegang, rectificatie, annulering en oppositie uitoefenen door contact met Ons op te nemen.
      Houd er rekening mee dat We u kunnen vragen om uw identiteit te verifiëren voordat We op
      dergelijke verzoeken reageren. Als u een verzoek indient, zullen We Ons best doen om zo snel
      mogelijk op u te reageren.
    </Element>
    <Paragraph>
      U hebt het recht om een klacht in te dienen bij een gegevensbeschermingsautoriteit over Onze
      verzameling en gebruik van uw Persoonsgegevens. Neem voor meer informatie contact op met uw
      lokale gegevensbeschermingsautoriteit in de EER als u zich in de Europese Economische Ruimte
      (EER) bevindt.
    </Paragraph>
    <TitleH3>Links naar andere websites</TitleH3>
    <Paragraph>
      Onze Service kan links bevatten naar andere websites die niet door ons worden beheerd. Als u
      op een link van een derde partij klikt, wordt u doorgestuurd naar de site van die derde
      partij. We raden u ten zeerste aan om het privacy beleid van elke site die u bezoekt te lezen.
    </Paragraph>
    <Paragraph>
      We hebben geen controle over en nemen geen verantwoordelijkheid voor de inhoud, het privacy
      beleid of de praktijken van sites of services van derden.
    </Paragraph>
    <TitleH3>Recht op inzage, correcties, recht op bezwaar en recht op dataportabiliteit</TitleH3>
    <Paragraph>
      Wanneer u inzage wilt in uw Persoonsgegevens, uw gegevens wilt wijzigingen of verwijderen of
      als u uw Persoonsgegevens geheel of gedeeltelijk wilt laten overdragen aan u of aan een derde,
      kunt u contact opnemen met het Bedrijf (Inzage is alleen mogelijk indien er geen sprake is van
      volledige anonimiteit) door een e-mail te sturen naar info@medvice.io of een brief sturen aan.
    </Paragraph>
    <Paragraph>Medvice Enterprises B.V.</Paragraph>
    <Paragraph>Molengraaffsingel 12,</Paragraph>
    <Paragraph>2629JD, Delft, </Paragraph>
    <Paragraph>
      Als u bezwaar heeft op de wijze waarop Wij met uw gegevens omgaan, kunt u een klacht indienen
      bij de Autoriteit Persoonsgegevens.
    </Paragraph>
    <TitleH3>Wijzigingen</TitleH3>
    <Paragraph>
      Het Bedrijf kan van tijd tot tijd deze Privacy Policy aanpassen of updaten. U wordt daarom
      geadviseerd om deze Privacy Policy regelmatig te raadplegen. Aanpassingen van deze Privacy
      Policy treden in werking op het moment dat deze zijn gepubliceerd op Onze website.
    </Paragraph>
    <Paragraph>
      We zullen u hiervan op de hoogte stellen via e-mail en/ of een prominente mededeling over onze
      service, voordat de wijziging van kracht wordt en de datum "Laatst bijgewerkt" bovenaan dit
      privacy beleid bijwerken.
    </Paragraph>
    <TitleH3>Contact</TitleH3>
    <Paragraph>
      Wanneer u vragen heeft over deze Privacy Policy, kunt u contact opnemen met het Bedrijf door
      een e-mail te sturen naar info@medvice.io.
    </Paragraph>
  </>
)
