import { memo, useCallback, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import isSameDay from 'date-fns/isSameDay'
import { useTranslation } from 'services/Translation'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import { hp } from 'styles/Sizes'
import { textPrimary, purple } from 'styles/Colors'
import { useAppointmentsDispatch, create } from 'services/Appointments'
import { useSummariesDispatch } from 'services/Summaries'
import { getAccessToken } from 'services/Authentication'
import DefaultBtn from 'components/common/buttons/Default/Component'
import { TimeWindow, Step } from '../Types'

export const FooterSchedule = styled.div`
  width: 100%;
  padding-bottom: 24px;
  z-index: 0;
`

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    title: getText('Scheduled AV request', 'Patient_Details_Modal_Confirmd_Form'),
    confirmDurationLabel: getText('Appointment duration', 'Patient_Details_Modal_Confirmd_Form'),
    confirmDateLabel: getText(
      'Appointment date & time window',
      'Patient_Details_Modal_Confirmd_Form'
    ),
    confirmNotesLabel: getText('Note for the patient', 'Patient_Details_Modal_Confirmd_Form'),
    emptyNotes: getText("There's no note for the patient", 'Patient_Details_Modal_Confirmd_Form'),
    confirmText: getText('You have selected :', 'Patient_Details_Modal_Confirmd_Form'),
    audioVisual: getText('AV Call', 'Patient_Details_Modal_Confirmd_Form'),
    btnLabelAppoint: getText('Send request', 'Patient_Details_Modal_Confirmd_Form'),
  }[key])

function generateTimeWindowText(
  validTimeWindow: { start: Date | null; end: Date | null },
  formatDate: any
) {
  if (
    validTimeWindow.start &&
    validTimeWindow.end &&
    isSameDay(validTimeWindow.start, validTimeWindow.end)
  ) {
    return `On ${formatDate(validTimeWindow.start, 'dd MMMM, yyyy')}`
  }

  if (validTimeWindow.start && validTimeWindow.end) {
    return `Between ${formatDate(validTimeWindow.start, 'dd MMMM, yyyy')} and ${formatDate(
      validTimeWindow.end,
      'dd MMMM, yyyy'
    )}`
  }

  if (validTimeWindow.end) {
    return `Before ${formatDate(validTimeWindow.end, 'dd MMMM, yyyy')}`
  }

  if (validTimeWindow.start) {
    return `After ${formatDate(validTimeWindow.start, 'dd MMMM, yyyy')}`
  }

  return `Any time`
}

interface ConfirmProps {
  notes: string
  validTimeWindow: TimeWindow
  duration: number
  summaryId: number | undefined
  setStep: Dispatch<SetStateAction<Step>>
}

const Confirm = ({ notes, validTimeWindow, duration, summaryId, setStep }: ConfirmProps) => {
  const { t, formatDate: format } = useTranslation(texts)
  const token = getAccessToken()
  const summariesDispatch = useSummariesDispatch()
  const appointmentsDispatch = useAppointmentsDispatch()

  const submit = useCallback(async () => {
    setStep(Step.InProgress)

    const data = {
      summaryId,
      validDateTimeWindow: validTimeWindow,
      duration: duration * 60000,
      doctorNote: notes,
    }

    await create({ token }, data, { appointmentsDispatch, summariesDispatch })

    setStep(Step.Success)
  }, [
    token,
    summaryId,
    appointmentsDispatch,
    summariesDispatch,
    duration,
    notes,
    validTimeWindow,
    setStep,
  ])

  return (
    <>
      <div className="flex flex-col text-lg py-3 mb-3">
        <Typography
          type="title"
          className="font-csc65"
          text={t('title')}
          color={textPrimary}
          size={18}
          style={undefined}
        />
        <Separator size={hp(3)} />
        <div className="flex justify-start items-center">
          <Typography
            className="font-csc45 text-medium"
            text={t('confirmText')}
            color={textPrimary}
            size={15}
            style={undefined}
          />
          <Typography
            type="title"
            className="ml-3 font-csc65"
            text={t('audioVisual')}
            color={purple}
            size={15}
            style={undefined}
          />
        </div>
        <Separator size={hp(3.5)} />
        <div className="flex items-center">
          <Typography
            type="title"
            className="font-csc65 mr-6"
            text={t('confirmDurationLabel')}
            size={15}
            style={undefined}
          />
        </div>
        <Separator size={hp(3)} />
        <Typography
          type="title"
          className="font-csc45 mr-6"
          text={`${duration} minutes`}
          size={15}
          style={undefined}
        />
        <Separator size={hp(3)} />
        <Typography
          type="title"
          className="font-csc65"
          text={t('confirmDateLabel')}
          size={15}
          style={undefined}
        />
        <Separator size={hp(3)} />
        <Typography
          type="title"
          className="font-csc45 mr-6"
          text={generateTimeWindowText(validTimeWindow, format)}
          size={15}
          style={undefined}
        />
        <Separator size={hp(3)} />
        <Typography
          type="title"
          className="font-csc65"
          text={t('confirmNotesLabel')}
          size={15}
          style={undefined}
        />
        <Separator size={hp(3)} />
        <Typography
          type="title"
          className="font-csc45"
          text={notes === '' ? t('emptyNotes') : notes}
          size={15}
          style={undefined}
        />
      </div>
      <Separator size={hp(5)} />
      <FooterSchedule>
        <div className="w-full flex justify-center align-center border-t border-grey-100 pt-12">
          <DefaultBtn
            label={t('btnLabelAppoint')}
            onClick={submit}
            type="submit"
            className="font-csc65"
            reversedColors
            color={textPrimary}
            style={{ width: '9.375rem' }}
            nextIcon={undefined}
          />
        </div>
      </FooterSchedule>
    </>
  )
}

Confirm.whyDidYouRender = true

export default memo(Confirm)
