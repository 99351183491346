import { memo, useCallback, useState, useEffect } from 'react'
import { CS, CS_m } from 'back-end-api'
import { useTranslation } from 'services/Translation'
import { usePatientsState, usePatientsDispatch } from 'services/Patients'
import { getDiseaseIdentifiers } from 'services/Patients/queries'
import InputText from 'components/common/inputs/Text'
import Separator from 'components/common/Separator'
import Typography from 'components/common/Typography'
import UnderlineBtn from 'components/common/buttons/Underline'
import { useSummariesDispatch, update as updateSummary } from 'services/Summaries'
import { usePatientRouteParams } from 'components/patient/Parameters'
import { hp } from 'styles/Sizes'

import Actions from './Actions'
import { formatDiseases } from '../Tools'
import Selector from './Selector'
import PendingRequest from './PendingRequest'
import SelectedDiseases from './SelectedDiseases'
import { Icpc } from '../Types'

const texts = (getText: (text: string, key: string) => string) => (key: string) =>
  ({
    title: getText('Actions', 'Patient_Details_Actions'),
    titleEvaluation: getText('Your evaluation', 'Patient_Details_Actions'),
    titleIcpc: getText(
      'Please choose an ICPC code to finish the consultation',
      'Patient_Details_Actions'
    ),
    selectedIcpcs: getText('Chosen ICPC code(s)', 'Patient_Details_Actions'),
    content: getText('Please enter your evaluation here.', 'Patient_Details_Actions'),
    makeEdit: getText('Make an edit', 'Patient_Details_Actions'),
    done: getText('Done', 'Patient_Details_Actions'),
  }[key])

interface DiagnosisProps {
  codes: Icpc[]
  setCodes: (codes: Icpc[]) => void
  token: string
  mandatoryActionTaken: boolean
}

const Diagnosis = ({ codes, setCodes, token, mandatoryActionTaken }: DiagnosisProps) => {
  const { summary, appointment } = usePatientRouteParams()

  const { t, lang } = useTranslation(texts)
  const { diseases: rawDiseases } = usePatientsState()
  const patientsDispatch = usePatientsDispatch()
  const summariesDispatch = useSummariesDispatch()
  const [evaluationNotes, setEvaluationNotes] = useState(summary?.doctor_evaluation)
  const [editEvaluationNotes, setEditEvaluationNotes] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const formattedDiseases = formatDiseases(rawDiseases, lang)

  const handleDelete = useCallback(
    (idx: number) => {
      const newCodes = [...codes]
      newCodes.splice(idx, 1)
      setCodes(newCodes)
    },
    [codes, setCodes]
  )

  useEffect(() => {
    if (!rawDiseases?.length) {
      ;(async () => {
        await getDiseaseIdentifiers({ token }, { system: 'icpc', lang }, patientsDispatch, true)
      })()
    }
  }, [rawDiseases, patientsDispatch, token, lang])

  const handleEvaluationNotes = useCallback(({ target: { value } }: any) => {
    setEvaluationNotes(value)
  }, [])

  const confirmEdit = useCallback(async () => {
    setEditLoading(true)
    await updateSummary(
      { token },
      summary?.id,
      { doctor_evaluation: evaluationNotes },
      summariesDispatch
    )
    setEditLoading(false)
    setEditEvaluationNotes(false)
  }, [evaluationNotes, summariesDispatch, summary?.id, token])

  const onEdit = useCallback(() => {
    setEditEvaluationNotes(true)
  }, [])

  if (
    (appointment?.status === CS_m.ModelsAppointmentStatus.pending ||
      appointment?.status === CS_m.ModelsAppointmentStatus.accepted) &&
    !appointment?.is_immediate
  ) {
    return <PendingRequest />
  }

  return (
    <div className="h-96 overflow-y-auto">
      <div className="font-csc65 flex flex-col text-lg py-3 mb-3">
        {mandatoryActionTaken && (
          <>
            <div className="font-csc65 flex justify-start items-center">
              {t('titleEvaluation')}
              {!CS.isSummaryStatusFinal(summary) && (
                <UnderlineBtn
                  className="ml-12 pl-12 border-l border-grey-100"
                  bold
                  disabled={editLoading}
                  withNext
                  label={editEvaluationNotes ? t('done') : t('makeEdit')}
                  onClick={editEvaluationNotes ? confirmEdit : onEdit}
                  innerRef={undefined}
                  previousIcon={undefined}
                  nextIcon={undefined}
                  style={undefined}
                />
              )}
            </div>
            <InputText
              className="mt-3 font-csc45"
              field={{
                onChange: handleEvaluationNotes,
                value: evaluationNotes,
              }}
              placeholder={t('content')}
              noBorder
              noPadding
              multiline
              readOnly={!editEvaluationNotes || CS.isSummaryStatusFinal(summary)}
              inputStyle={{ lineHeight: '25px', fontSize: '15px' }}
              containerStyle={{ flex: 1 }}
              style={undefined}
              label={undefined}
              inputRef={undefined}
              placeholderStyle={undefined}
            />
          </>
        )}
      </div>
      <div className="font-csc65 flex flex-col text-lg py-3 mb-3">{t('title')}</div>
      <Actions />
      <Separator size={hp(3)} />
      {mandatoryActionTaken && (
        <>
          <Typography
            type="title"
            text={!CS.isSummaryStatusFinal(summary) ? t('titleIcpc') : t('selectedIcpcs')}
            className="font-csc65"
            bold
            size={20 * 0.8}
          />
          <Separator size={hp(3.7)} />
          <div className="relative w-full flex-wrap flex justify-end">
            <Selector
              icpcs={formattedDiseases}
              codes={codes}
              setCodes={setCodes}
              disabled={CS.isSummaryStatusFinal(summary)}
            />
            <SelectedDiseases
              codes={codes}
              onDelete={handleDelete}
              disabled={CS.isSummaryStatusFinal(summary)}
            />
          </div>
        </>
      )}
    </div>
  )
}

Diagnosis.whyDidYouRender = true

export default memo(Diagnosis)
