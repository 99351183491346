import styled from 'styled-components'

export const Container = styled.div`
  &::before {
    content: ' ';
    position: absolute;
    top: -100%;
    left: -4rem;
    height: 200%;
    width: calc(100% + 8rem);
    background-color: #fcfcfc;
  }
`

export const Title = styled(({ selected, innerRef, ...props }) => (
  <div {...props} ref={innerRef} />
))`
  opacity: ${({ selected }) => (selected ? 0 : 1)};
`
export const LargeTitle = styled(({ selected, innerRef, ...props }) => (
  <div {...props} ref={innerRef} />
))`
  opacity: ${({ selected }) => (selected ? 1 : 0)};
`
