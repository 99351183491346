import styled from 'styled-components'

export const Container = styled.div`
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //padding: 1px 4px 1px 16px;
  //background-color: white;
  //border-radius: 50px;
`
export const SubmitBtn = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  &:hover {
    opacity: 0.8;
  }
`
