import { memo, useState, useCallback } from 'react'
import { useTranslation } from 'services/Translation'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import OptionButton from 'components/common/buttons/Option'
import CopyIcon from 'assets/icons/Copy'
import EditIcon from 'assets/icons/Edit'
import InfoIcon from 'assets/icons/Info'
import { PatientSideBar, setPatientSideBar } from 'services/Misc/patientSideBar'
import { useMiscDispatch } from 'services/Misc'
import { usePatientRouteParams } from 'components/patient/Parameters'
import { CS } from 'back-end-api'

import LockAction from './LockAction'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    editBtnOn: getText('Done', 'Patient_Details_Tabs_Summary_Form'),
    editBtnOff: getText('Make an edit in Summary', 'Patient_Details_Tabs_Summary_Form'),
    copyBtnOn: getText('Copied!', 'Patient_Details_Tabs_Summary_Form'),
    copyBtnOff: getText('Copy summary', 'Patient_Details_Tabs_Summary_Form'),
    rawInfo: getText('Raw information', 'Patient_Details_Tabs_Summary_Form'),
    clipMedHist: getText('Medical History', 'Patient_Details_Tabs_Summary'),
    redFlags: getText('Red Flags', 'Patient_Details_Tabs_Summary_Form'),
    otherComplaints: getText('Other Complaints', 'Patient_Details_Tabs_Summary_Form'),
    help: getText('Helping question', 'Patient_Details_Tabs_Summary'),
  }[key])

interface MoreCardProps {
  onEdit: () => void | undefined
  editing: boolean
}

const MoreCard = ({ onEdit, editing }: MoreCardProps) => {
  const { t, lang } = useTranslation(texts)
  const [copied, setCopied] = useState(false)
  const miscDispatch = useMiscDispatch()

  const handleCopy = useCallback(() => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }, [])

  const handleRawClick = () => {
    setPatientSideBar(miscDispatch, { type: PatientSideBar.SummaryResponses })
  }

  const { summary } = usePatientRouteParams()
  if (!summary) return null

  const clipboardStr = `${t('clipMedHist')}\n${
    summary.complaint?.name
  }: ${CS.getSummarySumupTranslateIfNone(summary, lang)}\n\n${t(
    'redFlags'
  )}\n${summary.red_flags?.join(', ')}\n\n${t(
    'otherComplaints'
  )}\n${summary.additional_complaints?.join(', ')}\n\n${t(
    'help'
  )}\n${CS.getSummaryPatientCommentTranslateIfNone(summary, lang)}`

  return (
    <div className=" flex flex-col text-blue-900 " style={{ padding: '0' }}>
      <div>
        <OptionButton
          style={{ marginBottom: '0.875rem' }}
          label={t('rawInfo')}
          onClick={handleRawClick}
          previousIcon={<InfoIcon className="" />}
          reversedColors={undefined}
          nextIcon={undefined}
          withNext={undefined}
          bold={undefined}
          disabled={undefined}
          mini={undefined}
        />
        <CopyToClipboard text={clipboardStr} onCopy={handleCopy}>
          <OptionButton
            style={{ margin: '0.875rem 0' }}
            label={copied ? t('copyBtnOn') : t('copyBtnOff')}
            onClick={undefined}
            previousIcon={<CopyIcon className="" />}
            reversedColors={undefined}
            nextIcon={undefined}
            withNext={undefined}
            bold={undefined}
            disabled={undefined}
            mini={undefined}
          />
        </CopyToClipboard>
        <OptionButton
          style={{ margin: '0.875rem 0' }}
          label={editing ? t('editBtnOn') : t('editBtnOff')}
          onClick={onEdit}
          previousIcon={<EditIcon className="" />}
          reversedColors={undefined}
          nextIcon={undefined}
          withNext={undefined}
          bold={undefined}
          disabled={undefined}
          mini={undefined}
        />
        <LockAction />
      </div>
    </div>
  )
}

MoreCard.whyDidYouRender = true

export default memo(MoreCard)
