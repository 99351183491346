import { memo, useState, useCallback, useRef } from 'react'
import { useTranslation } from 'services/Translation'
import Typography from 'components/common/Typography'
import TextInputComposed from 'components/common/inputs/Text/Composed'
import DefaultBtn from 'components/common/buttons/Default/Component'
import Separator from 'components/common/Separator'
import { getClaims } from 'services/Authentication'
import { textPrimary, purple } from 'styles/Colors'
import { CS, CS_m } from 'back-end-api'
import TextInput from 'components/common/inputs/Text'
import Close from 'components/common/buttons/Close/Component'
import UnderlineBtn from 'components/common/buttons/Underline'
import ArrowIcon from 'assets/icons/arrows/Small'
import FloatingCard from 'components/common/Card/Floating'
import { hp } from 'styles/Sizes'
import styled from 'styled-components'
import { usePatientRouteParams } from 'components/patient/Parameters'

import { ModalContainer } from '../../Common'
import TemplatesCard from './TemplatesCard'
import { AdviceStatus } from '../common'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    complaint_title: getText('Complaint', 'Patient_Details_Tabs_Summary_Form'),
    btnLabelClose: getText('Close', 'Patient_Details_Modal_Advice'),
    title: getText('Advice Notification', 'Patient_Details_Modal_Advice'),
    text: getText(
      'This is a one time message only. Please make sure your patients can always call for additional questions.',
      'Patient_Details_Modal_Advice'
    ),
    templateLoad: getText('Load templates or, write your own', 'Patient_Details_Modal_Advice'),
    templateTitle: getText('Templates', 'Patient_Details_Modal_Advice'),
    templateCreateBtn: getText('Create new template', 'Patient_Details_Modal_Advice'),
    templateCreateBtnShort: getText('Create as new', 'Patient_Details_Modal_Advice'),
    adviceInputLabel: getText('Please type your advice', 'Patient_Details_Modal_Advice'),
    adviceInputPlaceholder: getText('Write here', 'Patient_Details_Modal_Advice'),
    btnLabelNext: getText('Next', 'Patient_Details_Modal_Advice'),
    additional_complaint: getText('Additional Complaint', 'Patient_Details_Modal_Advice'),
    general_question: getText('General Medical Question', 'Patient_Details_Modal_Advice'),
  }[key])

interface WriteAdviceProps {
  onClose: () => void
  formatTemplateText: (text: string) => string
  setStatus: (status: AdviceStatus) => void
  advice: string
  setAdvice: (advice: string) => void
  title: string
  setTitle: (title: string) => void
}

const FooterAdvice = styled.div`
  width: 100%;
  padding-bottom: 24px;
  z-index: 0;
`

const WriteAdvice = ({
  onClose,
  formatTemplateText,
  setStatus,
  advice,
  setAdvice,
  title,
  setTitle,
}: WriteAdviceProps) => {
  const { t, lang } = useTranslation(texts)

  const [openTemplateSelection, setOpenTemplateSelection] = useState(false)
  const templatesFloatingRef = useRef<any>(null)

  const claims = getClaims()
  const res = CS.useAdviceTemplatesList({ doctor_account_id: claims?.sub ?? '' })
  const templates = res.data?.sort((a, b) => (a.title > b.title ? -1 : 1)) ?? []
  const templateTitleAlreadyUsed = res.data?.some(te => te.title === title)

  const { summary } = usePatientRouteParams()
  const complaint = CS.getSummarySumupTranslateIfNone(summary, lang)
  const additionalComplaint = CS.getSummaryPatientCommentTranslateIfNone(summary, lang)

  const deleteTemplate = useCallback(
    async (templateToDelete: CS_m.ModelsAdviceTemplate) => {
      setTitle('')
      setAdvice('')
      await CS.deleteAdviceTemplate({ id: templateToDelete.id })
    },
    [setTitle, setAdvice]
  )

  const openCreateTemplateModal = useCallback(
    (newTitle: string, newAdvice: string) => () => {
      setAdvice(newAdvice)
      setTitle(newTitle)
      setOpenTemplateSelection(false)
      setStatus(AdviceStatus.CreateAdviceTemplate)
    },
    [setAdvice, setTitle, setOpenTemplateSelection, setStatus]
  )

  const stopUsingTemplate = useCallback(() => {
    setAdvice('')
    setTitle('')
  }, [setAdvice, setTitle])

  const selectTemplate = useCallback(
    (newSelectedTemplate: CS_m.ModelsAdviceTemplate | null) => {
      if (newSelectedTemplate) {
        setAdvice(formatTemplateText(newSelectedTemplate.text))
        setTitle(newSelectedTemplate.title)
      }
    },
    [setAdvice, setTitle, formatTemplateText]
  )
  return (
    <>
      <ModalContainer label={t('btnLabelClose')} onClose={onClose}>
        <div
          className="flex flex-col text-lg py-3 mb-3 border-b border-grey-100 py-6"
          style={{ height: 180, overflow: 'scroll' }}
        >
          {summary?.complaint?.name && complaint && (
            <>
              <div className="flex flex-col text-lg py-3 mb-3">
                <Typography
                  type="title"
                  className="font-csc65"
                  text={t('complaint_title')}
                  color={textPrimary}
                  size={18}
                />
                <Typography
                  className="mt-3 font-csc45 text-medium"
                  text={summary?.complaint?.name}
                  color={purple}
                  size={15}
                />
                <Typography
                  className="mt-3 font-csc45 text-medium"
                  text={complaint}
                  color={textPrimary}
                  size={15}
                />
              </div>
              {additionalComplaint && (
                <div className="flex flex-col text-lg py-3 mb-3">
                  <Typography
                    type="title"
                    className="font-csc65"
                    text={t('additional_complaint')}
                    color={textPrimary}
                    size={18}
                  />
                  <Typography
                    className="mt-3 font-csc45 text-medium"
                    text={additionalComplaint}
                    color={textPrimary}
                    size={15}
                  />
                </div>
              )}
            </>
          )}
          {!complaint && additionalComplaint && (
            <div className="flex flex-col text-lg py-3 mb-3">
              <Typography
                type="title"
                className="font-csc65"
                text={t('general_question')}
                color={textPrimary}
                size={18}
              />
              <Typography
                className="mt-3 font-csc45 text-medium"
                text={additionalComplaint}
                color={textPrimary}
                size={15}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col text-lg py-3 mb-3">
          <Typography
            type="title"
            className="font-csc65"
            text={t('title')}
            color={textPrimary}
            size={18}
          />
          <Typography
            className="mt-3 font-csc45 text-medium"
            text={t('text')}
            color={textPrimary}
            size={15}
          />
        </div>
        <>
          <div className="flex justify-between align-center border-b border-grey-100 py-6">
            <div
              className={`flex flex-wrap justify-start items-center ${
                title && !templateTitleAlreadyUsed ? 'w-80' : 'w-1/2'
              }`}
            >
              <TextInput
                noBorder
                className="font-csc65"
                noPadding
                inputStyle={{ color: textPrimary, fontSize: '1.125rem' }}
                // eslint-disable-next-line no-nested-ternary
                containerStyle={{
                  width: ` ${title ? '60%' : !templateTitleAlreadyUsed ? '100%' : '50%'}`,
                }}
                placeholderStyle={{ fontSize: '1.125rem' }}
                placeholder={t('templateLoad')}
                field={{
                  value: title,
                  onChange: ({ target: { value } }: any) => setTitle(value),
                }}
                style={undefined}
                label={undefined}
                inputRef={undefined}
              />
              {title && templateTitleAlreadyUsed && (
                <Close style={{ height: '16px', width: '16px' }} mini onClick={stopUsingTemplate} />
              )}
            </div>

            {title && !templateTitleAlreadyUsed && (
              <div className="flex justify-center align-center border-l border-grey-100 px-6">
                <UnderlineBtn
                  bold
                  onClick={openCreateTemplateModal(title, advice)}
                  label={t('templateCreateBtnShort')}
                  innerRef={undefined}
                  previousIcon={undefined}
                  nextIcon={undefined}
                  style={undefined}
                />
              </div>
            )}
            <UnderlineBtn
              innerRef={templatesFloatingRef}
              bold
              onClick={() => {
                setOpenTemplateSelection(v => !v)
              }}
              nextIcon={<ArrowIcon color={textPrimary} style={undefined} />}
              label={t('templateTitle')}
              previousIcon={undefined}
              style={undefined}
            />
          </div>

          <FloatingCard
            active={openTemplateSelection}
            setActive={() => {
              setOpenTemplateSelection(v => !v)
            }}
            top={
              templatesFloatingRef?.current &&
              templatesFloatingRef.current.offsetTop +
                templatesFloatingRef.current.offsetHeight -
                24
            }
            left={
              templatesFloatingRef?.current &&
              templatesFloatingRef.current.offsetLeft + templatesFloatingRef.current.offsetWidth
            }
            arrowPos="right"
            paddingHorizontal={undefined}
            paddingVetical={undefined}
            style={undefined}
          >
            <TemplatesCard
              options={[...templates.map(el => el.title)]}
              footerLabel={t('templateCreateBtn')}
              footerCallback={openCreateTemplateModal('', '')}
              onTemplateSelect={(value: number | undefined) =>
                selectTemplate(value ? templates[value - 1] : null)
              }
              onTemplateDelete={(idx: number) => {
                deleteTemplate(templates[idx - 1])
              }}
            />
          </FloatingCard>

          <Separator size={hp(3)} />
          <Typography
            type="title"
            className="font-csc65"
            text={t('adviceInputLabel')}
            color={textPrimary}
            size={18}
          />
          <Separator size={hp(3)} />
          <TextInputComposed
            height={250}
            hideToolbar
            noPadding
            placeholder={t('adviceInputPlaceholder')}
            field={{
              value: advice,
              onChange: setAdvice,
            }}
            data-cy="inputAdvice"
          />
        </>

        <FooterAdvice>
          <div className="w-full flex justify-center align-center border-t border-grey-100 pt-12">
            <DefaultBtn
              label={t('btnLabelNext')}
              onClick={() => {
                setStatus(AdviceStatus.ConfirmAdvice)
              }}
              type="submit"
              className="font-csc65"
              style={{ width: '9.375rem' }}
              color={textPrimary}
              reversedColors
              disabled={!advice || advice === '<p><br></p>' || !title}
              nextIcon={undefined}
            />
          </div>
        </FooterAdvice>
      </ModalContainer>
    </>
  )
}

WriteAdvice.whyDidYouRender = true

export default memo(WriteAdvice)
