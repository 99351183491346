import { memo, useState, useCallback, useRef, useMemo } from 'react'
import { textPrimary } from 'styles/Colors'
import Typography from 'components/common/Typography'
import DefaultBtn from 'components/common/buttons/Default/Component'
import DefaultCross from 'assets/icons/crosses/Default'
import UnderlineBtn from 'components/common/buttons/Underline'
import FloatingCard from 'components/common/Card/Floating'
import OptionButton from 'components/common/buttons/Option'
import { useTranslation } from 'services/Translation'
import { useTimeOffDispatch, create as createTimeOff, deleteTimeOff } from 'services/TimeOff'
import { getAccessTokenAndClaims } from 'services/Authentication'
import { Container } from './Styles'
import BlockView from './BlockView'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    blockViewTitle: getText('Time slot customization', 'Schedule_Sidebar_Event_Manager'),
    patientDetails: getText('Patient details', 'Schedule_Sidebar_Event_Manager'),
    closeBtnLabel: getText('Close', 'Schedule_Sidebar_Event_Manager'),
    customizeLabelBtn: getText('Customize time slot(s)', 'Schedule_Sidebar_Event_Manager'),
    audioVisual: getText('Video consult', 'Schedule_Sidebar_Event_Manager'),
    reschedule: getText('Reschedule', 'Schedule_Sidebar_Event_Manager'),
    empty: getText('Currently the slot is empty', 'Schedule_Sidebar_Event_Manager'),
    block: getText('Block timeslot', 'Schedule_Sidebar_Event_Manager'),
    unblock: getText('Unblock timeslot', 'Schedule_Sidebar_Event_Manager'),
    remove: getText('Remove timeslot', 'Schedule_Sidebar_Event_Manager'),
  }[key])

const contentVariants = {
  initial: {
    opacity: 0,
    x: '100%',
    transition: {
      when: 'afterChildren',
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      when: 'beforeChildren',
    },
  },
}

interface EventManagerProps {
  onClose: () => void
  timeSlots: any[]
}

const EventManager = ({ onClose, timeSlots }: EventManagerProps) => {
  const { t } = useTranslation(texts)
  const [customTimeSlotOpen, setCustomTimeSlotOpen] = useState(false)
  const [showBlockView, setShowBlockView] = useState(false)
  const customTimeSlotRef = useRef<any>(null)
  const timeOffDispatch = useTimeOffDispatch()

  const { token, claims } = getAccessTokenAndClaims()
  const staffId = claims?.sub

  const isBlocked = useMemo(() => {
    return !!timeSlots?.[0]?.doctorId
  }, [timeSlots])

  const handleCustomTimeSlotOpen = useCallback(() => {
    setCustomTimeSlotOpen(v => !v)
  }, [])

  const handleShowBlockView = useCallback(() => {
    setShowBlockView(v => !v)
  }, [])

  const handleCreate = useCallback(
    async (newBlockedTime: any) => {
      await createTimeOff(
        {
          token,
        },
        { data: newBlockedTime, staffId },
        timeOffDispatch,
        true
      )
    },
    [token, staffId, timeOffDispatch]
  )

  const handleDelete = useCallback(async () => {
    onClose()

    await Promise.all(
      timeSlots.map(({ id }) => {
        return deleteTimeOff({ token }, { timeOffId: id, staffId }, timeOffDispatch, true)
      })
    )
  }, [onClose, token, staffId, timeOffDispatch, timeSlots])

  return (
    <>
      <Container initial="initial" animate="visible" variants={contentVariants}>
        <DefaultBtn
          mini
          label={t('closeBtnLabel')}
          onClick={onClose}
          className="absolute top-9 left-0 z-10"
          style={{ transform: 'translate(-50%, -50%)' }}
          nextIcon={<DefaultCross style={{ width: 12, height: 'auto' }} />}
          background={textPrimary}
          color="white"
        />
        {showBlockView ? (
          <BlockView
            timeSlots={timeSlots}
            onShowBlockView={handleShowBlockView}
            createTimeoff={handleCreate}
            onClose={onClose}
          />
        ) : (
          <>
            <div className="px-16 pt-4">
              <div className="h-12 w-full flex items-center justify-start relative z-30 border-b border-grey-100">
                <Typography
                  size={18}
                  className="font-csc65 pb-3"
                  text={t('blockViewTitle')}
                  style={undefined}
                />
              </div>
            </div>
            <div className="mx-16 flex justify-center items-center pb-8 pt-8 border-b border-grey-100">
              <UnderlineBtn
                innerRef={customTimeSlotRef}
                size="0.75rem"
                bold
                withNext
                label={t('customizeLabelBtn')}
                onClick={handleCustomTimeSlotOpen}
                nextIcon={undefined}
                style={undefined}
                previousIcon={undefined}
              />
            </div>
          </>
        )}
      </Container>
      <FloatingCard
        active={customTimeSlotOpen}
        setActive={handleCustomTimeSlotOpen}
        style={{ position: 'absolute', width: '200px' }}
        top={
          customTimeSlotRef &&
          customTimeSlotRef.current &&
          customTimeSlotRef.current.offsetTop + customTimeSlotRef.current.offsetHeight / 2
        }
        left={
          customTimeSlotRef &&
          customTimeSlotRef.current &&
          customTimeSlotRef.current.offsetLeft + customTimeSlotRef.current.offsetWidth - 20
        }
        arrowPos="right"
        paddingHorizontal={undefined}
        paddingVetical={undefined}
      >
        <OptionButton
          onClick={isBlocked ? handleDelete : handleShowBlockView}
          label={isBlocked ? t('unblock') : t('block')}
          reversedColors={undefined}
          previousIcon={undefined}
          nextIcon={undefined}
          withNext={undefined}
          style={undefined}
        />
      </FloatingCard>
    </>
  )
}

EventManager.whyDidYouRender = true

export default memo(EventManager)
