export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73">
    <g id="Group_1039" data-name="Group 1039" transform="translate(-1385 -748)">
      <circle
        id="Ellipse_183"
        data-name="Ellipse 183"
        cx="36.5"
        cy="36.5"
        r="36.5"
        transform="translate(1385 748)"
        fill="#11ce8c"
      />
      <path
        id="Path_1964"
        data-name="Path 1964"
        d="M-1107.694-1870.3l7.645,7.841,17.2-17.2"
        transform="translate(2517.194 2655.152)"
        fill="none"
        stroke="#e8e8e8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
)
