import { Net, multiple } from '../Tools'
import { formatAppointment, reverseFormatAppointment } from './formatter'

export const getAll = async ({ token }, { staffId, appointmentsDispatch }, log = false) => {
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/ListAppointments`,
    token,
    staffId ? { doctor_account_id: staffId } : null,
    log
  )
  if (log) console.log('GetAll Res: ', res)
  if (typeof res === 'number') {
    return null
  }
  const formattedList = multiple(formatAppointment, res)
  if (log) console.log('Formatted Appointments: ', formattedList)
  if (formattedList?.length) {
    // eslint-disable-next-line no-unused-expressions
    appointmentsDispatch?.({
      type: 'addBatch',
      payload: formattedList,
    })
  }
  return true
}

export const getOne = async (
  { token },
  appointmentId,
  { appointmentsDispatch, summariesDispatch } = {},
  log = false
) => {
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/GetAppointment`,
    token,
    { id: appointmentId },
    log
  )
  if (log) console.log('Get Appointment Res: ', res)
  if (typeof res === 'number') {
    if (res === 404) {
      // eslint-disable-next-line no-unused-expressions
      appointmentsDispatch?.({
        type: 'delete',
        payload: appointmentId,
        log,
      })
    }
    return false
  }
  const formattedAppointment = formatAppointment(res)
  if (log) console.log('Formatted Appointment: ', formattedAppointment)
  if (formattedAppointment) {
    // eslint-disable-next-line no-unused-expressions
    appointmentsDispatch?.({
      type: 'update',
      payload: {
        id: formattedAppointment.id,
        data: formattedAppointment,
      },
      log,
    })
    // eslint-disable-next-line no-unused-expressions
    summariesDispatch?.({
      type: 'update',
      payload: {
        summaryId: formattedAppointment.summaryId,
        data: {
          appointmentStatus: formattedAppointment.status,
          appointmentIsImmediate: formattedAppointment.isImmediate || false,
          formattedStatus: formattedAppointment.formattedStatus,
        },
      },
      log,
    })
  }
  return formattedAppointment
}

export const create = async (
  { token },
  data,
  { appointmentsDispatch, summariesDispatch },
  log = true
) => {
  const res = await Net.post(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/CreateAppointment`,
    token,
    reverseFormatAppointment(data),
    log
  )
  if (log) console.log('CreateAppointment Res: ', res)
  if (typeof res === 'number') return null
  const formattedAppointment = formatAppointment(res)
  if (log) console.log('FormattedAppointment: ', formattedAppointment)
  appointmentsDispatch({
    type: 'add',
    payload: formattedAppointment,
    log,
  })
  // eslint-disable-next-line no-unused-expressions
  summariesDispatch?.({
    type: 'update',
    payload: {
      summaryId: formattedAppointment.summaryId,
      data: {
        appointmentId: formattedAppointment.id,
        appointmentStatus: formattedAppointment.status,
        appointmentIsImmediate: formattedAppointment.isImmediate || false,
        formattedStatus: formattedAppointment.formattedStatus,
      },
    },
    log,
  })
  return res
}
