import { memo } from 'react'
import { usePatientRouteParams } from 'components/patient/Parameters'
import {
  usePatientSideBarState,
  PatientSideBar,
  patientSideBarDefault,
} from 'services/Misc/patientSideBar'

import PastEncounter from './PastEncounter'
import SummaryResponses from './SummaryResponses'
import DummySideBar from './DummySideBar'

const PatientSidebar = () => {
  const patientSideBarProps = usePatientSideBarState()
  const { registration } = usePatientRouteParams()

  let sideBar = patientSideBarProps.type
  if (
    sideBar === PatientSideBar.PastEncounter &&
    patientSideBarProps.pastEncounterProps?.currentRegistrationId !== registration?.id
  ) {
    sideBar = patientSideBarDefault.type
  }

  return (
    <div className="flex flex-col justify-between relative" style={{ height: '84%' }}>
      {(() => {
        switch (sideBar) {
          case PatientSideBar.Dummy:
            return <DummySideBar />
          case PatientSideBar.PastEncounter:
            return <PastEncounter />
          case PatientSideBar.SummaryResponses:
            return <SummaryResponses />
          default:
            console.error('Unhandled patient sidebar type', patientSideBarProps.type)
            return null
        }
      })()}
    </div>
  )
}

PatientSidebar.whyDidYouRender = true

export default memo(PatientSidebar)
