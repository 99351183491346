import { diff } from 'deep-object-diff'

import { saveToSession } from '../Tools'

const KEY_TIMEOFF = process.env.REACT_APP_KEY_TIMEOFF

export default (state, { type, payload, username, log }) => {
  switch (type) {
    case 'addBatch': {
      console.log('Add TimeOffs for user: ', username)
      const userTimeOffs = state?.list?.[username]

      if (!Object.keys(diff(userTimeOffs, payload)).length) {
        if (log) console.log('TimeOffs are the same, doing nothing')
        return state
      }
      const finalState = {
        ...state,
        list: {
          ...state.list,
          [username]: payload,
        },
      }
      saveToSession(finalState, KEY_TIMEOFF)
      return finalState
    }
    case 'addOne': {
      const userTimeOffs = state?.list?.[username]
      const finalState = { ...state }

      if (userTimeOffs && userTimeOffs.find(el => el.id === payload.id)) {
        if (log) console.log('Cannot add timeOff: already exist', payload)
        return state
      }
      if (!userTimeOffs) {
        finalState.list = {
          ...finalState.list,
          [username]: [],
        }
      }
      finalState.list?.[username]?.push(payload)
      saveToSession(finalState, KEY_TIMEOFF)
      return finalState
    }
    case 'deleteOne': {
      const userTimeOffs = state?.list?.[username]

      const deleteIndex = !userTimeOffs ? -1 : userTimeOffs.findIndex(el => el.id === payload)
      if (deleteIndex === -1) {
        if (log) console.log('Cannot delete timeoff: not found', payload)
        return state
      }
      const finalState = { ...state }
      finalState.list?.[username]?.splice(deleteIndex, 1)
      saveToSession(finalState, KEY_TIMEOFF)
      return finalState
    }
    case 'unavailabilities': {
      const finalState = { ...state, unavs: { ...state?.unavs, [username]: payload } }
      saveToSession(finalState, KEY_TIMEOFF)
      return finalState
    }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
