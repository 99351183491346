import { primary, purple, error, green } from 'styles/Colors'

export const ROUTES = {
  LOGIN: (next?: string | undefined): string => {
    let route = '/auth/login'
    if (next) {
      route += `?next=${encodeURIComponent(next)}`
    }
    return route
  },
  RESET: '/auth/reset',
  SET_PASSWORD: '/auth/setPassword',

  HOME: '/dashboard',
  PATIENT: '/dashboard/patient/',
  SCHEDULE: '/dashboard/schedule',
}

export type PatientRouteParams = {
  id: string
}

// [Deprecated] should use CS_m.ModelsSummaryStatus & CS_m.ModelsAppointmentStatus
export const STATUS = {
  WAITING: 'standby',
  MIA_IN_PROCESS: 'picked',
  MIA_COMPLETE: 'submitted',
  CONSULT_IN_PROCESS: 'consulting',
  CONSULT_COMPLETE: 'consulted',
  identified: {
    value: 'identified',
    label: 'summaryIdentified',
    color: primary,
  },
  submitted: {
    value: 'submitted',
    label: 'summarySubmitted',
    color: purple,
  },
  standby: { label: 'summaryStandby', color: error, value: 'standby' },
  picked: {
    label: 'summaryPicked',
    color: primary,
    value: 'picked',
  },
  consulting: {
    label: 'summaryConsulting',
    color: primary,
    value: 'consulting',
  },
  timeout: {
    label: 'summaryTimeout',
    color: error,
    value: 'timeout',
  },
  cancelled: {
    label: 'summaryCancelled',
    color: error,
    value: 'cancelled',
  },
  pending: {
    label: 'summaryPending',
    color: primary,
    value: 'pending',
  },
  accepted: {
    label: 'summaryAccepted',
    color: purple,
    value: 'accepted',
  },
  missed: {
    label: 'summaryMissed',
    color: error,
    value: 'missed',
  },
  consulted: {
    label: 'summaryConsulted',
    color: green,
    value: 'consulted',
  },
  ready: {
    color: green,
    value: 'ready',
  },
  inProgress: {
    color: primary,
    value: 'inProgress',
    label: 'summaryPicked',
  },
  done: {
    color: green,
    value: 'done',
  },
}

export const MAP_STATUS_COLOR = {
  submitted: primary,
  pending: primary,
  picked: primary,
  consulting: primary,
  identified: primary,
  accepted: purple,
  ready: green,
  inProgress: green,
  consulted: green,
  done: green,
  declined: error,
  timeout: error,
  cancelled: error,
  missed: error,
  standby: error,
}

// eslint-disable-next-line no-unused-vars
export const statusText = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    summarySubmitted: getText('MIA Complete / Submitted', 'Statuses'),
    summaryStandby: getText('Pending request', 'Statuses'),
    summaryPicked: getText('MIA in progress', 'Statuses'),
    summaryPending: getText('Appointment pending', 'Statuses'),
    summaryCancelled: getText('Summary cancelled', 'Statuses'),
    summaryAccepted: getText('Appointment accepted', 'Statuses'),
    summaryTimeout: getText('Request timed out', 'Statuses'),
    summaryConsulting: getText('Consultation in progress', 'Statuses'),
    summaryMissed: getText('Consultation in progress', 'Statuses'),
    summaryConsulted: getText('Appointment finished', 'Statuses'),
    summaryRegistered: getText('Registered', 'Statuses'),

    avPendImm: getText('Immediate AV request is pending', 'Statuses'),
    avPendSched: getText('Video consult pending', 'Statuses'),
    avAccImm: getText('Immediate AV is accepted', 'Statuses'),
    avAccSched: getText('Scheduled AV accepted', 'Statuses'),
    avDecImm: getText('Immediate AV is declined', 'Statuses'),
    avDecSched: getText('Scheduled AV declined', 'Statuses'),
    avReadyImm: getText('Ready for AV', 'Statuses'),
    avReadySched: getText('Ready for AV', 'Statuses'),
    avProgrImm: getText('(AV) Consult in progress', 'Statuses'),
    avProgrSched: getText('(AV) Consult in progress', 'Statuses'),
    avTimeImm: getText('Request for AV timed out', 'Statuses'),
    avTimeSched: getText('Request for AV timed out', 'Statuses'),
    avDoneImm: getText('AV consultation finished', 'Statuses'),
    avDoneSched: getText('AV consultation finished', 'Statuses'),
    avMissedImm: getText('Appointment missed', 'Statuses'),
    avMissedSched: getText('Appointment missed', 'Statuses'),
    avCancelImm: getText('Appointment cancelled', 'Statuses'),
    avCancelSched: getText('Appointment cancelled', 'Statuses'),
  }[key])

// Map appointments by Status => IsImmediate
export const MAP_APPOINTMENTS = {
  pending: {
    immediate: 'avPendImm',
    scheduled: 'avPendSched',
  },
  accepted: {
    immediate: 'avAccImm',
    scheduled: 'avAccSched',
  },
  declined: {
    immediate: 'avDecImm',
    scheduled: 'avDecSched',
  },
  ready: {
    immediate: 'avReadyImm',
    scheduled: 'avReadySched',
  },
  inProgress: {
    immediate: 'avProgrImm',
    scheduled: 'avProgrSched',
  },
  timeout: {
    immediate: 'avTimeImm',
    scheduled: 'avTimeSched',
  },
  done: {
    immediate: 'avDoneImm',
    scheduled: 'avDoneSched',
  },
  missed: {
    immediate: 'avMissedImm',
    scheduled: 'avMissedSched',
  },
  cancelled: {
    immediate: 'avCancelImm',
    scheduled: 'avCancelSched',
  },
}
export const MAP_JOB_STATUS = {
  PICKED: {
    value: 'picked',
    label: 'summaryPicked',
    color: primary,
  },
  STANDBY: {
    value: 'standby',
    label: 'summaryStandby',
    color: primary,
  },
}
