export default ({ className, color = '#1c165b' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.308"
    height="13.308"
    viewBox="0 0 13.308 13.308"
    className={className}
  >
    <g id="copy" transform="translate(-2.25 -2.25)">
      <path
        id="Path_351"
        data-name="Path 351"
        d="M16.668,18.806H10.014a2.139,2.139,0,0,1-2.139-2.139V10.014a2.139,2.139,0,0,1,2.139-2.139h6.654a2.139,2.139,0,0,1,2.139,2.139v6.654a2.139,2.139,0,0,1-2.139,2.139Z"
        transform="translate(-3.249 -3.249)"
        fill={color}
      />
      <path
        id="Path_352"
        data-name="Path 352"
        d="M6.052,3.676h7.007A2.142,2.142,0,0,0,11.043,2.25H4.389A2.139,2.139,0,0,0,2.25,4.389v6.654a2.142,2.142,0,0,0,1.426,2.016V6.052A2.376,2.376,0,0,1,6.052,3.676Z"
        transform="translate(0)"
        fill={color}
      />
    </g>
  </svg>
)
