import styled from 'styled-components'

export const FooterAdvice = styled.div`
  position: absolute;
  bottom: 10%;
  width: 100%;
  padding-bottom: 24px;
  padding-left: 15%;
  padding-right: 15%;
  z-index: 1;
`

export enum AdviceStatus {
  WriteAdvice,
  CreateAdviceTemplate,
  ConfirmAdvice,
  Loading,
  Success,
  Error,
  Review,
}
