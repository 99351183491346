import { useEffect, useCallback, useState } from 'react'

import usePoll, { alreadyStartedLoops } from 'hooks/usePoll'
import { getSummary, useSummariesDispatch, getPatientSummaries } from 'services/Summaries'
import { getOne as getOneAppointment, useAppointmentsDispatch } from 'services/Appointments'
import { getOne as getPatient, usePatientsDispatch } from 'services/Patients'
import { ROUTES } from 'Constants'

const LOG = false

const REFRESH_PATIENT_DETAILS = parseInt(process.env.REACT_APP_REFRESH_PATIENT_DETAILS, 10)
const REFRESH_PATIENT_APPOINTMENT = parseInt(process.env.REACT_APP_REFRESH_PATIENT_APPOINTMENT, 10)

export const useRefresh = ({ token, patientId, patient, appointment, summary, history }) => {
  const appointmentsDispatch = useAppointmentsDispatch()
  const summariesDispatch = useSummariesDispatch()
  const patientsDispatch = usePatientsDispatch()
  const [patientLoading, setPatientLoading] = useState(!patient)
  const [summaryLoading, setSummaryLoading] = useState(!summary)
  const [appointmentLoading, setAppointmentLoading] = useState(
    !appointment && !!summary?.appointmentId
  )

  // Get PATIENT
  useEffect(() => {
    if (!patient) {
      ;(async () => {
        const res = await getPatient({ token, history }, patientId, {
          patientsDispatch,
        })
        if (LOG) console.log('Got patient: ', res)
        if (patientLoading) setPatientLoading(false)
      })()
    }
  }, [
    patient,
    token,
    patientsDispatch,
    summariesDispatch,
    patientLoading,
    patientId,
    history,
    summary,
  ])
  // Get SUMMARIES
  useEffect(() => {
    if (patient && !summary) {
      ;(async () => {
        const summariesRes = await getPatientSummaries(
          { token },
          { patientId: patient?.id, identityProvider: patient?.identityProvider },
          { appointmentsDispatch, summariesDispatch }
        )
        if (LOG) console.log('Summaries Res: ', summariesRes)
        setSummaryLoading(false)
      })()
    }
  }, [patient, token, summariesDispatch, appointmentsDispatch, patientId, history, summary])

  // Get SUMMARY INTERVAL
  const intervalGetSummaryDetails = useCallback(async () => {
    if (alreadyStartedLoops[`${window.location.pathname}/summary`]) {
      // Summary not found or incomplete. Fetching.
      const res = await getSummary({ token, history }, summary.id, {
        summariesDispatch,
      })
      if (LOG) console.log('Get Summary Details: ', res)
      if (summaryLoading) setSummaryLoading(false)
    }
  }, [history, summariesDispatch, summary, summaryLoading, token])
  usePoll(intervalGetSummaryDetails, REFRESH_PATIENT_DETAILS, {
    contextName: `${window.location.pathname}/summary`,
    isRunning: Boolean(
      window.location.pathname === `${ROUTES.PATIENT}${patientId}` &&
        token &&
        patient &&
        summary &&
        summary?.status !== 'cancelled' &&
        summary?.status !== 'timeout' &&
        summary?.status !== 'consulted'
    ),
  })

  // Get APPOINTMENT INTERVAL
  const intervalGetAppointment = useCallback(async () => {
    if (alreadyStartedLoops[`${window.location.pathname}/appointment`]) {
      const res = await getOneAppointment({ token, history }, summary.appointmentId, {
        appointmentsDispatch,
        summariesDispatch,
      })
      if (LOG) console.log('Get One Appointment: ', res)
      if (appointmentLoading) setAppointmentLoading(false)
    }
  }, [appointmentLoading, appointmentsDispatch, history, summariesDispatch, summary, token])
  usePoll(intervalGetAppointment, REFRESH_PATIENT_APPOINTMENT, {
    contextName: `${window.location.pathname}/appointment`,
    isRunning: Boolean(
      window.location.pathname === `${ROUTES.PATIENT}${patientId}` &&
        summary?.appointmentId &&
        appointment?.status !== 'done' &&
        appointment?.status !== 'declined' &&
        appointment?.status !== 'cancelled' &&
        appointment?.status !== 'timeout'
    ),
  })
  if (LOG) {
    console.log('Loading states: pat, sum, app', patientLoading, summaryLoading, appointmentLoading)
  }
  return summaryLoading || patientLoading
}
