import styled from 'styled-components'
import { motion } from 'framer-motion'
import { background } from 'styles/Colors'

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 25vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: ${background};
`
