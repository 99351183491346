import styled from 'styled-components'
import { motion } from 'framer-motion'

import { transparency, greySecondary } from 'styles/Colors'

export const Container = styled(({ active, ...props }) => <motion.div {...props} />)`
  border-radius: 15px;
  ${({ active }) =>
    active
      ? `
          box-shadow: 3px 6px 24px 0 #2E4CE61A;
      `
      : 'box-shadow: none;'}
  &:before {
    position: absolute;
    content: ' ';
    top: 0;
    left: 20px;
    width: 30%;
    height: 3px;
    background-color: #33b897;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 24px;
  padding: 0 20px;
`
export const Line = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 0 20px;
`
export const IconContainer = styled(({ color, ...props }) => <div {...props} />)`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: ${({ color }) => `${color}${transparency[25]}`};
  transform: scale(0.8);
`
export const Expand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  border-top: solid 1px ${greySecondary};
`
