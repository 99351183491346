import { memo, useCallback, useState } from 'react'
import { motion } from 'framer-motion'
import { hp } from 'styles/Sizes'
import Header from './Header'
import DateSelector from './DateSelector'

const Calendar = () => {
  const [expanded, setExpanded] = useState(true)

  const handleExpand = useCallback(() => {
    setExpanded(v => !v)
  }, [setExpanded])

  return (
    <motion.div animate={{ height: 'auto', marginBottom: expanded ? 10 : 0 }}>
      <Header onExpand={handleExpand} expanded={expanded} />
      <div
        className="relative bg-background z-20"
        style={{
          transition: 'height 200ms ease-out',
          height: 60 + (expanded ? hp(30) : hp(8)),
        }}
      >
        <DateSelector onExpand={handleExpand} expanded={expanded} />
      </div>
    </motion.div>
  )
}

Calendar.whyDidYouRender = true

export default memo(Calendar)
