import { textPrimary } from 'styles/Colors'

export default ({ style, color = textPrimary }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="9.237"
    height="11.369"
    viewBox="0 0 9.237 11.369"
  >
    <path
      id="Icon_metro-bin"
      data-name="Icon metro-bin"
      d="M5.209,5.481v7.106a.713.713,0,0,0,.711.711h6.395a.713.713,0,0,0,.711-.711V5.481Zm2.132,6.395H6.63V6.9h.711Zm1.421,0H8.052V6.9h.711Zm1.421,0H9.473V6.9h.711Zm1.421,0h-.711V6.9H11.6Zm1.6-8.527H10.894V2.461a.534.534,0,0,0-.533-.533H7.874a.534.534,0,0,0-.533.533v.888H5.032a.534.534,0,0,0-.533.533V4.77h9.237V3.882A.534.534,0,0,0,13.2,3.349Zm-3.02,0H8.052v-.7h2.132v.7Z"
      transform="translate(-4.499 -1.928)"
      fill={color}
    />
  </svg>
)
