import { memo } from 'react'

import SelectArrowIcon from 'assets/icons/arrows/Select'
import { useTranslation } from 'services/Translation'

import texts from './Texts'

const Expand = ({ onExpand, canExpand, expanded, reverseArrow = false, style }) => {
  const { t } = useTranslation(texts)
  return (
    <div
      className="underline flex items-center transition font-csc85 text-xs"
      onClick={onExpand}
      tabIndex={0}
      role="button"
      onKeyDown={onExpand}
      style={
        canExpand
          ? {
              ...style,
              pointerEvents: 'all',
              opacity: 1,
            }
          : {
              ...style,
              pointerEvents: 'none',
              opacity: 0,
            }
      }
    >
      {expanded ? t('reduce') : t('expand')}
      <SelectArrowIcon
        className="ml-3"
        style={
          expanded
            ? {
                transform: reverseArrow ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'all 250ms linear',
              }
            : {
                transform: reverseArrow ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 250ms linear',
              }
        }
      />
    </div>
  )
}

Expand.whyDidYouRender = true

export default memo(Expand)
