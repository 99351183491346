import styled from 'styled-components'
import { motion } from 'framer-motion'

import { primary, transparency } from 'styles/Colors'
import { hp } from 'styles/Sizes'

export const Container = styled(({ hidden, disabled, hasLabel, squared, innerRef, ...props }) => (
  <motion.button {...props} ref={innerRef} />
))`
  position: relative;
  border: none;
  border-radius: ${({ squared }) => (squared ? 7 : 50)}px;
  height: ${({ hasLabel }) => hp(hasLabel ? 7.3 : 5.6)}px;
  ${({ squared, hasLabel }) =>
    squared
      ? `
    min-width: ${hp(hasLabel ? 7.3 : 5.6)}px;
  `
      : `width: ${hp(hasLabel ? 7.3 : 5.6)}px;`}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ hidden }) => (hidden ? 'none' : `3px 6px 18px 0 ${primary + transparency[20]}`)};
  opacity: ${({ disabled, hidden }) => (hidden ? 0 : disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled, hidden }) => (disabled || hidden ? 'none' : 'inherit')};
  cursor: ${({ disabled, hidden }) => (disabled || hidden ? 'auto' : 'pointer')};
  ${({ hasLabel }) =>
    hasLabel &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`
export const Tooltip = styled(motion.div)`
  position: absolute;
  bottom: -8px;
  left: 50%;
  height: 42px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 3px 6px 24px 0 ${primary + transparency[10]};
  white-space: nowrap;
  border-radius: 10px;
  transform: translate(-50%, 100%);
  z-index: 1;
  color: ${primary};
  pointer-events: none;
`
export const Label = styled(({ color, ...props }) => <div {...props} />)`
  margin-top: 4px;
  width: min-content;
  min-width: 85px;
  padding: 0 6px;
  font-size: ${15 * 0.8}px;
  color: ${({ color }) => color};
`
