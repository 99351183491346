/* eslint-disable */

import coresanscr_15thin_Woff from '../assets/fonts/CoreSansCr/coresanscr_15thin_macroman/coresanscr15-webfont.woff'
import coresanscr_15thin_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_15thin_macroman/coresanscr15-webfont.woff2'
import coresanscr_15thinitalic_Woff from '../assets/fonts/CoreSansCr/coresanscr_15thinitalic_macroman/coresanscr15it-webfont.woff'
import coresanscr_15thinitalic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_15thinitalic_macroman/coresanscr15it-webfont.woff2'
import coresanscr_25extralight_Woff from '../assets/fonts/CoreSansCr/coresanscr_25extralight_macroman/coresanscr25-webfont.woff'
import coresanscr_25extralight_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_25extralight_macroman/coresanscr25-webfont.woff2'
import coresanscr_25extralightitalic_Woff from '../assets/fonts/CoreSansCr/coresanscr_25extralightitalic_macroman/coresanscr25it-webfont.woff'
import coresanscr_25extralightitalic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_25extralightitalic_macroman/coresanscr25it-webfont.woff2'
import coresanscr_35light_Woff from '../assets/fonts/CoreSansCr/coresanscr_35light_macroman/coresanscr35-webfont.woff'
import coresanscr_35light_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_35light_macroman/coresanscr35-webfont.woff2'
import coresanscr_35lightitalic_Woff from '../assets/fonts/CoreSansCr/coresanscr_35lightitalic_macroman/coresanscr35it-webfont.woff'
import coresanscr_35lightitalic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_35lightitalic_macroman/coresanscr35it-webfont.woff2'
import coresanscr_45regular_Woff from '../assets/fonts/CoreSansCr/coresanscr_45regular_macroman/coresanscr45-webfont.woff'
import coresanscr_45regular_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_45regular_macroman/coresanscr45-webfont.woff2'
import coresanscr_45italic_Woff from '../assets/fonts/CoreSansCr/coresanscr_45italic_macroman/coresanscr45it-webfont.woff'
import coresanscr_45italic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_45italic_macroman/coresanscr45it-webfont.woff2'
import coresanscr_55medium_Woff from '../assets/fonts/CoreSansCr/coresanscr_55medium_macroman/coresanscr55-webfont.woff'
import coresanscr_55medium_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_55medium_macroman/coresanscr55-webfont.woff2'
import coresanscr_55mediumitalic_Woff from '../assets/fonts/CoreSansCr/coresanscr_55mediumitalic_macroman/coresanscr55it-webfont.woff'
import coresanscr_55mediumitalic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_55mediumitalic_macroman/coresanscr55it-webfont.woff2'
import coresanscr_65bold_Woff from '../assets/fonts/CoreSansCr/coresanscr_65bold_macroman/coresanscr65-webfont.woff'
import coresanscr_65bold_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_65bold_macroman/coresanscr65-webfont.woff2'
import coresanscr_65bolditalic_Woff from '../assets/fonts/CoreSansCr/coresanscr_65bolditalic_macroman/coresanscr65it-webfont.woff'
import coresanscr_65bolditalic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_65bolditalic_macroman/coresanscr65it-webfont.woff2'
import coresanscr_75extrabold_Woff from '../assets/fonts/CoreSansCr/coresanscr_75extrabold_macroman/coresanscr75-webfont.woff'
import coresanscr_75extrabold_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_75extrabold_macroman/coresanscr75-webfont.woff2'
import coresanscr_75extrabolditalic_Woff from '../assets/fonts/CoreSansCr/coresanscr_75extrabolditalic_macroman/coresanscr75it-webfont.woff'
import coresanscr_75extrabolditalic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_75extrabolditalic_macroman/coresanscr75it-webfont.woff2'
import coresanscr_85heavy_Woff from '../assets/fonts/CoreSansCr/coresanscr_85heavy_macroman/coresanscr85-webfont.woff'
import coresanscr_85heavy_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_85heavy_macroman/coresanscr85-webfont.woff2'
import coresanscr_85heavyitalic_Woff from '../assets/fonts/CoreSansCr/coresanscr_85heavyitalic_macroman/coresanscr85it-webfont.woff'
import coresanscr_85heavyitalic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_85heavyitalic_macroman/coresanscr85it-webfont.woff2'
import coresanscr_95black_Woff from '../assets/fonts/CoreSansCr/coresanscr_95black_macroman/coresanscr95-webfont.woff'
import coresanscr_95black_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_95black_macroman/coresanscr95-webfont.woff2'
import coresanscr_95blackitalic_Woff from '../assets/fonts/CoreSansCr/coresanscr_95blackitalic_macroman/coresanscr95it-webfont.woff'
import coresanscr_95blackitalic_Woff2 from '../assets/fonts/CoreSansCr/coresanscr_95blackitalic_macroman/coresanscr95it-webfont.woff2'

export default `
  @font-face {
      font-family: 'coresanscr15';
      src:  url(${coresanscr_15thin_Woff}) format('woff'),
            url(${coresanscr_15thin_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr15it';
      src:  url(${coresanscr_15thinitalic_Woff}) format('woff'),
            url(${coresanscr_15thinitalic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr25';
      src:  url(${coresanscr_25extralight_Woff}) format('woff'),
            url(${coresanscr_25extralight_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr25it';
      src:  url(${coresanscr_25extralightitalic_Woff}) format('woff'),
            url(${coresanscr_25extralightitalic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr35';
      src:  url(${coresanscr_35light_Woff}) format('woff'),
            url(${coresanscr_35light_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr35it';
      src:  url(${coresanscr_35lightitalic_Woff}) format('woff'),
            url(${coresanscr_35lightitalic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr45';
      src:  url(${coresanscr_45regular_Woff}) format('woff'),
            url(${coresanscr_45regular_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr45it';
      src:  url(${coresanscr_45italic_Woff}) format('woff'),
            url(${coresanscr_45italic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr55';
      src:  url(${coresanscr_55medium_Woff}) format('woff'),
            url(${coresanscr_55medium_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr55it';
      src:  url(${coresanscr_55mediumitalic_Woff}) format('woff'),
            url(${coresanscr_55mediumitalic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr65';
      src:  url(${coresanscr_65bold_Woff}) format('woff'),
            url(${coresanscr_65bold_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr65it';
      src:  url(${coresanscr_65bolditalic_Woff}) format('woff'),
            url(${coresanscr_65bolditalic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr75';
      src:  url(${coresanscr_75extrabold_Woff}) format('woff'),
            url(${coresanscr_75extrabold_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr75it';
      src:  url(${coresanscr_75extrabolditalic_Woff}) format('woff'),
            url(${coresanscr_75extrabolditalic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr85';
      src:  url(${coresanscr_85heavy_Woff}) format('woff'),
            url(${coresanscr_85heavy_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr85it';
      src:  url(${coresanscr_85heavyitalic_Woff}) format('woff'),
            url(${coresanscr_85heavyitalic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr95';
      src:  url(${coresanscr_95black_Woff}) format('woff'),
            url(${coresanscr_95black_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'coresanscr95it';
      src:  url(${coresanscr_95blackitalic_Woff}) format('woff'),
            url(${coresanscr_95blackitalic_Woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
  }

`
