export const variants = {
  initial: () => ({
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      when: 'afterChildren',
    },
  }),
  active: () => ({
    opacity: 1,
    pointerEvents: 'all',
    transition: {
      duration: 0.15,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  }),
}
