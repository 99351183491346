export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.554"
    height="14.511"
    viewBox="0 0 15.554 14.511"
  >
    <g id="chat" transform="translate(0 0.001)">
      <g id="Group_1333" data-name="Group 1333" transform="translate(0 -0.001)">
        <path
          id="Path_3146"
          data-name="Path 3146"
          d="M13.888,0H1.666A1.614,1.614,0,0,0,0,1.556v8.3A1.614,1.614,0,0,0,1.666,11.4H3.638l-.3,2.535a.528.528,0,0,0,.491.572.582.582,0,0,0,.433-.13L7.8,11.4h6.082a1.614,1.614,0,0,0,1.667-1.55V1.556A1.614,1.614,0,0,0,13.888,0Z"
          transform="translate(0 0.001)"
          fill="#1c165b"
        />
      </g>
    </g>
  </svg>
)
