import { memo } from 'react'

import { hp } from 'styles/Sizes'
import Typography from 'components/common/Typography'
import SuccessImg from 'assets/images/Success'
import Separator from 'components/common/Separator'
import DefaultBtn from 'components/common/buttons/Default'
import Popup from 'components/common/Popup'
import SecondaryBtn from 'components/common/buttons/Secondary'
import { useTranslation } from 'services/Translation'
import { greyPrimary, error as colorError } from 'styles/Colors'
import { Container, ButtonsContainer } from './Styles'

import texts from './Texts'

const ConfirmFinish = ({ visible, onCancel, onConfirm, type = 'definitive', loading }) => {
  const { t } = useTranslation(texts)
  if (!visible) return null
  if (loading) {
    return (
      <Popup>
        <Container className=" flex flex-col justify-center items-center p-11 bg-white border border-grey-100">
          <div className="flex flex-col items-center">
            <SuccessImg style={{ width: '55px', height: '55px' }} />
            <Separator size={hp(3)} />
            <Typography type="title" className="font-csc65" center text={t('finished')} size={18} />
          </div>
        </Container>
      </Popup>
    )
  }

  return (
    <Popup>
      <Container className="flex flex-col justify-center items-center p-11 bg-white border border-grey-100">
        <div className="flex justify-center pb-4 border-b border-grey-100 w-full">
          <Typography className="font-csc65" type="title" text={t('title')} />
        </div>
        <Separator size={hp(2)} />
        <div className="flex justify-center gap-x-1">
          <Typography className="font-csc45" text={t('textSelect')} />
          <Typography className="font-csc65" underline bold text={t(type)} />
          <Typography className="font-csc65" text={t('consultation')} />
        </div>
        <Separator size={hp(5)} />
        <div className="w-full pb-4 border-b border-grey-100">
          <Typography
            center
            style={{ lineHeight: '22px' }}
            color={greyPrimary}
            className="font-csc45"
            text={t('disclaimerText')}
          />
        </div>
        <Separator size={hp(3)} />
        <ButtonsContainer>
          <SecondaryBtn
            style={{ width: '9.375rem' }}
            data-cy="btnFinishConfirm"
            label={t('cancelBtnLabel')}
            onClick={onCancel}
            color={colorError}
            bold
            size="14px"
          />
          <DefaultBtn
            style={{ width: '9.375rem' }}
            disabled={loading}
            reversedColors
            label={t('finishBtnLabel')}
            onClick={onConfirm}
            data-cy="btnFinishConfirm"
          />
        </ButtonsContainer>
      </Container>
    </Popup>
  )
}

ConfirmFinish.whyDidYouRender = true

export default memo(ConfirmFinish)
