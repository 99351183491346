import { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { textPrimary, purple, transparency } from 'styles/Colors'
import Typography from 'components/common/Typography'
import DefaultBtn from 'components/common/buttons/Default/Component'
import DefaultCross from 'assets/icons/crosses/Default'
import GenderIcon from 'assets/icons/gender/Gender'
import { useStatusTranslation, getStatusText, getStatusColor } from 'services/Registrations'
import VideoIcon from 'assets/icons/Video'
import ThermometerIcon from 'assets/icons/Thermometer'
import IdIcon from 'assets/icons/Id'
import { ROUTES } from 'Constants'
import { useTranslation } from 'services/Translation'
import { CS } from 'back-end-api'
import { Container } from './Styles'

const contentVariants = {
  initial: {
    opacity: 0,
    x: '100%',
    transition: {
      when: 'afterChildren',
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      when: 'beforeChildren',
    },
  },
}

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    title: getText('Appointments Details', 'Schedule_Sidebar_Appointment_Details'),
    closeBtnLabel: getText('Close', 'Schedule_Sidebar_Appointment_Details'),
    audioVisual: getText('Video consult', 'Next_Appointment_Siderbar'),
    detailView: getText('View Details', 'Next_Appointment_Siderbar'),
  }[key])

interface AppointmentDetailsProps {
  registrationId: string
  onClose: () => void
}

const AppointmentDetails = ({ registrationId, onClose }: AppointmentDetailsProps) => {
  const { t, formatDate: format } = useTranslation(texts)
  const tStatus = useStatusTranslation()
  const history = useHistory()

  const handleViewDetails = useCallback(() => {
    history.push(`${ROUTES.PATIENT}${registrationId}`)
  }, [registrationId, history])

  const res = CS.useRegistration({ id: registrationId })
  if (!res.data) return null

  const summary = CS.getSummary(res.data)

  const status = getStatusText(res.data, tStatus)
  const color = getStatusColor(res.data)

  return (
    <Container initial="initial" animate="visible" variants={contentVariants}>
      <DefaultBtn
        mini
        label={t('closeBtnLabel')}
        onClick={onClose}
        className="absolute top-9 left-0 z-10"
        style={{ transform: 'translate(-50%, -50%)' }}
        nextIcon={<DefaultCross style={{ width: 12, height: 'auto' }} />}
        background={textPrimary}
        color="white"
      />
      <div className="px-16 pt-4">
        <div className="h-12 w-full flex items-center justify-between relative z-30 border-b border-grey-100">
          <Typography size={18} className="font-csc65 pb-3" text={t('title')} style={undefined} />
          <div className="font-csc65 pb-3 rounded">
            <div
              className="font-csc65 px-2 rounded-full h-5 text-medium"
              style={{
                color,
                backgroundColor: `${color + transparency[10]}`,
              }}
            >
              {status}
            </div>
          </div>
        </div>
      </div>
      <div className="mx-14">
        <div className="flex pt-6 items-center">
          <Typography
            size={20}
            className="font-csc55 pr-3"
            text={`${res.data?.first_name} ${res.data?.last_name}`}
            style={undefined}
          />
          <GenderIcon size={14} gender={res.data?.gender} style={undefined} />
        </div>
        <div className="flex flex-col pb-3 border-b border-grey-100">
          <div className="flex justify-start items-center py-6 border-b border-grey-100">
            <Typography
              size={15}
              color={purple}
              className="font-csc55 pr-6 border-r border-grey-100"
              text={format(new Date(CS.getAppointment(summary)?.datetime ?? ''), 'h:mm a')}
              style={undefined}
            />
            <div className="flex justify-start items-center pl-6">
              <VideoIcon className="w-3 mr-2 mb-1" color={purple} />
              <Typography
                size={15}
                color={purple}
                className="font-csc55"
                text={t('audioVisual')}
                style={undefined}
              />
            </div>
          </div>
          <div className="flex justify-start items-center pt-6">
            <ThermometerIcon className="mr-2.5" />
            <Typography
              style={{ minHeight: '14px' }}
              size={14}
              className="font-csc45 pr-3"
              text={summary?.complaint?.name}
            />
          </div>
          <div className="flex justify-start items-center pt-6">
            <IdIcon className="mr-2.5" />
            <Typography
              style={{ minHeight: '14px' }}
              size={14}
              className="font-csc45 pr-3"
              text={registrationId}
            />
          </div>
          <div className="flex justify-start items-center pt-6">
            <IdIcon className="mr-2.5" />
            <Typography
              style={{ minHeight: '14px' }}
              size={14}
              className="font-csc45 pr-3"
              text={res.data?.email}
            />
          </div>
          <div className="flex justify-start items-center pt-6">
            <IdIcon className="mr-2.5" />
            <Typography
              style={{ minHeight: '14px' }}
              size={14}
              className="font-csc45 pr-3"
              text={res.data?.mobile_phone}
            />
          </div>
          {summary?.red_flags?.length && (
            <div className="flex justify-start items-center py-6">
              <IdIcon className="mr-2.5" />
              <Typography
                style={{ minHeight: '14px' }}
                size={14}
                className="font-csc45 pr-3"
                text={summary?.red_flags?.join(', ')}
              />
            </div>
          )}
        </div>
        <DefaultBtn
          label={t('detailView')}
          type="submit"
          className="font-csc65 mt-9"
          reversedColors
          withNext
          onClick={() => handleViewDetails()}
          color={textPrimary}
          nextIcon={undefined}
          style={undefined}
        />
      </div>
    </Container>
  )
}

AppointmentDetails.whyDidYouRender = true

export default memo(AppointmentDetails)
