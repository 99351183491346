import { createContext, useContext, useReducer, useMemo } from 'react'

import defaultState from './default.json'
import timeOffReducer from './reducer'
import { getFromSessionOrDefault } from '../Tools'

const KEY_TIMEOFF = process.env.REACT_APP_KEY_TIMEOFF

const TimeOffStateContext = createContext()
const TimeOffDispatchContext = createContext()

export const TimeOffProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    timeOffReducer,
    getFromSessionOrDefault(defaultState, KEY_TIMEOFF)
  )

  return (
    <TimeOffStateContext.Provider value={state}>
      <TimeOffDispatchContext.Provider value={dispatch}>{children}</TimeOffDispatchContext.Provider>
    </TimeOffStateContext.Provider>
  )
}

export const useTimeOffState = ({ id, staffId } = {}) => {
  const context = useContext(TimeOffStateContext)
  if (context === undefined) {
    throw new Error('useTimeOffState must be used within a TimeOffProvider')
  }

  const timeOff = useMemo(() => {
    if (id) return context?.list.find(el => el.id === id)
    return undefined
  }, [context?.list, id])
  const dataByStaffId = useMemo(() => {
    if (staffId) {
      return {
        list: context?.list?.[staffId],
        unavs: context?.unavs?.[staffId],
      }
    }
    return {}
  }, [staffId, context])

  if (staffId !== undefined) return dataByStaffId
  if (id !== undefined) return timeOff
  return context || defaultState
}
export const useTimeOffDispatch = () => {
  const context = useContext(TimeOffDispatchContext)
  if (context === undefined) {
    throw new Error('useTimeOffDispatch must be used within a TimeOffProvider')
  }
  return context
}
