import { Container } from './Styles'

const ErrorUnknown = () => (
  <Container>
    <div>Sorry, something went wrong</div>
    <div>You may try to reload the page</div>
  </Container>
)

ErrorUnknown.whyDidYouRender = true

export default ErrorUnknown
