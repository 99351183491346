import { CS, CS_m } from 'back-end-api'
import { useTranslation } from 'services/Translation'
import { primary, purple, error, green } from 'styles/Colors'

export const ownRegistration = (registration: CS_m.ModelsRegistration): boolean => {
  return (registration.doctor_account_id || 0) !== 0
}

export const useStatusTranslation = () => {
  const statusTexts = (getText: (key: string, category: string) => string) => (key: string) =>
    ({
      Registered: getText('Registered', 'Registration_Status'),
      SummarySubmitted: getText('Summary Submitted', 'Registration_Status'),
      SummaryInProgress: getText('Summary In Progress', 'Registration_Status'),
      SummaryConsulted: getText('Summary Consulted', 'Registration_Status'),
      SummaryCancelled: getText('Summary Cancelled', 'Registration_Status'),
      SummaryTimeout: getText('Summary Timeout', 'Registration_Status'),
      AppointmentPending: getText('Appointment Pending', 'Registration_Status'),
      AppointmentAccepted: getText('Appointment Accepted', 'Registration_Status'),
      AppointmentReady: getText('Appointment Ready', 'Registration_Status'),
      AppointmentInProgress: getText('Appointment In Progress', 'Registration_Status'),
      AppointmentDone: getText('Appointment Done', 'Registration_Status'),
      AppointmentDeclined: getText('Appointment Declined', 'Registration_Status'),
      AppointmentCancelled: getText('Appointment Cancelled', 'Registration_Status'),
      AppointmentTimeout: getText('Appointment Timeout', 'Registration_Status'),
      AppointmentMissed: getText('Appointment Missed', 'Registration_Status'),
    }[key])

  const { t } = useTranslation(statusTexts)
  return t
}

export const getStatusText = (
  registration: CS_m.ModelsRegistration,
  t: (key: string) => string
): string => {
  const status = CS.getStatus(registration)

  switch (status) {
    case CS.RegistrationStatus.Registered:
      return t('Registered')
    case CS.RegistrationStatus.SummarySubmitted:
      return t('SummarySubmitted')
    case CS.RegistrationStatus.SummaryInProgress:
      return t('SummaryInProgress')
    case CS.RegistrationStatus.SummaryConsulted:
      return t('SummaryConsulted')
    case CS.RegistrationStatus.SummaryCancelled:
      return t('SummaryCancelled')
    case CS.RegistrationStatus.SummaryTimeout:
      return t('SummaryTimeout')
    case CS.RegistrationStatus.AppointmentPending:
      return t('AppointmentPending')
    case CS.RegistrationStatus.AppointmentAccepted:
      return t('AppointmentAccepted')
    case CS.RegistrationStatus.AppointmentReady:
      return t('AppointmentReady')
    case CS.RegistrationStatus.AppointmentInProgress:
      return t('AppointmentInProgress')
    case CS.RegistrationStatus.AppointmentDone:
      return t('AppointmentDone')
    case CS.RegistrationStatus.AppointmentDeclined:
      return t('AppointmentDeclined')
    case CS.RegistrationStatus.AppointmentCancelled:
      return t('AppointmentCancelled')
    case CS.RegistrationStatus.AppointmentTimeout:
      return t('AppointmentTimeout')
    case CS.RegistrationStatus.AppointmentMissed:
      return t('AppointmentMissed')
    default:
      console.error(`[getStatusText] Unknown status: ${status}`)
      return '-'
  }
}

export const getStatusColor = (registration: CS_m.ModelsRegistration): string => {
  const status = CS.getStatus(registration)

  switch (status) {
    case CS.RegistrationStatus.Registered:
    case CS.RegistrationStatus.SummarySubmitted:
    case CS.RegistrationStatus.AppointmentPending:
      return primary
    case CS.RegistrationStatus.AppointmentAccepted:
      return purple
    case CS.RegistrationStatus.SummaryInProgress:
    case CS.RegistrationStatus.SummaryConsulted:
    case CS.RegistrationStatus.AppointmentReady:
    case CS.RegistrationStatus.AppointmentInProgress:
    case CS.RegistrationStatus.AppointmentDone:
      return green
    case CS.RegistrationStatus.SummaryCancelled:
    case CS.RegistrationStatus.SummaryTimeout:
    case CS.RegistrationStatus.AppointmentDeclined:
    case CS.RegistrationStatus.AppointmentCancelled:
    case CS.RegistrationStatus.AppointmentTimeout:
    case CS.RegistrationStatus.AppointmentMissed:
      return error
    default:
      console.error(`[getStatusColor] Unknown status: ${status}`)
      return primary
  }
}
