import { memo, useState, useMemo } from 'react'
import styled from 'styled-components'

import { useMiscState } from 'services/Misc'
import { TABS } from './Tools'
import Header from './Header'
import Main from './Main'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 15% 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'main';
`

const Schedule = () => {
  const [tab, setTab] = useState(TABS.APPOINTMENTS)

  const {
    scheduleDate: { dateSelected },
  } = useMiscState()

  const date = useMemo(() => (dateSelected ? new Date(dateSelected) : new Date()), [dateSelected])

  return (
    <Container>
      <Header tab={tab} setTab={setTab} date={date} />
      <Main tab={tab} date={date} />
    </Container>
  )
}

Schedule.whyDidYouRender = true

export default memo(Schedule)
