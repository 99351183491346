import { memo } from 'react'

import Cross from 'assets/icons/crosses/Default'
import MiniCross from 'assets/icons/crosses/Error'

const Close = ({ onClick = () => {}, style, className = '', text = '', mini = false }) => {
  return (
    <div
      className={`cursor-pointer w-9 h-9 flex items-center justify-center ${
        mini ? '' : 'rounded-full bg-red-300'
      } ${className}`}
      onClick={onClick}
      style={style}
      tabIndex={0}
      role="button"
      onKeyDown={onClick}
    >
      {mini ? null : text}
      {mini ? <MiniCross style={{ height: '9px', width: '9px' }} /> : <Cross />}
    </div>
  )
}

Close.whyDidYouRender = true

export default memo(Close)
