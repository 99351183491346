import { createContext, useContext, useMemo, useReducer } from 'react'

import defaultState from './default.json'
import summariesReducer from './reducer'
import { getFromSessionOrDefault } from '../Tools'

const KEY_SUMMARIES = process.env.REACT_APP_KEY_SUMMARIES

const SummariesStateContext = createContext()
const SummariesDispatchContext = createContext()

export const SummariesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    summariesReducer,
    getFromSessionOrDefault(defaultState, KEY_SUMMARIES)
  )

  return (
    <SummariesStateContext.Provider value={state}>
      <SummariesDispatchContext.Provider value={dispatch}>
        {children}
      </SummariesDispatchContext.Provider>
    </SummariesStateContext.Provider>
  )
}

export const useSummariesState = ({ id, patientId, last = false } = {}) => {
  const context = useContext(SummariesStateContext)
  if (context === undefined) {
    throw new Error('useSummariesState must be used within a SummariesProvider')
  }

  const summary = useMemo(() => {
    if (id !== undefined) return context?.list.find(el => el.id === id)
    return undefined
  }, [context, id])
  const list = useMemo(() => {
    if (patientId) {
      const filteredList = context.list.filter(el => el.patientId === patientId)
      return last
        ? filteredList?.reduce((a, b) => (a.statusTime > b.statusTime ? a : b), filteredList[0])
        : filteredList
    }
    return undefined
  }, [context, last, patientId])

  if (id !== undefined) return summary
  if (patientId !== undefined) return list
  return context || defaultState
}
export const useSummariesDispatch = () => {
  const context = useContext(SummariesDispatchContext)
  if (context === undefined) {
    throw new Error('useSummariesDispatch must be used within a SummariesProvider')
  }
  return context
}
