import { saveToLocal } from '../Tools'

const KEY_MISC = process.env.REACT_APP_KEY_MISC

export const patientSideBar = 'patientSideBar'
export const appointmentSideBar = 'appointmentSideBar'
export const audioVisuals = 'audioVisuals'

export default (state, { type, payload }) => {
  switch (type) {
    case 'menuOpen': {
      const finalState = { ...state }
      finalState.menuOpen = payload
      saveToLocal(finalState, KEY_MISC)
      return finalState
    }
    case 'scheduleDate': {
      const finalState = { ...state }
      finalState.scheduleDate = payload
      saveToLocal(finalState, KEY_MISC)
      return finalState
    }
    case patientSideBar: {
      const finalState = { ...state }
      finalState.patientSideBar = payload
      saveToLocal(finalState, KEY_MISC)
      return finalState
    }
    case appointmentSideBar: {
      const finalState = { ...state }
      finalState.appointmentSideBar = payload
      saveToLocal(finalState, KEY_MISC)
      return finalState
    }
    case audioVisuals: {
      const finalState = { ...state }
      finalState.audioVisuals = payload
      return finalState
    }
    case 'notifSent': {
      const finalState = { ...state }
      if (!finalState.notifSent) finalState.notifSent = {}
      finalState.notifSent[payload] = true
      saveToLocal(finalState, KEY_MISC)
      return finalState
    }
    case 'zendeskLoaded': {
      const finalState = { ...state }
      if (finalState.zendeskOpen === false && finalState.zendeskLoaded === payload) return state
      finalState.zendeskOpen = false
      finalState.zendeskLoaded = payload
      saveToLocal(finalState, KEY_MISC)
      return finalState
    }
    case 'zendeskOpen': {
      const finalState = { ...state }
      if (finalState.zendeskOpen === payload) return state
      finalState.zendeskOpen = payload
      saveToLocal(finalState, KEY_MISC)
      return finalState
    }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
