import { memo, useCallback } from 'react'
import { useTranslation } from 'services/Translation'
import Typography from 'components/common/Typography'
import TextInputComposed from 'components/common/inputs/Text/Composed'
import DefaultBtn from 'components/common/buttons/Default/Component'
import Separator from 'components/common/Separator'
import { primary, textPrimary } from 'styles/Colors'
import SecondaryBtn from 'components/common/buttons/Secondary'
import { CS, CS_m } from 'back-end-api'
import { hp } from 'styles/Sizes'
import { ModalContainer } from '../../Common'
import { AdviceStatus, FooterAdvice } from '../common'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    btnLabelClose: getText('Close', 'Patient_Details_Modal_Advice'),
    title: getText('Advice Notification', 'Patient_Details_Modal_Advice'),
    text: getText(
      'This is a one time message only. Please make sure your patients can always call for additional questions.',
      'Patient_Details_Modal_Advice'
    ),
    screeningTitle: getText('Screening', 'Patient_Details_Modal_Advice'),
    templateConfirmAdvice: getText('Your advice', 'Patient_Details_Modal_Advice'),
    adviceInputPlaceholder: getText('Write here', 'Patient_Details_Modal_Advice'),
    templateConfirmExtraText: getText(
      'Please make sure your patients can always call for additional questions.',
      'Patient_Details_Modal_Advice'
    ),
    btnLabelBack: getText('Go Back', 'Patient_Details_Modal_Advice'),
    btnLabelSend: getText('Send advice', 'Patient_Details_Modal_Advice'),
  }[key])

interface ConfirmAdviceProps {
  onClose: () => void
  summary: CS_m.ModelsSummary | null
  setStatus: (status: AdviceStatus) => void
  advice: string
  title: string
}

const ConfirmAdvice = ({ onClose, summary, setStatus, advice, title }: ConfirmAdviceProps) => {
  const { t } = useTranslation(texts)

  const detectLinksAndAddHttpTags = (text: string) => {
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/g

    return text.replace(urlRegex, match => {
      if (text.includes(`href="${match}"`)) {
        return match
      }
      return `<a href="${match}">${match}</a>`
    })
  }

  const sendAdvice = useCallback(async () => {
    setStatus(AdviceStatus.Loading)
    const resCreateSum = await CS.setSummaryAdvice({
      id: summary?.id ?? 0,
      text: detectLinksAndAddHttpTags(advice),
    })
    setStatus(!resCreateSum.error ? AdviceStatus.Success : AdviceStatus.Error)
  }, [advice, summary, setStatus])

  return (
    <>
      <ModalContainer label={t('btnLabelClose')} onClose={onClose}>
        <div className="flex flex-col text-lg py-3 mb-3">
          <Typography
            type="title"
            className="font-csc65"
            text={t('title')}
            color={textPrimary}
            size={18}
          />
          <Typography
            className="mt-3 font-csc45 text-medium"
            text={t('text')}
            color={textPrimary}
            size={15}
          />
        </div>
        <div className="flex flex-col">
          <Typography
            type="title"
            className="font-csc65"
            text={t('screeningTitle')}
            color={textPrimary}
            size={15}
          />
          <Separator size={hp(3)} />
          <Typography
            type="text"
            className="font-csc45"
            underline
            text={title}
            color={textPrimary}
            size={15}
          />
          <Separator size={hp(3)} />
          <Typography
            type="title"
            className="font-csc65"
            text={t('templateConfirmAdvice')}
            color={textPrimary}
            size={15}
          />
          <Separator size={hp(3)} />
          <TextInputComposed
            height={200}
            readOnly
            hideToolbar
            noPadding
            placeholder={t('adviceInputPlaceholder')}
            field={{
              value: advice,
            }}
            data-cy="inputAdvice"
          />
        </div>
      </ModalContainer>

      <FooterAdvice>
        <Typography
          type="title"
          className="font-csc45 mb-6"
          center
          italic
          text={t('templateConfirmExtraText')}
          color={primary}
          size={15}
        />
        <div className="w-full flex justify-center align-center border-t border-grey-100 pt-12">
          <SecondaryBtn
            label={t('btnLabelBack')}
            onClick={() => {
              setStatus(AdviceStatus.WriteAdvice)
            }}
            type="submit"
            className="font-csc65"
            style={{ width: '9.375rem' }}
            color={textPrimary}
            background={undefined}
            innerRef={undefined}
          />
          <Separator type="horizontal" size={hp(3)} />
          <DefaultBtn
            label={t('btnLabelSend')}
            onClick={sendAdvice}
            type="submit"
            className="font-csc65"
            reversedColors
            style={{ width: '9.375rem' }}
            color={undefined}
            nextIcon={undefined}
          />
        </div>
      </FooterAdvice>
    </>
  )
}

ConfirmAdvice.whyDidYouRender = true

export default memo(ConfirmAdvice)
