export default ({ color = '#1C165B', className, style }) => (
  <svg
    width="11.391"
    height="10.674"
    viewBox="0 0 11.391 10.674"
    style={style}
    className={className}
  >
    <g transform="translate(0.999 1.414)">
      <path
        d="M-17473.066-17103.3h8.977l-.117-.117-3.877-3.875"
        transform="translate(17473.066 17107.289)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M-17468-17099.914l3.854-3.854"
        transform="translate(17473.125 17107.76)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)
