import { memo, useCallback } from 'react'
import { motion } from 'framer-motion'
import {
  setAppointmentSideBar,
  useAppointmentSideBarState,
  AppointmentSideBar,
  appointmentSideBarDefault,
} from 'services/Misc/appointmentSideBar'
import { useMiscDispatch } from 'services/Misc'

import AppointmentDetails from './AppointmentDetails'
import Calendar from './Calendar'
import EventManager from './EventManager'

const Sidebar = () => {
  const appointmentSidebar = useAppointmentSideBarState()
  const miscDispatch = useMiscDispatch()

  const handleClose = useCallback(
    () => () => {
      setAppointmentSideBar(miscDispatch, appointmentSideBarDefault)
    },
    [miscDispatch]
  )

  return (
    <motion.div
      className="mt-8 pt-8 relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {(() => {
        switch (appointmentSidebar.type) {
          case AppointmentSideBar.AppointmentDetails:
            return (
              <AppointmentDetails
                registrationId={
                  appointmentSidebar.appointmentDetailsSideBarProps?.registrationId?.toString() ||
                  ''
                }
                onClose={handleClose()}
              />
            )
          case AppointmentSideBar.EventManager:
            return (
              <EventManager
                onClose={handleClose()}
                timeSlots={appointmentSidebar.eventManagerSideBarProps?.timeSlots || []}
              />
            )
          case AppointmentSideBar.Calendar:
            return <Calendar />
          default:
            console.error('Unhandled appointments sideBar', appointmentSidebar.type)
            return null
        }
      })()}
    </motion.div>
  )
}

Sidebar.whyDidYouRender = true

export default memo(Sidebar)
