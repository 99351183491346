import { createContext, useContext, useReducer } from 'react'

import defaultState from './default.json'
import miscReducer from './reducer'
import { getFromLocalOrDefault } from '../Tools'

const KEY_MISC = process.env.REACT_APP_KEY_MISC

const MiscStateContext = createContext()
const MiscDispatchContext = createContext()

export const MiscProvider = ({ children }) => {
  const [state, dispatch] = useReducer(miscReducer, getFromLocalOrDefault(defaultState, KEY_MISC))

  return (
    <MiscStateContext.Provider value={state}>
      <MiscDispatchContext.Provider value={dispatch}>{children}</MiscDispatchContext.Provider>
    </MiscStateContext.Provider>
  )
}

export const useMiscState = () => {
  const context = useContext(MiscStateContext)
  if (context === undefined) throw new Error('useMiscState must be used within a MiscProvider')
  return context || defaultState
}

export const useMiscDispatch = () => {
  const context = useContext(MiscDispatchContext)
  if (context === undefined) throw new Error('useMiscDispatch must be used within a MiscProvider')
  return context
}
