import { memo, useState } from 'react'

import { hp } from 'styles/Sizes'
import { CS, CS_m } from 'back-end-api'
import List from 'components/common/List'
import isSameDay from 'date-fns/isSameDay'

import Header from './Header'
import Element from './Element'

interface AppointmentsProps {
  expanded: boolean
  onExpand: () => void
}

const Appointments = ({ expanded, onExpand }: AppointmentsProps) => {
  const [currDate, setCurrDate] = useState(new Date())

  const appointments = CS.useAppointmentsList({ without_old_closed_summaries: 'true' }).filter(
    (a: CS_m.ModelsAppointment) =>
      a?.datetime && isSameDay(new Date(a.datetime), currDate) && !CS.isAppointmentFinalStatus(a)
  )

  const manyAppointments = appointments?.length > 4

  return (
    <div className="relative w-full h-full">
      <Header
        date={currDate}
        setDate={setCurrDate}
        expanded={expanded}
        onExpand={onExpand}
        canExpand={manyAppointments}
      />
      <List
        className="px-16 pb-8"
        containerClassName={undefined}
        height={expanded ? hp(72) : hp(35)}
        initialBotShadow={manyAppointments}
      >
        {appointments?.map((a: CS_m.ModelsAppointment, idx: number) => (
          <Element key={a.id} style={!idx ? { marginTop: 0 } : {}} appointment={a} />
        ))}
      </List>
    </div>
  )
}

Appointments.whyDidYouRender = true

export default memo(Appointments)
