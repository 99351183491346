import { memo } from 'react'

import { Container } from './Styles'

const Micro = ({ active, size = 24, color = '#1C165B', center = false, ...props }) => {
  if (!active) return null
  return <Container size={size} color={color} center={center} {...props} />
}

Micro.whyDidYouRender = true

export default memo(Micro)
