export default getText => key =>
  ({
    header: getText('Header', 'Layout_Search_Element'),
    topic: getText('Topic', 'Layout_Search_Element'),
    date: getText('Date', 'Layout_Search_Element'),
    status: getText('Status', 'Layout_Search_Element'),
    yearsMin: getText('yrs', 'Layout_Search_Element'),
    male: getText('Male', 'Layout_Search_Element'),
    female: getText('Female', 'Layout_Search_Element'),
  }[key])
