/* eslint-disable no-nested-ternary */

import styled from 'styled-components'
import { wp } from 'styles/Sizes'

import { greyPrimary } from 'styles/Colors'

export const Container = styled.div`
  position: ${({ large }) => large && 'relative'};
  grid-area: video;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: ${({ large }) => (large ? 'block' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ small, large }) => (small ? '0 50px 50px 0' : large ? 0 : '50px 100px 0 100px')};
`

export const VideoPlayer = styled.div`
  position: relative;
  background-image: url('https://images.unsplash.com/photo-1651002288503-51eaa73b5b89?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80');
  background-position: bottom;
  width: 100%;
  height: 100%;
  border-radius: ${({ large }) => (large ? 'none' : '30px')};
  border: solid 0.5px #e8e8e8;
  overflow: hidden;
  z-index: 8;
  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const SettingBtn = styled(({ disabled, ...props }) => <div {...props} />)`
  background-color: ${greyPrimary};
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  `
      : ''}
`

export const SettingsContainer = styled.div`
  position: absolute;
  left: ${({ small }) => (!small ? '40px' : '10px')};
  bottom: ${({ small }) => !small && '40px'};
  top: ${({ small }) => small && '10px'};
  width: 50px;
  height: ${({ small }) => (small ? '60px' : '130px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  z-indez: 11;
`

export const SelfCamera = styled.div`
  position: absolute;
  top: 25px;
  right: ${({ large, resize }) => (large && resize ? `${wp(29)}px` : '25px')};
  width: 246px;
  height: 180px;
  border-radius: 16px;
  border: none;
  background-color: lightgray;
  overflow: hidden;
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  z-index: 1;
  & > video {
    height: 100%;
    width: auto;
  }
`
