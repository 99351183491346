import { memo, useState } from 'react'
import { TABS } from './Tools'
import Tabs from './Tabs'
import Main from './Main'

const Encounters = () => {
  const [tab, setTab] = useState(TABS.HISTORY)

  return (
    <div>
      <Tabs setTab={setTab} tab={tab} />
      <Main tab={tab} />
    </div>
  )
}

Encounters.whyDidYouRender = true

export default memo(Encounters)
