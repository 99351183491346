import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 80%;
  padding-bottom: 24px;
  padding-left: 15%;
  padding-right: 15%;
`
