import styled from 'styled-components'

export const Container = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: 5rem;
  margin-left: 6.5rem;
`
export const Content = styled.div`
  grid-area: content;
`
