export default getText => key =>
  ({
    title: getText('Block timeslot', 'Schedule_Sidebar_Block_Time_Slot'),
    text: getText(
      'Are you sure that you want to block this specific time slot? ',
      'Schedule_Sidebar_Block_Time_Slot'
    ),
    blckBtnLabel: getText('Yes, Block it', 'Schedule_Sidebar_Block_Time_Slot'),
    cancelBtnLabel: getText('Cancel', 'Schedule_Sidebar_Block_Time_Slot'),
  }[key])
