export default ({ color = '#1C165B', className = '', style }) => (
  <svg width={8} height={6} viewBox="0 0 8 6" className={className} style={style}>
    <path
      d="M2.5,0,5,3H0Z"
      transform="translate(6.5 4.5) rotate(180)"
      fill="#fff"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
)
