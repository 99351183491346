export const LIVE_UPDATES_TYPES = {
  PATIENT_INVITED: 'patient_invited',
  PATIENT_REGISTERED: 'patient_registered',
  SUMMARY_CANCELLED: 'summary_cancelled',
  SUMMARY_CREATED: 'summary_created',
  READY_FOR_APPOINTMENT: 'ready_for_appointment',
  ASSISTANT_NOTES_UPDATED: 'assistant_notes_updated',
  SCHEDULED_APPOINTMENT_WITHIN_30_MIN: 'scheduled_appointment_within_30_min',
  SCHEDULED_APPOINTMENT_NOW: 'scheduled_appointment_now',
  APPOINTMENT_ACCEPTED: 'appointment_accepted',
  APPOINTMENT_CANCELLED: 'appointment_cancelled',
}

export const formatLiveUpdate = data => {
  const modelId = data.model_id
  const finalUpdate = {
    id: data.id,
    createdAt: data.created_at,
    type: data.type,
  }
  switch (finalUpdate.type) {
    case LIVE_UPDATES_TYPES.PATIENT_INVITED:
      finalUpdate.inviteId = modelId
      break
    case LIVE_UPDATES_TYPES.PATIENT_REGISTERED:
      finalUpdate.patientId = modelId
      break
    case LIVE_UPDATES_TYPES.SUMMARY_CREATED:
    case LIVE_UPDATES_TYPES.SUMMARY_CANCELLED:
    case LIVE_UPDATES_TYPES.ASSISTANT_NOTES_UPDATED:
      finalUpdate.summaryId = modelId
      break
    case LIVE_UPDATES_TYPES.READY_FOR_APPOINTMENT:
    case LIVE_UPDATES_TYPES.SCHEDULED_APPOINTMENT_NOW:
    case LIVE_UPDATES_TYPES.SCHEDULED_APPOINTMENT_WITHIN_30_MIN:
    case LIVE_UPDATES_TYPES.APPOINTMENT_ACCEPTED:
    case LIVE_UPDATES_TYPES.APPOINTMENT_CANCELLED:
      finalUpdate.appointmentId = modelId
      break
  }
  return finalUpdate
}

export const formatInvite = data => ({
  id: data.id,
  firstname: data.first_name,
  lastname: data.last_name,
  phone: data.mobile_phone,
  email: data.email,
  deletedAt: data.deleted_at,
})

/*

Id of model related to the notification, model type deducted from type field
types/Model:
. "patient_invited" -> models.Invite;
. "patient_registered" -> models.Account
. "summary_created", "assistant_notes_updated" -> models.Summary
. "ready_for_appointment", "scheduled_appointment_within_30_min", "scheduled_appointment_now" -> models.Appointment


type:  patient_invited, patient_registered, summary_created, ready_for_appointment, assistant_notes_updated, scheduled_appointment_within_30_min, scheduled_appointment_now ]
 */
