import styled from 'styled-components'
import CheckIcon from 'assets/checkbox.svg'

import { textPrimary } from 'styles/Colors'

export const Container = styled(({ active, ...props }) => <div {...props} />)`
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border: solid 2px ${textPrimary};
  ${({ selected }) =>
    selected &&
    `
    &:before {
      position: absolute;
      content: ' ';
      background: url(${CheckIcon}) no-repeat center;
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 3px;
      background-color: ${textPrimary};
    }
  `}
`
