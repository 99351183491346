import { memo, useRef, useCallback, useMemo, useState, useEffect } from 'react'
import { LargeTitle, Title } from 'components/home/Sidebar/Tabs/Header/Styles'
import { useTranslation } from 'services/Translation'
import { motion } from 'framer-motion'
import { TABS } from '../Tools'
import texts from './Texts'

const Tabs = ({ tab, setTab }) => {
  const { t } = useTranslation(texts)
  const containerRef = useRef(null)
  const followUpTitleRef = useRef(null)
  const historyTitleRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [followUpTitleWidth, setFollowUpTitleWidth] = useState(0)
  const [historyTitleWidth, setHistoryTitleWidth] = useState(0)

  const handleTabChange = useCallback(
    newTab => () => {
      if (tab !== newTab) setTab(newTab)
    },
    [tab, setTab]
  )

  const indicatorLeft = useMemo(() => {
    return tab === TABS.HISTORY ? followUpTitleWidth + 48 + 22 : 0
  }, [tab, followUpTitleWidth])

  const indicatorWidth = useMemo(() => {
    return (tab === TABS.HISTORY ? historyTitleWidth : followUpTitleWidth) + 48
  }, [tab, followUpTitleWidth, historyTitleWidth])

  useEffect(() => {
    if (
      !followUpTitleWidth &&
      followUpTitleRef?.current &&
      followUpTitleRef?.current?.offsetWidth
    ) {
      setTimeout(() => setFollowUpTitleWidth(followUpTitleRef?.current?.offsetWidth), 250)
    }
    if (!historyTitleWidth && historyTitleRef?.current && historyTitleRef?.current?.offsetWidth) {
      setTimeout(() => setHistoryTitleWidth(historyTitleRef?.current?.offsetWidth), 250)
    }
    if (!containerWidth && containerRef?.current && containerRef?.current?.offsetWidth) {
      setTimeout(() => setContainerWidth(containerRef?.current?.offsetWidth), 250)
    }
  }, [historyTitleRef, historyTitleRef, containerRef])

  return (
    <div className="relative" style={{ width: '100%' }}>
      <div className="flex items-center justify-start relative h-12 w-full z-3" ref={containerRef}>
        <div className="relative text-base w-auto text-blue-900 transition z-3 cursor-pointer px-6">
          <LargeTitle
            innerRef={followUpTitleRef}
            className="absolute font-csc65"
            style={{ fontSize: '15px' }}
            selected={tab === TABS.FOLLOWUP}
            onClick={handleTabChange(TABS.FOLLOWUP)}
            data-cy="tabFollowUp"
          >
            {t('followUp')}
          </LargeTitle>
          <Title
            className="font-csc35"
            style={{ fontSize: '15px' }}
            selected={tab === TABS.FOLLOWUP}
            onClick={handleTabChange(TABS.FOLLOWUP)}
            data-cy="tabFollowUp"
          >
            {t('followUp')}
          </Title>
        </div>
        <div
          className="relative text-base w-auto cursor-pointer text-blue-900 transition z-3 px-6"
          style={{ marginLeft: '1.375rem' }}
        >
          <LargeTitle
            innerRef={historyTitleRef}
            className="absolute font-csc65"
            style={{ fontSize: '15px' }}
            selected={tab === TABS.HISTORY}
            onClick={handleTabChange(TABS.HISTORY)}
            data-cy="tabHistory"
          >
            {t('history')}
          </LargeTitle>
          <Title
            className="font-csc35"
            style={{ fontSize: '15px' }}
            selected={tab === TABS.HISTORY}
            onClick={handleTabChange(TABS.HISTORY)}
            data-cy="tabHistory"
          >
            {t('history')}
          </Title>
        </div>
      </div>
      <div className="h-px relative w-full bg-grey-100 overflow-hidden">
        <motion.div
          transition={{
            left: { ease: 'easeOut' },
            width: { duration: 0.2 },
          }}
          className="rounded-full h-px -t-px absolute bg-blue-900"
          animate={{
            left: indicatorLeft,
            width: indicatorWidth,
          }}
        />
      </div>
    </div>
  )
}

Tabs.whyDidYouRender = true

export default memo(Tabs)
