import { memo } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import { useTranslation } from 'services/Translation'
import Dummy from 'components/common/widgets/Dummy'
import { useRegistrations } from 'services/Registrations'
import NavArrowIcon from 'assets/icons/arrows/Paginate'

import { ImageContainer } from './Styles'
import texts from './Texts'
import { imageVariants } from './Animations'

const DummySideBar = () => {
  const { t } = useTranslation(texts)
  const {
    data: { summaries },
    isLoading,
    isError,
  } = useRegistrations()
  const patientIdByUrl = parseInt(window.location.pathname.split('/').reverse()[0], 10)
  const summary = !isLoading && !isError && summaries?.find(s => s.patientId === patientIdByUrl)

  if (!summary) return null

  const imageIndex = wrap(0, summary?.bodyParts.length, 0)

  // If body part selection is skipped in summary creation, it will still be set but with parts = 'null'
  // We need to filter those out as they shouldn't be used
  const bodyParts = summary?.bodyParts.filter(x => x.parts !== 'null')

  return (
    <>
      <div className="mt-16 w-3/5 border-b border-grey-100 mx-auto pb-3 text-center font-csc65 text-lg">
        {t('title')}
      </div>
      <div className="flex flex-col justify-center">
        {!!bodyParts.length && (
          <>
            <ImageContainer>
              <AnimatePresence initial={false} custom={0}>
                <motion.div
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    top: '50%',
                  }}
                  key={0}
                  custom={0}
                  variants={imageVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    x: { type: 'spring', stiffness: 300, damping: 200 },
                    opacity: { duration: 0.2 },
                  }}
                >
                  {bodyParts[imageIndex] && (
                    <Dummy
                      selection={
                        bodyParts[imageIndex].parts.includes(', ')
                          ? bodyParts[imageIndex].parts.split(', ')
                          : bodyParts[imageIndex].parts
                      }
                      questionId={bodyParts[imageIndex].name}
                    />
                  )}
                </motion.div>
              </AnimatePresence>
            </ImageContainer>
          </>
        )}
      </div>
      {bodyParts.length > 1 && (
        <div className="flex items-center w-full justify-center">
          <NavArrowIcon style={{ transform: 'rotate(180deg)' }} />
          <div className="mx-6 w-px h-4 bg-grey-100" />
          <NavArrowIcon />
        </div>
      )}
    </>
  )
}

DummySideBar.whyDidYouRender = true

export default memo(DummySideBar)
