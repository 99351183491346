import { useContext, useCallback } from 'react'
import format from 'date-fns/format'
import TranslationContext, { dateFnsLocales } from './context'

export default messages => {
  const { getSingular, getPlural, setLanguage, language } = useContext(TranslationContext)
  const storedLang = localStorage.getItem('translations')

  const t = useCallback(key => messages?.(getSingular, getPlural)(key), [
    messages,
    getSingular,
    getPlural,
  ])

  const formatDate = useCallback(
    (date, formatString, options) => {
      return format(date, formatString, {
        locale: dateFnsLocales[language],
        ...options,
      })
    },
    [language]
  )

  return {
    t,
    lang: language || storedLang || 'en',
    setLang: setLanguage,
    formatDate,
  }
}
