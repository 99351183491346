import { memo, useCallback, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { hp } from 'styles/Sizes'
import usePrevious from 'hooks/usePrevious'

import Header from './Header'
import Appointments from './Appointments'
import Encounters from './Encounters'
import { TABS } from './Tools'
import { tabsVariants } from './Animations'

const Tabs = ({ expanded, setExpanded, hidden = false }) => {
  const [tab, setTab] = useState(TABS.APPOINTMENTS)
  const previousTab = usePrevious(tab)
  const direction =
    Object.keys(TABS).findIndex(e => e === previousTab) >
    Object.keys(TABS).findIndex(e => e === tab)
      ? -1
      : 1

  const handleExpand = useCallback(() => {
    setExpanded(v => !v)
  }, [setExpanded])

  return (
    <motion.div animate={{ height: hidden ? 0 : 'auto', marginBottom: expanded ? 40 : 0 }}>
      <Header setTab={setTab} tab={tab} />
      <div
        className="relative bg-background z-20"
        style={{
          transition: 'height 200ms ease-out',
          height: 60 + (expanded ? hp(72) : hp(35)),
        }}
      >
        <AnimatePresence initial={false} custom={direction} exitBeforeEnter={false}>
          <motion.div
            custom={direction}
            variants={tabsVariants}
            key={tab}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 200 },
              opacity: { duration: 0.2 },
            }}
            className="absolute w-full h-full"
          >
            {tab === TABS.APPOINTMENTS && (
              <Appointments onExpand={handleExpand} expanded={expanded} />
            )}
            {tab === TABS.ENCOUNTERS && <Encounters onExpand={handleExpand} expanded={expanded} />}
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  )
}

Tabs.whyDidYouRender = true

export default memo(Tabs)
