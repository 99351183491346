import styled from 'styled-components'
import { hp, wp } from 'styles/Sizes'
import { textPrimary } from 'styles/Colors'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${hp(10.3)}px;
  padding: 0 ${wp(5.7)}px;
`
export const LogoContainer = styled.div`
  flex: 1;
  text-align: center;
  color: ${textPrimary};
`
export const LogoIcon = styled.div`
  & > svg {
    height: ${hp(2.1)}px;
  }
  margin-bottom: ${hp(1.7)}px;
  display: flex;
  justify-content: center;
`
export const LogoSubtext = styled.div`
  font-size: 15px;
`
export const Links = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
