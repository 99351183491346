export default getText => key =>
  ({
    btnLabel: getText('Next', 'Auth_Reset_OTP_Form'),
    error: getText('Wrong OTP', 'Auth_Reset_OTP_Form'),
    passwordAlreadyUsed: getText(
      'Your new password has been used too recently',
      'Auth_Reset_OTP_Form'
    ),
    otpExpired: getText('OTP Expired', 'Auth_Reset_OTP_Form'),
  }[key])
