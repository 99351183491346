/* eslint-disable jsx-a11y/media-has-caption */
import { memo, useCallback, useRef, useEffect } from 'react'

import Resize from 'assets/icons/communication/Resize'
import OvalLoader from 'components/common/loaders/oval/Component'
import Separator from 'components/common/Separator'
import LogoFull from 'assets/LogoFull'
import { ROOM_STATUSES } from 'hooks/twilio/useVideo'
import VideoButtons from './VideoButtons'

import { Container, SettingsContainer, VideoPlayer, SettingBtn, SelfCamera } from './Styles'

const VideoComp = ({
  tracks,
  status,
  setMic,
  setCam,
  hangUp,
  microphoneActive,
  setMicrophoneActive,
  videoActive,
  setVideoActive,
  openMessages,
  setSidebarContent,
  sidebarContent,
  handleResize,
  small,
  medium,
  large,
  showInfo,
}) => {
  const selfVideoRef = useRef()
  const patientVideoRef = useRef()
  const patientAudioRef = useRef()

  let selfVideoTrack
  let patientVideoTrack
  let patientAudioTrack

  if (Object.keys(tracks).length) {
    selfVideoTrack = tracks?.[Object.keys(tracks).find(tr => tr.includes('_doctor_'))]?.video
    const patientTracks = tracks?.[Object.keys(tracks).find(tr => tr.includes('_patient_'))] || {}
    patientAudioTrack = patientTracks.audio
    patientVideoTrack = patientTracks.video
  }

  const handleDevice = useCallback(
    device => () => {
      if (device === 'micro') {
        setMic(microphoneActive ? 'off' : 'on')
        setMicrophoneActive(!microphoneActive)
      } else if (device === 'camera') {
        setCam(videoActive ? 'off' : 'on')
        setVideoActive(!videoActive)
      }
    },
    [setMic, microphoneActive, setMicrophoneActive, setCam, videoActive, setVideoActive]
  )

  // Attach tracks to video and audio ref
  useEffect(() => {
    const toDetach = []

    if (selfVideoTrack) {
      selfVideoTrack.attach(selfVideoRef.current)
      toDetach.push(selfVideoTrack)
    }
    if (patientAudioTrack) {
      patientAudioTrack.attach(patientAudioRef.current)
      toDetach.push(patientAudioTrack)
    }
    if (patientVideoTrack) {
      patientVideoTrack.attach(patientVideoRef.current)
      toDetach.push(patientVideoTrack)
    }

    return () => {
      toDetach.forEach(e => e.detach())
    }
  }, [patientAudioTrack, patientVideoTrack, selfVideoTrack])

  const handleOpenMessage = useCallback(() => {
    setSidebarContent('messages')
    if (!showInfo || sidebarContent !== 'info') {
      openMessages()
    }
  }, [openMessages, setSidebarContent, showInfo, sidebarContent])

  if (status === ROOM_STATUSES.DISCONNECTED) {
    return <div />
  }

  const waitingPatient = status !== ROOM_STATUSES.FULL && status !== ROOM_STATUSES.DISCONNECTED

  const settingsContainer = (
    <SettingsContainer small={small}>
      <SettingBtn onClick={() => handleResize(large ? 'medium' : 'large')}>
        <Resize />
      </SettingBtn>
    </SettingsContainer>
  )

  return (
    <Container small={small} large={large} medium={medium}>
      {waitingPatient ? (
        <div
          className={`flex flex-col justify-center items-center h-full w-full bg-background ${
            small && 'rounded-2xl border border-grey-100 '
          }`}
        >
          <LogoFull />
          <Separator />
          <OvalLoader center />
          {settingsContainer}
        </div>
      ) : (
        <VideoPlayer large={large}>
          <SelfCamera resize={showInfo} large={large} disabled={!selfVideoTrack || small}>
            <video ref={selfVideoRef} autoPlay />
          </SelfCamera>
          {patientVideoTrack && <video ref={patientVideoRef} autoPlay />}
          {patientAudioTrack && <audio ref={patientAudioRef} autoPlay />}
          {settingsContainer}
        </VideoPlayer>
      )}
      {!small && (
        <VideoButtons
          hangUp={hangUp}
          microphoneActive={microphoneActive}
          videoActive={videoActive}
          handleOpenMessage={handleOpenMessage}
          selfVideoTrack={selfVideoTrack}
          handleResize={handleResize}
          handleDevice={handleDevice}
          large={large}
          showInfo={showInfo}
        />
      )}
    </Container>
  )
}

VideoComp.whyDidYouRender = true

export default memo(VideoComp)
