import { createContext, useContext, useReducer } from 'react'

import defaultState from './default.json'
import userReducer from './reducer'
import { getFromSessionOrDefault } from '../Tools'

const KEY_APPOINTMENTS = process.env.REACT_APP_KEY_APPOINTMENTS

const AppointmentsStateContext = createContext()
const AppointmentsDispatchContext = createContext()

export const AppointmentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    userReducer,
    getFromSessionOrDefault(defaultState, KEY_APPOINTMENTS)
  )

  return (
    <AppointmentsStateContext.Provider value={state}>
      <AppointmentsDispatchContext.Provider value={dispatch}>
        {children}
      </AppointmentsDispatchContext.Provider>
    </AppointmentsStateContext.Provider>
  )
}

export const useAppointmentsState = () => {
  const context = useContext(AppointmentsStateContext)
  if (context === undefined) {
    throw new Error('useAppointmentsState must be used within a AppointmentsProvider')
  }

  return context
}

export const useAppointmentsDispatch = () => {
  const context = useContext(AppointmentsDispatchContext)
  if (context === undefined) {
    throw new Error('useAppointmentsDispatch must be used within a AppointmentsProvider')
  }
  return context
}
