import { textPrimary } from 'styles/Colors'

export default ({ style, color = textPrimary }) => (
  <svg style={style} width="22.52" height="13.228" viewBox="0 0 22.52 13.228">
    <path
      d="M12.736,96.512H2.464A2.471,2.471,0,0,0,0,98.976v8.3a2.471,2.471,0,0,0,2.464,2.464H12.736a2.471,2.471,0,0,0,2.464-2.464v-8.3a2.455,2.455,0,0,0-2.464-2.464Z"
      transform="translate(0 -96.512)"
      fill={color}
    />
    <g transform="translate(16.432 1.315)">
      <path
        d="M345.766,123.887a1.255,1.255,0,0,0-.419.172l-3.843,2.217v5.715l3.868,2.217a1.465,1.465,0,0,0,2.02-.542,1.5,1.5,0,0,0,.2-.739v-7.612a1.483,1.483,0,0,0-1.826-1.428Z"
        transform="translate(-341.504 -123.847)"
        fill={color}
      />
    </g>
  </svg>
)
