import styled from 'styled-components'

import { greySecondary, primary, purple, transparency } from 'styles/Colors'

export const EventText = styled(({ colapsed, ...props }) => <div {...props} />)`
  position: relative;
  font-size: 10px;
  font-weight: bold;
  width: 95%;
  height: ${({ colapsed }) => (colapsed ? '40px' : '140px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border: 1px solid ${greySecondary};
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  &:before {
    position: absolute;
    top: 0;
    left: 15px;
    content: ' ';
    border-radius: 2px;
    background-color: ${purple};
    height: 2px;
    width: 40px;
  }
  &:hover {
    box-shadow: 3px 6px 24px 0 ${primary + transparency[20]};
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14.6px;
  height: 14.6px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`
