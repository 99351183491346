import { memo } from 'react'

import { textPrimary } from 'styles/Colors'
import DefaultArrow from 'assets/icons/arrows/Default'

import { Container, PrevIcon, NextIcon, Label } from './Styles'

const DefaultBtn = ({
  label,
  onClick = () => {},
  color = textPrimary,
  background = '',
  reversedColors = false,
  previousIcon = undefined,
  nextIcon,
  withNext = false,
  style,
  size = `${17 * 0.8}px`,
  disabled = false,
  type = '',
  mini = false,
  bold = false,
  ...props
}) => {
  return (
    <Container
      onClick={disabled ? undefined : onClick}
      style={style}
      disabled={disabled}
      type={type}
      color={reversedColors ? 'white' : color}
      background={background || (reversedColors ? color : 'white')}
      mini={mini}
      {...props}
    >
      {previousIcon && <PrevIcon>{previousIcon}</PrevIcon>}
      <Label mini={mini} bold={bold} size={size}>
        {label}
      </Label>
      {withNext && (
        <NextIcon>
          <DefaultArrow color={reversedColors ? 'white' : color} />
        </NextIcon>
      )}
      {nextIcon && <NextIcon>{nextIcon}</NextIcon>}
    </Container>
  )
}

DefaultBtn.whyDidYouRender = true

export default memo(DefaultBtn)
