import { useParams } from 'react-router-dom'
import { CS } from 'back-end-api'

export type PatientRouteParams = {
  id: string
}

export const usePatientRouteParams = () => {
  const { id } = useParams<PatientRouteParams>()
  const registrationRes = CS.useRegistration({ id })
  const registration = registrationRes.data
  const summary = CS.getSummary(registration)
  const appointment = CS.getAppointment(summary)
  return { registrationRes, registration, summary, appointment }
}
