import { memo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useRegistrations } from 'services/Registrations'
import { getAccessToken } from 'services/Authentication'
import Header from './Header'
import Tabs from './Tabs'
import { useRefresh } from './Request'

const PatientContent = () => {
  const token = getAccessToken()
  const { id } = useParams()
  const history = useHistory()
  const patientId = parseInt(id, 10)
  const {
    data: { patients, summaries, appointments },
    isLoading,
    isError,
  } = useRegistrations()
  const patient = !isLoading && !isError && patients?.find(p => p.id === patientId)
  const summary = !isLoading && !isError && summaries?.find(s => s.patientId === patientId)

  const appointment = summary?.appointmentId
    ? appointments?.find(a => a.id === summary?.appointmentId)
    : undefined

  useRefresh({
    token,
    patientId,
    patient,
    summary,
    appointment,
    history,
  })

  return (
    <div style={{ marginTop: '6.375rem' }}>
      {!!patient && <Header patient={patient} />}
      <Tabs summary={summary} token={token} />
    </div>
  )
}

PatientContent.whyDidYouRender = true

export default memo(PatientContent)
