import styled from 'styled-components'

export const TopShadow = styled(({ topExtraSafe, ...props }) => <div {...props} />)`
  overflow: hidden;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 123;
  pointer-events: none;
  ${({ topExtraSafe }) =>
    topExtraSafe
      ? `
      &:before {
      content: ' ';
      position: absolute;
      background: #fcfcfc;
      width: 100%;
      height: 300px;
      top: -300px;
      left: 0;
      z-index: 1;
    }`
      : ''}
`

export const BottomShadow = styled.div`
  overflow: hidden;
  height: 300px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 123;
  pointer-events: none;
`
