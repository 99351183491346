import React, { createContext, useContext } from 'react'
import DragSelect from 'dragselect'

type ProviderProps = {
  children: React.ReactNode
}

const Context = createContext<DragSelect | undefined>(undefined)

export function DragSelectProvider({ children }: ProviderProps) {
  // dragSelect has to be changed at every render otherwise it breaks timeslot selection
  const ds = new DragSelect({ draggability: false })

  return <Context.Provider value={ds}>{children}</Context.Provider>
}

export const useDragSelect = () => {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error('useDragSelect must be used within a DragSelectProvider')
  }

  return context
}
