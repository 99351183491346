import { memo, useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { motion } from 'framer-motion'

import { LargeTitle, Title } from 'components/home/Sidebar/Tabs/Header/Styles'
import { useTranslation } from 'services/Translation'

import { TABS } from './Tools'
import texts from './Texts'

const Header = ({ tab, setTab, date }) => {
  const { t, formatDate: format } = useTranslation(texts)
  const containerRef = useRef(null)
  const summaryTitleRef = useRef(null)
  const diagnosisTitleRef = useRef(null)
  const encountersTitleRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [summaryTitleWidth, setSummaryTitleWidth] = useState(0)
  const [diagnosisTitleWidth, setDiagnosisTitleWidth] = useState(0)
  const [encountersTitleWidth, setEncountersTitleWidth] = useState(0)

  const handleTabChange = useCallback(
    newTab => () => {
      if (tab !== newTab) setTab(newTab)
    },
    [tab, setTab]
  )
  useEffect(() => {
    if (
      !encountersTitleWidth &&
      encountersTitleRef?.current &&
      encountersTitleRef?.current?.offsetWidth
    ) {
      setTimeout(() => setEncountersTitleWidth(encountersTitleRef?.current?.offsetWidth), 250)
    }
    if (!summaryTitleWidth && summaryTitleRef?.current && summaryTitleRef?.current?.offsetWidth) {
      setTimeout(() => setSummaryTitleWidth(summaryTitleRef?.current?.offsetWidth), 250)
    }
    if (
      !diagnosisTitleWidth &&
      diagnosisTitleRef?.current &&
      diagnosisTitleRef?.current?.offsetWidth
    ) {
      setTimeout(() => setDiagnosisTitleWidth(diagnosisTitleRef?.current?.offsetWidth), 250)
    }
    if (!containerWidth && containerRef?.current && containerRef?.current?.offsetWidth) {
      setTimeout(() => setContainerWidth(containerRef?.current?.offsetWidth), 250)
    }
  }, [diagnosisTitleRef, diagnosisTitleRef, containerRef, encountersTitleWidth])

  const indicatorLeft = useMemo(() => {
    switch (tab) {
      case TABS.SCHEDULES:
        return summaryTitleWidth + 48 + 22
      default:
        return 0
    }
  }, [tab, summaryTitleWidth, encountersTitleWidth, diagnosisTitleWidth, containerWidth])

  const indicatorWidth = useMemo(() => {
    switch (tab) {
      case TABS.SCHEDULES:
        return diagnosisTitleWidth + 48
      default:
        return summaryTitleWidth + 48
    }
  }, [tab, summaryTitleWidth, encountersTitleWidth, diagnosisTitleWidth])

  return (
    <div className="relative" style={{ width: '100%' }}>
      <div className="flex items-center justify-start relative h-12 w-full z-3" ref={containerRef}>
        <div className="relative text-base w-auto text-blue-900 transition z-3 cursor-pointer px-6">
          <LargeTitle
            innerRef={summaryTitleRef}
            className="absolute font-csc65"
            selected={tab === TABS.APPOINTMENTS}
            onClick={handleTabChange(TABS.APPOINTMENTS)}
            data-cy="tabSummary"
          >
            {t('appointments')}
          </LargeTitle>
          <Title
            className="font-csc35"
            selected={tab === TABS.APPOINTMENTS}
            onClick={handleTabChange(TABS.APPOINTMENTS)}
            data-cy="tabSummary"
          >
            {t('appointments')}
          </Title>
        </div>
        <div
          className="relative text-base w-auto cursor-pointer text-blue-900 transition z-3 px-6"
          style={{ marginLeft: '1.375rem' }}
        >
          <LargeTitle
            innerRef={diagnosisTitleRef}
            className="absolute font-csc65"
            selected={tab === TABS.SCHEDULES}
            onClick={handleTabChange(TABS.SCHEDULES)}
            data-cy="tabDiagnosis"
          >
            {t('schedules')}
          </LargeTitle>
          <Title
            className="font-csc35"
            selected={tab === TABS.SCHEDULES}
            onClick={handleTabChange(TABS.SCHEDULES)}
            data-cy="tabDiagnosis"
          >
            {t('schedules')}
          </Title>
        </div>
      </div>
      <div className="h-px relative w-full bg-grey-100 overflow-hidden">
        <motion.div
          transition={{
            left: { ease: 'easeOut' },
            width: { duration: 0.2 },
          }}
          className="rounded-full h-px -t-px absolute bg-blue-900"
          animate={{
            left: indicatorLeft,
            width: indicatorWidth,
          }}
        />
      </div>
      <div
        style={{ fontSize: '15px' }}
        className="absolute top-4 right-0 flex items-center justify-between font-csc65"
      >
        <div>{format(date, 'd MMMM, yyyy')}</div>
        <div className="w-6" />
        <div>{format(date, 'EEEE')}</div>
      </div>
    </div>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
