import { useState, useEffect, useCallback } from 'react'
import Chat from 'twilio-chat'

const MESSAGES_HISTORY_LIMIT = 25
const initialMessageList = {
  items: [],
  hasNextPage: false,
  hasPrevPage: false,
}

export default (token, channelUniqueName) => {
  const [loading, setLoading] = useState(true)
  const [messageList, setMessageList] = useState(initialMessageList)
  const [typing, setTyping] = useState(false)
  const [chatRoom, setChatRoom] = useState(null)
  const [channel, setChannel] = useState(null)

  const reset = useCallback(() => {
    setLoading(true)
    setMessageList(initialMessageList)
    setTyping(false)
    setChatRoom(null)
    setChannel(null)
  }, [])

  const refreshToken = useCallback(async () => {
    console.log('[Twilio-Chat] Refreshing token... or not')
  }, [])

  const addMessageToList = useCallback(newMessage => {
    setMessageList(({ items, ...v }) => ({
      items: [...items, newMessage],
      ...v,
    }))
  }, [])

  const handleTyping = useCallback(() => channel?.typing(), [channel])

  const handleTypingFeedback = useCallback(() => setTyping(v => !v), [])

  const handleLeaveChat = useCallback(async () => {
    if (channel) {
      try {
        const leftChannel = await channel.leave()
        leftChannel.removeListener('messageAdded', addMessageToList)
        leftChannel.removeListener('typingStarted', handleTypingFeedback)
        leftChannel.removeListener('typingEnded', handleTypingFeedback)
      } catch (e) {
        console.log('[Twilio-Chat] Error leaving chat: ', e)
      }
    }
    reset()
  }, [channel, reset, addMessageToList, handleTypingFeedback])

  const setOldMessages = useCallback(
    async theChannel => {
      if (theChannel.uniqueName === channelUniqueName) {
        const newMessages = await theChannel.getMessages(MESSAGES_HISTORY_LIMIT)
        if (newMessages.items.length) {
          setMessageList(oldMessages => ({
            ...newMessages,
            items: [...oldMessages.items, ...newMessages.items].sort((a, b) =>
              a.state.timestamp < b.state.timestamp ? -1 : 1
            ),
          }))
        }
      }
    },
    [channelUniqueName]
  )

  const joinOrCreateChannel = useCallback(
    async (room, chanName) => {
      try {
        const newChannel = await room.getChannelByUniqueName(chanName)
        if (newChannel.status !== 'joined') await newChannel.join()
        await setOldMessages(newChannel)
        return newChannel
      } catch (e) {
        const channelDescriptor = await room.createChannel({
          uniqueName: chanName,
        })
        let newChannel
        if (channelDescriptor.getChannel) {
          newChannel = await channelDescriptor.getChannel()
          await newChannel.join()
        } else {
          await channelDescriptor.join()
          newChannel = channelDescriptor
        }
        return newChannel
      }
    },
    [setOldMessages]
  )

  const handleSendMessage = useCallback(
    async newMessage => {
      const res = await channel.sendMessage(newMessage)
      return res
    },
    [channel]
  )

  useEffect(() => {
    if (loading && token && channelUniqueName) {
      ;(async () => {
        const newChatRoom = await Chat.create(token)
        newChatRoom.on('tokenExpired', refreshToken)
        newChatRoom.on('channelJoined', setOldMessages)

        const newChannel = await joinOrCreateChannel(newChatRoom, channelUniqueName)
        newChannel.on('messageAdded', addMessageToList)
        newChannel.on('typingStarted', handleTypingFeedback)
        newChannel.on('typingEnded', handleTypingFeedback)

        setChatRoom(newChatRoom)
        setChannel(newChannel)
        setLoading(false)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, channelUniqueName]) // Not having every items is wanted. Otherwise it gets useless refreshes

  return {
    loading,
    messageList,
    typing,
    onTyping: handleTyping,
    sendMessage: handleSendMessage,
    chatRoom,
    channel,
    close: handleLeaveChat,
  }
}
