import { memo, useCallback, useState } from 'react'
import Notification from 'react-web-notification'
import { useHistory } from 'react-router-dom'

import Logo from './assets/logo.png'
import { useRefresh } from './Tools'

const NotificationComp = () => {
  const history = useHistory()
  const [active, setActive] = useState(false)
  const [patientUrl, setPatientUrl] = useState('')
  const [isPermitted, setIsPermitted] = useState(true)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  const resetNotif = useCallback(() => {
    setTitle('')
    setBody('')
    setActive(false)
  }, [])

  useRefresh({ setActive, setTitle, setBody, setPatientUrl, resetNotif })

  const handlePermission = useCallback(
    newValue => () => {
      if (isPermitted !== newValue) setIsPermitted(newValue)
    },
    [isPermitted]
  )

  const handleOnClick = useCallback(
    (e, tag) => {
      console.log(e, 'Notification clicked tag:', tag)
      history.push(patientUrl)
      window.focus()
      resetNotif()
    },
    [resetNotif, history, patientUrl]
  )
  const handleOnError = useCallback(
    (e, tag) => {
      console.log(e, 'Notification error tag:', tag)
      resetNotif()
    },
    [resetNotif]
  )
  const handleOnClose = useCallback(
    (e, tag) => {
      console.log(e, 'Notification closed tag:', tag)
      resetNotif()
    },
    [resetNotif]
  )
  const handleOnShow = useCallback(
    (e, tag) => {
      console.log(e, 'Notification Shown:', tag)
      resetNotif()
    },
    [resetNotif]
  )

  return (
    <Notification
      disableActiveWindow
      ignore={!active || !isPermitted || !title}
      notSupported={handlePermission(false)}
      onPermissionGranted={handlePermission(true)}
      onPermissionDenied={handlePermission(false)}
      onShow={handleOnShow}
      onClick={handleOnClick}
      onClose={handleOnClose}
      onError={handleOnError}
      timeout={7000}
      title={title}
      options={{
        body,
        icon: Logo,
      }}
    />
  )
}

NotificationComp.whyDidYouRender = true

export default memo(NotificationComp)
