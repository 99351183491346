import { memo, useCallback } from 'react'

import { textPrimary } from 'styles/Colors'
import { hp } from 'styles/Sizes'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import DefaultBtn from 'components/common/buttons/Default/Component'
import DefaultCross from 'assets/icons/crosses/Default'
import BODYPARTS_LABELS from 'components/common/widgets/Dummy/constants/bodyparts.labels'
import { useTranslation } from 'services/Translation'
import { useRegistrations } from 'services/Registrations'
import { setPatientSideBar, patientSideBarDefault } from 'services/Misc/patientSideBar'
import { useMiscDispatch } from 'services/Misc'
import texts from './Texts'

const SummaryResponses = () => {
  const { t } = useTranslation(texts)
  const miscDispatch = useMiscDispatch()

  const {
    data: { summaries },
    isLoading,
    isError,
  } = useRegistrations()

  const patientIdByUrl = parseInt(window.location.pathname.split('/').reverse()[0], 10)
  const summary = !isLoading && !isError && summaries?.find(s => s.patientId === patientIdByUrl)

  const rtl = summary?.complaintLang === 'ar'

  const onClose = useCallback(() => {
    setPatientSideBar(miscDispatch, patientSideBarDefault)
  }, [])

  const answerHandler = a => {
    let answer
    if (a?.question?.template === 'Emergency') {
      if (a?.response === 'false') {
        answer = t('questionSkipped')
      } else {
        answer = t('emergencyCalled')
      }
    } else if (a?.response) {
      if (a?.question?.template === 'Localization') {
        answer = a?.response
          ?.split(', ')
          ?.map(b => BODYPARTS_LABELS?.[summary?.complaintLang]?.[b])
          .join(', ')
      } else {
        answer = a?.response
      }
    } else {
      answer = t('questionSkipped')
    }
    return answer
  }

  return (
    <div className="flex flex-col px-20 relative" style={{ height: '84%' }}>
      <div className="font-csc65 border-b border-grey-100 py-6">
        <Typography text={t('title')} type="title" size={18} />
      </div>
      <Separator size={hp(1.3)} />
      <Separator size={hp(2)} />
      <div className="font-csc65">
        {summary?.answers?.map(
          a =>
            !!((a?.response && a?.response !== '[]') || !a?.question?.content?.isMandatory) && (
              <>
                <Typography
                  text={a?.question?.text}
                  size={20 * 0.8}
                  rtl={rtl}
                  style={{ width: '100%', paddingRight: 12 }}
                />
                <Separator size={hp(0.3)} />
                <Typography
                  text={answerHandler(a)}
                  size={16 * 0.8}
                  rtl={rtl}
                  style={{ width: '100%', paddingRight: 12 }}
                />
                <Separator size={hp(2.5)} />
              </>
            )
        )}
      </div>
      <DefaultBtn
        mini
        label={t('closeBtnLabel')}
        onClick={onClose}
        className="absolute top-9 left-0"
        style={{ transform: 'translate(-50%, -50%)' }}
        nextIcon={<DefaultCross style={{ width: 12, height: 'auto' }} />}
        background={textPrimary}
        color="white"
      />
    </div>
  )
}

SummaryResponses.whyDidYouRender = true

export default memo(SummaryResponses)
