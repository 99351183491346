import { diff } from 'deep-object-diff'

import { saveToSession } from '../Tools'

const KEY_APPOINTMENTS = process.env.REACT_APP_KEY_APPOINTMENTS

const getUpdatedList = (oldList, newList) => {
  const out = []

  oldList.forEach(oldA => {
    const newAIndex = newList.findIndex(a => a.id === oldA.id)
    if (newAIndex !== -1) {
      out.push(newList[newAIndex])
      newList.splice(newAIndex, 1)
    } else out.push(oldA)
  })
  newList.forEach(a => out.push(a))
  return out
}

export default (state, { type, payload, log = false }) => {
  switch (type) {
    case 'addBatch': {
      const newList = getUpdatedList(state.list, payload)

      if (!newList || !Object.keys(diff(state.list, newList)).length) {
        if (log) console.log('Appointments are the same, doing nothing')
        return state
      }
      const finalState = {
        ...state,
        list: newList,
      }
      saveToSession(finalState, KEY_APPOINTMENTS)
      return finalState
    }
    case 'addOwn': {
      const newList = getUpdatedList(state.list, payload)

      if (!newList.length || !Object.keys(diff(state.list, newList)).length) {
        if (log) console.log('AddOwn Appointments - No new Elements: ', newList)
        return state
      }
      const finalState = { ...state, list: newList }
      saveToSession(finalState, KEY_APPOINTMENTS)
      return finalState
    }
    case 'add': {
      const finalState = { ...state }
      const existingIndex = finalState.list.findIndex(a => a.id === payload.id)
      if (existingIndex !== -1) {
        if (!Object.keys(diff(finalState.list[existingIndex], payload)).length) {
          if (log) {
            console.log(
              'Adding appointment failed: appointment already exist with the same values: ',
              finalState.list[existingIndex]
            )
          }
          return state
        }
        finalState.list[existingIndex] = payload
      } else finalState.list.push(payload)
      saveToSession(finalState, KEY_APPOINTMENTS)
      return finalState
    }
    case 'update': {
      const { id, data } = payload
      const elIndex = state.list.findIndex(el => el.id === id)
      if (elIndex !== -1 && !Object.keys(diff(state.list[elIndex], data)).length) {
        if (log) console.log('Appointment Update Returning STATE')
        return state
      }
      const finalState = { ...state }
      if (elIndex === -1) finalState.list.push(data)
      else finalState.list[elIndex] = data
      saveToSession(finalState, KEY_APPOINTMENTS)
      return finalState
    }
    case 'delete': {
      const elIndex = state.list.findIndex(el => el.id === payload)
      if (elIndex === -1) {
        if (log) console.log('Appointment Delete error: not found', payload, state.list)
        return state
      }
      const finalState = { ...state }
      finalState.list.splice(elIndex, 1)
      saveToSession(finalState, KEY_APPOINTMENTS)
      return finalState
    }
    case 'cancel': {
      const elIndex = state.list.findIndex(el => el.id === payload)
      if (elIndex === -1) {
        if (log) console.log('Appointment Cancel error: not found', payload, state.list)
        return state
      }
      const finalState = { ...state }
      finalState.list[elIndex] = { ...finalState.list[elIndex], cancelled: true }
      saveToSession(finalState, KEY_APPOINTMENTS)
      return finalState
    }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
