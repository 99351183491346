import { memo, useCallback } from 'react'

import { useTranslation } from 'services/Translation'
import OptionButton from 'components/common/buttons/Option'
import { CS } from 'back-end-api'
import { ownRegistration } from 'services/Registrations'
import LockIcon from 'assets/icons/Lock'
import { usePatientRouteParams } from 'components/patient/Parameters'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    lock: getText('Lock', 'Patient_Details_Lock'),
    unlock: getText('Unlock', 'Patient_Details_Lock'),
  }[key])

const LockAction = () => {
  const { t } = useTranslation(texts)
  const { registration } = usePatientRouteParams()

  const handleLock = useCallback(async () => {
    await CS.claimRegistration({ id: registration?.id ?? 0 })
  }, [registration])

  const handleUnlock = useCallback(async () => {
    await CS.cedeRegistration({ id: registration?.id ?? 0 })
  }, [registration])

  if (!registration || CS.isRegistrationTerminated(registration)) {
    return null
  }

  const properties = ownRegistration(registration)
    ? {
        onClick: handleUnlock,
        label: t('unlock'),
      }
    : {
        onClick: handleLock,
        label: t('lock'),
      }

  return (
    <OptionButton
      style={{ marginTop: '0.875rem' }}
      onClick={properties.onClick}
      previousIcon={<LockIcon className="" />}
      label={properties.label}
      reversedColors={undefined}
      nextIcon={undefined}
      withNext={undefined}
      bold={undefined}
      disabled={undefined}
      mini={undefined}
    />
  )
}

LockAction.whyDidYouRender = true

export default memo(LockAction)
