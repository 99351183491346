import { PureComponent } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import AuthLayout from 'components/layouts/AuthLayout'
import DashboardLayout from 'components/layouts/DashboardLayout'
import ErrorUnknown from 'components/layouts/error/unknown'
import InitBackEndServices from 'components/InitBackEndServices'

import { PatientsProvider } from 'services/Patients'
import { MiscProvider } from 'services/Misc'
import { AppointmentsProvider } from 'services/Appointments'
import { SummariesProvider } from 'services/Summaries'
import { TimeOffProvider } from 'services/TimeOff'
import { TranslationProvider } from 'services/Translation'
import { initialize as initSentry } from 'services/Sentry'

import Login from 'pages/auth/login'
import Reset from 'pages/auth/reset'
import SetPassword from 'pages/auth/setPassword'

import GlobalStyles from './styles/globalStyle'

import { ROUTES } from './Constants'

initSentry()

export default class App extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    crashed: false,
  }

  componentDidCatch() {
    this.setState({ crashed: true })
  }

  render() {
    const { crashed } = this.state
    if (crashed) {
      return <ErrorUnknown />
    }
    return (
      <Sentry.ErrorBoundary fallback="An error has occurred">
        <InitBackEndServices />
        <TranslationProvider>
          <MiscProvider>
            <Router>
              <GlobalStyles />
              <Switch>
                <Route exact path="/">
                  <Redirect to={ROUTES.LOGIN()} />
                </Route>
                <Route path="/auth/:path?" exact>
                  <AuthLayout>
                    <Switch>
                      <Route path={ROUTES.LOGIN()} exact component={Login} />
                      <Route path={ROUTES.RESET} exact component={Reset} />
                      <Route path={ROUTES.SET_PASSWORD} exact component={SetPassword} />
                    </Switch>
                  </AuthLayout>
                </Route>
                <Route path="/dashboard/:path?/:id?" exact>
                  <PatientsProvider>
                    <SummariesProvider>
                      <AppointmentsProvider>
                        <TimeOffProvider>
                          <DashboardLayout />
                        </TimeOffProvider>
                      </AppointmentsProvider>
                    </SummariesProvider>
                  </PatientsProvider>
                </Route>
              </Switch>
            </Router>
          </MiscProvider>
        </TranslationProvider>
      </Sentry.ErrorBoundary>
    )
  }
}
