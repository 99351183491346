import * as Sentry from '@sentry/react'
import { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import { Integrations } from '@sentry/tracing'
import jwtDecode from 'jwt-decode'
import { HeaderXRequestId } from 'back-end-api'

const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS

export const initialize = () => {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const formatRequest = (request: AxiosRequestConfig) => {
  const token = request?.headers?.Authorization?.substring(7)

  return {
    url: request?.url,
    method: request?.method?.toUpperCase(),
    data: request?.data,
    params: request?.params,
    headers: request?.headers,
    requestId: request?.headers[HeaderXRequestId],
    claims: token ? jwtDecode(token) : null,
  }
}

const formatResponse = (response: AxiosResponse) => {
  return {
    status: response.status,
    data: response.data,
  }
}

export const logNetworkError = (err: AxiosError) => {
  Sentry.captureException(err, {
    extra: {
      request: formatRequest(err.config),
      response: err.response ? formatResponse(err.response) : null,
    },
  })
}
