import { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import LogoFull from 'assets/LogoFull'
import { useTranslation } from 'services/Translation'

import { ROUTES } from 'Constants'
import texts from './Texts'

const TopBar = () => {
  const { t } = useTranslation(texts)

  return (
    <div className="h-12 w-full flex items-end justify-between">
      <div className="ml-10">
        <LogoFull />
      </div>
      <nav className="flex mr-20 space-x-20 font-csc55 text-sm text-grey-200">
        <RouterLink to={ROUTES.HOME} className="hover:text-blue-900 transition">
          {t('home')}
        </RouterLink>
        <RouterLink to={ROUTES.SCHEDULE} className="hover:text-blue-900 transition">
          {t('schedule')}
        </RouterLink>
        <div className="hover:text-blue-900 transition">{t('invite')}</div>
      </nav>
    </div>
  )
}

TopBar.whyDidYouRender = true

export default memo(TopBar)
