import { useState, useEffect, useCallback, createContext } from 'react'
import GetText from 'node-gettext'
import enUsDF from 'date-fns/locale/en-US'
import nlDF from 'date-fns/locale/nl'
import arDF from 'date-fns/locale/ar-DZ'

import en from '../../translations/en_US.json'
import nl from '../../translations/nl.json'
import ar from '../../translations/ar.json'

export const dateFnsLocales = {
  en: enUsDF,
  nl: nlDF,
  ar: arDF,
}
const files = { en, nl, ar }

const DEFAULT_LANG = 'en'
const LC_MESSAGE = 'messages'
const NUMBER_REGEX = /{{n}}|%d/

const getText = new GetText({ debug: false })

const TRANSLATION_KEY = 'translations'
const TranslationContext = createContext({})

const setGetTextLang = async language => {
  const translations = files[language]
  await getText.addTranslations(language, LC_MESSAGE, translations)
}

export const TranslationProvider = ({ lang, children }) => {
  const [loading, setLoading] = useState(true)
  const [language, setLanguage] = useState(lang)

  useEffect(() => {
    // on mount and language change, fetch translation file
    setLoading(true)
    ;(async () => {
      const storedValue = await localStorage.getItem(TRANSLATION_KEY)
      if (!language && !storedValue) {
        await setGetTextLang(DEFAULT_LANG)
        getText.setLocale(DEFAULT_LANG)
        setLanguage(DEFAULT_LANG)
        await localStorage.setItem(TRANSLATION_KEY, DEFAULT_LANG)
        // console.log('Loaded from default');
      } else if (!language && storedValue) {
        await setGetTextLang(storedValue)
        setLanguage(storedValue)
        getText.setLocale(storedValue)
        // console.log('Loaded from store');
      } else if (language) {
        await setGetTextLang(language)
        getText.setLocale(language)
        await localStorage.setItem(TRANSLATION_KEY, language)
        // console.log('Loaded from change');
      }

      setLoading(false)
    })()
  }, [language])

  const getSingular = useCallback((msgid, msgctxt) => getText.pgettext(msgctxt, msgid), [])

  const getPlural = useCallback((msgid, msgidPlural, count, msgctxt) => {
    const actualCount = typeof count === 'number' ? count : 0
    const rawPluralString = getText.npgettext(msgctxt, msgid, msgidPlural, actualCount)
    return rawPluralString.replace(NUMBER_REGEX, actualCount)
  }, [])

  return (
    <TranslationContext.Provider value={{ loading, language, getSingular, getPlural, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  )
}

export default TranslationContext
