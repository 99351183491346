import styled from 'styled-components'

import { primary, transparency } from 'styles/Colors'

export const Container = styled(({ height, ...props }) => <div {...props} />)`
  & > div {
    & > div {
      display: block;
    }
  }
  & [class$='Table'] {
    max-height: 100%;
    height: auto;
    background-color: #fcfcfc;
  }
  & [class$='TableBody'] {
    min-width: max-content;
    max-height: ${({ height }) => height};
    transition: all ease-out 250ms;
    background-color: #fcfcfc;
    padding-bottom: 2rem;
    margin-top: 1rem;
  }
  & [class$='TableHeadRow'] {
    border: none;
    min-height: 17px;
    background-color: #fcfcfc;
    padding-left: 7.5rem;
    padding-right: 7.5rem;
    & [class$='TableCol'] {
      padding: 0;
      &:last-child {
        text-align: right;
        padding-right: 1rem;
        & > div {
          padding-left: 0;
          justify-content: flex-end;
        }
      }
      & > div {
        color: #1c165b;
        font-family: coresanscr85;
        font-size: 0.8125rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;
        padding-left: 0.75rem;
        & > span {
          margin-left: 8px;
          color: ${primary + transparency[70]};
        }
      }
    }
  }
  & [class$='TableRow'] {
    border-radius: 24px;
    margin-top: 2rem;
    min-height: 73px;
    align-items: flex-start;
    border: 1px solid #e8e8e8;
    font-family: coresanscr45;
    background-color: #fcfcfc;
    cursor: pointer;
    width: calc(100% - 15rem);
    margin-left: 7.5rem;

    &:first-child {
      margin-top: 0;
    }
    &:hover {
      box-shadow: 3px 6px 24px 0 ${primary + transparency[10]};
    }
    & [class$='TableCell'] {
      font-size: 0.9375rem;
      height: 73px;
      align-items: center;
      justify-content: flex-start;
    }
  }
`
export const ColorDiv = styled(({ color, ...props }) => <div {...props} />)`
  color: ${({ color }) => color};
  text-align: center;
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.9375rem;
`

export const LockContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 90px;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
`
