export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.828"
    height="10.828"
    viewBox="0 0 10.828 10.828"
  >
    <line
      id="Line_319"
      data-name="Line 319"
      y1="8"
      x2="8"
      transform="translate(1.414 1.414)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <line
      id="Line_320"
      data-name="Line 320"
      x1="8"
      y1="8"
      transform="translate(1.414 1.414)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
)
