import { memo, useCallback } from 'react'

import { useTranslation } from 'services/Translation'
import NavArrow from 'assets/icons/arrows/Nav'
import DefaultBtn from 'components/common/buttons/Default/Component'
import DefaultCross from 'assets/icons/crosses/Default'
import { textPrimary } from 'styles/Colors'
import texts from './Texts'
import PatientInfo from './PatientInfo'
import Messages from './Messages'

import { Container, PatientContainer, BtnClose } from './Styles'

const InfoBox = ({
  onSubmit,
  large,
  onKeyPress,
  onCloseInfo,
  showInfo,
  small,
  sidebarContent,
  setSidebarContent,
  typing,
  list,
  ...props
}) => {
  const { t } = useTranslation(texts)

  const handlePatientInfoOpen = useCallback(() => {
    setSidebarContent('info')
    onCloseInfo()
  }, [onCloseInfo, setSidebarContent])

  return (
    <Container
      large={large}
      className={`relative ${large ? 'w-1/4' : 'w-full'} h-full border-l border-grey-100`}
      {...props}
    >
      {!small && sidebarContent === 'info' && (
        <BtnClose
          large={large}
          role="button"
          onKeyDown={handlePatientInfoOpen}
          onClick={handlePatientInfoOpen}
        >
          <NavArrow color="#fff" />
        </BtnClose>
      )}
      {!small && sidebarContent === 'messages' && showInfo ? (
        <DefaultBtn
          mini
          label={t('btnLabelClose')}
          onClick={onCloseInfo}
          className="absolute top-16 left-0"
          style={{ transform: 'translate(-50%, -50%)', zIndex: 99 }}
          nextIcon={<DefaultCross style={{ width: 12, height: 'auto' }} />}
          background={textPrimary}
          color="white"
        />
      ) : (
        !small && (
          <BtnClose
            large={large}
            role="button"
            onKeyDown={handlePatientInfoOpen}
            onClick={handlePatientInfoOpen}
          >
            <NavArrow color="white" />
          </BtnClose>
        )
      )}

      <PatientContainer large={large}>
        <div className="py-3 border-b border-grey-100 font-csc65 text-lg w-full">
          {sidebarContent === 'messages' ? t('messagingTitle') : t('infoTitle')}
        </div>
        {sidebarContent === 'messages' ? (
          <Messages list={list} typing={typing} onSubmit={onSubmit} onKeyPress={onKeyPress} />
        ) : (
          <PatientInfo />
        )}
      </PatientContainer>
    </Container>
  )
}

InfoBox.whyDidYouRender = true

export default memo(InfoBox)
