import { memo } from 'react'

import { CS, CS_m, GS } from 'back-end-api'
import CalendarIcon from 'assets/icons/Calendar'
import { getClaims } from 'services/Authentication'
import { useTranslation } from 'services/Translation'
import isSameDay from 'date-fns/isSameDay'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    hello: getText('Hello,', 'Home_Header'),
    doctor: getText('Doctor', 'Home_Header'),
    assistant: getText('Assistant', 'Home_Header'),
    pending1: getText('You have', 'Home_Header'),
    pending0: getText('no patient', 'Home_Header'),
    pending2: getText('patient', 'Home_Header'),
    pending2s: getText('patients', 'Home_Header'),
    pending3: getText('to attend to today', 'Home_Header'),
  }[key])

function buildPatientPendingText(
  todayPendingAppointmentCount: number,
  t: (key: string) => string
): string {
  switch (todayPendingAppointmentCount) {
    case 0:
      return t('pending0')
    case 1:
      return `1 ${t('pending2')}`
    default:
      return `${todayPendingAppointmentCount} ${t('pending2s')}`
  }
}

const Header = () => {
  const { t, formatDate: format } = useTranslation(texts)
  const claims = getClaims()
  const user = GS.useOwnAccount()
  const date = new Date()

  const appointments = CS.useAppointmentsList({ without_old_closed_summaries: 'true' }).filter(
    (a: CS_m.ModelsAppointment) =>
      a?.datetime && isSameDay(new Date(a.datetime), date) && !CS.isAppointmentFinalStatus(a)
  )

  return (
    <div
      className="flex items-stretch text-blue-900"
      style={{
        height: '7.4375rem',
        marginLeft: '7.5rem',
        marginRight: '7.5rem',
        marginTop: '6.25rem',
      }}
    >
      <div className="w-full h-fit self-center max-w-md">
        <div className="text-3xl mb-3.5">
          <div className="font-csc35">{t('hello')}</div>
          <div className="truncate font-csc55">
            {`${t(claims?.role ?? 'doctor')} ${user.data?.profile?.last_name ?? ''}!`}
          </div>
        </div>
        <div className="font-csc65 text-sm flex">
          {t('pending1')}
          <div className="text-purple-200 mx-1.5">
            {buildPatientPendingText(appointments?.length ?? 0, t)}
          </div>
          {t('pending3')}
        </div>
      </div>
      <div
        className="w-full border-r border-l border-grey-100 flex"
        style={{ padding: '0 3.625rem' }}
      >
        <div className="self-center w-full">
          <div className="flex mb-4 items-center" style={{ width: 'fit-content' }}>
            <CalendarIcon className="mr-3" />
          </div>
          <div className="flex items-center justify-between font-csc35">
            <div>{format(date, 'd MMMM, yyyy')}</div>
            <div className="h-8 w-px bg-grey-100" />
            <div>{format(date, 'EEEE')}</div>
          </div>
        </div>
      </div>
      <div className="w-full flex " style={{ paddingLeft: '3.625rem' }} />
    </div>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
