import { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { primary } from 'styles/Colors'
import { Container } from './Styles'

const Link = ({
  to,
  style = {},
  label,
  italic = false,
  noUnderline = false,
  underlineOnHover = false,
  color = primary,
  bold = false,
  size = `${17 * 0.8}px`,
  disabled = false,
  className = '',
  ...props
}) => {
  return (
    <Container
      style={style}
      className={className}
      italic={italic}
      noUnderline={noUnderline}
      underlineOnHover={underlineOnHover}
      color={color}
      bold={bold}
      size={size}
      disabled={disabled}
    >
      <RouterLink to={to} {...props} disabled={disabled}>
        {label}
      </RouterLink>
    </Container>
  )
}

Link.whyDidYouRender = true

export default memo(Link)
