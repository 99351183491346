export const formatList = data => {
  if (!data?.length || !data[1]?.length) return []
  const patients = []

  data?.[1]?.forEach(s => {
    const knownPatient = patients.findIndex(p => p.id === s.accountId)
    const patient =
      knownPatient !== -1 ? patients[knownPatient] : data?.[0]?.find(p => p.id === s.patientId)
    if (knownPatient === -1) {
      patients.push({
        ...patient,
        registrationId: patient.id,
        id: s.accountId,
        summaries: [s],
      })
    } else patients[knownPatient].summaries.push(s)
  })
  patients.forEach(p => p.summaries?.sort((a, b) => (a.statusTime > b.statusTime ? -1 : 1)))
  return patients
}
