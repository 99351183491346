import { memo, useRef } from 'react'

import { Container } from './Styles'
import { variants } from './Animations'

const FloatingCard = ({
  children,
  setActive,
  top,
  left,
  paddingHorizontal,
  paddingVetical,
  style,
  className = '',
  arrowPos = 'center',
  active = false,
  ...props
}) => {
  const containerRef = useRef()

  return (
    <Container
      style={style}
      innerRef={containerRef}
      className={`fixed bg-background rounded-lg shadow-cxl z-50 ${className}`}
      animate={active ? 'active' : 'initial'}
      initial="initial"
      variants={variants}
      paddingHorizontal={paddingHorizontal}
      paddingVetical={paddingVetical}
      onMouseLeave={setActive}
      top={top}
      left={left}
      custom={{ arrowPos, selfWidth: containerRef?.current?.offsetWidth }}
      arrowPos={arrowPos}
      {...props}
    >
      {children}
    </Container>
  )
}

FloatingCard.whyDidYouRender = true

export default memo(FloatingCard)
