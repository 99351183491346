import { memo } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import usePrevious from 'hooks/usePrevious'
import { TABS } from '../Tools'
import { tabsVariants } from './Animations'
import { Container, Content } from './Styles'
import FollowUps from './FollowUps'
import History from './History'

const Main = ({ tab }) => {
  const previousTab = usePrevious(tab)
  const direction =
    Object.keys(TABS).findIndex(e => e === previousTab) >
    Object.keys(TABS).findIndex(e => e === tab)
      ? -1
      : 1

  return (
    <Content>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className="absolute"
          style={{ width: '111%' }}
          custom={direction}
          variants={tabsVariants}
          key={tab}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 200 },
            opacity: { duration: 0.2 },
          }}
        >
          <Container custom={direction} key={tab}>
            {tab === TABS.FOLLOWUP && <FollowUps />}
            {tab === TABS.HISTORY && <History />}
          </Container>
        </motion.div>
      </AnimatePresence>
    </Content>
  )
}

Main.whyDidYouRender = true

export default memo(Main)
