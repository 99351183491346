import { Suspense, lazy } from 'react'

import LoaderMini from 'components/common/loaders/mini'

const Component = lazy(() => import('./Component'))

export default props => (
  <Suspense fallback={<LoaderMini center />}>
    <Component {...props} />
  </Suspense>
)
