import { memo, useState, useEffect, useCallback } from 'react'
import { motion } from 'framer-motion'

import LoaderMicro from 'components/common/loaders/micro'
import { useTranslation } from 'services/Translation'

import Element from './Element'
import { LengthCounter } from './Styles'
import { footerVariants, shadowVariants } from './Animations'
import { formatList } from './Tools'
import texts from './Texts'

const List = ({ data = [], loading, searchValue, height }) => {
  const { t } = useTranslation(texts)
  const [formattedList, setFormattedList] = useState(formatList(data))
  const [visibleList, setVisibleList] = useState([])
  const [hideShadow, setHideShadow] = useState(true)

  const handleScroll = useCallback(
    e => {
      setHideShadow(e.target.scrollTop <= 50)
    },
    [setHideShadow]
  )

  useEffect(() => {
    if (searchValue && !loading && formattedList.length) {
      const out = []
      formattedList?.forEach(p => out.push(<Element patient={p} />))
      if (out.length) setVisibleList(out)
    } else if (visibleList?.length && (!data?.length || loading || !searchValue)) setVisibleList([])
  }, [formattedList, searchValue, loading])
  useEffect(() => {
    if (searchValue && !loading && data?.length) {
      setVisibleList([])
      setFormattedList(formatList(data))
    }
  }, [data, searchValue, loading])

  if (loading && !visibleList?.length) {
    return <LoaderMicro active center style={{ marginTop: 48 }} />
  }
  if (!searchValue || !visibleList.length) return null
  return (
    <>
      <div className="relative z-10">
        <motion.div
          animate={hideShadow ? 'hide' : 'show'}
          variants={shadowVariants}
          initial="hide"
          className="absolute h-6 -mt-px -top-12 bg-transparent left-2/4 transition"
          style={{
            boxShadow: 'rgb(47 76 203 / 10%) 0px 30px 30px 30px',
            transform: 'translate(-50%)',
            borderRadius: '100%',
            width: '55%',
          }}
        />
      </div>
      <div className="overflow-auto pb-48 relative z-0" style={{ height }} onScroll={handleScroll}>
        {visibleList}
      </div>
      <LengthCounter
        transition={{ ease: 'easeOut', duration: 0.2 }}
        className="fixed bottom-0 right-0 bg-white w-1/4 h-0 flex items-center justify-center border-l border-grey-100 font-csc45 text-sm"
        variants={footerVariants}
        animate="initial"
      >
        {`${visibleList.length} ${t(visibleList.length === 1 ? 'result' : 'results')}`}
      </LengthCounter>
    </>
  )
}

List.whyDidYouRender = true

export default memo(List)
