export default ({ className, color = '#2e4ce6' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.234"
    height="18.507"
    viewBox="0 0 14.234 18.507"
    className={className}
  >
    <g id="consulting" transform="translate(0.25 0.249)">
      <g id="Group_169" data-name="Group 169">
        <path
          id="Path_63"
          data-name="Path 63"
          d="M74.414,14.9c-.04-2.446-2.541-3.169-4.368-3.7-.213-.062-.418-.121-.61-.18V10.013a3.6,3.6,0,0,0,.964-.887h1.113a.988.988,0,0,0,.987-.987v-1.2a1.268,1.268,0,0,0,.1-.5V6.071a1.266,1.266,0,0,0-.1-.5V4.954a4.953,4.953,0,0,0-9.906,0v.614a1.266,1.266,0,0,0-.1.5V6.44a1.275,1.275,0,0,0,1.273,1.274h.291a3.578,3.578,0,0,0,1.6,2.3v1.009c-.192.059-.4.118-.61.18-1.826.528-4.328,1.251-4.368,3.7v2.75a.352.352,0,0,0,.352.352h1.042a.352.352,0,1,0,0-.7h-.691v-2.39A2.3,2.3,0,0,1,62.617,12.9a8.057,8.057,0,0,1,1.87-.792l.849,1.655a.355.355,0,0,0,.509.131l.379-.255.325,1.222L65.993,17.3h-.762a.352.352,0,0,0,0,.7h8.831a.352.352,0,0,0,.352-.352V14.9Zm-4.563-3.021.07.02-.608,1.185-1.137-.767.964-.65c.224.07.462.139.711.211Zm-2.3.863.7.475-.34,1.283h-.729l-.341-1.28Zm0-.848-1.186-.8v-.74a3.563,3.563,0,0,0,2.372,0v.74ZM70.415,5.02,70.4,5.009a2.594,2.594,0,0,0-2.321-.577l-.027.009c-.207.078-.4.15-.577.22a6.3,6.3,0,0,1-2.789.632V5.011a2.864,2.864,0,1,1,5.728,0v.007ZM71.8,8.139a.285.285,0,0,1-.284.284h-.7a3.538,3.538,0,0,0,.227-.706h.291a1.265,1.265,0,0,0,.468-.089v.511Zm.1-1.7a.571.571,0,0,1-.57.571h-.216V5.5h.215a.571.571,0,0,1,.57.57v.372Zm-8.136.571a.571.571,0,0,1-.57-.571V6.071a.571.571,0,0,1,.57-.57h.215V7.014Zm.222-2.21h-.222a1.266,1.266,0,0,0-.466.089,4.25,4.25,0,1,1,8.5,0A1.266,1.266,0,0,0,71.33,4.8h-.222a3.567,3.567,0,0,0-7.122,0ZM64.7,7.33h0a2.88,2.88,0,0,1-.021-.341V6a7.01,7.01,0,0,0,3.042-.678l.558-.213c.664-.185,1.046.058,1.737.5.12.076.251.16.392.246v1.14a2.846,2.846,0,0,1-.387,1.435H67.547a.352.352,0,0,0,0,.7h1.9A2.861,2.861,0,0,1,64.7,7.33Zm1.078,5.753L65.173,11.9l.07-.02c.249-.072.487-.141.711-.211l.964.65Zm.933,4.214.478-2.1H67.9l.479,2.1Zm7,0H69.1l-.558-2.443.325-1.223.38.256a.355.355,0,0,0,.509-.131L70.6,12.1a8.058,8.058,0,0,1,1.87.792,2.3,2.3,0,0,1,1.234,2.013V17.3Z"
          transform="translate(-60.68)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
        <path
          id="Path_64"
          data-name="Path 64"
          d="M333.554,441.995h-1.772a.352.352,0,0,0,0,.7h1.772a.352.352,0,1,0,0-.7Z"
          transform="translate(-321.912 -426.456)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
        <path
          id="Path_65"
          data-name="Path 65"
          d="M135.579,492a.352.352,0,0,0,0,.7h0a.352.352,0,0,0,0-.7Z"
          transform="translate(-132.606 -474.701)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
)
