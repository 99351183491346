import { memo } from 'react'

import { Container } from './Styles'
import { containerVariants } from './Animations'

const Chip = ({ onClick, active, label }) => {
  return (
    <Container
      active={active}
      onClick={onClick}
      variants={containerVariants}
      animate={active ? 'active' : 'inactive'}
      className="font-csc45 text-xs cursor-pointer rounded-full inline-block text-center mr-8 mb-4 border border-blue-900 py-0.5 px-3 hover:shadow-csm"
      whileHover={{ scale: 1.04 }}
      whileTap={{ scale: 0.95 }}
    >
      {label}
    </Container>
  )
}

Chip.whyDidYouRender = true

export default memo(Chip)
