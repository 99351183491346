import { memo } from 'react'

import SetPassword from 'components/auth/SetPassword'

const SetPasswordPage = () => {
  return <SetPassword />
}

SetPasswordPage.whyDidYouRender = true

export default memo(SetPasswordPage)
