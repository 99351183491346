import { memo } from 'react'
import { hp } from 'styles/Sizes'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import DefaultBtn from 'components/common/buttons/Default'
import { useTranslation } from 'services/Translation'
import { textPrimary } from 'styles/Colors'
import { CS_m } from 'back-end-api'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    ready: getText('Ready for the AV Call?', 'Patient_Details_Actions'),
    proceedBtnLabel: getText('Proceed', 'Patient_Details_Actions'),
    snoozeBtnLabel: getText('Snooze', 'Patient_Details_Actions'),
  }[key])

interface ReadyAVCallProps {
  appointment: CS_m.ModelsAppointment
  onPickup: () => void
  onSnooze: () => void
}

const ReadyAVCall = ({ appointment, onPickup, onSnooze }: ReadyAVCallProps) => {
  const { t } = useTranslation(texts)

  const patient = appointment?.summary?.registration
  const complaint = appointment?.summary?.complaint?.name

  return (
    <div>
      <div className="font-csc65 flex flex-col text-lg py-3 mb-3">{t('title')} </div>
      <Typography
        className="mt-3 font-csc45"
        center
        text={t('ready')}
        color={textPrimary}
        size={30}
        style={undefined}
      />
      <Typography
        className="mt-3 font-csc45"
        center
        text={`Patient: ${patient?.first_name} ${patient?.last_name}`}
        color={textPrimary}
        size={15}
        style={undefined}
      />
      {complaint && (
        <Typography
          className="mt-3 font-csc45"
          center
          text={`Complaint: ${complaint}`}
          color={textPrimary}
          size={15}
          style={undefined}
        />
      )}
      <Separator size={hp(5)} innerRef={undefined} />
      <div className="flex justify-center items-center">
        <DefaultBtn
          label={t('proceedBtnLabel')}
          onClick={onPickup}
          type="submit"
          className="font-csc65"
          reversedColors
          size="12px"
          style={{ width: '10rem', marginRight: '1rem' }}
          color={textPrimary}
          nextIcon={undefined}
        />
        <DefaultBtn
          label={t('snoozeBtnLabel')}
          onClick={onSnooze}
          type="submit"
          className="font-csc65"
          reversedColors
          size="12px"
          style={{ width: '10rem' }}
          color={textPrimary}
          nextIcon={undefined}
        />
      </div>
    </div>
  )
}

ReadyAVCall.whyDidYouRender = true

export default memo(ReadyAVCall)
