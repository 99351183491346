import { memo } from 'react'

import { primary } from 'styles/Colors'
import DefaultArrow from 'assets/icons/arrows/Default'

import { NextIcon, Label } from './Styles'

const OptionButton = ({
  label = '',
  onClick = () => {},
  color = primary,
  reversedColors = false,
  previousIcon,
  nextIcon,
  withNext = false,
  bold = false,
  style,
  size = '0.9375rem',
  disabled = false,
  mini = false,
  ...props
}) => {
  return (
    <button
      onClick={disabled ? undefined : onClick}
      style={style}
      disabled={disabled}
      type="button"
      className={`${
        disabled && 'opacity-30'
      } w-full flex items-center px-2 rounded font-csc45 text-sm py-1.5 hover:bg-purple-200-o25`}
      {...props}
    >
      {previousIcon && <div className="pr-6">{previousIcon}</div>}
      <Label className={`${bold ? 'font-csc65' : 'font-csc45'}`} mini={mini} size={size}>
        {label}
      </Label>
      {withNext && (
        <NextIcon>
          <DefaultArrow color={reversedColors ? 'white' : color} />
        </NextIcon>
      )}
      {nextIcon && <div className="pr-6">{nextIcon}</div>}
    </button>
  )
}

OptionButton.whyDidYouRender = true

export default memo(OptionButton)
