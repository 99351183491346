import { memo } from 'react'
import VideoIcon from 'assets/icons/Video'

interface FieldProps {
  value: string | undefined
}

const Field = ({ value }: FieldProps) => {
  if (!value || value === '') {
    return null
  }

  return (
    <div className="flex items-center mb-3">
      <VideoIcon className="mr-6" />
      {value}
    </div>
  )
}

Field.whyDidYouRender = true

export default memo(Field)
