import { Net, multiple } from '../Tools'
import { formatTimeOff, reverseFormatTimeOff, formatUnavs } from './formatter'

export const getAll = async ({ token }, staffId, timeOffDispatch, log = false) => {
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/ListTimesOff`,
    token,
    { doctor_account_id: staffId },
    log
  )
  if (log) console.log('Get own Time offs res: ', res)
  if (typeof res === 'number') {
    // eslint-disable-next-line no-unused-expressions
    timeOffDispatch?.({
      type: 'addBatch',
      payload: [],
      username: staffId,
      log,
    })
    return false
  }
  const formattedTimeOffs = multiple(formatTimeOff, res)
  if (log) console.log('Formatted Time offs: ', formattedTimeOffs)
  // eslint-disable-next-line no-unused-expressions
  timeOffDispatch?.({
    type: 'addBatch',
    payload: formattedTimeOffs,
    username: staffId,
    log,
  })
  return true
}

export const create = async ({ token }, { staffId, data }, timeOffDispatch, log = false) => {
  const params = {
    ...reverseFormatTimeOff(data),
    doctor_account_id: null,
  }
  const res = await Net.post(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/CreateTimeOff`,
    token,
    params,
    log
  )
  if (log) console.log('Get own Time offs res: ', res)
  if (typeof res === 'number') return false
  const formattedTimeOff = formatTimeOff(res)
  // eslint-disable-next-line no-unused-expressions
  timeOffDispatch?.({
    type: 'addOne',
    payload: formattedTimeOff,
    username: staffId,
    log,
  })
  return true
}

export const deleteTimeOff = async (
  { token },
  { staffId, timeOffId },
  timeOffDispatch,
  log = false
) => {
  const res = await Net.delete(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/DeleteTimeOff`,
    token,
    { id: timeOffId },
    log
  )
  if (log) console.log('Delete time off res', res)
  if (typeof res === 'number') return false
  // eslint-disable-next-line no-unused-expressions
  timeOffDispatch?.({
    type: 'deleteOne',
    payload: timeOffId,
    username: staffId,
    log,
  })
  return true
}

export const getUnavailabilities = async ({ token }, staffId, timeOffDispatch, log = false) => {
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/ListUserUnavailabilities`,
    token,
    { doctor_account_id: staffId },
    log
  )
  if (log) console.log('Get unavailabilities res: ', res)
  if (typeof res === 'number') {
    timeOffDispatch?.({
      type: 'unavailabilities',
      payload: [],
      username: staffId,
      log,
    })
    return false
  }
  const formattedUnav = formatUnavs(res || [])
  // eslint-disable-next-line no-unused-expressions
  timeOffDispatch?.({
    type: 'unavailabilities',
    payload: formattedUnav,
    username: staffId,
    log,
  })
  return true
}
