import styled from 'styled-components'
import { primary, transparency } from 'styles/Colors'

export const Container = styled(({ containerRef, ...props }) => (
  <div ref={containerRef} {...props} />
))`
  overflow: auto;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`
export const ButtonsContainer = styled.div`
  position: relative;
  width: fit-content;
`

export const TitleSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${primary + transparency[10]};
  border-radius: 20px;
  font-family: coresanscr65;
  color: ${primary};
  font-size: 12px;
`
