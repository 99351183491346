import { useState, useEffect, useCallback } from 'react'

import usePrevious from 'hooks/usePrevious'
import BodyView from './BodyView'
import { ORIENTATION } from './constants'
import styles from './Dummy.styles'
import {
  getBodyPartOrientation,
  findBodyPartByName,
  findBodyPartParent,
  VIEWS,
} from './Dummy.tools'

export default ({ selection: propSelection, questionId }) => {
  const [orientation, setOrientation] = useState(ORIENTATION.FRONT)
  const [currentBodyPart, setCurrentBodyPart] = useState(VIEWS[orientation])
  const [selection, setSelection] = useState([])
  const previousQuestionId = usePrevious(questionId)

  const initialize = useCallback(() => {
    let newOrientation = ORIENTATION.FRONT
    let newCurrentBodyPart = VIEWS[ORIENTATION.FRONT]
    let newSelection = []

    if (propSelection) {
      if (propSelection instanceof Array) {
        if (propSelection.length > 0) {
          const [firstSelection] = propSelection
          newOrientation = getBodyPartOrientation(firstSelection)
          newCurrentBodyPart = findBodyPartParent(firstSelection)
          newSelection = propSelection.map(x => findBodyPartByName(x))
        }
      } else {
        newOrientation = getBodyPartOrientation(propSelection)
        newCurrentBodyPart = findBodyPartParent(propSelection)
        newSelection = [findBodyPartByName(propSelection)]
      }
    }

    setCurrentBodyPart(newCurrentBodyPart)
    setOrientation(newOrientation)
    setSelection(newSelection)
  }, [propSelection])

  useEffect(() => {
    if (previousQuestionId !== questionId) initialize()
  }, [initialize, questionId, previousQuestionId])

  const viewData = VIEWS[orientation]

  return (
    <div style={styles.container}>
      <div style={styles.svgWrapper}>
        <BodyView {...viewData} {...currentBodyPart} style={styles.svg} selectedAreas={selection} />
      </div>
    </div>
  )
}
