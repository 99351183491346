import { memo, useEffect, ChangeEvent, useCallback, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import SearchIcon from 'assets/icons/Search'
import Bell from 'assets/icons/Bell'
import Arrow from 'assets/icons/arrows/Select'
import FloatingCard from 'components/common/Card/Floating'
import HomeSidebar from 'components/home/Sidebar'
import PatientSidebar from 'components/patient/Sidebar'
import AppointmentsSiderbar from 'components/appointments/Sidebar'
import { useLiveUpdates } from 'services/LiveUpdates/hooks'
import { ROUTES } from 'Constants'
import { GS, downloadPicture } from 'back-end-api'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import LoaderMini from 'components/common/loaders/mini'
import LangPopup from 'components/common/LangPopup'

import NotifCard from './Notifications/Card'
import Notifications from './Notifications'
import Search from './Search'

const MenuItemLabel = styled.label`
  display: inline-flex;
  justify-content: center;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  min-width: 180px;
  font-family: coresanscr45;
  font-size: 14px;
  margin-top: 10px;

  // Hide the file input next to the button
  input[type='file'] {
    display: none;
  }

  &:hover {
    background-color: #cccccc;
  }
  &:active {
    background-color: #bbbbbb;
  }
`

const enum ProfilePictureStatus {
  Unavailable,
  Available,
  Loading,
}

const Sidebar = () => {
  const location = useLocation()
  const { data: liveUpdatesData } = useLiveUpdates()
  const accountResp = GS.useOwnAccount({ refreshInterval: 0 })

  const profileRef = useRef<any>(null)
  const alertRef = useRef<any>(null)

  const [searchOpen, setSearchOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertPopupOpen, setAlertPopupOpen] = useState(false)
  const [profileCardOpen, setProfileCardOpen] = useState(false)
  const [langOpen, setLangOpen] = useState(false)

  const [profilePicture, setProfilePicture] = useState<string | null>(null)
  const [profilePictureStatus, setProfilePictureStatus] = useState(ProfilePictureStatus.Unavailable)

  useEffect(() => {
    const fetchAndDecodeImage = async () => {
      if (accountResp?.data?.profile.picture_url) {
        setProfilePictureStatus(ProfilePictureStatus.Loading)
        const image = await downloadPicture(accountResp.data.profile.picture_url)
        setProfilePicture(image)
        setProfilePictureStatus(
          image ? ProfilePictureStatus.Available : ProfilePictureStatus.Unavailable
        )
      }
    }

    fetchAndDecodeImage()
  }, [accountResp?.data?.profile.picture_url])

  const handleSearch = useCallback(() => {
    setSearchOpen(v => !v)
  }, [])

  const handleAlert = useCallback(() => {
    setAlertOpen(v => !v)
    setAlertPopupOpen(false)
  }, [])

  const toggleAlertPopupOpen = useCallback(() => {
    if (!alertOpen) setAlertPopupOpen(v => !v)
  }, [alertOpen])

  const toggleProfileCard = useCallback(() => {
    setProfileCardOpen(v => !v)
  }, [])

  const updateProfilePicture = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = async () => {
        setProfilePictureStatus(ProfilePictureStatus.Loading)
        await GS.updateOwnPicture({
          contentBase64: reader.result as string,
          extension: file.name.split('.').pop() ?? 'png',
        })
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      <div className="w-full h-11 my-6 flex justify-end items-center px-12 space-x-6">
        <div
          className="aspect-square p-2 rounded-full hover:bg-grey-100 cursor-pointer transition"
          onClick={handleSearch}
          tabIndex={0}
          role="button"
          onKeyDown={handleSearch}
        >
          <SearchIcon />
        </div>
        <div
          ref={alertRef}
          className="aspect-square p-2 rounded-full hover:bg-grey-100 cursor-pointer transition"
          onClick={alertOpen ? handleAlert : toggleAlertPopupOpen}
          tabIndex={0}
          role="button"
          onKeyDown={alertOpen ? handleAlert : toggleAlertPopupOpen}
        >
          <Bell />
        </div>
        <div className="h-full w-0.5 bg-grey-100 mx-1" />
        <div
          ref={profileRef}
          className="rounded-full flex items-center cursor-pointer relative"
          style={{ height: 30, width: 60 }}
          onClick={toggleProfileCard}
          tabIndex={0}
          role="button"
          onKeyDown={toggleProfileCard}
        >
          {(() => {
            switch (profilePictureStatus) {
              case ProfilePictureStatus.Loading:
                return <LoaderMini />
              case ProfilePictureStatus.Available:
                return (
                  <img
                    src={profilePicture ?? ''}
                    alt="Uploaded"
                    style={{
                      height: 40,
                      width: 40,
                      overflow: 'hidden',
                      borderRadius: '50%',
                    }}
                  />
                )
              case ProfilePictureStatus.Unavailable:
                return <div className="rounded-full bg-blue-900 h-6 w-6 ml-1 aspect-square" />
              default:
                console.error('Unhandled profile picture status', profilePictureStatus)
                return null
            }
          })()}
          <div className="ml-2.5">
            <Arrow style={undefined} />
          </div>
        </div>
      </div>
      <FloatingCard
        active={alertPopupOpen}
        setActive={toggleAlertPopupOpen}
        top={alertRef?.current && alertRef.current.offsetTop + alertRef.current.offsetHeight}
        left={alertRef?.current && alertRef.current.offsetLeft + alertRef.current.offsetWidth / 2}
        arrowPos="right"
        paddingHorizontal={undefined}
        paddingVetical={undefined}
        style={undefined}
      >
        <NotifCard data={liveUpdatesData} onExpand={handleAlert} />
      </FloatingCard>
      <FloatingCard
        active={profileCardOpen || langOpen}
        setActive={toggleProfileCard}
        top={profileRef?.current && profileRef.current.offsetTop + profileRef.current.offsetHeight}
        left={
          profileRef?.current && profileRef.current.offsetLeft + profileRef.current.offsetWidth / 2
        }
        arrowPos="right"
        paddingHorizontal={undefined}
        paddingVetical={undefined}
        style={undefined}
      >
        <MenuItemLabel htmlFor="profilePictureInput">
          Update picture
          <input
            type="file"
            id="profilePictureInput"
            accept="image/*"
            onChange={updateProfilePicture}
          />
        </MenuItemLabel>
        <MenuItemLabel>
          <div
            tabIndex={0}
            role="button"
            onClick={() => setLangOpen(true)}
            onKeyDown={() => setLangOpen(true)}
          >
            Language
          </div>
        </MenuItemLabel>
      </FloatingCard>
      <LangPopup
        opened={langOpen}
        setClose={() => {
          setLangOpen(false)
        }}
        top={
          profileRef?.current && profileRef.current.offsetTop + profileRef.current.offsetHeight + 70
        }
        left={
          profileRef?.current &&
          profileRef.current.offsetLeft + profileRef.current.offsetWidth / 2 - 300
        }
      />
      {/* @ts-ignore */}
      <AnimatePresence>
        {(() => {
          if (searchOpen) {
            return <Search openHandler={handleSearch} />
          }
          if (alertOpen) {
            return <Notifications data={liveUpdatesData} />
          }
          if (location.pathname === ROUTES.HOME) {
            return <HomeSidebar />
          }
          if (location.pathname.startsWith(ROUTES.PATIENT)) {
            return <PatientSidebar />
          }
          if (location.pathname.startsWith(ROUTES.SCHEDULE)) {
            return <AppointmentsSiderbar />
          }

          return null
        })()}
      </AnimatePresence>
    </>
  )
}

Sidebar.whyDidYouRender = true

export default memo(Sidebar)
