import styled from 'styled-components'

import { wp, hp } from 'styles/Sizes'
import { textPlaceholder, error, transparency } from 'styles/Colors'

export const Container = styled.div`
  width: 100%;
  padding: 0 ${wp(3.3)}px;
  & > .react-code-input {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const inputStyle = {
  borderRadius: 0,
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: `solid 1px ${textPlaceholder}`,
  fontSize: 35,
  maxWidth: 45,
  height: hp(5.8),
  textAlign: 'center',
}
export const inputStyleInvalid = {
  ...inputStyle,
  backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.25), ${
    error + transparency[50]
  })`,
  color: error,
}
