export default ({ style, color = '#33b897' }) => (
  <svg style={style} width="6.673" height="16.297" viewBox="0 0 6.673 16.297">
    <path
      d="M157.83,123.191h-3.837a1.3,1.3,0,0,0-1.3,1.3v4.735a.459.459,0,0,0,.918,0v-3.877a.255.255,0,0,1,.51,0v9.278a.769.769,0,0,0,1.538,0v-5.3a.255.255,0,1,1,.51,0v5.3a.769.769,0,0,0,1.538,0v-9.278a.255.255,0,0,1,.51,0v3.877a.459.459,0,1,0,.918,0v-4.734a1.3,1.3,0,0,0-1.3-1.3Z"
      transform="translate(-152.575 -119.216)"
      fill={color}
    />
    <path
      d="M153.511,132.077a.885.885,0,0,1-.884-.884v-5.3a.14.14,0,0,0-.281,0v5.3a.884.884,0,0,1-1.767,0v-9.278a.14.14,0,1,0-.28,0v3.877a.574.574,0,1,1-1.148,0v-4.734a1.42,1.42,0,0,1,1.418-1.418h3.837a1.42,1.42,0,0,1,1.418,1.418v4.734a.574.574,0,1,1-1.148,0v-3.877a.14.14,0,1,0-.281,0v9.278a.885.885,0,0,1-.884.884Zm-1.024-6.553a.37.37,0,0,1,.37.37v5.3a.654.654,0,0,0,1.308,0v-9.278a.37.37,0,1,1,.74,0v3.877a.344.344,0,0,0,.689,0v-4.734a1.19,1.19,0,0,0-1.189-1.189h-3.837a1.19,1.19,0,0,0-1.189,1.189v4.734a.344.344,0,0,0,.689,0v-3.877a.37.37,0,1,1,.74,0v9.278a.654.654,0,0,0,1.308,0v-5.3A.37.37,0,0,1,152.487,125.524Z"
      transform="translate(-149.151 -115.78)"
      fill={color}
    />
    <circle cx="1.495" cy="1.495" r="1.495" transform="translate(1.841 0.115)" fill={color} />
    <path
      d="M204.257,3.219a1.61,1.61,0,1,1,1.61-1.61A1.61,1.61,0,0,1,204.257,3.219Zm0-2.991a1.381,1.381,0,1,0,1.381,1.381A1.381,1.381,0,0,0,204.257.228Z"
      transform="translate(-200.921 0.001)"
      fill={color}
    />
  </svg>
)
