export default async (
  array: unknown[],
  // eslint-disable-next-line no-unused-vars
  callback: (element: unknown, id: number, arr: unknown[]) => Promise<void>
): Promise<void> => {
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array)
  }
}
