import { textPrimary } from 'styles/Colors'

export default ({ style, color = textPrimary }) => (
  <svg width="9.769" height="16.538" viewBox="0 0 9.769 16.538" style={style}>
    <path
      d="M869.679,4576.454l6.148,6.148-6.148,6.148"
      transform="translate(877.327 4590.871) rotate(180)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
)
