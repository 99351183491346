import { memo } from 'react'

import { primary } from 'styles/Colors'

import { Container, Label } from './Styles'

const SecondaryBtn = ({
  label = '',
  onClick = () => {},
  color = primary,
  background,
  reversedColors = false,
  style,
  size = `${17 * 0.8}px`,
  disabled = false,
  type,
  mini = false,
  bold = false,
  innerRef,
  ...props
}) => {
  return (
    <Container
      onClick={disabled ? undefined : onClick}
      style={style}
      disabled={disabled}
      type={type}
      innerRef={innerRef}
      reversedColors={reversedColors}
      color={reversedColors ? 'white' : color}
      background={background || (reversedColors ? color : 'white')}
      mini={mini}
      {...props}
    >
      <Label mini={mini} bold={bold} size={size}>
        {label}
      </Label>
    </Container>
  )
}

SecondaryBtn.whyDidYouRender = true

export default memo(SecondaryBtn)
