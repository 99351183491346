export default ({ className, color = '#6c5bff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.383"
    height="15.876"
    viewBox="0 0 18.383 15.876"
    className={className}
  >
    <g id="video" transform="translate(0.25 0.257)">
      <path
        id="Path_66"
        data-name="Path 66"
        d="M218.794,217.4a1.4,1.4,0,1,0-1.4,1.4,1.4,1.4,0,0,0,1.4-1.4Zm-1.4.7a.7.7,0,1,1,.006,0Z"
        transform="translate(-208.456 -209.712)"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        id="Path_67"
        data-name="Path 67"
        d="M2.445,51.368H7.37a.35.35,0,1,0,0-.7H2.445a1.749,1.749,0,0,1-1.711-1.4H17.15a1.749,1.749,0,0,1-1.711,1.4H10.513a.35.35,0,0,0,0,.7h4.925a2.448,2.448,0,0,0,2.445-2.445.349.349,0,0,0-.349-.349H16.312V39.493a.349.349,0,0,0-.349-.349h-2.48v-2.8a.349.349,0,0,0-.543-.291l-2.6,1.734V36.349A.349.349,0,0,0,9.99,36H4.75a.349.349,0,0,0-.349.349v2.794H1.921a.349.349,0,0,0-.349.349v9.081H.349A.349.349,0,0,0,0,48.924a2.448,2.448,0,0,0,2.445,2.444Zm8.243-2.794H7.2V47.526a1.747,1.747,0,0,1,3.493,0ZM12.784,37v2.886l-2.165-1.443ZM5.1,36.7H9.64v3.493H5.1ZM2.27,39.842H4.4v.7a.349.349,0,0,0,.349.349H9.99a.349.349,0,0,0,.349-.349V39.1l2.6,1.734a.349.349,0,0,0,.543-.291v-.7h2.131v8.732H11.387V47.526a2.445,2.445,0,0,0-4.89,0v1.048H2.27Z"
        transform="translate(0 -35.999)"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <ellipse
        id="Ellipse_25"
        data-name="Ellipse 25"
        cx="0.492"
        cy="0.246"
        rx="0.492"
        ry="0.246"
        transform="translate(8.388 14.301)"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </g>
  </svg>
)
