import { background } from 'styles/Colors'

export const infoColumnVariants = {
  hidden: {
    x: '100%',
  },
  visible: {
    x: 0,
  },
}

export const containerVariants = {
  hidden: {
    x: '100%',
  },
  small: {
    x: 0,
    y: 0,
    height: '33vh',
    width: '30vw',
    backgroundColor: 'transparent',
    borderRadius: '30px',
    border: 'none',
  },
  large: {
    x: 0,
    y: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: background,
    borderRadius: 'none',
  },
  visible: {
    x: 0,
    y: 0,
    height: '90vh',
    width: 'auto',
    backgroundColor: background,
    borderRadius: 'none',
  },
}
