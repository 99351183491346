import { memo } from 'react'

import Header from './Header'
import PatientsList from './PatientsList'

const Content = () => {
  return (
    <>
      <Header />
      <PatientsList />
    </>
  )
}

Content.whyDidYouRender = true

export default memo(Content)

/*
mark notif as read when the notif is clicked (if can be) or if press on all as read
 */
