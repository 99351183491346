import styled from 'styled-components'

import { hp } from 'styles/Sizes'
import { textPrimary, transparency, greyPrimary } from 'styles/Colors'

export const Container = styled(
  ({ disabled, color, background, mini, reversedColors, innerRef, ...props }) => (
    // eslint-disable-next-line react/button-has-type
    <button ref={innerRef} {...props} />
  )
)`
  border: ${({ background, disabled, reversedColors }) =>
    reversedColors
      ? disabled
        ? `1px solid ${background + transparency[30]}`
        : 'none'
      : disabled
      ? `1px solid ${greyPrimary + transparency[30]}`
      : `1px solid ${textPrimary + transparency[30]}`};
  border-radius: 50px;
  padding: ${({ mini }) => (mini ? '0 22px' : '0 44px')};
  height: ${({ mini }) => (mini ? hp(2.7) : hp(4.2))}px;
  // padding: ${({ mini }) => (mini ? `0 ${22 * 0.8}px` : `0 ${44 * 0.8}px`)};
  // height: ${({ mini }) => (mini ? hp(2.7) * 0.8 : hp(4.2) * 0.8)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ background, disabled }) => (disabled ? '#ffffff' : background)};
  color: ${({ disabled, color, background }) => (disabled ? background + transparency[30] : color)};

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  white-space: nowrap;
  &:hover {
    background-color: ${({ disabled, background }) =>
      disabled ? 'none' : background + transparency[70]};
  }
`
export const PrevIcon = styled.div`
  margin-right: ${18 * 0.8}px;
  transform: scale(0.8);
`
export const NextIcon = styled.div`
  margin-left: ${17 * 0.8}%;
  margin-top: 2px;
  transform: scale(0.8);
`
export const Label = styled(({ mini, bold, size, ...props }) => <div {...props} />)`
  font-size: ${({ mini, size }) => (mini ? `${12 * 0.8}px` : size)};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-family: 'coresanscr65';
`
