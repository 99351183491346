export const containerVariants = {
  initial: {
    height: '7.75rem',
    // boxShadow:
    //   'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(46, 76, 230, 0.1) 0px 0px 0px 0px',
    // transition: {
    //   boxShadow: {
    //     when: 'before'
    //   }
    // }
  },
  open: {
    height: 'auto',
    // boxShadow:
    //   'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(46, 76, 230, 0.1) 3px 6px 24px 0px',
  },
}
