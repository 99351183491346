import { memo, useState } from 'react'
import { motion } from 'framer-motion'

import Tabs from './Tabs'
import LiveUpdates from './LiveUpdates'

const Sidebar = () => {
  const [tabsExpanded, setTabsExpanded] = useState(false)
  const [luExpanded, setLuExpanded] = useState(false)

  return (
    <motion.div
      className="mt-8 pt-8 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Tabs expanded={tabsExpanded} setExpanded={setTabsExpanded} hidden={luExpanded} />
      <LiveUpdates expanded={luExpanded} setExpanded={setLuExpanded} />
    </motion.div>
  )
}

Sidebar.whyDidYouRender = true

export default memo(Sidebar)
