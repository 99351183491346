export default ({ className = '', color = '#6c5bff' }) => (
  <svg width="15.73" height="10.155" viewBox="0 0 15.73 10.155" className={className}>
    <g transform="translate(-1727.277 -157.813)">
      <path
        d="M2558.691-12034.785l2.914,2.753,2.621-4.059,2.4-3.713"
        transform="translate(-825 12199)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M2558.691-12034.785l2.914,2.753,2.621-4.059,2.4-3.713"
        transform="translate(-830 12199)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)
