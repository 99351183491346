import { createContext, useContext, useReducer, useMemo } from 'react'

import defaultState from './default.json'
import patientsReducer from './reducer'
import { getFromSessionOrDefault } from '../Tools'

const KEY_PATIENTS = process.env.REACT_APP_KEY_PATIENTS

const PatientsStateContext = createContext()
const PatientsDispatchContext = createContext()

export const PatientsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    patientsReducer,
    getFromSessionOrDefault(defaultState, KEY_PATIENTS)
  )

  return (
    <PatientsStateContext.Provider value={state}>
      <PatientsDispatchContext.Provider value={dispatch}>
        {children}
      </PatientsDispatchContext.Provider>
    </PatientsStateContext.Provider>
  )
}

export const usePatientsState = ({ id } = {}) => {
  const context = useContext(PatientsStateContext)
  const patient = useMemo(() => {
    if (id) return context?.list.find(el => el.id === id)
    return undefined
  }, [context, id])

  if (context === undefined) {
    throw new Error('usePatientsState must be used within a PatientsProvider')
  }
  if (id !== undefined) return patient
  return context || defaultState
}
export const usePatientsDispatch = () => {
  const context = useContext(PatientsDispatchContext)
  if (context === undefined) {
    throw new Error('usePatientsDispatch must be used within a PatientsProvider')
  }
  return context
}
