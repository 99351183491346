import { memo, useState, useCallback, useRef, ChangeEvent } from 'react'
import { CS_m } from 'back-end-api'
import FloatingSelectInput from 'components/common/inputs/SelectFloating'
import { textPrimary } from 'styles/Colors'
import UnderlineBtn from 'components/common/buttons/Underline'
import DefaultBtn from 'components/common/buttons/Default/Component'
import { useTranslation } from 'services/Translation'
import Typography from 'components/common/Typography'
import CheckBox from 'components/common/inputs/CheckBox'
import ArrowIcon from 'assets/icons/arrows/Small'
import TextInput from 'components/common/inputs/Text'
import subDays from 'date-fns/subDays'
import FloatingCard from 'components/common/Card/Floating'
import CalendarCard from 'components/patient/Content/Tabs/Diagnosis/Actions/AppointmentSchedule/Select/CalendarCard'
import { wholeDayDuration_ns } from 'services/TimeOff'
import { BlockGrid, GridContainer, Label, CheckboxLabel } from './Styles'
import ConfirmBlockTimeSlot from '../../ConfirmBlockTime'

const frequencySelection = [
  {
    label: 'day',
    value: CS_m.IntervalRepeatFrequency.daily,
  },
  {
    label: 'week',
    value: CS_m.IntervalRepeatFrequency.weekly,
  },
  {
    label: 'month',
    value: CS_m.IntervalRepeatFrequency.monthly,
  },
  {
    label: 'year',
    value: CS_m.IntervalRepeatFrequency.yearly,
  },
]

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    title: getText('Time slot(s):', 'Schedule_Sidebar_Event_Manager'),
    blockLabel: getText('Block time :', 'Schedule_Sidebar_Block_View'),
    repeatLabel: getText('Repeat Every :', 'Schedule_Sidebar_Block_View'),
    dontRepeatLabel: getText("Don't repeat:", 'Schedule_Sidebar_Block_View'),
    endLabel: getText('Ends on :', 'Schedule_Sidebar_Block_View'),
    never: getText('Never', 'Schedule_Sidebar_Block_View'),
    on: getText('On', 'Schedule_Sidebar_Block_View'),
    datePickerLabel: getText('Pick a date', 'Schedule_Sidebar_Block_View'),
    day: getText('Day', 'Schedule_Sidebar_Block_View'),
    week: getText('Week', 'Schedule_Sidebar_Block_View'),
    month: getText('Month', 'Schedule_Sidebar_Block_View'),
    year: getText('Year', 'Schedule_Sidebar_Block_View'),
    doneLabel: getText('Done', 'Schedule_Sidebar_Block_View'),
  }[key])

interface BlockViewProps {
  timeSlots: any[]
  onClose: () => void
  createTimeoff: (newBlockedTime: any) => Promise<void>
  onShowBlockView: () => void
}

const BlockView = ({ timeSlots, onClose, createTimeoff, onShowBlockView }: BlockViewProps) => {
  const { t, formatDate: format } = useTranslation(texts)
  const [frequency, setFrequency] = useState(0)
  const [period, setPeriod] = useState('1')
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [until, setUntil] = useState(false)
  const [dontRepeatOn, setDontRepeatOn] = useState(true)
  const [untilEndDate, setUntilEndDate] = useState<Date | null>(null)
  const datePickerRef = useRef<any>(null)
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const handleDatePickerOpen = useCallback(() => setDatePickerOpen(v => !v), [])

  const handleChangeRepInput = useCallback((value: ChangeEvent<HTMLInputElement>) => {
    setPeriod(value.target.value)
  }, [])

  const handleChangeRepSelect = useCallback((value: number) => {
    setFrequency(value)
  }, [])

  const handleChangeEndsNever = useCallback(() => {
    setUntil(false)
    setUntilEndDate(null)
  }, [])

  const handleChangeEndsOn = useCallback(() => {
    setUntil(true)
  }, [])

  const handleChangeDontRepeat = useCallback(() => {
    setDontRepeatOn(v => !v)
  }, [])

  const handleChangeEndDate = useCallback((value: Date) => {
    setUntilEndDate(value)
  }, [])

  const handleValid = useCallback(async () => {
    setConfirmVisible(false)
    onShowBlockView()
    onClose()

    const repeat = dontRepeatOn
      ? null
      : {
          frequency: frequencySelection[frequency].value,
          period,
          until: untilEndDate,
        }
    await Promise.all(
      timeSlots.map(({ time, duration }) => {
        return createTimeoff({
          time,
          duration,
          repeat,
        })
      })
    )
  }, [
    dontRepeatOn,
    onShowBlockView,
    onClose,
    frequency,
    period,
    untilEndDate,
    timeSlots,
    createTimeoff,
  ])

  const handleConfirmOpen = useCallback(() => {
    setConfirmVisible(v => !v)
  }, [])

  const handleConfirmClose = useCallback(() => {
    setConfirmVisible(false)
    onShowBlockView()
    onClose()
  }, [onClose, onShowBlockView])

  function buildBlockedTimeslotsText(slots: any[]) {
    if (slots.length === 1 && slots[0].duration === wholeDayDuration_ns / 1000000) {
      return 'Whole day'
    }

    return slots.map(timeSlot => format(new Date(timeSlot.time), 'h:mm a')).join(', ')
  }

  return (
    <>
      <div className="px-16 pt-4">
        <div className="h-12 w-full flex items-center justify-start relative z-30 border-b border-grey-100">
          <Typography size={18} className="font-csc65 pb-3" text={t('title')} style={undefined} />
          <Typography
            size={15}
            className="font-csc65 pb-3 ml-2"
            text={buildBlockedTimeslotsText(timeSlots)}
            style={undefined}
          />
        </div>
      </div>
      <div className="mx-14 mt-6">
        <ConfirmBlockTimeSlot
          visible={confirmVisible}
          onCancel={handleConfirmClose}
          onConfirm={() => handleValid()}
        />
        <div className="border-b border-grey-100">
          <BlockGrid>
            <GridContainer gridArea="dontRepeatLabel">
              <Label>{t('dontRepeatLabel')}</Label>
            </GridContainer>
            <GridContainer gridArea="dontRepeatSelector">
              <CheckBox
                field={{
                  onChange: handleChangeDontRepeat,
                  value: dontRepeatOn,
                }}
                data-cy="checkAfter"
              />
            </GridContainer>
            {!dontRepeatOn && (
              <>
                <GridContainer gridArea="repeatLabel">
                  <Label>{t('repeatLabel')}</Label>
                </GridContainer>
                <GridContainer gridArea="repeatSelector">
                  <TextInput
                    field={{
                      onChange: handleChangeRepInput,
                      value: period,
                    }}
                    noBorder
                    style={{ height: 40 }}
                    inputStyle={{
                      width: 18,
                      color: textPrimary,
                      alignSelf: 'center',
                      margin: '0 0 18px 0',
                      textDecoration: 'underline',
                    }}
                    size={12}
                    noPadding
                    data-cy="inputAfter"
                    name={undefined}
                    placeholder={undefined}
                    label={undefined}
                    inputRef={undefined}
                    containerStyle={undefined}
                    placeholderStyle={undefined}
                  />
                </GridContainer>
                <GridContainer gridArea="repeatWeekSelector">
                  <FloatingSelectInput
                    field={{
                      onChange: handleChangeRepSelect,
                      value: frequency,
                    }}
                    size="0.75rem"
                    noPadding
                    options={frequencySelection.map(type => `${t(type.label)}`)}
                    data-cy="repeatWeekSelector"
                    label={undefined}
                    style={undefined}
                  />
                </GridContainer>
                <GridContainer gridArea="endLabel">
                  <Label>{t('endLabel')}</Label>
                </GridContainer>
                <GridContainer gridArea="endSelector">
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-full flex items-center justify-between mb-3">
                      <CheckBox
                        field={{
                          onChange: handleChangeEndsNever,
                          value: !until,
                        }}
                      />
                      <CheckboxLabel>{t('never')}</CheckboxLabel>
                    </div>
                    <div className="w-full flex items-center justify-between mb-3">
                      <CheckBox
                        field={{
                          onChange: handleChangeEndsOn,
                          value: until,
                        }}
                      />
                      <CheckboxLabel>{t('on')}</CheckboxLabel>
                    </div>
                  </div>
                </GridContainer>
                {until && (
                  <GridContainer gridArea="endDateSelector">
                    <UnderlineBtn
                      innerRef={datePickerRef}
                      nextIcon={<ArrowIcon color={textPrimary} style={undefined} />}
                      bold
                      size="0.75rem"
                      onClick={handleDatePickerOpen}
                      label={
                        untilEndDate ? format(untilEndDate, 'dd MMM, yyyy') : t('datePickerLabel')
                      }
                      previousIcon={undefined}
                      style={undefined}
                    />
                  </GridContainer>
                )}
              </>
            )}
          </BlockGrid>
          <FloatingCard
            active={datePickerOpen}
            setActive={setDatePickerOpen}
            style={{ position: 'absolute' }}
            top={
              datePickerRef &&
              datePickerRef.current &&
              datePickerRef.current.offsetTop + datePickerRef.current.offsetHeight
            }
            left={
              datePickerRef &&
              datePickerRef.current &&
              datePickerRef.current.offsetLeft + datePickerRef.current.offsetWidth
            }
            arrowPos="right"
            paddingHorizontal={undefined}
            paddingVetical={undefined}
          >
            <CalendarCard
              field={{
                value: untilEndDate,
                onChange: handleChangeEndDate,
              }}
              events={[]}
              disableBefore={subDays(new Date(), 1)}
            />
          </FloatingCard>
        </div>
        <DefaultBtn
          label={t('doneLabel')}
          type="submit"
          className="font-csc65 mt-9"
          reversedColors
          withNext
          onClick={handleConfirmOpen}
          color={textPrimary}
          style={undefined}
          nextIcon={undefined}
        />
      </div>
    </>
  )
}

BlockView.whyDidYouRender = true

export default memo(BlockView)
