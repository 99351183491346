import { memo, useCallback, useEffect, useState } from 'react'
import DataTable, {
  IDataTableConditionalRowStyles,
  IDataTableColumn,
} from 'react-data-table-component'
import LoaderMini from 'components/common/loaders/mini'
import { getStatusText, useStatusTranslation } from 'services/Registrations'
import { useTranslation } from 'services/Translation'
import { CS, CS_m } from 'back-end-api'
import { usePatientRouteParams } from 'components/patient/Parameters'
import { purple } from 'styles/Colors'
import CalendarIcon from 'assets/icons/Calendar'
import {
  setPatientSideBar,
  usePatientSideBarState,
  PatientSideBar,
  patientSideBarDefault,
} from 'services/Misc/patientSideBar'
import { useMiscDispatch } from 'services/Misc'
import { Container } from './Styles'
import texts from './Texts'

function partition<T>(predicate: (val: T) => boolean, arr: Array<T>): [Array<T>, Array<T>] {
  const partitioned: [Array<T>, Array<T>] = [[], []]
  arr.forEach((val: T) => {
    const partitionIndex: 0 | 1 = predicate(val) ? 0 : 1
    partitioned[partitionIndex].push(val)
  })
  return partitioned
}

const History = () => {
  const { t, formatDate } = useTranslation(texts)
  const tStatus = useStatusTranslation()
  const { registration } = usePatientRouteParams()
  const { data, isLoading } = CS.useRegistrationsList({
    without_old_closed_summaries: 'false',
  })
  const miscDispatch = useMiscDispatch()
  const patientOtherRegistrations = data?.filter((r: CS_m.ModelsRegistration) => {
    return (
      r.account_id === registration?.account_id &&
      r.id !== registration?.id &&
      r.summaries &&
      r.summaries.length > 0
    )
  })
  const [ongoingRegistrations, pastRegistrations] = partition(
    (r: CS_m.ModelsRegistration): boolean => {
      return r.summaries?.every((s: CS_m.ModelsSummary) => !CS.isSummaryStatusFinal(s)) ?? false
    },
    patientOtherRegistrations ?? []
  )

  const patientSideBarProps = usePatientSideBarState()
  const [selectedRegistrationId, setSelectedRegistrationId] = useState<number | undefined>(
    patientSideBarProps.type === PatientSideBar.PastEncounter &&
      patientSideBarProps.pastEncounterProps?.currentRegistrationId === registration?.id
      ? patientSideBarProps.pastEncounterProps?.selectedRegistrationId
      : undefined
  )

  const handleRowClicked = useCallback(
    (row: CS_m.ModelsRegistration) => {
      if (selectedRegistrationId === row.id) {
        setSelectedRegistrationId(undefined)
        setPatientSideBar(miscDispatch, patientSideBarDefault)
        return
      }
      setSelectedRegistrationId(row.id)

      setPatientSideBar(miscDispatch, {
        type: PatientSideBar.PastEncounter,
        pastEncounterProps: {
          selectedRegistrationId: row.id,
          currentRegistrationId: registration?.id ?? 0,
        },
      })
    },
    [selectedRegistrationId, setSelectedRegistrationId, registration, miscDispatch]
  )

  // Unselect registration row when the past encounter side bar is closed
  useEffect(() => {
    if (
      !(
        patientSideBarProps.type === PatientSideBar.PastEncounter &&
        patientSideBarProps.pastEncounterProps?.currentRegistrationId === registration?.id
      )
    ) {
      setSelectedRegistrationId(undefined)
    }
  }, [patientSideBarProps, registration])

  const createConditionalRowStyles = useCallback((): IDataTableConditionalRowStyles<
    CS_m.ModelsRegistration
  >[] => {
    return [
      {
        when: (row: CS_m.ModelsRegistration) => {
          return row.id === selectedRegistrationId
        },
        style: {
          backgroundColor: 'rgba(108, 91, 255, 0.1)!important',
          borderRadius: '12px',
          borderBottom: 'none !important',
          position: 'relative',
        },
      },
    ]
  }, [selectedRegistrationId])

  const cellContentWith = (
    row: CS_m.ModelsRegistration,
    content: string,
    isFirstColumn: boolean
  ) => (
    <div
      role="presentation"
      className={`flex items-center w-full font-csc65 pl-6 pr-6 ${isFirstColumn ? '' : 'border-l'}`}
      style={{ borderLeft: '2px solid rgba(108, 91, 255, 0.1)' }}
      onClick={() => {
        handleRowClicked(row)
      }}
      onKeyDown={() => {
        handleRowClicked(row)
      }}
    >
      <CalendarIcon className="mr-6" />
      {content}
    </div>
  )

  const createColumns = (title: string): IDataTableColumn<CS_m.ModelsRegistration>[] => {
    return [
      {
        name: title,
        cell: (row: CS_m.ModelsRegistration) =>
          cellContentWith(row, formatDate(new Date(row.created_at), 'd MMMM, yyyy'), true),
      },
      {
        name: '',
        cell: (row: CS_m.ModelsRegistration) => {
          const summary = CS.getSummary(row)
          const complaint = summary?.complaint?.name ?? CS.SummaryType.GeneralMedicalQuestion
          return cellContentWith(row, complaint, false)
        },
      },
      {
        name: '',
        cell: (row: CS_m.ModelsRegistration) => {
          const summary = CS.getSummary(row)
          const redflags =
            summary?.red_flags && summary.red_flags.length > 0
              ? summary.red_flags.join(', ')
              : t('noRedFlags')
          return cellContentWith(row, redflags, false)
        },
      },
      {
        name: '',
        cell: (row: CS_m.ModelsRegistration) =>
          cellContentWith(row, getStatusText(row, tStatus), false),
      },
      {
        name: '',
        cell: (row: CS_m.ModelsRegistration) => {
          if (row.id !== selectedRegistrationId) {
            return null
          }
          return (
            <>
              <div
                style={{
                  content: '" "',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: `${purple}`,
                  right: '-58px',
                  width: '50px',
                  height: '1px',
                  zIndex: 1,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  borderRadius: '8px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: `${purple}`,
                  right: '-108px',
                  width: '50px',
                  height: '12px',
                  fontSize: '9px',
                  color: 'white',
                  zIndex: 1,
                }}
              >
                Selected
              </div>
            </>
          )
        },
        width: '0px',
      },
    ]
  }

  return (
    <Container height="30rem">
      <div className="ml-2 my-3 font-csc45">{t('content')}</div>
      {ongoingRegistrations.length > 0 && (
        <DataTable
          progressPending={isLoading}
          progressComponent={<LoaderMini center={undefined} />}
          columns={createColumns(t('ongoingTitle'))}
          data={[...ongoingRegistrations]}
          noHeader
          fixedHeader
          fixedHeaderScrollHeight="unset"
          dense
          conditionalRowStyles={createConditionalRowStyles()}
          onRowClicked={handleRowClicked}
        />
      )}
      {pastRegistrations.length > 0 && (
        <DataTable
          progressPending={isLoading}
          progressComponent={<LoaderMini center={undefined} />}
          columns={createColumns(t('pastTitle'))}
          data={[...pastRegistrations]}
          noHeader
          fixedHeader
          fixedHeaderScrollHeight="unset"
          dense
          conditionalRowStyles={createConditionalRowStyles()}
          onRowClicked={handleRowClicked}
        />
      )}
    </Container>
  )
}

History.whyDidYouRender = true

export default memo(History)
