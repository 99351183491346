import styled from 'styled-components'

import { hp } from 'styles/Sizes'

export const BlockGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  grid-column-gap: 0px;
  grid-row-gap: 1px;
  grid-template-rows: 50px 50px 50px 100px;
  grid-template-areas:
    'blockLabel blockSelector blockSelector2'
    'dontRepeatLabel dontRepeatSelector dontRepeatWeekSelector'
    'repeatLabel repeatSelector repeatWeekSelector'
    'endLabel endSelector endDateSelector';
`

export const Label = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: coresanscr45;
`

export const CheckboxLabel = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: coresanscr65;
  marginleft: 10px;
  width: 50%;
`

export const GridContainer = styled(({ gridArea, justifyContent, paddingRight, ...props }) => (
  <div {...props} />
))`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  padding-right: ${({ paddingRight }) => (paddingRight ? `${paddingRight}px` : `${hp(1)}px`)};
  padding-top: ${hp(1.7)}px;
  padding-bottom: ${hp(1.7)}px;
  align-items: flex-start;
`
