import { memo } from 'react'
import VideoIcon from 'assets/icons/Video'
import { useTranslation } from 'services/Translation'
import { usePatientRouteParams } from 'components/patient/Parameters'
import { useStatusTranslation, getStatusText, getStatusColor } from 'services/Registrations'
import styled from 'styled-components'

export const StatusText = styled.div`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
`

export const TimeText = styled.div`
  margin-right: 5px;
`

const Status = () => {
  const { formatDate } = useTranslation()
  const tStatus = useStatusTranslation()

  const { registration, appointment } = usePatientRouteParams()
  if (!registration) return null

  const status = getStatusText(registration, tStatus)
  const color = getStatusColor(registration)

  const date = new Date(appointment?.datetime ? appointment?.datetime : registration.created_at)

  return (
    <div className="flex flex-row items-center">
      <StatusText className={`text-sm pr-6 'border-r border-grey-100'`} color={color}>
        <VideoIcon className="mr-5" color={color} />
        {status}
      </StatusText>
      <StatusText className="text-sm pl-6 " color={color}>
        <TimeText>{`${formatDate(date, 'hh:mm')},`}</TimeText>
        {date.toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' })}
      </StatusText>
    </div>
  )
}

Status.whyDidYouRender = true

export default memo(Status)
