import { memo } from 'react'

import { useMiscState } from 'services/Misc'
import { Container, Content, Overlay } from './Styles'

const Popup = ({ children, onOverlayClick, contentWidth = 30 }) => {
  const { menuOpen } = useMiscState()

  return (
    <Container small={menuOpen}>
      <Overlay onClick={onOverlayClick} />
      <Content noEvents={onOverlayClick} width={contentWidth}>
        {children}
      </Content>
    </Container>
  )
}

Popup.whyDidYouRender = true

export default memo(Popup)
