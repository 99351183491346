import removeUndefined from 'utils/removeUndefined'

import { MAP_APPOINTMENTS, MAP_STATUS_COLOR } from 'Constants'

const getStatus = appointment => {
  if (!appointment) return ''
  const { status, isImmediate } = appointment
  return {
    label: MAP_APPOINTMENTS[status][isImmediate ? 'immediate' : 'scheduled'],
    color: MAP_STATUS_COLOR[status],
  }
}

export const formatAppointment = data => {
  if (!data) return {}
  const appointment = {
    id: data.id,
    patientId: data.registration_id,
    doctorNote: data.doctor_note,
    cancelledById: data.cancelled_by_professional,
    duration: data.duration_ns / 1000000 || undefined,
    replyWithin: {
      time: data.reply_within?.datetime,
      text: data.reply_within?.text,
    },
    validDateTimeWindow: {
      start: data.valid_datetime_window?.begin,
      end: data.valid_datetime_window?.end,
    },
    updatedAt: data.updated_at,
    summaryId: data.summary_id,
    location: data.location,
    time: data.datetime,
    status: data.status,
    isImmediate: data.is_immediate,
    videoRoomId: data.video_room_id,
  }
  appointment.formattedStatus = getStatus(appointment)
  removeUndefined(appointment)
  return appointment
}

export const reverseFormatAppointment = data => {
  return {
    id: data.id,
    account_id: data.patientId,
    duration_ns: data.duration * 1000000,
    doctor_note: data.doctorNote,
    reply_within: {
      datetime: data.replyWithin?.time,
      text: data.replyWithin?.text,
    },
    valid_datetime_window: {
      begin: data.validDateTimeWindow?.start,
      end: data.validDateTimeWindow?.end,
    },
    summary_id: data.summaryId,
    location: data.location,
    datetime: data.time,
    status: data.status,
    is_immediate: data.isImmediate,
    video_room_id: data.videoRoomId,
  }
}

export const reverseFormatAppointments = data => {
  const out = []
  data.forEach(el => {
    out.push(reverseFormatAppointment(el))
  })
  return out
}
