import { memo, useRef, useState, useCallback } from 'react'
import SmallArrowIcon from 'assets/icons/arrows/Select'
import MoreOptionsIcon from 'assets/icons/MoreOptions'
import FloatingCard from 'components/common/Card/Floating'
import { ownRegistration } from 'services/Registrations'
import LockIcon from 'assets/icons/Lock'
import styled from 'styled-components'
import { usePatientRouteParams } from 'components/patient/Parameters'

import Status from './Status'
import MoreCard from './MoreCard'
import SkippedQuestionCard from './SkippedQuestionCard'

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface AppointmentProps {
  onEdit: () => void
  editing: boolean
}

const Appointment = ({ onEdit, editing }: AppointmentProps) => {
  const skippedQuestionsRef = useRef<any>(null)
  const moreOptionsRef = useRef<any>(null)
  const [skippedQuestionsOpen, setSkippedQuestionsOpen] = useState(false)
  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false)
  const { registration } = usePatientRouteParams()

  const handleSkippedQuestions = useCallback(() => {
    setSkippedQuestionsOpen(v => !v)
  }, [])

  const handleMoreOptions = useCallback(() => {
    setMoreOptionsOpen(v => !v)
  }, [])

  return (
    <div className="flex flex-row justify-between font-csc55 border-b border-grey-100 py-6 pl-6">
      <Status />
      {registration && ownRegistration(registration) && <LockIcon className="" />}
      <BtnContainer>
        <div
          className="flex items-center justify-center font-csc65 text-blue-900 text-sm underline cursor-pointer"
          ref={skippedQuestionsRef}
          onClick={handleSkippedQuestions}
          tabIndex={0}
          role="button"
          onKeyDown={handleSkippedQuestions}
        >
          Skipped questions
          <SmallArrowIcon className="ml-3" style={undefined} />
        </div>
        <div
          className="ml-5 flex items-center justify-center font-csc65 text-blue-900 text-sm underline cursor-pointer"
          ref={moreOptionsRef}
          onClick={handleMoreOptions}
          tabIndex={0}
          role="button"
          onKeyDown={handleMoreOptions}
        >
          <MoreOptionsIcon />
        </div>
      </BtnContainer>

      <FloatingCard
        active={skippedQuestionsOpen}
        setActive={handleSkippedQuestions}
        top={skippedQuestionsRef?.current && skippedQuestionsRef.current.offsetTop * 23}
        left={
          skippedQuestionsRef?.current &&
          skippedQuestionsRef.current.offsetLeft + skippedQuestionsRef.current.offsetWidth * 1.8
        }
        arrowPos="right"
        paddingHorizontal={undefined}
        paddingVetical={undefined}
        style={undefined}
      >
        <SkippedQuestionCard />
      </FloatingCard>
      <FloatingCard
        active={moreOptionsOpen}
        setActive={handleMoreOptions}
        top={moreOptionsRef?.current && moreOptionsRef.current.offsetTop * 17}
        left={
          moreOptionsRef?.current &&
          moreOptionsRef.current.offsetLeft + moreOptionsRef.current.offsetWidth * 4.3
        }
        arrowPos="right"
        paddingHorizontal={undefined}
        paddingVetical={undefined}
        style={undefined}
      >
        <MoreCard onEdit={onEdit} editing={editing} />
      </FloatingCard>
    </div>
  )
}

Appointment.whyDidYouRender = true

export default memo(Appointment)
