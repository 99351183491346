import { hp, wp } from 'styles/Sizes'

export const closeBtnStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(50%, -50%)',
  zIndex: 1,
}
export const cardStyle = {
  position: 'fixed',
  top: hp(31.7),
  right: wp(14.1),
  maxWidth: wp(14.3),
}
