import { memo } from 'react'
import { CS_m } from 'back-end-api'
import DayTable from './DayTable'
import WeekTable from './WeekTable'
import { VIEW_TYPE } from '../Tools'

interface AppointmentsViewProps {
  data: CS_m.ModelsAppointment[]
  currentView: string
  isLoading: boolean
  date: Date
}

const AppointmentsView = ({ data, currentView, isLoading, date }: AppointmentsViewProps) => {
  switch (currentView) {
    case VIEW_TYPE.day:
      return <DayTable appointments={data} loading={isLoading} date={date} />
    case VIEW_TYPE.week:
      return <WeekTable appointments={data} currDate={date} />
    default:
      console.error('Unhandled AppointmentsView type', currentView)
      return null
  }
}

AppointmentsView.whyDidYouRender = true

export default memo(AppointmentsView)
