export default ({ className, color = '#1c165b' }) => (
  <svg
    id="md-information-circle"
    xmlns="http://www.w3.org/2000/svg"
    width="14.291"
    height="14.291"
    viewBox="0 0 14.291 14.291"
    className={className}
  >
    <path
      id="md-information-circle-2"
      data-name="md-information-circle"
      d="M10.52,3.375a7.145,7.145,0,1,0,7.145,7.145A7.148,7.148,0,0,0,10.52,3.375Zm.722,10.718H9.8V9.8h1.443v4.294Zm0-5.7H9.8V6.948h1.443V8.391Z"
      transform="translate(-3.375 -3.375)"
      fill={color}
    />
  </svg>
)
