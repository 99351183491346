import styled from 'styled-components'
import { primary } from 'styles/Colors'

export const Container = styled(
  ({ italic, noUnderline, underlineOnHover, color, bold, size, disabled, ...props }) => (
    <div {...props} />
  )
)`
  & > a {
    color: ${({ color }) => color};
    font-size: ${({ size }) => size};
    text-decoration: none;
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    ${({ disabled }) =>
      disabled &&
      `
	      opacity: 0.5;
	      pointer-events: none;
        cursor: normal !important;
     `}
  }
  width: fit-content;
  font-style: ${({ italic }) => (italic ? 'italic' : 'initial')};
  border-bottom: ${({ noUnderline, underlineOnHover }) =>
    noUnderline || underlineOnHover ? 'none' : `1px solid ${primary}`};
  ${({ underlineOnHover, disabled }) =>
    underlineOnHover &&
    !disabled &&
    `
		&:hover {
		  border-bottom: 1px solid ${primary} !important;
		}
	`}
`
