import { memo, useCallback, useState, useEffect, useRef, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { CS, CS_m } from 'back-end-api'

import { terminate, useSummariesDispatch, update as updateSummary } from 'services/Summaries'
import usePrevious from 'hooks/usePrevious'
import { textPrimary } from 'styles/Colors'
import { useTranslation } from 'services/Translation'
import DefaultBtn from 'components/common/buttons/Default'
import SecondaryBtn from 'components/common/buttons/Secondary'
import DefaultCross from 'assets/icons/crosses/Default'
import {
  setPatientSideBar,
  usePatientSideBarState,
  PatientSideBar,
  patientSideBarDefault,
} from 'services/Misc/patientSideBar'
import { ROUTES } from 'Constants'
import { useMiscDispatch } from 'services/Misc'
import styled from 'styled-components'

import Diagnosis from './Diagnosis'
import Header from './Header'
import Summary from './Summary'
import Encounters from './Encounters'
import { TABS, formatDiseases } from './Tools'
import ConfirmFinish from './ConfirmFinish'

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
`
const Content = styled.div`
  width: 100%;
`
const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
`

const tabsVariants = {
  enter: direction => {
    return {
      x: direction > 0 ? 500 : -500,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 500 : -500,
      opacity: 0,
    }
  },
}

const texts = getText => key =>
  ({
    closeBtnLabel: getText('Close', 'Patient_Details_Tabs'),
    finishBtnLabel: getText('Finish consultation', 'Patient_Details_Tabs'),
  }[key])

const Tabs = ({ summary, token }) => {
  const { t, lang } = useTranslation(texts)
  const finishRef = useRef(null)
  const [tab, setTab] = useState(TABS.SUMMARY)
  const [finishVisible, setFinishVisible] = useState(false)
  const [finishType, setFinishType] = useState('definitive')
  const [loadingConfirm, setLoadingConfirm] = useState(null)
  const [codes, setCodes] = useState(formatDiseases(summary?.diagnosis?.diseases, lang))
  const { id } = useParams()
  const miscDispatch = useMiscDispatch()
  const history = useHistory()

  const summariesDispatch = useSummariesDispatch()
  const previousTab = usePrevious(tab)
  const direction =
    Object.keys(TABS).findIndex(e => e === previousTab) >
    Object.keys(TABS).findIndex(e => e === tab)
      ? -1
      : 1

  const patientSideBarProps = usePatientSideBarState()
  useEffect(() => {
    if (
      patientSideBarProps.type === PatientSideBar.PastEncounter &&
      patientSideBarProps.pastEncounterProps?.selectedRegistrationId.toString() === id
    ) {
      setTab(TABS.SUMMARY)
      setPatientSideBar(miscDispatch, patientSideBarDefault)
    }
  }, [patientSideBarProps, id, miscDispatch])

  const handleCodesChange = useCallback(
    async newCodes => {
      const oldCodes = codes
      setCodes(newCodes)

      const finalCodes = newCodes.map(c => c.id)
      const res = await updateSummary(
        { token },
        summary.id,
        { disease_identifier_ids: finalCodes },
        summariesDispatch,
        true
      )
      if (!res) {
        setCodes(oldCodes)
      }
    },
    [summariesDispatch, summary, token, codes]
  )

  const handleClose = useCallback(() => {
    history.push(ROUTES.HOME)
  }, [history])

  const handleSubmit = useCallback(async () => {
    setLoadingConfirm(true)
    await terminate({ token }, summary.id, summariesDispatch)
    setLoadingConfirm(false)
    setFinishVisible(false)
  }, [summary, token, summariesDispatch])

  const handleFinish = useCallback(
    type => {
      setFinishType(type)
      setFinishVisible(!finishVisible)
    },
    [finishVisible, setFinishType]
  )

  const handleConfirmFinish = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const mandatoryActionTaken = useMemo(() => {
    return summary?.advice?.text
  }, [summary])

  return (
    <div style={{ height: '62vh', display: 'flex', flexDirection: 'column' }}>
      <div className="mt-20 relative h-full">
        <Header setTab={setTab} tab={tab} />
        <Content>
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              className="w-full absolute"
              custom={direction}
              variants={tabsVariants}
              key={tab}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 200 },
                opacity: { duration: 0.2 },
              }}
            >
              <Container custom={direction} key={tab}>
                {(() => {
                  switch (tab) {
                    case TABS.SUMMARY:
                      return <Summary />
                    case TABS.DIAGNOSIS:
                      return (
                        <Diagnosis
                          token={token}
                          codes={codes}
                          setCodes={handleCodesChange}
                          mandatoryActionTaken={mandatoryActionTaken}
                        />
                      )
                    case TABS.ENCOUNTERS:
                      return <Encounters />
                    default:
                      console.error('Unhandled patient tab', tab)
                      return null
                  }
                })()}
              </Container>
            </motion.div>
          </AnimatePresence>
        </Content>
      </div>
      <FooterContainer className="w-full">
        <div className="border-t border-grey-100 py-5 w-full flex items-start ">
          <div className="border-r border-grey-100 pr-5">
            <DefaultBtn
              label={t('closeBtnLabel')}
              nextIcon={<DefaultCross style={{ width: 12, height: 'auto' }} />}
              type="submit"
              className="font-csc65"
              reversedColors
              color={textPrimary}
              onClick={handleClose}
            />
          </div>
          <div className="pl-5">
            <SecondaryBtn
              label={t('finishBtnLabel')}
              type="submit"
              innerRef={finishRef}
              className="font-csc65"
              onClick={() => handleFinish('definitive')}
              disabled={!codes.length || CS.isSummaryStatusFinal(summary) || !mandatoryActionTaken}
              reversedColors
              color={textPrimary}
            />
          </div>
        </div>
      </FooterContainer>
      <ConfirmFinish
        type={finishType}
        visible={finishVisible}
        onCancel={handleFinish}
        onConfirm={handleConfirmFinish}
        loading={loadingConfirm}
      />
    </div>
  )
}

Tabs.whyDidYouRender = true

export default memo(Tabs)
