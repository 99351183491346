import { memo, useCallback, useMemo } from 'react'
import isSameDay from 'date-fns/isSameDay'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'

import { useTranslation } from 'services/Translation'
import PaginateIcon from 'assets/icons/arrows/Paginate'
import ExpandBtn from 'components/common/buttons/Expand'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    today: getText('Today', 'Home_Tabs_Appointments_Header'),
  }[key])

interface HeaderProps {
  expanded: boolean
  onExpand: () => void
  date: Date
  setDate: (date: Date) => void
  canExpand: boolean
}

const Header = ({ expanded, onExpand, date, setDate, canExpand }: HeaderProps) => {
  const { t, formatDate } = useTranslation(texts)
  const isToday = useMemo(() => isSameDay(date, new Date()), [date])

  const changeDay = useCallback(
    (direction: number) => () => {
      if (direction === 1) setDate(addDays(date, 1))
      if (direction === -1) setDate(subDays(date, 1))
    },
    [date, setDate]
  )

  return (
    <div className="font-csc85 text-blue-900 text-xs px-16 h-16 bg-background z-20 relative flex items-center justify-between">
      <div>{isToday ? t('today') : formatDate(date, 'iii dd MMM')}</div>
      <div className="flex items-center border-l border-grey-100 h-4">
        {!isToday && (
          <div
            className="hover:bg-grey-100 bg-background-white transition p-2 rounded-full mx-5 cursor-pointer"
            onClick={changeDay(-1)}
            tabIndex={0}
            role="button"
            onKeyDown={changeDay(-1)}
          >
            <PaginateIcon className={undefined} style={{ transform: 'rotate(180deg)' }} />
          </div>
        )}
        <div
          className="hover:bg-grey-100 bg-background-white transition p-2 rounded-full cursor-pointer"
          onClick={changeDay(1)}
          tabIndex={0}
          role="button"
          onKeyDown={changeDay(1)}
        >
          <PaginateIcon className={undefined} style={undefined} />
        </div>
      </div>
      <ExpandBtn style={undefined} expanded={expanded} onExpand={onExpand} canExpand={canExpand} />
    </div>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
