export default ({ style, color = 'white' }) => (
  <svg style={style} width="16.292" height="16.292" viewBox="0 0 16.292 16.292">
    <g transform="translate(1.414 1.414)">
      <line
        y1="13.301"
        x2="13.301"
        transform="translate(0.081 0.081)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        y1="13.464"
        x2="13.464"
        transform="translate(13.464) rotate(90)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)
