import styled from 'styled-components'

import { primary, textPrimary, transparency, greySecondary } from 'styles/Colors'

export const Container = styled(({ containerRef, ...props }) => (
  <div ref={containerRef} {...props} />
))`
  position: 'relative',
  height: 70%;
  max-height: 610px;
  width: 100%;
  overflow: auto;
  padding: 0;
`
export const Inner = styled(({ containerRef, ...props }) => <div ref={containerRef} {...props} />)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 38px;
  height: 100%;
`

export const Element = styled(({ selected, isFirst, isLast, ...props }) => <div {...props} />)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ selected }) =>
    selected &&
    `
    background: rgb(255,72,141);
background: radial-gradient(circle, rgba(255,72,141,0.13631390056022408) 44%, rgba(118,90,247,0.10830269607843135) 86%);
  `}
  ${({ isFirst }) =>
    isFirst &&
    `
      margin-left: 10%;
      width: 90%;
      justify-content: flex-start;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

  `}
  ${({ isLast }) =>
    isLast &&
    `
      margin-right: 10%;
      width: 90%;
      justify-content: flex-end;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

  `}
`

export const TimeBlock = styled(
  ({ backgroundColor, isFirst, isLast, innerPosition, selected, ...props }) => <div {...props} />
)`
  position: relative;
  font-family: coresanscr45;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: ${({ isFirst, isLast }) => (isFirst || isLast ? '90%' : '80%')};
  height: 40px;
  border: ${({ isSelected }) => `1px solid ${isSelected ? textPrimary : greySecondary}`};

  background-color: ${({ selected }) => selected && 'pink'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ backgroundColor }) => (backgroundColor === 'white' ? textPrimary : 'white')};
  border-radius: 20px;
  transition: all 0.3s linear;
  &:hover {
    border: ${`1px solid ${textPrimary}`};
    box-shadow: 3px 6px 16px 0
      ${({ backgroundColor }) =>
        backgroundColor === 'white'
          ? primary + transparency[10]
          : backgroundColor + transparency[10]};
  }
`
