export interface TimeWindow {
  start: Date | null
  end: Date | null
}

export enum Step {
  TimeWindowSelection,
  AppointmentCreation,
  InProgress,
  Success,
}
