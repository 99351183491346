import { memo, useEffect, useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-module-react'

import { Container } from './Styles'

Quill.register('modules/imageResize', ImageResize)

const defaultToolbar = [
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
  ['link', 'image'],
  ['clean'],
]
const miniToolbar = [['bold', 'italic', 'underline']]

const Composed = ({
  field,
  height,
  placeholder = '',
  readOnly = false,
  mini = false,
  hideToolbar = false,
  noPadding = false,
}) => {
  const editorRef = useRef()
  useEffect(() => {
    if (editorRef.current) editorRef.current.updater.enqueueForceUpdate(editorRef.current)
  }, [field, editorRef])
  return (
    <Container
      noToolbar={readOnly || hideToolbar}
      mini={mini}
      noPadding={noPadding}
      height={height}
    >
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={field.value}
        onChange={field.onChange}
        onKeyDown={field.onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        modules={{
          imageResize: {
            parchment: Quill.import('parchment'),
          },
          toolbar: mini ? miniToolbar : readOnly ? [] : defaultToolbar,
        }}
      />
    </Container>
  )
}

Composed.whyDidYouRender = true

export default memo(Composed)
