import { memo } from 'react'

import { useTranslation } from 'services/Translation'

import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  padding-right: 1rem;
  width: 100%;
`

interface TimeCellProps {
  time: string | undefined
}

const TimeCell = ({ time }: TimeCellProps) => {
  const { formatDate: format } = useTranslation()

  if (time) {
    const dateTime = new Date(time)
    const dateStr = format(dateTime, 'd MMMM, yyyy')
    const timeStr = format(dateTime, 'HH.mm')
    return (
      <Container>
        {dateStr}
        <br />
        {timeStr}
      </Container>
    )
  }
  return null
}

TimeCell.whyDidYouRender = true

export default memo(TimeCell)
