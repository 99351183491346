import { memo, useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { hp } from 'styles/Sizes'
import Link from 'components/common/Link'
import Separator from 'components/common/Separator'
import Form from 'components/auth/LoginForm'
import CodeForm from 'components/auth/CodeForm'
import { alreadyStartedLoops } from 'hooks/usePoll'
import { getQueryStringArgs } from 'utils/functions'
import { multiFactorAuth, getAccessToken } from 'services/Authentication'
import { useTranslation } from 'services/Translation'
import { useMiscDispatch } from 'services/Misc'

import { ROUTES } from 'Constants'

const texts = getText => key =>
  ({
    forgotPassword: getText('Forgot password?', 'Auth_Login'),
    moreClinicsTitle: getText(
      'Your account is registered under more than one clinic. Please select the desired clinic below.',
      'Auth_Login'
    ),
    btnLabel: getText('Continue', 'Auth_Login'),
    inactivity: getText('You have been logged out due to inactivity.', 'Auth_Login'),
    MFATooManyErrors: getText('Too many wrong codes, please retry.', 'Auth_Login'),
    MFAWrong1: getText('Wrong code, ', 'Auth_Login'),
    MFAWrong2: getText('tries left.', 'Auth_Login'),
    MFAWrong2Bis: getText('try left.', 'Auth_Login'),
    MFATimeout: getText('Code timed out, please retry.', 'Auth_Login'),
  }[key])

const STEPS = {
  INITIAL: 'INITIAL',
  CODE: 'CODE',
}

const Login = () => {
  const { t } = useTranslation(texts)
  const history = useHistory()
  const miscDispatch = useMiscDispatch()
  const [step, setStep] = useState(STEPS.INITIAL)
  const [loginResp, setLoginResp] = useState(null)
  const [loading2FA, setLoading2FA] = useState(false)
  const [error2FA, setError2FA] = useState('')
  const [errorLogin, setErrorLogin] = useState('')

  const handleLoggedIn = useCallback(user => {
    setLoginResp(user)
    setStep(STEPS.CODE)
  }, [])

  const handle2FASubmit = useCallback(
    async code => {
      setLoading2FA(true)
      const res = await multiFactorAuth({
        access_token: loginResp.mfaPendingToken,
        one_time_password: parseInt(code, 10),
      })
      if (res?.error) {
        if (res.error?.message?.startsWith('Wrong')) {
          const triesLeft = parseInt(res.error.message.split(', ')[1].split(' ')[0], 10)
          if (triesLeft === 0) {
            setErrorLogin(t('MFATooManyErrors'))
            setStep(STEPS.INITIAL)
            setError2FA(null)
            setLoading2FA(false)
            setLoginResp(null)
            return
          }

          setError2FA(
            `${t('MFAWrong1')} ${triesLeft} ${triesLeft === 1 ? t('MFAWrong2Bis') : t('MFAWrong2')}`
          )
          setLoading2FA(false)
          return
        }

        setErrorLogin(t('MFATimeout'))
        setStep(STEPS.INITIAL)
        setLoading2FA(false)
        setLoginResp(null)
        return
      }

      const { next } = getQueryStringArgs()
      history.push(next || ROUTES.HOME)
      setLoginResp(null)

      setLoading2FA(false)
    },
    [t, loginResp, history]
  )

  useEffect(() => {
    const keys = Object.keys(alreadyStartedLoops)
    if (keys.length) {
      keys.forEach(key => {
        if (alreadyStartedLoops[key]) {
          clearInterval(alreadyStartedLoops[key])
          alreadyStartedLoops[key] = null
        }
      })
    }
    if (sessionStorage.length) {
      const token = getAccessToken()
      if (token) {
        history.push(ROUTES.HOME)
        return
      }
      sessionStorage.clear()
    }
  }, [history, miscDispatch])

  if (step === STEPS.CODE) {
    return (
      <CodeForm
        onSubmit={handle2FASubmit}
        partialTarget={loginResp?.partialTarget}
        error={error2FA}
        loading={loading2FA}
      />
    )
  }

  return (
    <>
      <Form onLoggedIn={handleLoggedIn} error={errorLogin} />
      <Separator size={hp(4.7)} />
      <Link to={ROUTES.RESET} label={t('forgotPassword')} italic style={{ margin: 'auto' }} />
    </>
  )
}

Login.whyDidYouRender = true

export default memo(Login)
