import { memo, useCallback } from 'react'
import { useTranslation } from 'services/Translation'
import { useMiscDispatch } from 'services/Misc'
import { purple, error, green } from 'styles/Colors'
import { AnimatePresence, motion } from 'framer-motion'
import UnderlineBtn from 'components/common/buttons/Underline'
import { setAppointmentSideBar, AppointmentSideBar } from 'services/Misc/appointmentSideBar'
import { wholeDayDuration_ns, formatTimeOff } from 'services/TimeOff'
import { isDayAffectedByEvent, getDayStart } from 'components/common/Month/Tools'
import { getClaims } from 'services/Authentication'
import { CS, CS_m } from 'back-end-api'
import { FilterBtn } from './Styles'
import { VIEW_TYPE } from '../Tools'
import { TABS } from '../../Tools'

const texts = getText => key =>
  ({
    all: getText('All', 'Schedule_List_Filter'),
    upcoming: getText('Upcoming', 'Schedule_List_Filter'),
    completed: getText('Completed', 'Schedule_List_Filter'),
    cancelled: getText('Cancelled', 'Schedule_List_Filter'),
    dayView: getText('Day View', 'Schedule_List_Filter'),
    weekView: getText('Week View', 'Schedule_List_Filter'),
    timeOfTheDay: getText('Time of the days', 'Schedule_List_Filter'),
    timeSlot: getText('Manage day schedule', 'Schedule_List_Filter'),
  }[key])

const Filter = ({
  date,
  appointmentFilter,
  setAppointmentFilter,
  onChangeView,
  view,
  direction,
  tabsVariants,
  content,
}) => {
  const { t } = useTranslation(texts)
  const miscDispatch = useMiscDispatch()
  const claims = getClaims()
  const { data } = CS.useTimeOffsList({ doctor_account_id: claims.sub })

  const handleChangeView = useCallback(
    newVal => {
      onChangeView(newVal)
    },
    [onChangeView]
  )

  const blockWholeDay = useCallback(() => {
    const wholeDayTimeOff = data?.find(timeOff => {
      const { frequency, period, until_datetime } = timeOff.interval?.repeat || {}
      return (
        isDayAffectedByEvent(date, {
          time: timeOff.interval.datetime,
          period,
          frequency,
          until: until_datetime,
        }) && timeOff.interval.duration_ns === wholeDayDuration_ns
      )
    })

    const timeslot = wholeDayTimeOff
      ? formatTimeOff(wholeDayTimeOff)
      : {
          time: getDayStart(date),
          duration: wholeDayDuration_ns / 1000000,
        }

    setAppointmentSideBar(miscDispatch, {
      type: AppointmentSideBar.EventManager,
      eventManagerSideBarProps: { timeSlots: [timeslot] },
    })
  }, [miscDispatch, date, data])

  return (
    <div className="relative h-16 flex items-center border-b border-grey-100">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className="w-full absolute"
          custom={direction}
          variants={tabsVariants}
          key={content}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 200 },
            opacity: { duration: 0.2 },
          }}
        >
          {content === TABS.APPOINTMENTS && (
            <div className="h-full flex justify-between items-center">
              <div className="w-2/5 flex justify-between">
                <FilterBtn
                  active={appointmentFilter === null}
                  onKeyDown={() => setAppointmentFilter(null)}
                  onClick={() => setAppointmentFilter(null)}
                >
                  {t('all')}
                </FilterBtn>
                <FilterBtn
                  color={purple}
                  colorMark
                  active={appointmentFilter === CS_m.ModelsAppointmentStatus.accepted}
                  onKeyDown={() => setAppointmentFilter(CS_m.ModelsAppointmentStatus.accepted)}
                  onClick={() => setAppointmentFilter(CS_m.ModelsAppointmentStatus.accepted)}
                >
                  {t('upcoming')}
                </FilterBtn>
                <FilterBtn
                  color={green}
                  colorMark
                  active={appointmentFilter === CS_m.ModelsAppointmentStatus.done}
                  onKeyDown={() => setAppointmentFilter(CS_m.ModelsAppointmentStatus.done)}
                  onClick={() => setAppointmentFilter(CS_m.ModelsAppointmentStatus.done)}
                >
                  {t('completed')}
                </FilterBtn>
                <FilterBtn
                  color={error}
                  colorMark
                  active={appointmentFilter === CS_m.ModelsAppointmentStatus.cancelled}
                  onKeyDown={() => setAppointmentFilter(CS_m.ModelsAppointmentStatus.cancelled)}
                  onClick={() => setAppointmentFilter(CS_m.ModelsAppointmentStatus.cancelled)}
                >
                  {t('cancelled')}
                </FilterBtn>
              </div>
              <div className="w-1/5 flex justify-between font-csc65">
                <FilterBtn
                  noPadding
                  active={view === VIEW_TYPE.day}
                  onKeyDown={() => handleChangeView(VIEW_TYPE.day)}
                  onClick={() => handleChangeView(VIEW_TYPE.day)}
                >
                  {t('dayView')}
                </FilterBtn>
                <FilterBtn
                  noPadding
                  active={view === VIEW_TYPE.week}
                  onKeyDown={() => handleChangeView(VIEW_TYPE.week)}
                  onClick={() => handleChangeView(VIEW_TYPE.week)}
                >
                  {t('weekView')}
                </FilterBtn>
              </div>
            </div>
          )}
          {content === TABS.SCHEDULES && (
            <div className="h-full flex justify-between items-center">
              <div>{t('timeOfTheDay')}</div>
              <UnderlineBtn
                className="ml-12 pl-12 border-l border-grey-100"
                bold
                italic
                withNext
                label={t('timeSlot')}
                onClick={blockWholeDay}
              />
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

Filter.whyDidYouRender = true

export default memo(Filter)
