import { memo, useCallback, useRef, useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import { useTranslation } from 'services/Translation'
import { TABS } from '../Tools'
import { LargeTitle, Title, Container } from './Styles'
import texts from './Texts'

const Header = ({ tab, setTab }) => {
  const { t } = useTranslation(texts)
  const containerRef = useRef(null)
  const appointmentsTitleRef = useRef(null)
  const encountersTitleRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [appointmentsTitleWidth, setAppointmentsTitleWidth] = useState(0)
  const [encountersTitleWidth, setEncountersTitleWidth] = useState(0)

  const handleTabChange = useCallback(
    newTab => () => {
      if (tab !== newTab) setTab(newTab)
    },
    [tab, setTab]
  )
  useEffect(() => {
    if (
      !appointmentsTitleWidth &&
      appointmentsTitleRef?.current &&
      appointmentsTitleRef?.current?.offsetWidth
    ) {
      setTimeout(() => setAppointmentsTitleWidth(appointmentsTitleRef?.current?.offsetWidth), 250)
    }
    if (
      !encountersTitleWidth &&
      encountersTitleRef?.current &&
      encountersTitleRef?.current?.offsetWidth
    ) {
      setTimeout(() => setEncountersTitleWidth(encountersTitleRef?.current?.offsetWidth), 250)
    }
    if (!containerWidth && containerRef?.current && containerRef?.current?.offsetWidth) {
      setTimeout(() => setContainerWidth(containerRef?.current?.offsetWidth), 250)
    }
  }, [encountersTitleRef, encountersTitleRef, containerRef])

  return (
    <div className="px-16">
      <Container
        className="h-12 w-full flex items-center justify-between relative z-30"
        ref={containerRef}
      >
        <LargeTitle
          innerRef={appointmentsTitleRef}
          className="absolute font-csc65 text-base w-auto cursor-pointer text-blue-900 transition z-30 px-1"
          selected={tab === TABS.APPOINTMENTS}
          onClick={handleTabChange(TABS.APPOINTMENTS)}
          data-cy="tabAppointments"
        >
          {t('appointments')}
        </LargeTitle>
        <Title
          className="font-csc35 text-base w-auto cursor-pointer text-blue-900 transition z-30 px-1"
          selected={tab === TABS.APPOINTMENTS}
          onClick={handleTabChange(TABS.APPOINTMENTS)}
          data-cy="tabAppointments"
        >
          {t('appointments')}
        </Title>
        <LargeTitle
          innerRef={encountersTitleRef}
          className="absolute right-0 font-csc65 text-base w-auto cursor-pointer text-blue-900 transition z-30 px-1"
          selected={tab === TABS.ENCOUNTERS}
          onClick={handleTabChange(TABS.ENCOUNTERS)}
          data-cy="tabDiagnosis"
        >
          {t('encounters')}
        </LargeTitle>
        <Title
          className="font-csc35 text-base w-auto cursor-pointer text-blue-900 transition z-30 pr-1 px-1"
          selected={tab === TABS.ENCOUNTERS}
          onClick={handleTabChange(TABS.ENCOUNTERS)}
          data-cy="tabDiagnosis"
        >
          {t('encounters')}
        </Title>
      </Container>
      <div className="h-px relative w-full bg-grey-100 overflow-hidden">
        <motion.div
          transition={{
            left: { ease: 'easeOut' },
            width: { duration: 0.2 },
          }}
          // transition={{ ease: 'easeOut', duration: 0.2 }}
          className="rounded-full h-px -t-px absolute bg-blue-900"
          animate={{
            ...(tab === TABS.ENCOUNTERS
              ? {
                  left:
                    appointmentsTitleWidth +
                    (containerWidth - (appointmentsTitleWidth + encountersTitleWidth)),
                }
              : { left: 0 }),
            width: tab === TABS.APPOINTMENTS ? appointmentsTitleWidth : encountersTitleWidth || 0,
          }}
        />
      </div>
    </div>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
