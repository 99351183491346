import { memo, useState, useRef, useMemo } from 'react'
import { motion } from 'framer-motion'

import Unfold from 'assets/icons/Unfold'
import ArrowIcon from 'assets/icons/arrows/Small'

import Header from './Header'
import Table from './Table'

const PatientsList = () => {
  const containerRef = useRef(null)
  const [open, setOpen] = useState(false)

  const containerHeight = useMemo(() => {
    return containerRef?.current?.offsetHeight || 0
  }, [containerRef])

  return (
    <div className="relative h-full" style={{ marginTop: '3.6875rem' }} ref={containerRef}>
      <motion.div
        className="h-32 absolute w-full"
        animate={{
          top: open ? -180 : 0,
          left: 0,
          height: open ? containerHeight + 180 : containerHeight,
        }}
        transition={{
          height: { ease: 'easeOut', duration: 0.25 },
          top: { ease: 'easeOut', duration: 0.25 },
        }}
      >
        <div
          className="relative w-full cursor-pointer"
          onClick={() => setOpen(v => !v)}
          tabIndex={0}
          role="button"
          onKeyDown={() => setOpen(v => !v)}
        >
          <Unfold
            style={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: 0,
              zIndex: 1,
            }}
          />
          <ArrowIcon
            style={{
              position: 'absolute',
              transform: `translate(-50%) ${open ? 'rotate(0deg)' : 'rotate(180deg)'}`,
              transition: 'all 250ms linear',
              left: '50%',
              top: -10,
              zIndex: 1,
            }}
            color="#1C165B"
          />
          <div
            className="absolute h-6 -mt-px top-12 bg-transparent left-2/4 transition"
            style={{
              boxShadow: 'rgb(47 76 203 / 10%) 0px -30px 30px 30px',
              transform: 'translate(-50%)',
              borderRadius: '100%',
              width: '55%',
            }}
          />
        </div>
        <div className="relative w-full bg-background z-10">
          <Header />
          <Table open={open} />
        </div>
      </motion.div>
    </div>
  )
}

PatientsList.whyDidYouRender = true

export default memo(PatientsList)
