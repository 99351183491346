import styled from 'styled-components'

import { wp } from 'styles/Sizes'
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 90vh;
  z-index: 99;
`

interface VideoGridContainerProps {
  showInfo: boolean
  large: boolean
  small: boolean
}

export const VideoGridContainer = styled(motion.div)<VideoGridContainerProps>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${({ showInfo, large, small }) =>
    // eslint-disable-next-line no-nested-ternary
    large
      ? `${wp(showInfo ? 100 : 100)}px ${wp(showInfo ? 25 : 0)}px`
      : small
      ? `30vw 0px`
      : `${wp(showInfo ? 75 : 100)}px ${wp(showInfo ? 25 : 0)}px`};
  grid-template-rows: 1fr;
  grid-template-areas: 'video info';
`
