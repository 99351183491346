import styled from 'styled-components'
import { greyPrimary } from 'styles/Colors'

export const FilterBtn = styled(({ active, color, ...props }) => (
  <button type="button" {...props} />
))`
  position: relative;
  ${({ noPadding }) =>
    !noPadding &&
    `padding-left: 20px;
  margin-right: 4rem;`}

  ${({ active }) =>
    active
      ? `font-family: 'coresanscr65';`
      : `font-family: 'coresanscr65'; color: ${greyPrimary};`}
  ${({ colorMark, color }) =>
    colorMark &&
    `
		&:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    height: 6px;
    width: 6px;
    background-color: ${color};
  }	
	`}
`
