import { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { logNetworkError } from 'services/Sentry'
import { Mutex } from 'async-mutex' // Import the Mutex class
import {
  refresh,
  eraseTokens,
  getAccessToken,
  isTokenExpiringWithin,
} from 'services/Authentication'
import { ROUTES } from 'Constants'
import { ConfigureGlobalService, ConfigureClinicService } from 'back-end-api'

export default function InitBackEndServices() {
  const history = useHistory()

  const goBackToLogin = () => {
    eraseTokens()

    const next =
      window.location.pathname !== ROUTES.LOGIN(undefined) ? window.location.pathname : undefined

    history.push(ROUTES.LOGIN(next))
  }

  const onError = (err: AxiosError) => {
    if (err.response?.status === 401) {
      goBackToLogin()
    }

    logNetworkError(err)
  }

  const mutex = new Mutex()

  const getToken = async (): Promise<string> => {
    const accessToken = getAccessToken()
    if (accessToken && !isTokenExpiringWithin(accessToken, 1000 * 60)) {
      return accessToken
    }

    const refreshedToken = await mutex.runExclusive(async () => {
      const latestAccessToken = getAccessToken()
      if (latestAccessToken !== accessToken) {
        return latestAccessToken
      }

      return refresh()
    })

    if (refreshedToken) {
      return refreshedToken
    }

    goBackToLogin()

    return ''
  }

  const globalServiceUrl = process.env.REACT_APP_GLOBAL_SERVICE_URL
  if (!globalServiceUrl) {
    throw new Error('REACT_APP_GLOBAL_SERVICE_URL environment variable is not set')
  }

  const clinicServiceUrl = process.env.REACT_APP_CLINIC_SERVICE_URL
  if (!clinicServiceUrl) {
    throw new Error('REACT_APP_CLINIC_SERVICE_URL environment variable is not set')
  }

  ConfigureGlobalService({
    baseUrl: globalServiceUrl,
    onErrorCallback: onError,
    getTokenCallback: getToken,
  })

  ConfigureClinicService({
    baseUrl: clinicServiceUrl,
    onErrorCallback: onError,
    getTokenCallback: getToken,
  })
}
