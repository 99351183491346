import useSWR from 'swr'

import { fetchFunc } from 'services/Tools'
import { getAccessToken } from 'services/Authentication'
import { formatRegistrations } from './formatter'

// [Deprecated] Use useRegistrationsList instead & remove when not used anymore
export const useRegistrations = () => {
  const token = getAccessToken()

  const { data, error } = useSWR(
    {
      url: `${process.env.REACT_APP_CLINIC_SERVICE_URL}/ListRegistrations`,
      args: {
        token,
        params: { without_old_closed_summaries: false },
        log: false,
      },
    },
    fetchFunc,
    { refreshInterval: 20000 }
  )

  return {
    data: data && typeof data !== 'number' ? formatRegistrations(data) : {},
    isLoading: !error && !data,
    isError: typeof data === 'number' ? data : error,
  }
}
