import { memo } from 'react'
import { useTranslation } from 'services/Translation'
import Separator from 'components/common/Separator'
import { usePatientRouteParams } from 'components/patient/Parameters'
import { hp } from 'styles/Sizes'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    skipped: getText('Skipped questions', 'Patient_Details_Tabs_Summary_Form'),
  }[key])

const SkippedQuestionCard = () => {
  const { t } = useTranslation(texts)

  const { summary } = usePatientRouteParams()
  if (!summary) return null

  const skippedQuestions =
    summary.answers?.filter(a => !a?.question?.content?.isMandatory && !a.response) ?? []

  return (
    <div className="w-96 flex flex-col text-blue-900">
      <div className="border-b border-grey-100 mb-6">
        <div
          className="font-csc75 mt-0.5 flex items-center"
          style={{ fontSize: '15px', marginBottom: '0.93125rem' }}
        >
          {t('skipped')}
          {skippedQuestions?.length > 0 && (
            <div className="flex justify-center items-center rounded-full bg-blue-900 w-5 h-5 text-white ml-3">
              {skippedQuestions?.length}
            </div>
          )}
        </div>
      </div>
      <div style={{ fontSize: '15px' }} className="font-csc65">
        {skippedQuestions.map(({ question }, index) => (
          <>
            <div>{question?.text}</div>
            {index !== skippedQuestions.length - 1 && <Separator size={hp(1.7)} />}
          </>
        ))}
      </div>
    </div>
  )
}

SkippedQuestionCard.whyDidYouRender = true

export default memo(SkippedQuestionCard)
