import styled from 'styled-components'

import { greySecondary } from 'styles/Colors'

export const Container = styled(({ noToolbar, mini, noPadding, height, ...props }) => (
  <div {...props} />
))`
  height: ${height => height}px;
  overflow: auto;
  ${({ mini }) =>
    mini &&
    `
      width: 100%;
      `}
  & > .quill {
    & > .ql-toolbar {
      ${({ noToolbar }) =>
        noToolbar
          ? 'display: none;'
          : `
      border: none;
      border-bottom: 1px solid ${greySecondary};
      `}
    }
    & > .ql-container {
      border: none;
      & > .ql-editor {
        font-size: 16px;
        font-family: coresanscr45;
        ${({ mini }) =>
          mini &&
          `
          padding: 12px 4px;
          &:before {
            left: 6px;
            font-style: normal;
          }
        `}
        ${({ noPadding }) => noPadding && `padding: 0;`}
      }
    }
  }
`
