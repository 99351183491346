import { memo, useCallback, useState, useRef } from 'react'

import Link from 'components/common/Link'
import { useTranslation } from 'services/Translation'
import LangPopup from 'components/common/LangPopup'

import QuestionMark from './assets/QuestionMark'
import { Container, Label, LinkContainer } from './Styles'
import { containerVariants, labelVariants, linkVariants } from './Animations'
import texts from './Texts'
import { LINKS } from './Tools'

const Help = ({ visible }) => {
  const { t, lang } = useTranslation(texts)
  const langRef = useRef()
  const [langOpen, setLangOpen] = useState(false)
  const [hovering, setHovering] = useState(false)

  const handleHovering = useCallback(
    newState => () => {
      if (!newState && !langOpen) setHovering(newState)
      else if (newState) setHovering(newState)
    },
    [langOpen]
  )
  const openLink = useCallback(
    type => e => {
      e?.preventDefault()
      window.open(lang === 'nl' ? type.NL : type.EN)
    },
    [lang]
  )
  const handleLangOpen = useCallback(
    newState => e => {
      e?.preventDefault()
      if (!newState) setHovering(newState)
      setLangOpen(newState)
    },
    []
  )

  if (!visible) return null
  return (
    <Container
      initial="closed"
      animate={hovering ? 'open' : 'closed'}
      variants={containerVariants}
      onMouseEnter={handleHovering(true)}
      onMouseLeave={handleHovering(false)}
    >
      <Label animate={hovering ? 'hidden' : 'visible'} variants={labelVariants}>
        <QuestionMark style={{ marginRight: 12 }} />
        {t('btnLabel')}
      </Label>
      <LinkContainer variants={linkVariants}>
        <Link
          disabled={lang !== 'nl'}
          underlineOnHover
          to={LINKS.START[lang === 'nl' ? 'NL' : 'EN']}
          onClick={openLink(LINKS.START)}
          target="_blank"
          label={t('start')}
        />
      </LinkContainer>
      <LinkContainer variants={linkVariants}>
        <Link
          underlineOnHover
          to={LINKS.FAQ[lang === 'nl' ? 'NL' : 'EN']}
          onClick={openLink(LINKS.FAQ)}
          target="_blank"
          label={t('faq')}
        />
      </LinkContainer>
      <LinkContainer variants={linkVariants}>
        <Link
          underlineOnHover
          to={LINKS.SUPPORT[lang === 'nl' ? 'NL' : 'EN']}
          onClick={openLink(LINKS.SUPPORT)}
          target="_blank"
          label={t('support')}
        />
      </LinkContainer>
      <LinkContainer variants={linkVariants} innerRef={langRef}>
        <Link underlineOnHover onClick={handleLangOpen(true)} label={t('language')} />
      </LinkContainer>
      <LangPopup
        opened={langOpen}
        setClose={handleLangOpen(false)}
        top={langRef?.current?.offsetTop}
        left={-110}
      />
    </Container>
  )
}

Help.whyDidYouRender = true

export default memo(Help)
