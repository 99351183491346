import { memo, useCallback, useMemo } from 'react'
import isSameDay from 'date-fns/isSameDay'

import { hp } from 'styles/Sizes'
import Separator from 'components/common/Separator/Component'
import { useTranslation, dateFnsLocales } from 'services/Translation'
import { primary } from 'styles/Colors'

import { Container, DayLine, DayTitle, NumbersGrid, Number, Event, Divider } from './Styles'
import { getNumberList, getTimeOffsForDay, getAvailableTimes, getNumberWeekList } from './Tools'

const Month = ({
  className,
  currDate,
  onDateChange,
  selectedDate,
  monthView: { month, year },
  small = false,
  mini = false,
  events = [],
  appointmentDuration = 0,
  disableBefore,
  dataCy = '',
  calendarType,
}) => {
  const { lang, formatDate: format } = useTranslation()

  const days = useMemo(() => {
    const out = []
    for (let i = 1; i < 7; i++) {
      out.push(dateFnsLocales[lang]?.localize.day(i, { width: 'abbreviated' }))
    }
    out.push(dateFnsLocales[lang]?.localize.day(0, { width: 'abbreviated' }))
    return out
  }, [lang])

  const numberList = useMemo(() => {
    if (calendarType === 'week') return getNumberWeekList(year, month, days, format)
    return getNumberList(year, month, days, disableBefore, format)
  }, [calendarType, days, disableBefore, format, month, year])

  const handleNumberClick = useCallback(
    newDate => () => {
      onDateChange(new Date(year, month, newDate))
    },
    [month, onDateChange, year]
  )
  const getDayStatus = useCallback(
    date => {
      // 8 to 18
      const eventsOfTheDay = events.filter(
        e => !e.repeat?.frequency && isSameDay(new Date(e.time), date)
      )
      if (!eventsOfTheDay.length) return 'empty' // Todo: Warning this leads to a bug. return empty only if there are free slots. Otherwise it could be vacations.
      const timeOffsOfTheDay = getTimeOffsForDay(date, events)
      const availableTimes = getAvailableTimes([...eventsOfTheDay, ...timeOffsOfTheDay], date)
      if (!availableTimes.length) return 'full'
      if (availableTimes.find(t => t.duration >= appointmentDuration)) return 'filling'
      return 'full'
    },
    [events, appointmentDuration]
  )

  return (
    <Container small={small} className={className} mini={mini}>
      <DayLine className="mb-5" mini={mini}>
        {days.map(el => (
          <DayTitle key={el} small={small} mini={mini}>
            {`${el?.[0]?.toUpperCase()}${el.substring(1)}`}
          </DayTitle>
        ))}
      </DayLine>
      <NumbersGrid small={small} mini={mini}>
        {numberList.map(({ value, disabled }, i) => {
          const isToday =
            !disabled &&
            currDate.getDate() === value &&
            currDate.getMonth() === month &&
            currDate.getFullYear() === year
          const loopDate = new Date(year, month, value)

          let color = 'transparent'
          let colorEvent = 'transparent'
          const statusDay = getDayStatus(loopDate)
          if (isToday) color = mini ? primary : '#1C165B'
          if (!disabled) {
            if (isSameDay(loopDate, selectedDate)) color = '#1C165B'
            else {
              switch (statusDay) {
                case 'filling':
                  color = mini ? 'transparent' : '#1C165B'
                  colorEvent = '#1C165B'
                  break
                case 'full':
                  color = '#F43E51'
                  break
              }
            }
          }

          return (
            <Number
              key={i}
              disabled={disabled}
              color={small || (!small && isToday) ? color : color}
              onClick={disabled ? undefined : handleNumberClick(value)}
              small={small}
              mini={mini}
              data-cy={`${dataCy}_number_${month}_${value}`}
            >
              {!small && color !== 'transparent' && <Event color={color} />}
              {statusDay === 'filling' && <Event color={colorEvent} />}
              {value}
            </Number>
          )
        })}
      </NumbersGrid>
      {!small ||
        (!mini && (
          <>
            <Separator size={hp(4)} />
            <Divider />
          </>
        ))}
    </Container>
  )
}

Month.whyDidYouRender = true

export default memo(Month)
