export const TABS = {
  SUMMARY: 'SUMMARY',
  DIAGNOSIS: 'DIAGNOSIS',
  ENCOUNTERS: 'ENCOUNTERS',
}

export const formatDiseases = (diseases, lang) => {
  if (!diseases || !diseases?.length) return []
  const out = []
  diseases.forEach(d => {
    out.push({
      id: d.id,
      code: d.code,
      name: d.name[lang],
    })
  })
  return out
}
