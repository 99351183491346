import { PropsWithChildren } from 'react'
import Typography from 'components/common/Typography'
import Separator from 'components/common/Separator'
import DefaultBtn from 'components/common/buttons/Default/Component'
import Cross from 'assets/icons/crosses/Default'
import SuccessImg from 'assets/images/Success'
import { textPrimary, error } from 'styles/Colors'
import { hp } from 'styles/Sizes'
import { Container } from './Styles'

export interface ModalContainerProps {
  label: string
  onClose: () => void
}

export const ModalContainer = ({
  children,
  onClose,
  label,
}: PropsWithChildren<ModalContainerProps>) => (
  <>
    <DefaultBtn
      mini
      label={label}
      onClick={onClose}
      className="absolute top-40 left-0"
      style={{ transform: 'translate(-50%, -50%)', zIndex: 99 }}
      nextIcon={<Cross style={{ width: 12, height: 'auto' }} />}
      background={textPrimary}
      color="white"
    />
    <Container>{children}</Container>
  </>
)

export interface ModalSucceededProps {
  label: string
  containerProps: ModalContainerProps
}

export const ModalSucceeded = ({ label, containerProps }: ModalSucceededProps) => (
  <ModalContainer label={containerProps.label} onClose={containerProps.onClose}>
    <div className="flex flex-col items-center">
      <SuccessImg />
      <Separator size={hp(3)} />
      <Typography
        type="title"
        className="font-csc65"
        center
        text={label}
        color={textPrimary}
        size={20}
        style={undefined}
      />
    </div>
  </ModalContainer>
)

export interface ModalFailedProps {
  errorMsg: string
  containerProps: ModalContainerProps
}

export const ModalFailed = ({ errorMsg, containerProps }: ModalFailedProps) => (
  <ModalContainer label={containerProps.label} onClose={containerProps.onClose}>
    <div className="flex flex-col items-center">
      <Cross style={{ width: 12, height: 'auto' }} color={error} />
      <Separator size={hp(3)} />
      <Typography
        type="title"
        className="font-csc65"
        center
        text={errorMsg}
        color={textPrimary}
        size={20}
        style={undefined}
      />
    </div>
  </ModalContainer>
)
