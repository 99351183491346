import { memo, useCallback, useMemo } from 'react'
import { useRegistrations } from 'services/Registrations'
import { getAccessTokenAndClaims } from 'services/Authentication'
import usePoll, { alreadyStartedLoops } from 'hooks/usePoll'
import { ROUTES } from 'Constants'
import { DragSelectProvider } from 'components/common/dragSelect'
import {
  wholeDayDuration_ns,
  useTimeOffState,
  useTimeOffDispatch,
  getAll as getTimeOffs,
} from 'services/TimeOff'
import { isDayAffectedByEvent } from 'components/common/Month/Tools'
import TimeSlots from './TimeSlots'
import { Container } from './Styles'

const REFRESH_DOCTOR_APPOINTMENT = parseInt(process.env.REACT_APP_REFRESH_DOCTOR_APPOINTMENT, 10)

const SchedulesView = ({ date }) => {
  const { token, claims } = getAccessTokenAndClaims()
  const {
    data: { appointments },
  } = useRegistrations()
  const { list: rawTimeOffs } = useTimeOffState()
  const timeOffDispatch = useTimeOffDispatch()
  const timeOffs = rawTimeOffs?.[claims?.sub] || []

  // Get TimeOffs
  const timeOffsInterval = useCallback(async () => {
    if (alreadyStartedLoops.scheduleTimeOffs) {
      await getTimeOffs({ token }, claims?.sub, timeOffDispatch)
    }
  }, [token, claims, timeOffDispatch])

  usePoll(timeOffsInterval, REFRESH_DOCTOR_APPOINTMENT, {
    contextName: 'scheduleTimeOffs',
    isRunning: Boolean(token && window.location.pathname === ROUTES.SCHEDULE),
  })

  const scheduledAppointments = useMemo(() => appointments?.filter(a => !a.isImmediate) || [], [
    appointments,
  ])

  if (
    timeOffs.some(timeOff => {
      const { frequency, period, until } = timeOff.repeat || {}
      return (
        isDayAffectedByEvent(date, { time: timeOff.time, period, frequency, until }) &&
        timeOff.duration === wholeDayDuration_ns / 1000000
      )
    })
  ) {
    return (
      <div className="mt-10 text-center font-csc55 text-blue-900" style={{ fontSize: '30px' }}>
        Whole day is blocked
      </div>
    )
  }

  return (
    <Container height="40rem">
      <DragSelectProvider>
        <TimeSlots
          currDate={date}
          timeOffs={timeOffs}
          scheduledAppointments={scheduledAppointments}
        />
      </DragSelectProvider>
    </Container>
  )
}

SchedulesView.whyDidYouRender = true

export default memo(SchedulesView)
