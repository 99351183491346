import { memo, useCallback, useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'

import { TopShadow, BottomShadow } from './Styles'
import { topShadowVariants, bottomShadowVariants } from './Animations'

const List = ({
  children,
  className,
  containerClassName,
  height = 0,
  initialBotShadow = false,
  topExtraSafe = false,
}) => {
  const containerRef = useRef(null)
  const listRef = useRef(null)
  const [hideTopShadow, setHideTopShadow] = useState(true)
  const [hideBottomShadow, setHideBottomShadow] = useState(true)

  const handleScroll = useCallback(
    e => {
      setHideTopShadow(e.target.scrollTop <= 20)
      setHideBottomShadow(
        e.target.scrollTop + (height || containerRef?.current?.offsetHeight) >=
          e.target.scrollHeight
      )
    },
    [setHideTopShadow, setHideBottomShadow, height, listRef, containerRef]
  )

  useEffect(() => {
    if (listRef && listRef?.current) {
      setHideBottomShadow(listRef?.current?.offsetHeight >= listRef?.current?.scrollHeight)
    }
  }, [listRef])
  // Initial
  useEffect(() => {
    if (initialBotShadow) setHideBottomShadow(false)
  }, [initialBotShadow])
  // During resize
  useEffect(() => {
    if (
      children?.length &&
      listRef?.current?.offsetHeight + height !== listRef?.current?.scrollHeight
    ) {
      setHideBottomShadow(false)
    }
  }, [height, listRef, children])

  return (
    <div className={`relative ${containerClassName}`} ref={containerRef}>
      <TopShadow className="" topExtraSafe={topExtraSafe}>
        <motion.div
          animate={hideTopShadow ? 'hide' : 'show'}
          variants={topShadowVariants}
          initial="hide"
          className="absolute h-6 -mt-px -top-12 bg-transparent left-2/4 transition"
          style={{
            boxShadow: 'rgb(47 76 203 / 10%) 0px 30px 30px 30px',
            transform: 'translate(-50%)',
            borderRadius: '100%',
            width: '55%',
          }}
        />
      </TopShadow>
      <div
        className={`overflow-auto relative z-0 ${className || ''}`}
        style={{
          transition: 'height 200ms ease-out',
          height: height || containerRef?.current?.offsetHeight,
        }}
        onScroll={handleScroll}
        ref={listRef}
      >
        {children}
      </div>
      <BottomShadow className="">
        <motion.div
          animate={hideBottomShadow ? 'hide' : 'show'}
          variants={bottomShadowVariants}
          initial="hide"
          className="absolute h-6 -mb-px -bottom-12 bg-transparent left-2/4 transition"
          style={{
            boxShadow: 'rgb(47 76 203 / 10%) 0px -30px 30px 30px',
            transform: 'translate(-50%)',
            borderRadius: '100%',
            width: '55%',
          }}
        />
      </BottomShadow>
    </div>
  )
}

List.whyDidYouRender = true

export default memo(List)
