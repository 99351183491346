import { CS_m } from 'back-end-api'

import { useMiscState } from './context'
import { audioVisuals } from './reducer'

export interface AudioVisualsProps {
  pickedUpAppointmentId: number | null
  appointmentSnoozedIds: number[]
}

export const useAudioVisualsState = (): AudioVisualsProps => {
  const miscState = useMiscState()

  return {
    pickedUpAppointmentId: miscState.audioVisuals?.pickedUpAppointmentId ?? null,
    appointmentSnoozedIds: miscState.audioVisuals?.appointmentSnoozedIds ?? [],
  }
}

const setAudioVisuals = (miscDispatch: any, appointmentSideBarProps: AudioVisualsProps): void => {
  miscDispatch({ type: audioVisuals, payload: appointmentSideBarProps })
}

export const setAppointmentPickedUp = (
  miscDispatch: any,
  state: AudioVisualsProps,
  appointment: CS_m.ModelsAppointment
): void => {
  setAudioVisuals(miscDispatch, {
    pickedUpAppointmentId: appointment.id,
    appointmentSnoozedIds: state.appointmentSnoozedIds.filter(id => id !== appointment.id),
  })
}

export const setAppointmentSnoozed = (
  miscDispatch: any,
  state: AudioVisualsProps,
  appointment: CS_m.ModelsAppointment
): void => {
  setAudioVisuals(miscDispatch, {
    pickedUpAppointmentId: state.pickedUpAppointmentId,
    appointmentSnoozedIds: [...state.appointmentSnoozedIds, appointment.id],
  })
}

export const setHangUpAppointment = (miscDispatch: any, state: AudioVisualsProps): void => {
  setAudioVisuals(miscDispatch, {
    pickedUpAppointmentId: null,
    appointmentSnoozedIds: state.appointmentSnoozedIds,
  })
}
