export default (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    patient: getText('Patient contact information', 'History_List_Table'),
    content: getText(
      "Here you'll find all the encounters happened with this patient so far.",
      'History_List_Table'
    ),
    complaints: getText('Complaint(s)', 'History_List_Table'),
    ongoingTitle: getText('Ongoing encounter', 'History_List_Table'),
    pastTitle: getText('Past encounters', 'History_List_Table'),
    empty: getText('There are no records to display', 'History_List_Table'),
    details: getText('Details', 'History_List_Table'),
    audioVisual: getText('Video consultation', 'History_List_Table'),
    completed: getText('Consultation finished', 'History_List_Table'),
    upcoming: getText('Consultation upcoming', 'History_List_Table'),
    cancelled: getText('Consultation time out', 'History_List_Table'),
    pending: getText('pending', 'History_List_Table'),
    accepted: getText('accepted', 'History_List_Table'),
    noRedFlags: getText('No red flags', 'History_List_Table'),
  }[key])
