import { memo, useCallback, useState } from 'react'
import DataTable, {
  IDataTableColumn,
  IDataTableConditionalRowStyles,
} from 'react-data-table-component'
import { useHistory } from 'react-router-dom'
import { CS, CS_m } from 'back-end-api'

import LoaderMini from 'components/common/loaders/mini'
import { useTranslation } from 'services/Translation'
import { useStatusTranslation, getStatusText, getStatusColor } from 'services/Registrations'

import { error, transparency } from 'styles/Colors'

import { STATUS, ROUTES } from 'Constants'
import Lock from 'assets/icons/Lock'
import ArrayCell from './ArrayCell'
import TimeCell from './TimeCell'
import PickUp from './PickUp'
import { ColorDiv, StatusContainer, LockContainer, Container } from './Styles'

export const conditionalRowStyles: IDataTableConditionalRowStyles<CS_m.ModelsRegistration>[] = [
  {
    when: (r: CS_m.ModelsRegistration) =>
      CS.getSummary(r)?.red_flags != null && CS.getSummary(r)!.red_flags!.length > 0,
    style: {
      boxShadow: `3px 6px 24px 0 ${error + transparency[10]} !important`,
    },
  },
  {
    when: (r: CS_m.ModelsRegistration): boolean =>
      CS.getSummary(r) != null && CS.isSummaryEmergency(CS.getSummary(r)!),
    style: {
      boxShadow: `0px 0px 16px 0 ${error + transparency[40]} !important`,
    },
  },
  {
    when: (r: CS_m.ModelsRegistration) => {
      return CS.getSummary(r)?.status === CS_m.ModelsSummaryStatus.inProgress
    },
    style: {
      cursor: 'default !important',
    },
  },
]

const ClickableCell = ({ children, onClick }: any) => (
  <div role="presentation" onClick={onClick} onKeyDown={onClick}>
    {children}
  </div>
)

interface ColumnStructure<T> {
  id: string
  name: string
  handleRowClicked: (r: T) => void
  cell: (r: T) => any
  sortable?: boolean
  sortFunction?: (a: T, b: T) => boolean
  center?: boolean
  minWidth?: string
  width?: string
}

function createColumn<T>(column: ColumnStructure<T>): IDataTableColumn<T> {
  return {
    id: column.id,
    name: column.name,
    selector: column.id,
    cell: (r: T) => (
      <ClickableCell
        onClick={() => {
          column.handleRowClicked(r)
        }}
      >
        {column.cell(r)}
      </ClickableCell>
    ),
    sortable: column.sortable,
    sortFunction: !column.sortFunction
      ? undefined
      : (a: T, b: T) => {
          return column.sortFunction!(a, b) ? 1 : -1
        },
    center: column.center,
    minWidth: column.minWidth,
    width: column.width,
  }
}

const columns = (
  t: (key: string) => string,
  tStatus: (key: string) => string,
  handleRowClicked: (r: CS_m.ModelsRegistration) => void
): IDataTableColumn<CS_m.ModelsRegistration>[] => [
  createColumn<CS_m.ModelsRegistration>({
    id: 'contact',
    name: t('patient'),
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => (
      <div
        data-tag="___react-data-table-allow-propagation___"
        data-cy="tableContact"
        className="w-full font-csc65 text-blue-900 flex justify-between items-center truncate"
        style={{ fontSize: '0.9375rem', paddingLeft: 24 }}
      >
        {`${r?.first_name} ${r?.last_name}`}
        <div className="w-px bg-grey-100" style={{ height: 33 }} />
      </div>
    ),
    sortable: true,
    sortFunction: (a, b) => a.last_name > b.first_name,
    minWidth: '255px',
  }),
  createColumn<CS_m.ModelsRegistration>({
    id: 'complaints',
    name: t('complaints'),
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => CS.getSummary(r)?.complaint?.name ?? 'Medical question',
    sortable: true,
    sortFunction: (a, b) =>
      (CS.getSummary(a)?.complaint?.name ?? 'Medical question') >
      (CS.getSummary(b)?.complaint?.name ?? 'Medical question'),
    center: true,
    minWidth: '143px',
  }),
  createColumn<CS_m.ModelsRegistration>({
    id: 'age',
    name: t('age'),
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => r.age,
    sortable: true,
    sortFunction: (a, b) => a.age < b.age,
    center: false,
    minWidth: '89px',
  }),
  createColumn<CS_m.ModelsRegistration>({
    id: 'gender',
    name: t('gender'),
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => <div className="pl-1.5">{r.gender}</div>,
    sortable: true,
    sortFunction: (a, b) => a.gender < b.gender,
    center: false,
  }),
  createColumn<CS_m.ModelsRegistration>({
    id: 'redFlags',
    name: t('redFlags'),
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => <ArrayCell data={CS.getSummary(r)?.red_flags ?? []} />,
    sortable: true,
    sortFunction: (a, b) =>
      (CS.getSummary(a)?.red_flags?.length ?? 0) > (CS.getSummary(b)?.red_flags?.length ?? 0),
    center: true,
    width: '150px',
  }),
  createColumn<CS_m.ModelsRegistration>({
    id: 'status',
    name: t('status'),
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => (
      <StatusContainer>
        <ColorDiv color={getStatusColor(r)}>{getStatusText(r, tStatus)}</ColorDiv>
      </StatusContainer>
    ),
    sortable: true,
    sortFunction: (a, b) => {
      const statusSortList = [
        CS.RegistrationStatus.AppointmentReady,
        CS.RegistrationStatus.AppointmentDeclined,
        CS.RegistrationStatus.AppointmentTimeout,
        CS.RegistrationStatus.SummarySubmitted,
        CS.RegistrationStatus.AppointmentPending,
        CS.RegistrationStatus.AppointmentAccepted,
        CS.RegistrationStatus.AppointmentInProgress,
        CS.RegistrationStatus.AppointmentCancelled,
        CS.RegistrationStatus.SummaryCancelled,
        CS.RegistrationStatus.AppointmentMissed,
        CS.RegistrationStatus.AppointmentDone,
        CS.RegistrationStatus.SummaryConsulted,
        CS.RegistrationStatus.SummaryTimeout,
        CS.RegistrationStatus.SummaryInProgress,
        CS.RegistrationStatus.Registered,
      ]

      const sortNumber =
        statusSortList.indexOf(CS.getStatus(a)) - statusSortList.indexOf(CS.getStatus(b))
      if (sortNumber === 0) {
        return new Date(a.created_at) > new Date(b.created_at)
      }
      return sortNumber > 0
    },
    width: '160px',
  }),
  createColumn<CS_m.ModelsRegistration>({
    id: 'time',
    name: t('time'),
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => <TimeCell time={r.created_at} />,
    sortable: true,
    sortFunction: (a, b) => new Date(a.created_at) > new Date(b.created_at),
    width: '150px',
  }),
  createColumn<CS_m.ModelsRegistration>({
    id: 'lock',
    name: '',
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => (
      <>
        {r.doctor_account_id && (
          <LockContainer>
            <Lock />
          </LockContainer>
        )}
      </>
    ),
    center: true,
  }),
  createColumn<CS_m.ModelsRegistration>({
    id: 'pickup',
    name: '',
    handleRowClicked,
    cell: (r: CS_m.ModelsRegistration) => (
      <>
        {(CS.getAppointment(CS.getSummary(r))?.status === CS_m.ModelsAppointmentStatus.ready ||
          CS.getAppointment(CS.getSummary(r))?.status ===
            CS_m.ModelsAppointmentStatus.inProgress) && (
          <PickUp appointment={CS.getAppointment(CS.getSummary(r))!} />
        )}
      </>
    ),
    center: true,
  }),
]

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    patient: getText('Patient contact information', 'Patient_List_Table'),
    complaints: getText('Complaint(s)', 'Patient_List_Table'),
    age: getText('Age', 'Patient_List_Table'),
    gender: getText('Gender', 'Patient_List_Table'),
    patientId: getText('Patient ID', 'Patient_List_Table'),
    redFlags: getText('Red Flags', 'Patient_List_Table'),
    status: getText('MIA Status', 'Patient_List_Table'),
    time: getText('Time of status', 'Patient_List_Table'),
    male: getText('Male', 'Patient_List_Table'),
    female: getText('Female', 'Patient_List_Table'),
    empty: getText('There are no records to display', 'Patient_List_Table'),
    adviceSent: getText('Advice sent', 'Patient_List_Table'),
    adviceRead: getText('Advice read', 'Patient_List_Table'),
    emergency: getText('Emergency', 'Patient_List_Table'),
  }[key])

interface TableProps {
  open: boolean
}

const Table = ({ open }: TableProps) => {
  const { t } = useTranslation(texts)
  const tStatus = useStatusTranslation()
  const [selectedColumn, setSelectedColumn] = useState<string | undefined>('status')
  const [sortDirection, setSortDirection] = useState(true)

  const { data, isLoading } = CS.useRegistrationsList({
    without_old_closed_summaries: 'true',
  })

  const history = useHistory()

  const handleRowClicked = useCallback(
    (r: CS_m.ModelsRegistration) => {
      const summary = CS.getSummary(r)
      if (!summary || summary.status === STATUS.inProgress.value) {
        return
      }
      history.push(`${ROUTES.PATIENT}${r.id}`)
    },
    [history]
  )

  const handleSort = (e: IDataTableColumn<CS_m.ModelsRegistration>, direction: string) => {
    setSelectedColumn(e.selector as string)
    setSortDirection(direction === 'asc')
  }

  return (
    <Container height={open ? '45rem' : '34rem'}>
      <DataTable
        noDataComponent={t('empty')}
        progressPending={isLoading}
        progressComponent={<LoaderMini />}
        columns={columns(t, tStatus, handleRowClicked)}
        data={data ? [...data] : []}
        noHeader
        fixedHeader
        fixedHeaderScrollHeight="unset"
        dense
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
        defaultSortField={selectedColumn}
        defaultSortAsc={sortDirection}
        onSort={handleSort}
      />
    </Container>
  )
}

Table.whyDidYouRender = true

export default memo(Table)
