export default ({ color = '#1C165B' }) => (
  <svg width="14.929" height="16.287" viewBox="0 0 14.929 16.287">
    <g transform="translate(-614.035 -155.776)">
      <circle
        cx="5.5"
        cy="5.5"
        r="5.5"
        transform="translate(615.035 156.776)"
        fill="transparent"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M-12349.9,191.939l3.657,3.5"
        transform="translate(12973.798 -24.793)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)
