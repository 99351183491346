import { memo } from 'react'

import { hp } from 'styles/Sizes'
import { Container } from './Styles'

const Separator = ({ type = 'vertical', size = hp(4), innerRef = undefined, ...props }) => {
  return <Container type={type} size={size} innerRef={innerRef} {...props} />
}

Separator.whyDidYouRender = true

export default memo(Separator)
