export const SCREEN_WIDTH = window.innerWidth
export const SCREEN_HEIGHT = window.innerHeight

// Compute CSS like units
export const VW = SCREEN_WIDTH / 100
export const VH = Math.round(SCREEN_HEIGHT / 100)
export const VMAX = Math.max(VW, VH)
export const VMIN = Math.min(VW, VH)
export const REM = 4 * VMIN
export const hp = value => value * VH
export const wp = value => value * VW
export const defaultPaddingTop = `padding-top: ${hp(13.9)}px;`
export const defaultPaddingLeft = `padding-left: 8.5%;`
export const defaultPaddingRight = `padding-right: 7.15%;`

export const SPACING = 1 * REM
