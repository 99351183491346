import styled from 'styled-components'
import { motion } from 'framer-motion'

import { textPrimary } from 'styles/Colors'

export const Container = styled(motion.div)`
  ${({ large }) =>
    !large
      ? `grid-area: info;`
      : `position: fixed;
  right: 0;
  top: 0;
  background-color: rgba(252, 252, 252, 0.3);
  `}

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 15;
`
export const PatientContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  width: 100%;
  padding-top: ${({ large }) => (large ? '10vh' : '0vh')};
  box-sizing: border-box;
  padding-left: 15%;
  padding-right: 15%;
`

export const BtnClose = styled.div`
  background-color: ${textPrimary};
  position: absolute;
  top: ${({ large }) => (large ? '50%' : 'calc(50% - 10vh)')};
  left: -40px;
  height: 40px;
  width: 40px;
  transform: translate(0, -50%);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  color: white;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`
