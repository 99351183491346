import { useMiscState } from './context'
import { appointmentSideBar } from './reducer'

export enum AppointmentSideBar {
  AppointmentDetails,
  EventManager,
  Calendar,
}

export interface AppointmentSideBarProps {
  type: AppointmentSideBar

  appointmentDetailsSideBarProps?: AppointmentDetailsSideBarProps
  eventManagerSideBarProps?: EventManagerSideBarProps
}

export interface AppointmentDetailsSideBarProps {
  registrationId: number
}

export interface EventManagerSideBarProps {
  timeSlots: any[]
}

export const appointmentSideBarDefault: AppointmentSideBarProps = {
  type: AppointmentSideBar.Calendar,
}

export const setAppointmentSideBar = (
  miscDispatch: any,
  appointmentSideBarProps: AppointmentSideBarProps
): void => {
  miscDispatch({ type: appointmentSideBar, payload: appointmentSideBarProps })
}

export const useAppointmentSideBarState = (): AppointmentSideBarProps => {
  const miscState = useMiscState()
  return miscState.appointmentSideBar ?? appointmentSideBarDefault
}
