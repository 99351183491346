import { primary } from 'styles/Colors'

export default ({ color = primary, size = 20, style }) => (
  <svg viewBox="0 0 252.28 265.32" width={size} height={size} style={style}>
    <path
      fill={color}
      d="M99.17,265.32a99.17,99.17,0,1,1,91-138.7h0a99.21,99.21,0,0,1-91,138.7ZM99.17,87A79.12,79.12,0,1,0,171.8,134.6h0A79.17,79.17,0,0,0,99.17,87Z"
    />
    <path
      fill={color}
      d="M159,104.15a10,10,0,0,1-7.1-17L218.33,20H151.42a10,10,0,0,1,0-20h90.86a10,10,0,0,1,7.11,17l-83.24,84.15A10,10,0,0,1,159,104.15Z"
    />
    <path
      fill={color}
      d="M242.28,112.69a10,10,0,0,1-10-10V10a10,10,0,0,1,20,0v92.69A10,10,0,0,1,242.28,112.69Z"
    />
  </svg>
)
