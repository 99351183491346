import { memo, useEffect, useRef } from 'react'
import { greyPrimary } from 'styles/Colors'
import { useDragSelect } from 'components/common/dragSelect'

import { useTranslation } from 'services/Translation'

import { Container, Inner, Element, TimeBlock } from './Styles'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    blockTimeCardErrorLabel: getText(
      'Error: an event is scheduled during one of the future blocked time',
      'Schedule_TimeSlots_Grid'
    ),
  }[key])

interface GridProps {
  timeSlots: any[]
  selectedTimeSlots: any[]
}

const Grid = ({ timeSlots, selectedTimeSlots }: GridProps) => {
  const elementsContainerRef = useRef<any>(null)
  const { formatDate: format } = useTranslation(texts)
  const dragSelect = useDragSelect()

  useEffect(() => {
    dragSelect.addSelectables(elementsContainerRef.current.children)
  }, [dragSelect])

  return (
    <>
      <Container>
        <Inner containerRef={elementsContainerRef} id="elements-container">
          {timeSlots.map(
            (
              {
                index,
                // TimeOffRelated
                doctorId,
                time,
                // AppointmentRelated
                patientId,
                formattedStatus,
              },
              i
            ) => {
              const color = patientId ? formattedStatus.color : doctorId ? greyPrimary : 'white'
              const xRatio = i % 3
              const position = {
                x: xRatio === 0 ? 'left' : xRatio === 2 ? 'right' : 'middle',
                y: i > timeSlots.length - 4 && timeSlots.length >= 4 ? 'top' : 'bottom',
              }

              const isFirstSelected = selectedTimeSlots.findIndex(x => x.index === index) === 0
              const isLastSelected =
                selectedTimeSlots.length > 0 &&
                selectedTimeSlots.findIndex(x => x.index === index) === selectedTimeSlots.length - 1

              const isSelected = selectedTimeSlots.find(x => x.index === index) !== undefined

              return (
                <Element
                  key={`cell-${time}`}
                  data-testid={`grid-cell-${index}`}
                  selected={isSelected}
                  isFirst={isFirstSelected}
                  isLast={isLastSelected}
                  data-cy={`gridElem_${index}`}
                  className="time-slot"
                >
                  <TimeBlock
                    isFirst={isFirstSelected}
                    isLast={isLastSelected}
                    innerPosition={position}
                    isSelected={isSelected}
                    backgroundColor={isSelected ? 'white' : color}
                  >
                    {format(new Date(time), 'HH:mm')}
                  </TimeBlock>
                </Element>
              )
            }
          )}
        </Inner>
      </Container>
    </>
  )
}

Grid.whyDidYouRender = true

export default memo(Grid)
