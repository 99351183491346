export const overlayVariants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

export const contentVariants = {
  initial: {
    opacity: 0,
    x: '100%',
    transition: {
      when: 'afterChildren',
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      when: 'beforeChildren',
    },
  },
}
