import { memo, useMemo } from 'react'

import { primary } from 'styles/Colors'
import Typography from 'components/common/Typography'
import { useTranslation } from 'services/Translation'

import { statusText } from 'Constants'
import { Info, Title, Container, DateContainer, Header } from './Styles'
import texts from './Texts'

const Row = ({ summary, onClick }) => {
  const { formatDate: format, t } = useTranslation(texts)
  const { t: statusT } = useTranslation(statusText)

  const statusTime = useMemo(() => new Date(summary.statusTime), [summary?.statusTime])

  return (
    <Container onClick={onClick}>
      <Header>
        <DateContainer>
          <Typography text={format(statusTime, 'dd MMMM yyyy')} size={14} color={primary} />
        </DateContainer>
        <Typography text={format(statusTime, 'HH:mm')} size={14} />
      </Header>
      <Info style={{ marginBottom: 12 }}>
        <Title>
          <Typography text={t('topic')} color={primary} size={12} />
        </Title>
        <Typography
          text={summary?.name}
          size={12}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 200,
            lineHeight: '14px',
          }}
        />
      </Info>
      <Info>
        <Title>
          <Typography text={t('status')} color={primary} size={12} />
        </Title>
        <Typography
          text={statusT(summary.formattedStatus?.label)}
          color={summary.formattedStatus?.color}
          size={12}
        />
      </Info>
    </Container>
  )
}

Row.whyDidYouRender = true

export default memo(Row)
