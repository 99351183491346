import { memo } from 'react'
import Typography from 'components/common/Typography'
import IdIcon from 'assets/icons/Id'
import ThermometerIcon from 'assets/icons/Thermometer'
import ClockIcon from 'assets/icons/Clock'
import CheckIcon from 'assets/icons/communication/Check'
import { useTranslation } from 'services/Translation'

import { EventText, Icon } from './Styles'

interface EventLabelProps {
  color: string
  appointmentId: number
  name: string
  onClick: () => void
  reason: string
  age: number
  time: Date
  colapsed: boolean
}

export const EventLabel = ({
  color,
  appointmentId,
  name,
  onClick,
  reason,
  age,
  time,
  colapsed,
}: EventLabelProps) => {
  const { formatDate: format } = useTranslation()

  if (colapsed) {
    return (
      <EventText colapsed={colapsed}>
        <div className="pl-2 pr-5 font-csc65">
          <Typography size={15} text="..." style={undefined} />
        </div>
      </EventText>
    )
  }

  return (
    <EventText key={appointmentId} colapsed={colapsed} onClick={onClick}>
      <div className="h-9 pr-1 flex flex-col justify-between absolute top-0 right-0 mt-1">
        <Icon color={color}>
          <CheckIcon className="w-1.5" color="white" />
        </Icon>
      </div>
      <div className="h-1/3 border border-grey-100 rounded-xl flex items-center pl-2 pr-5 font-csc65">
        <Typography size={15} className="font-csc65" text={name} style={undefined} />
      </div>
      <div className="h-2/3 flex flex-col pl-5 justify-center">
        <div className="flex items-center mb-2">
          <ClockIcon className="mr-2.5" />
          <Typography
            size={15}
            className="font-csc45"
            text={format(time, 'h:mm a')}
            style={undefined}
          />
        </div>
        <div className="flex items-center mb-2">
          <ThermometerIcon className="mr-2.5" />
          <Typography size={15} className="font-csc45" text={`${age} yrs`} style={undefined} />
        </div>
        <div className="flex items-center">
          <IdIcon className="mr-2.5" />
          <Typography size={15} className="font-csc45" text={reason} style={undefined} />
        </div>
      </div>
    </EventText>
  )
}

EventLabel.whyDidYouRender = true

export default memo(EventLabel)
