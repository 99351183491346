import { useEffect, useRef } from 'react'
import setIntervalAndExecute from 'utils/setIntervalAndExecute'

export const alreadyStartedLoops = {}

export default (
  fetchFunc,
  delay = 5000,
  { onError = () => {}, params = {}, isRunning = true, contextName } = {}
) => {
  const savedCallback = useRef(fetchFunc)

  useEffect(() => {
    savedCallback.current = fetchFunc
  }, [fetchFunc])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const fetchData = async () => {
      try {
        await savedCallback.current(params)
      } catch (e) {
        // eslint-disable-next-line no-unused-expressions
        onError?.(e)
      }
    }

    if (isRunning) {
      if (!alreadyStartedLoops[contextName]) {
        alreadyStartedLoops[contextName] = setIntervalAndExecute(fetchData, delay)
      }
    } else {
      clearInterval(alreadyStartedLoops[contextName])
      alreadyStartedLoops[contextName] = null
    }
  }, [delay, onError, params, contextName, isRunning])
}
