import { memo, useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'

import useDebouncedEffect from 'hooks/useDebouncedEffect'
import { useTranslation } from 'services/Translation'
import { searchPatient, usePatientsDispatch } from 'services/Patients'
import { useSummariesDispatch } from 'services/Summaries'
import { useAppointmentsDispatch } from 'services/Appointments'
import { getAccessToken } from 'services/Authentication'
import Close from 'components/common/buttons/Close/Component'

import Bar from './Bar'
import List from './List'
import TypeSelector from './TypeSelector'
import { TYPES } from './Tools'
import texts from './Texts'

const Search = ({ openHandler }) => {
  const history = useHistory()
  const { t } = useTranslation(texts)
  const actionsRef = useRef(null)
  const token = getAccessToken()
  const patientsDispatch = usePatientsDispatch()
  const summariesDispatch = useSummariesDispatch()
  const appointmentsDispatch = useAppointmentsDispatch()

  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState(TYPES.LASTNAME)
  const [results, setResults] = useState()

  const onSearchChange = useCallback(({ target: { value } }) => {
    setResults([])
    setSearchValue(value)
  }, [])
  const handleTypeChange = useCallback(
    newType => () => {
      setSearchValue('')
      setType(newType)
      setResults([])
    },
    []
  )

  useDebouncedEffect(
    async () => {
      if (searchValue !== '  -  -    ' && searchValue) {
        setLoading(true)
        const searchRes = await searchPatient(
          { token, history },
          {
            [type]: searchValue,
          },
          { patientsDispatch, summariesDispatch, appointmentsDispatch }
        )
        console.log('SEARCH RES: ', searchRes)
        if (searchRes?.length) {
          setResults(searchRes)
        }
        setLoading(false)
      }
    },
    [searchValue, type, token, summariesDispatch, patientsDispatch, appointmentsDispatch],
    700
  )

  return (
    <motion.div
      className="mt-8 pt-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div ref={actionsRef} className="relative px-16 bg-background z-20">
        <Close
          className="absolute top-0 left-0"
          style={{ transform: 'translate(-50%, -50%)' }}
          onClick={openHandler}
        />
        <div className="font-csc65 text-lg mb-4">{t('title')}</div>
        <div className="w-full h-px bg-grey-100 mb-10" />
        <Bar
          field={{
            value: searchValue,
            onChange: onSearchChange,
          }}
          placeholder={t(`${type}Placeholder`)}
          {...(type === TYPES.DATE
            ? {
                withMask: true,
                mask: '99-99-9999',
                maskChar: ' ',
                alwaysShowMask: false,
              }
            : {})}
        />
        <TypeSelector onChange={handleTypeChange} currentType={type} />
      </div>
      <List
        height={
          (actionsRef &&
            actionsRef?.current &&
            window.innerHeight -
              (actionsRef?.current?.offsetTop + actionsRef?.current?.offsetHeight)) ||
          0
        }
        data={results}
        searchValue={searchValue}
        loading={loading}
      />
    </motion.div>
  )
}

Search.whyDidYouRender = true

export default memo(Search)
