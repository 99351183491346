/* eslint-disable jsx-a11y/media-has-caption */
import { memo } from 'react'

import MicrophoneOn from 'assets/icons/communication/MicrophoneOn'
import CameraOn from 'assets/icons/communication/CameraOn'
import CameraOff from 'assets/icons/communication/CameraOff'
import Phone from 'assets/icons/communication/Phone'
import Chat from 'assets/icons/communication/Chat'
import ArrowIcon from 'assets/icons/arrows/Default'
import { useTranslation } from 'services/Translation'

import { FaMicrophoneSlash } from 'react-icons/fa'
import { ButtonsContainer, SmallBtn, CallBtn, MinimizeBtn } from './Styles'
import texts from './Texts'

interface VideoButtonsProps {
  hangUp: () => void
  microphoneActive: boolean
  videoActive: boolean
  handleOpenMessage: () => void
  selfVideoTrack: MediaStreamTrack | null
  handleResize: (size: string) => void
  handleDevice: (device: string) => void
  large: boolean
  showInfo: boolean
}

const className = (large: boolean, showInfo: boolean) => {
  if (large) {
    return `z-10 absolute bottom-0 left-0 mx-32 ${
      showInfo ? 'w-8/12' : 'w-10/12'
    } flex justify-center items-center`
  }
  return `relative flex justify-center items-center w-full`
}

const VideoButtons = ({
  hangUp,
  microphoneActive,
  videoActive,
  handleOpenMessage,
  selfVideoTrack,
  handleResize,
  handleDevice,
  large,
  showInfo,
}: VideoButtonsProps) => {
  const { t } = useTranslation(texts)

  return (
    <div className={className(large, showInfo)}>
      <ButtonsContainer>
        <SmallBtn onClick={handleDevice('camera')} disabled={!selfVideoTrack}>
          {videoActive && selfVideoTrack ? (
            <CameraOn style={undefined} />
          ) : (
            <CameraOff style={undefined} />
          )}
        </SmallBtn>
        <SmallBtn onClick={() => handleOpenMessage()} disabled={!selfVideoTrack}>
          <Chat />
        </SmallBtn>
        <SmallBtn onClick={handleDevice('micro')}>
          {microphoneActive ? (
            <MicrophoneOn style={undefined} />
          ) : (
            <FaMicrophoneSlash size="1.65em" />
          )}
        </SmallBtn>
        <CallBtn onClick={hangUp}>
          <Phone style={{ transform: 'rotate(135deg)' }} />
        </CallBtn>
      </ButtonsContainer>
      <MinimizeBtn onClick={() => handleResize('small')}>
        {t('minimize')}
        <ArrowIcon style={{ transform: 'rotate(45deg)', marginLeft: '0.5rem' }} color="#1C165B" />
      </MinimizeBtn>
    </div>
  )
}

VideoButtons.whyDidYouRender = true

export default memo(VideoButtons)
