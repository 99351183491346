import { textPrimary } from 'styles/Colors'

export default ({ style, color = textPrimary }) => (
  <svg style={style} width="14.043" height="21.946" viewBox="0 0 14.043 21.946">
    <g transform="translate(-78.336)">
      <g transform="translate(78.336 10.558)">
        <path
          d="M92.379,210.182a.775.775,0,1,0-1.549,0,5.472,5.472,0,1,1-10.945,0,.775.775,0,1,0-1.549,0,7.02,7.02,0,0,0,6.247,7v2.065H81.769a.774.774,0,1,0,0,1.549h7.176a.774.774,0,1,0,0-1.549H86.132v-2.065a7.02,7.02,0,0,0,6.247-7Z"
          transform="translate(-78.336 -209.408)"
          fill={color}
        />
      </g>
      <g transform="translate(81.046)">
        <path
          d="M136.407,0A4.319,4.319,0,0,0,132.1,4.311v7a4.311,4.311,0,0,0,8.622.026V4.311A4.319,4.319,0,0,0,136.407,0Z"
          transform="translate(-132.096)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)
