import { memo, useCallback, useState } from 'react'

import { hp } from 'styles/Sizes'
import Separator from 'components/common/Separator/'
import Link from 'components/common/Link'
import Logo from 'assets/icons/logo/Full'
import { useTranslation } from 'services/Translation'

import Privacy from './legal/Privacy'
import Terms from './legal/Terms'
import { Container, LogoContainer, LogoIcon, LogoSubtext, Links } from './Styles'
import texts from './Texts'

const Footer = () => {
  const { t } = useTranslation(texts)
  const [termsOpen, setTermsOpen] = useState(false)
  const [privacyOpen, setPrivacyOpen] = useState(false)

  const handleLegal = useCallback(
    ({ newState, legalType }) => () => {
      if (legalType === 'privacy') setPrivacyOpen(newState)
      else setTermsOpen(newState)
    },
    []
  )

  return (
    <Container>
      <div style={{ flex: 1 }} />
      <LogoContainer>
        <LogoIcon>
          <Logo />
        </LogoIcon>
        <LogoSubtext>{t('subtext')}</LogoSubtext>
      </LogoContainer>
      <Links>
        <Link
          onClick={handleLegal({ newState: true, legalType: 'privacy' })}
          label={t('privacy')}
        />
        <Separator size={hp(0.8)} />
        <Link onClick={handleLegal({ newState: true, legalType: 'terms' })} label={t('terms')} />
      </Links>
      <Privacy
        active={privacyOpen}
        onClose={handleLegal({ newState: false, legalType: 'privacy' })}
      />
      <Terms active={termsOpen} onClose={handleLegal({ newState: false, legalType: 'terms' })} />
    </Container>
  )
}

Footer.whyDidYouRender = true

export default memo(Footer)
