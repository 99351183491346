export default ({ color = '#1C165B', className }) => (
  <svg className={className} width="18.008" height="18.008" viewBox="0 0 18.008 18.008">
    <g transform="translate(0 0)">
      <path
        d="M16.078,1.93H14.149V.643a.643.643,0,1,0-1.286,0V1.93H5.145V.643a.643.643,0,0,0-1.286,0V1.93H1.93A1.93,1.93,0,0,0,0,3.858V16.078a1.93,1.93,0,0,0,1.93,1.93H16.078a1.93,1.93,0,0,0,1.93-1.93V3.858A1.93,1.93,0,0,0,16.078,1.93Zm.643,14.149a.643.643,0,0,1-.643.643H1.93a.643.643,0,0,1-.643-.643V7.717H16.721Zm0-9.647H1.286V3.856a.643.643,0,0,1,.643-.643h1.93V4.5a.643.643,0,0,0,1.286,0V3.216h7.717V4.5a.643.643,0,0,0,1.286,0V3.216h1.93a.643.643,0,0,1,.643.643V6.435Z"
        transform="translate(0 0)"
        fill={color}
      />
    </g>
    <g transform="translate(3.216 9.004)">
      <g transform="translate(0 0)">
        <path
          d="M87.263,238.933H85.976a.643.643,0,1,0,0,1.286h1.286a.643.643,0,0,0,0-1.286Z"
          transform="translate(-85.333 -238.933)"
          fill={color}
        />
      </g>
    </g>
    <g transform="translate(7.718 9.004)">
      <g transform="translate(0 0)">
        <path
          d="M206.73,238.933h-1.286a.643.643,0,0,0,0,1.286h1.286a.643.643,0,1,0,0-1.286Z"
          transform="translate(-204.801 -238.933)"
          fill={color}
        />
      </g>
    </g>
    <g transform="translate(12.221 9.004)">
      <g transform="translate(0 0)">
        <path
          d="M326.2,238.933h-1.286a.643.643,0,1,0,0,1.286H326.2a.643.643,0,1,0,0-1.286Z"
          transform="translate(-324.268 -238.933)"
          fill={color}
        />
      </g>
    </g>
    <g transform="translate(3.216 11.577)">
      <path
        d="M87.263,307.2H85.976a.643.643,0,1,0,0,1.286h1.286a.643.643,0,0,0,0-1.286Z"
        transform="translate(-85.333 -307.2)"
        fill={color}
      />
    </g>
    <g transform="translate(7.718 11.577)">
      <g transform="translate(0)">
        <path
          d="M206.73,307.2h-1.286a.643.643,0,1,0,0,1.286h1.286a.643.643,0,1,0,0-1.286Z"
          transform="translate(-204.801 -307.2)"
          fill={color}
        />
      </g>
    </g>
    <g transform="translate(12.221 11.577)">
      <g transform="translate(0)">
        <path
          d="M326.2,307.2h-1.286a.643.643,0,1,0,0,1.286H326.2a.643.643,0,1,0,0-1.286Z"
          transform="translate(-324.268 -307.2)"
          fill={color}
        />
      </g>
    </g>
    <g transform="translate(3.216 14.149)">
      <g transform="translate(0 0)">
        <path
          d="M87.263,375.467H85.976a.643.643,0,1,0,0,1.286h1.286a.643.643,0,0,0,0-1.286Z"
          transform="translate(-85.333 -375.467)"
          fill={color}
        />
      </g>
    </g>
    <g transform="translate(7.718 14.149)">
      <g transform="translate(0 0)">
        <path
          d="M206.73,375.467h-1.286a.643.643,0,0,0,0,1.286h1.286a.643.643,0,1,0,0-1.286Z"
          transform="translate(-204.801 -375.467)"
          fill={color}
        />
      </g>
    </g>
    <g transform="translate(12.221 14.149)">
      <g transform="translate(0 0)">
        <path
          d="M326.2,375.467h-1.286a.643.643,0,1,0,0,1.286H326.2a.643.643,0,1,0,0-1.286Z"
          transform="translate(-324.268 -375.467)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)
