import { memo } from 'react'

import DoubleTickIcon from 'assets/icons/DoubleTick'
import { formatUpdates } from 'components/home/Sidebar/LiveUpdates/List/Tools'
import { useTranslation } from 'services/Translation'
import useClock from 'hooks/useClock'
import List from 'components/common/List'

import Element from './Element'
import texts from './Texts'

const NotifCard = ({ data, onExpand }) => {
  const { t, formatDate: format } = useTranslation(texts)
  const currentDate = useClock()
  const formattedUpdates = formatUpdates(data, currentDate, t, format)

  return (
    <div className="flex flex-col" style={{ width: '28rem', height: '33rem' }}>
      <div className="flex justify-between items-center h-12 px-5">
        <div className="font-csc65 text-lg text-blue-900">{t('title')}</div>
        <div className="flex items-center font-csc55 text-xs text-purple-200 cursor-pointer">
          <DoubleTickIcon className="mr-5" />
          {t('markAsRead')}
        </div>
      </div>
      <List
        containerClassName="flex-1"
        className="px-5"
        initialBotShadow={!!formattedUpdates && formattedUpdates?.length > 3}
      >
        {!!formattedUpdates?.length &&
          [...formattedUpdates]?.map((el, idx) => (
            <Element
              key={idx}
              type={el.type}
              link={el.link}
              name={el.name}
              action={el.action}
              time={el.time}
              fullTime={el.fullTime}
            />
          ))}
      </List>
      <div
        className="font-csc85 text-blue-900 text-sm border-t border-grey-100 underline h-10 flex items-center justify-center -mb-2 pt-5 cursor-pointer mx-5"
        onClick={onExpand}
        tabIndex={0}
        role="button"
        onKeyDown={onExpand}
      >
        {t('expand')}
      </div>
    </div>
  )
}

NotifCard.whyDidYouRender = true

export default memo(NotifCard)
