import { useState, memo } from 'react'
import Trash from 'assets/icons/Trash'
import OptionButton from 'components/common/buttons/Option'
import UnderlineBtn from 'components/common/buttons/Underline'
import IconBtn from 'components/common/buttons/Icon'
import TextInput from 'components/common/inputs/Text'

interface TemplatesCardProps {
  options: string[]
  footerLabel: string
  footerCallback: () => void
  onTemplateSelect: (id: number) => void
  onTemplateDelete: (id: number) => void
}

const TemplatesCard = ({
  options,
  footerLabel,
  footerCallback,
  onTemplateSelect,
  onTemplateDelete,
}: TemplatesCardProps) => {
  const [filter, setFilter] = useState('')

  return (
    <>
      <TextInput
        noBorder
        className="font-csc65"
        noPadding
        inputStyle={{ fontSize: '0.9rem' }}
        placeholderStyle={{ fontSize: '0.9rem' }}
        placeholder="Filter"
        field={{
          value: filter,
          onChange: ({ target: { value } }: any) => setFilter(value),
        }}
        style={undefined}
        containerStyle={undefined}
        label={undefined}
        inputRef={undefined}
      />
      <div
        className="w-60 font-csc65 text-medium pb-6 border-b border-t border-grey-100"
        style={{ height: 300, overflow: 'scroll' }}
      >
        {!!options.length &&
          options
            .filter(title => title.includes(filter))
            .map((title, i) => (
              <div className="flex justify-between items-center mt-3 ">
                <OptionButton
                  bold
                  label={title}
                  onClick={() => onTemplateSelect(i + 1)}
                  previousIcon={undefined}
                  nextIcon={undefined}
                  style={undefined}
                />
                <div
                  style={{ borderLeftWidth: '1.5px' }}
                  className="ml-3 pl-6 border-l border-grey-100 pr-3"
                >
                  <IconBtn
                    icon={Trash}
                    onClick={() => onTemplateDelete(i + 1)}
                    style={{
                      boxShadow: 'none',
                      margin: 0,
                      padding: 0,
                      height: 'auto',
                      width: 'auto',
                    }}
                    data-cy="btnAV"
                    innerRef={undefined}
                    color={undefined}
                  />
                </div>
              </div>
            ))}
      </div>
      {footerLabel && (
        <div className="flex justify-center items-center pt-3">
          <UnderlineBtn
            className="px-2 rounded py-1.5  hover:bg-purple-200-o25"
            bold
            onClick={() => footerCallback()}
            withNext
            label={footerLabel}
            innerRef={undefined}
            previousIcon={undefined}
            nextIcon={undefined}
            style={undefined}
          />
        </div>
      )}
    </>
  )
}

TemplatesCard.whyDidYouRender = true

export default memo(TemplatesCard)
