export default ({ className, color = '#1c165b' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.291"
    height="14.278"
    viewBox="0 0 14.291 14.278"
    className={className}
  >
    <path
      id="edit"
      d="M12.52,14.277H1.8a1.722,1.722,0,0,1-1.263-.523A1.718,1.718,0,0,1,.015,12.49V1.772A1.7,1.7,0,0,1,.538.516,1.733,1.733,0,0,1,1.8,0H9.84L8.054,1.771H2.694a.852.852,0,0,0-.635.265.869.869,0,0,0-.258.628V11.6a.852.852,0,0,0,.265.635.869.869,0,0,0,.628.258h8.932a.882.882,0,0,0,.893-.893V6.238l1.787-1.787V12.49a1.787,1.787,0,0,1-1.787,1.787ZM4.481,7.8l2.01,2.01-2.9.893Zm2.9,1.34L5.151,6.922l6.685-6.7a.827.827,0,0,1,1.13,0l1.1,1.117a.774.774,0,0,1,.23.551.746.746,0,0,1-.23.565Z"
      transform="translate(-0.015 0.001)"
      fill={color}
    />
  </svg>
)
