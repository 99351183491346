/* eslint-disable */

import {
  TitleH2,
  TitleH3,
  TitleH4,
  Paragraph,
  Strong,
  List,
  Element,
  Link,
  Info,
} from '../../Styles'

export default () => (
  <>
    <TitleH2>MIA Suite Privacy Policy</TitleH2>
    <Paragraph>
      This Privacy Policy describes Our policies and procedures on the collection, use and
      disclosure of Your information when You use the Service and tells You about Your privacy
      rights and how the law protects You.
    </Paragraph>
    <Paragraph>
      We use Your Personal data to provide and improve the Service. By using the Service, You agree
      to the collection and use of information in accordance with this Privacy Policy.
    </Paragraph>
    <TitleH3>Interpretation and Definitions</TitleH3>
    <TitleH4>Interpretation</TitleH4>
    <Paragraph>
      The words of which the initial letter is capitalized have meanings defined under the following
      conditions. The following definitions shall have the same meaning regardless of whether they
      appear in singular or in plural.
    </Paragraph>
    <TitleH4>Definitions</TitleH4>
    <Paragraph>For the purposes of this Privacy Policy:</Paragraph>
    <Paragraph>
      <Strong>You</Strong> means the individual accessing or using the Service, or the company, or
      other legal entity on behalf of which such individual is accessing or using the Service, as
      applicable. Under GDPR (General Data Protection Regulation), You can be referred to as the
      Data Subject or as the User as you are the individual using the Service.
    </Paragraph>
    <Paragraph>
      <Strong>Company</Strong> (referred to as either "the Company", "We", "Us" or "Our" in this
      Agreement) refers to Medvice Enterprises B.V., Molengraaffsingel 12, 2629JD, Delft. For the
      purpose of the GDPR, the Company is the Data Controller.
    </Paragraph>
    <Paragraph>
      <Strong>Application</Strong> means the software program provided by the Company downloaded by
      You on any electronic device, named the MIA suite. The MIA Suite consists of the following
      applications MIA API, the MIA mobile app for patients, the MIA Dashboard for medical
      professionals and the MIA Editor.
    </Paragraph>
    {/********************* PAGE 2 ********************/}
    <Paragraph>
      <Strong>Affiliate</Strong> means an entity that controls, is controlled by or is under common
      control with a party, where "control" means ownership of 50% or more of the shares, equity
      interest or other securities entitled to vote for election of directors or other managing
      authority.
    </Paragraph>
    <Paragraph>
      <Strong>Account</Strong> means a unique account created for You to access our Service or parts
      of our Service.
    </Paragraph>
    <Paragraph>
      <Strong>Service</Strong> refers to the Application.
    </Paragraph>
    <Paragraph>
      <Strong>Country</Strong> refers to: Netherlands
    </Paragraph>
    <Paragraph>
      <Strong>Service Provider</Strong> means any natural or legal person who processes the data on
      behalf of the Company. It refers to third-party companies or individuals employed by the
      Company to facilitate the Service, to provide the Service on behalf of the Company, to perform
      services related to the Service or to assist the Company in analyzing how the Service is used.
      For the purpose of the GDPR, Service Providers are considered Data Processors.
    </Paragraph>
    <Paragraph>
      <Strong>Medical Data</Strong> is collected and stored temporarily when using the Service.
      Medical Data may include information such as written medical information about the given
      health issue gathered by the Service. Tracing identity with these medical data, without access
      to other (as described under Personal Data) personal data is virtually impossible.
    </Paragraph>
    <Paragraph>
      <Strong>Third-party Social Media Service</Strong> refers to any website or any social network
      website through which a User can log in or create an account to use the Service.
    </Paragraph>
    <Paragraph>
      <Strong>Personal Data</Strong> is any information that relates to an identified or
      identifiable individual. For the purposes for GDPR, Personal Data means any information
      relating to You such as a name, an identification number, location data, online identifier or
      to one or more factors specific to the physical, physiological, genetic, mental, economic,
      cultural or social identity.
    </Paragraph>
    <Paragraph>
      <Strong>Device</Strong> means any device that can access the Service such as a computer, a
      cellphone or a digital tablet.
    </Paragraph>
    <Paragraph>
      <Strong>Usage Data</Strong> refers to data collected automatically, either generated by the
      use of the Service or from the Service infrastructure itself (for example, the duration of a
      page visit).
    </Paragraph>
    <Paragraph>
      <Strong>Data Controller</Strong>, for the purposes of the GDPR (General Data Protection
      Regulation), refers to the Company as the legal person which alone or jointly with others
      determines the purposes and means of the processing of Personal Data.
    </Paragraph>
    {/********************* PAGE 3 ********************/}
    <TitleH3>Collecting and Using Your Personal Data</TitleH3>
    <TitleH4>Types of Data Collected</TitleH4>
    <TitleH4>Personal Data</TitleH4>
    <Paragraph>
      While using Our Service, We may ask You to provide Us with certain personally identifiable
      information that can be used to contact or identify You. Personally identifiable information
      may include, but is not limited to:
    </Paragraph>
    <List>
      <Element>Email address</Element>
      <Element>First name and last name</Element>
      <Element>Phone number</Element>
      <Element>Usage Data (patient users only).</Element>
      <Element>Gender (at birth) (patient users only).</Element>
      <Element>Date of Birth (patient users only).</Element>
      <Element>Height (patient users only).</Element>
      <Element>Weight (patient users only).</Element>
      <Element>Medical Data (patient users only).</Element>
      <Element>Unique ID number (BIG, ABG, SSN, Insurance no.).</Element>
    </List>
    <TitleH4>Usage Data</TitleH4>
    <Paragraph>Usage Data is collected automatically when using the Service.</Paragraph>
    <Paragraph>
      Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
      address), browser type, browser version, the pages of our Service that You visit, the time and
      date of Your visit, the time spent on those pages, unique device identifiers and other
      diagnostic data.
    </Paragraph>
    <Paragraph>
      When You access the Service by or through a mobile device, We may collect certain information
      automatically, including, but not limited to, the type of mobile device You use, Your mobile
      device unique ID, the IP address of Your mobile device, Your mobile operating system, the type
      of mobile Internet browser You use, unique device identifiers and other diagnostic data.
    </Paragraph>
    <Paragraph>
      We may also collect information that Your browser sends whenever You visit our Service or when
      You access the Service by or through a mobile device.
    </Paragraph>
    <TitleH4>Information Collected while Using the Application</TitleH4>
    <Paragraph>
      While using Our Application, in order to provide features of Our Application, We may collect,
      with your prior permission:
    </Paragraph>
    {/********************* PAGE 4 ********************/}
    <Paragraph>Information regarding your location</Paragraph>
    <Paragraph>
      We use this information to provide features of Our Service, to improve and customize Our
      Service. The information may be uploaded to a Service Provider's server or it be simply stored
      on Your device.
    </Paragraph>
    <Paragraph>
      You can enable or disable access to this information at any time, through Your Device
      settings.
    </Paragraph>
    <TitleH3>Use of Your Personal Data</TitleH3>
    <Paragraph>The Company may use Personal Data for the following purposes:</Paragraph>
    <Element>
      <Strong>To provide and maintain our Service</Strong>, including to monitor the usage of our
      Service.
    </Element>
    <Element>
      <Strong>To manage Your Account</Strong>: to manage Your registration as a user of the Service.
      The Personal Data You provide can give You access to different functionalities of the Service
      that are available to You as a registered user.
    </Element>
    <Element>
      <Strong>To contact You</Strong>: To contact You by email, telephone calls, SMS, or other
      equivalent forms of electronic communication, such as a mobile application's push
      notifications regarding updates or informative communications related to the functionalities,
      products or contracted services, including the security updates, when necessary or reasonable
      for their implementation.
    </Element>
    <Element>
      <Strong>To manage Your requests</Strong>: To attend and manage Your requests to Us.
    </Element>
    <Paragraph>We may share your personal information in the following situations:</Paragraph>
    <Element>
      <Strong>With Service Providers</Strong>: We may share Your personal information with Service
      Providers to monitor and analyze the use of our Service, to contact You.
    </Element>
    <Element>
      <Strong>For Business transfers</Strong>: We may share or transfer Your personal information in
      connection with, or during negotiations of, any merger, sale of Company assets, financing, or
      acquisition of all or a portion of our business to another company.
    </Element>
    <Element>
      <Strong>With Affiliates</Strong>: We may share Your information with Our affiliates, in which
      case we will require those affiliates to honor this Privacy Policy. Affiliates include Our
      parent company and any other subsidiaries, joint venture partners or other companies that We
      control or that are under common control with Us.
    </Element>
    {/********************* PAGE 5 ********************/}
    <Element>
      <Strong>With Affiliated Clinics</Strong> (patient users only).: We may share the following
      Personal Data with a health professional of an affiliated clinic for identity verification:
    </Element>
    <List>
      <Paragraph>First name and last name</Paragraph>
      <Paragraph>Phone number</Paragraph>
      <Paragraph>Gender (at birth)</Paragraph>
      <Paragraph>Date of Birth</Paragraph>
      <Paragraph>Height</Paragraph>
      <Paragraph>Weight</Paragraph>
      <Paragraph>Unique ID number (SSN, Insurance no.)</Paragraph>
    </List>
    <Paragraph>
      Sharing this Personal Data happens only in line with a signed Data Processing Agreement
      between the Affiliated Clinic and Us.
    </Paragraph>
    <TitleH3>Retention of Your Personal Data</TitleH3>
    <Paragraph>
      The Company will retain Your Personal and Medical Data (patient users only) only for as long
      as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your
      Personal Data and Medical Data (patient users only) to the extent necessary to comply with our
      legal obligations (for example, if we are required to retain your data to comply with
      applicable laws), resolve disputes, and enforce our legal agreements and policies.
    </Paragraph>
    <Paragraph>
      The Company will also retain Usage Data for internal analysis purposes. Usage Data is
      generally retained for a shorter period of time, except when this data is used to strengthen
      the security or to improve the functionality of Our Service, or We are legally obligated to
      retain this data for longer time periods.
    </Paragraph>
    <Paragraph>
      By using the Service, you leave data with us for a definite period. This is achieved by
      obtaining Your Information after using the Service. Your Information gathered by the Service
      is stored by Us.
    </Paragraph>
    <Paragraph>
      <Strong>For more detail regarding retention of patient data please see:</Strong>
      <Link href="https://www.medvice.io/en/miasuiteprivacypolicy" target="_blank">
        www.medvice.io/en/miasuiteprivacypolicy
      </Link>{' '}
      This means that all patient Medical Data and Personal Data will be fully anonymized in a
      maximum of 12 months after the (e-) consultation has been completed. The stored Medical Data
      will -with the exception of the liaised physician- not be provided to third parties if there
      is no full anonymization of the patient Medical Data unless Medvice is required to do so by
      law or regulation. All patient Medical Data en Personal Data will be handled in line with the
      liaised physician in the setting of a doctor - patient relationship as described in the Dutch
      Medical Treatment Agreement Act (WGBO) or equivalent non-Dutch Acts. The Company uses all
      consented information collected for the purpose of Medvice Diagnostic Machine Learning
      development. All patient Medical Data and Personal Data can only be obtained and stored with
      prior informed consent from the patient. Giving consent is done digitally within the
      environment of the Service, referring to this privacy policy.
    </Paragraph>
    {/********************* PAGE 6 ********************/}
    <Paragraph>
      <Strong>For medical personnel</Strong>: The Application stores all the data referred to in
      paragraph “Personal Data” during the useful life of The Application, which is determined on
      the basis of a purchased MIA Suite license agreement. Termination of this license agreement
      will lead to the immediate removal of all data referred to in paragraph “Personal Data”.
    </Paragraph>
    <TitleH3>Transfer of Your Personal Data and Medical Data</TitleH3>
    <Paragraph>
      Your information, including Personal Data and Medical Data (patients only), is processed at
      the Company's operating offices and / or in any other places where the parties involved in the
      processing are located. It means that this information may be transferred to — and maintained
      on — computers located outside of Your state, province, country, or other governmental
      jurisdiction where the data protection laws may differ than those from Your jurisdiction. For
      EU users, the transfer of Personal and Medical Data will not surpass EU borders if not
      compliant with EU data storage and GDPR guidelines.
    </Paragraph>
    <Paragraph>
      Your consent to this Privacy Policy followed by Your submission of such information represents
      Your agreement to that transfer.
    </Paragraph>
    <Paragraph>
      The Company will take all steps reasonably necessary to ensure that Your data is treated
      securely and in accordance with this Privacy Policy and no transfer of Your Personal Data and
      Medical Data will take place to an organization or a country unless there are adequate
      controls in place including the security of Your data and other personal information.
    </Paragraph>
    <TitleH3>Disclosure of Your Personal Data</TitleH3>
    <TitleH4>Business Transactions</TitleH4>
    <Paragraph>
      If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
      transferred. We will provide notice before Your Personal Data is transferred and becomes
      subject to a different Privacy Policy.
    </Paragraph>
    {/********************* PAGE 7 ********************/}
    <TitleH4>Law enforcement</TitleH4>
    <Paragraph>
      Under certain circumstances, the Company may be required to disclose Your Personal Data if
      required to do so by law or in response to valid requests by public authorities (e.g. a court
      or a government agency).
    </Paragraph>
    <TitleH4>Other legal requirements</TitleH4>
    <Paragraph>
      The Company may disclose Your Personal Data in the good faith belief that such action is
      necessary to:
    </Paragraph>
    <Element>Comply with a legal obligation</Element>
    <Element>Protect and defend the rights or property of the Company</Element>
    <Element>Prevent or investigate possible wrongdoing in connection with the Service</Element>
    <Element>Protect the personal safety of Users of the Service or the public</Element>
    <Element>Protect against legal liability</Element>
    <TitleH3>Security of Your Personal Data</TitleH3>
    <Paragraph>
      The security of Your Personal Data is important to Us, but remember that no method of
      transmission over the Internet, or method of electronic storage is 100% secure. While We
      strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee
      its absolute security.
    </Paragraph>
    <TitleH3>Detailed Information on the Processing of Your Personal Data</TitleH3>
    <Paragraph>
      Service Providers have access to Your Personal Data only to perform their tasks on Our behalf
      and are obligated not to disclose or use it for any other purpose.
    </Paragraph>
    <TitleH4>Analytics</TitleH4>
    <Paragraph>
      We may use third-party Service providers to monitor and analyze the use of our Service.
    </Paragraph>
    <Paragraph>Google Analytics</Paragraph>
    {/********************* PAGE 8 ********************/}
    <Paragraph>
      Google Analytics is a web analytics service offered by Google that tracks and reports website
      traffic. Google uses the data collected to track and monitor the use of our Service. This data
      is shared with other Google services. Google may use the collected data to contextualise and
      personalise the ads of its own advertising network. You may opt-out of certain Google
      Analytics features through your mobile device settings, such as your device advertising
      settings or by following the instructions provided by Google in their Privacy Policy:
      https://policies.google.com/privacy. For more information on the privacy practices of Google,
      please visit the Google Privacy & Terms web page: https://policies.google.com/privacy
    </Paragraph>
    {/********************* PAGE 9 ********************/}
    <TitleH3>Usage, Performance and Miscellaneous</TitleH3>
    <Paragraph>
      We may use third-party Service Providers to provide better improvement of our Service.
    </Paragraph>
    <TitleH4>Okta</TitleH4>
    <Paragraph>
      Their Privacy Policy can be viewed at{' '}
      <Link href="https://www.okta.com/" target="_blank">
        https://www.okta.com/
      </Link>
    </Paragraph>
    <TitleH4>Twilio Platform</TitleH4>
    <Paragraph>
      Their Privacy Policy can be viewed at{' '}
      <Link href="https://www.twilio.com/legal/privacy" target="_blank">
        https://www.twilio.com/legal/privacy
      </Link>
    </Paragraph>
    <TitleH4>IBM Cloud Platform</TitleH4>
    <Paragraph>
      Their Privacy Policy can be viewed at{' '}
      <Link href="https://www.ibm.com/cloud" target="_blank">
        https://www.ibm.com/cloud
      </Link>
    </Paragraph>
    <TitleH3>GDPR Privacy</TitleH3>
    <TitleH4>Legal Basis for Processing Personal Data under GDPR</TitleH4>
    <Paragraph>We may process Personal Data under the following conditions:</Paragraph>
    <Element>
      <Strong>Consent</Strong>: You have given Your consent for processing Personal Data for one or
      more specific purposes.
    </Element>
    <Element>
      <Strong>Performance of a contract</Strong>
      Provision of Personal Data is necessary for the performance of an agreement with You and/or
      for any pre-contractual obligations thereof.
    </Element>
    <Element>
      <Strong>Legal obligations</Strong>: Processing Personal Data is necessary for compliance with
      a legal obligation to which the Company is subject.
    </Element>
    <Element>
      <Strong>Vital interests</Strong>: Processing Personal Data is necessary in order to protect
      Your vital interests or of another natural person.
    </Element>
    <Element>
      <Strong>Public interests</Strong>: Processing Personal Data is related to a task that is
      carried out in the public interest or in the exercise of official authority vested in the
      Company
    </Element>
    <Element>
      <Strong>Legitimate interests</Strong>: Processing Personal Data is necessary for the purposes
      of the legitimate interests pursued by the Company.
    </Element>
    {/********************* PAGE 10 ********************/}
    <Paragraph>
      In any case, the Company will gladly help to clarify the specific legal basis that applies to
      the processing, and in particular whether the provision of Personal Data is a statutory or
      contractual requirement, or a requirement necessary to enter into a contract.
    </Paragraph>
    <TitleH3>Your Rights under the GDPR</TitleH3>
    <Paragraph>
      The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee
      You can exercise Your rights.
    </Paragraph>
    <Paragraph>
      You have the right under this Privacy Policy, and by law if You are within the EU, to:
    </Paragraph>
    <Element>
      <Strong>Request access to Your Personal Data.</Strong>The right to access, update or delete
      the information We have on You. Whenever made possible, you can access, update or request
      deletion of Your Personal Data directly within Your account settings section. If you are
      unable to perform these actions yourself, please contact Us to assist You. This also enables
      You to receive a copy of the Personal Data We hold about You.
    </Element>
    <Element>
      <Strong>Request correction of the Personal Data that We hold about You.</Strong>You have the
      right to to have any incomplete or inaccurate information We hold about You corrected.
    </Element>
    <Element>
      <Strong>Object to processing of Your Personal Data.</Strong>This right exists where We are
      relying on a legitimate interest as the legal basis for Our processing and there is something
      about Your particular situation, which makes You want to object to our processing of Your
      Personal Data on this ground. You also have the right to object where We are processing Your
      Personal Data for direct marketing purposes.
    </Element>
    <Element>
      <Strong>Request erasure of Your Personal Data.</Strong>You have the right to ask Us to delete
      or remove Personal Data when there is no good reason for Us to continue processing it.
    </Element>
    <Element>
      <Strong>Request the transfer of Your Personal Data.</Strong>We will provide to You, or to a
      third-party You have chosen, Your Personal Data in a structured, commonly used,
      machine-readable format. Please note that this right only applies to automated information
      which You initially provided consent for Us to use or where We used the information to perform
      a contract with You.
    </Element>
    <Element>
      <Strong>Withdraw Your consent.</Strong>You have the right to withdraw Your consent on using
      your Personal Data. If You withdraw Your consent, We may not be able to provide You with
      access to certain specific functionalities of the Service.
    </Element>
    {/********************* PAGE 11 ********************/}
    <Paragraph>Exercising of Your GDPR Data Protection Rights</Paragraph>
    <Paragraph>
      You may exercise Your rights of access, rectification, cancellation and opposition by
      contacting Us. Please note that we may ask You to verify Your identity before responding to
      such requests. If You make a request, We will try our best to respond to You as soon as
      possible.
    </Paragraph>
    <Paragraph>
      You have the right to complain to a Data Protection Authority about Our collection and use of
      Your Personal Data. For more information, if You are in the European Economic Area (EEA),
      please contact Your local data protection authority in the EEA.
    </Paragraph>
    <TitleH3>Links to Other Websites</TitleH3>
    <Paragraph>
      Our Service may contain links to other websites that are not operated by Us. If You click on a
      third party link, You will be directed to that third party's site. We strongly advise You to
      review the Privacy Policy of every site You visit.
    </Paragraph>
    <Paragraph>
      We have no control over and assume no responsibility for the content, privacy policies or
      practices of any third party sites or services.
    </Paragraph>
    <TitleH3>Changes to this Privacy Policy</TitleH3>
    <Paragraph>
      We may update our Privacy Policy from time to time. We will notify You of any changes by
      posting the new Privacy Policy on this page.
    </Paragraph>
    <Paragraph>
      We will let You know via email and/or a prominent notice on Our Service, prior to the change
      becoming effective and update the "Last updated" date at the top of this Privacy Policy.
    </Paragraph>
    <Paragraph>
      You are advised to review this Privacy Policy periodically for any changes. Changes to this
      Privacy Policy are effective when they are posted on this page.
    </Paragraph>
    <TitleH3>Contact Us</TitleH3>
    <Paragraph>
      If you have any questions about this Privacy Policy, You can contact us: By email:
      info@medvice.io
    </Paragraph>
  </>
)
