import { memo, useCallback, useState } from 'react'

import { Container, Tooltip, Label } from './Styles'

const IconBtn = ({
  icon: Icon,
  style = {},
  onClick = () => {},
  tooltip = '',
  disabled = false,
  innerRef,
  hidden = false,
  label = '',
  color,
  squared = false,
  ...props
}) => {
  const [hovering, setHovering] = useState(false)

  const handleHover = useCallback(type => () => setHovering(type), [])

  return (
    <Container
      style={style}
      onClick={onClick}
      onHoverStart={disabled ? undefined : handleHover(true)}
      onHoverEnd={disabled ? undefined : handleHover(false)}
      disabled={disabled}
      innerRef={innerRef}
      hidden={hidden}
      squared={squared}
      hasLabel={!!label}
      {...props}
    >
      <Icon color={color} />
      {label && <Label color={color}>{label}</Label>}
      {tooltip && (
        <Tooltip animate={hovering ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
          {tooltip}
        </Tooltip>
      )}
    </Container>
  )
}

IconBtn.whyDidYouRender = true

export default memo(IconBtn)
