import { memo, useEffect, useRef, useState, useCallback } from 'react'

import RichTextInput from 'components/common/inputs/Text/Composed'
import { useTranslation } from 'services/Translation'
import PaperPlane from 'assets/icons/communication/PaperPlane'
import NavArrow from 'assets/icons/arrows/Nav'

import {
  Container,
  Wrapper,
  Message,
  Content,
  Time,
  TypingIndicator,
  SendBtn,
  ExtraBtn,
  Bar,
} from './Styles'
import { typingVariants, barVariants, extraBtnVariants } from './Animations'
import texts from './Texts'

const Messages = ({ list = [], typing, onSubmit, onKeyPress }) => {
  const { t, formatDate: format } = useTranslation(texts)
  const endRef = useRef(null)

  const [extraOpen, setExtraOpen] = useState(false)

  // first render
  useEffect(() => {
    if (endRef.current) {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        endRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [endRef])

  // Every new message
  useEffect(() => {
    if (endRef.current) {
      // eslint-disable-next-line no-unused-expressions
      endRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [list])

  //input box actions:
  const [value, setValue] = useState('')

  const handleChange = useCallback(newValue => {
    setValue(newValue)
  }, [])
  const handleKeyDown = useCallback(
    ({ keyCode }) => {
      if (keyCode === 13 && value && value !== '<p><br></p><p><br></p>') {
        onSubmit(value)
        setValue('')
      } else onKeyPress()
    },
    [onKeyPress, onSubmit, value]
  )
  const handleExtraActions = useCallback(() => setExtraOpen(v => !v), [])
  const handleSubmit = useCallback(() => {
    if (value !== '') {
      onSubmit(value)
      setValue('')
    }
  }, [onSubmit, value])

  return (
    <>
      <Container small={extraOpen}>
        <Wrapper>
          {list.map(({ state }, i) => (
            <Message fromSelf={state.author.includes('_doctor_')} key={i}>
              <Content>
                <RichTextInput field={{ value: state.body }} readOnly noPadding />
              </Content>
              <Time>{format(state.timestamp, 'h:mm a').toLowerCase()}</Time>
            </Message>
          ))}
          <div ref={endRef} />
        </Wrapper>
        <TypingIndicator
          animate={typing ? 'active' : 'initial'}
          initial="initial"
          variants={typingVariants}
        >
          {typing && t('typing')}
        </TypingIndicator>
      </Container>
      <Bar animate={extraOpen ? 'open' : 'initial'} variants={barVariants}>
        <ExtraBtn
          onClick={handleExtraActions}
          variants={extraBtnVariants}
          animate={extraOpen ? 'open' : 'initial'}
        >
          <NavArrow />
        </ExtraBtn>
        <RichTextInput
          field={{
            onKeyDown: handleKeyDown,
            onChange: handleChange,
            value,
          }}
          placeholder={t('placeholder')}
          mini
          hideToolbar={!extraOpen}
        />
        <SendBtn onClick={handleSubmit}>
          <PaperPlane />
        </SendBtn>
      </Bar>
    </>
  )
}

Messages.whyDidYouRender = true

export default memo(Messages)
