import styled from 'styled-components'

import { transparency, primary, greySecondary } from 'styles/Colors'

export const Container = styled.div`
  border-top: solid 1px ${greySecondary};
  padding: 12px 20px 16px;
  width: 100%;
  &:first-child {
    //margin-top: 16px;
  }
  &:hover {
    border: solid 1px ${primary};
    border-radius: 15px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const Title = styled.div`
  & > div {
    background-color: ${primary + transparency[20]};
    border-radius: 50px;
    padding: 4px 12px;
    min-width: 60px;
  }
  margin-right: 18px;
`
export const DateContainer = styled.div`
  border-right: 1px solid ${primary};
  padding-right: 12px;
  margin-right: 12px;
  line-height: 10px;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 14px;
`
