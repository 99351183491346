import { useMiscState } from './context'
import { patientSideBar } from './reducer'

export enum PatientSideBar {
  Dummy,
  SummaryResponses,
  PastEncounter,
}

export interface PastEncounterProps {
  selectedRegistrationId: number

  currentRegistrationId: number
}

export interface PatientSideBarProps {
  type: PatientSideBar
  pastEncounterProps?: PastEncounterProps
}

export const patientSideBarDefault: PatientSideBarProps = { type: PatientSideBar.Dummy }

export const setPatientSideBar = (
  miscDispatch: any,
  patientSideBarProps: PatientSideBarProps
): void => {
  miscDispatch({ type: patientSideBar, payload: patientSideBarProps })
}

export const usePatientSideBarState = (): PatientSideBarProps => {
  const miscState = useMiscState()
  return miscState.patientSideBar ?? patientSideBarDefault
}
