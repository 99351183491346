import differenceInMinutes from 'date-fns/differenceInMinutes'

import { LIVE_UPDATES_TYPES } from 'services/LiveUpdates/formatter'

import { ROUTES } from 'Constants'

export const formatUpdates = (updates, currentDate, t, format) => {
  const out = []

  updates.forEach((update, idx) => {
    const dateTime = new Date(update.createdAt)
    const minDiff = differenceInMinutes(dateTime, new Date(currentDate)) * -1
    const hourDiff = Math.floor(minDiff / 60)
    const time =
      hourDiff !== 0
        ? `${hourDiff}${t('hourShort')}${
            minDiff % 60 !== 0 ? ` ${minDiff % 60}${t('minuteShort')}` : ''
          } ${t('timeAgo')}`
        : !minDiff
        ? t('justNow')
        : `${minDiff} ${minDiff !== 1 ? t('minutes') : t('minute')} ${t('timeAgo')}`
    let name = ''
    let action = ''
    let link

    switch (update.type) {
      case LIVE_UPDATES_TYPES.PATIENT_INVITED:
        name = `${update?.inviteInfos?.firstname} ${update?.inviteInfos?.lastname}`
        action = t('invited')
        break
      case LIVE_UPDATES_TYPES.PATIENT_REGISTERED:
        name = `${update?.patient?.firstname || ''} ${update?.patient?.lastname || t('newPatient')}`
        action = t('registered')
        break
      case LIVE_UPDATES_TYPES.SUMMARY_CREATED:
        link = `${ROUTES.PATIENT}${update?.patient?.id}`
        name = `${update?.patient?.firstname} ${update?.patient?.lastname}`
        action = `${t('submitted')} ${update?.summary?.initialComplaint}`
        break
      case LIVE_UPDATES_TYPES.ASSISTANT_NOTES_UPDATED:
        link = `${ROUTES.PATIENT}${update?.patient?.id}`
        name = t('notes')
        action = `${update?.patient?.firstname} ${update?.patient?.lastname}`
        break
      case LIVE_UPDATES_TYPES.READY_FOR_APPOINTMENT:
        link = `${ROUTES.PATIENT}${update?.patient?.id}`
        name = `${update?.patient?.firstname} ${update?.patient?.lastname}`
        action = `${t('ready1')}${
          update.appointment?.time
            ? ` ${t('ready2')} ${format(new Date(update.appointment.time), 'HH:mm')}`
            : ''
        }.`
        break
      case LIVE_UPDATES_TYPES.SCHEDULED_APPOINTMENT_NOW:
        link = `${ROUTES.PATIENT}${update?.patient?.id}`
        name = ``
        action = `${t('willStart1')} ${update?.patient?.firstname} ${update?.patient?.lastname} ${t(
          'willStart1'
        )}`
        break
      case LIVE_UPDATES_TYPES.SCHEDULED_APPOINTMENT_WITHIN_30_MIN:
        link = `${ROUTES.PATIENT}${update?.patient?.id}`
        name = ``
        action = `${t('willStart301')} ${update?.patient?.firstname} ${
          update?.patient?.lastname
        } ${t('willStart302')}`
        break
      case LIVE_UPDATES_TYPES.APPOINTMENT_ACCEPTED: {
        const appointmentTime = new Date(update.appointment?.time)
        link = `${ROUTES.PATIENT}${update?.patient?.id}`
        name = `${update?.patient?.firstname} ${update?.patient?.lastname}`
        action = `${t('accepted1')}${
          update.appointment?.time
            ? ` ${format(appointmentTime, 'd LLL')
                ?.split(' ')
                .map((e, i) => (i ? `${e?.[0].toUpperCase()}${e?.substring(1)}` : e))
                .join(' ')}. ${t('accepted2')} ${format(appointmentTime, 'HH:mm').replace(
                ':',
                ` ${t('hourShort')} `
              )} ${t('minuteMin')}.`
            : '.'
        }`
        break
      }
      case LIVE_UPDATES_TYPES.APPOINTMENT_CANCELLED: {
        const appointmentTime = new Date(update.appointment?.time)
        link = `${ROUTES.PATIENT}${update?.patient?.id}`
        action = `${t('cancelled1')} ${update?.patient?.firstname} ${update?.patient?.lastname} ${t(
          'cancelled2'
        )} ${format(appointmentTime, 'd LLL')
          ?.split(' ')
          .map((e, i) => (i ? `${e?.[0].toUpperCase()}${e?.substring(1)}` : e))
          .join(' ')}. ${format(appointmentTime, 'HH:mm').replace(':', ` ${t('hourShort')} `)} ${t(
          'minuteMin'
        )} ${t('cancelled3')}`
        name = ''
        break
      }
    }
    out.push({
      type: update.type,
      link,
      name,
      action,
      time,
      fullTime: format(dateTime, 'HH:mm'),
      id: idx,
    })
  })
  return out.reverse()
}
