import { memo, useCallback, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { GS } from 'back-end-api'

import { hp } from 'styles/Sizes'
import TextInput from 'components/common/inputs/Text'
import DefaultBtn from 'components/common/buttons/Default'
import Separator from 'components/common/Separator/Component'
import Typography from 'components/common/Typography/Component'
import { useTranslation } from 'services/Translation'

import texts from './Texts'

const initialValues = { email: '' }

const EmailForm = ({ onStepChange, setEmail }) => {
  const { t } = useTranslation(texts)
  const [error, setError] = useState('')

  const schema = Yup.object().shape({
    email: Yup.string()
      .min(6, t('errorMin'))
      .required(t('errorReq'))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Okta email
        t('errorValid')
      ),
  })

  const handleSubmit = useCallback(
    async values => {
      const res = await GS.isAlreadyUsed({ email: values.email })
      if (res.data === true) {
        setError('')
        onStepChange()
      } else {
        setError(t('errorNotFound'))
      }
    },
    [onStepChange, t]
  )

  const validate = useCallback(
    async values => {
      return schema
        .validate(values)
        .then(async () => {
          setEmail(values.email)
          setError('')
          return {}
        })
        .catch(e => ({ email: e.message }))
    },
    [schema, setEmail]
  )

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
      {({ isSubmitting, errors, submitCount, ...props }) => {
        return (
          <Form>
            <Field name="email" type="email" label={t('emailLabel')} component={TextInput} />
            <Separator size={hp(1)} />
            <Typography text={errors.email || error} hidden={!submitCount} center error />
            <Separator size={hp(5.5)} />
            <DefaultBtn
              label={t('btnLabel')}
              type="submit"
              disabled={isSubmitting || error}
              style={{ width: '50%', margin: '0 auto' }}
              withNext
              reversedColors
            />
          </Form>
        )
      }}
    </Formik>
  )
}

EmailForm.whyDidYouRender = true

export default memo(EmailForm)
