import { diff } from 'deep-object-diff'

import { saveToSession } from '../Tools'

const KEY_PATIENTS = process.env.REACT_APP_KEY_PATIENTS

const getNewPatientList = (oldList, newList) => {
  const out = []
  const oldNormal = oldList?.filter(p => !p.fromLive)
  const oldFromLive = oldList?.filter(p => p.fromLive)

  newList?.forEach(p => {
    const normalIndex = oldNormal.findIndex(e => e.id === p.id)
    if (normalIndex === -1) {
      // Patient was not in the "Normal" list
      const fromLiveIndex = oldFromLive.findIndex(e => e.id === p.id)
      if (fromLiveIndex !== -1) {
        out.push(p)
        oldFromLive.splice(fromLiveIndex, 1)
      } else out.push(p)
    } else {
      // Patient is in the normal list, checking for new infos
      const patientDiff = diff(oldNormal[normalIndex], p)
      if (Object.keys(patientDiff).length) {
        // Patient has new infos
        out.push(p)
      } else {
        // Patient infos are the same
        out.push(oldNormal[normalIndex])
      }
      oldNormal.splice(normalIndex, 1)
    }
  })
  oldNormal.forEach(p => out.push(p))
  oldFromLive.forEach(p => out.push(p))
  out.sort((a, b) => a.id - b.id)
  return out
}

export default (state, { type, payload, log = false }) => {
  switch (type) {
    case 'setIcpcs': {
      if (!Object.keys(diff(state.icpcs, payload)).length) {
        if (log) console.log('Patients are the same, doing nothing')
        return state
      }
      const finalState = { ...state }
      finalState.icpcs = payload
      saveToSession(finalState, KEY_PATIENTS)
      return finalState
    }
    case 'setDiseases': {
      if (!Object.keys(diff(state.diseases, payload)).length) {
        if (log) console.log('Patients are the same, doing nothing')
        return state
      }
      const finalState = { ...state }
      finalState.diseases = payload
      saveToSession(finalState, KEY_PATIENTS)
      if (log) console.log('New state after setDiseases: ', finalState)
      return finalState
    }
    case 'addBatch': {
      const newList = getNewPatientList(state.list, payload)
      if (log) {
        console.log('Patients AddBatch newList: ', newList, state.list, diff(state.list, newList))
      }
      if (!newList.length || !Object.keys(diff(state.list, newList)).length) {
        if (log) console.log('Patients AddBatch No new list / infos: ', state, newList)
        return state
      }
      const finalState = { ...state, list: newList }
      saveToSession(finalState, KEY_PATIENTS)
      return finalState
    }
    case 'addOne': {
      const elemIndex = state.list.findIndex(e => e.id === payload.id)
      const finalState = { ...state }
      if (elemIndex !== -1) {
        // this is an update.
        if (!Object.keys(diff(state.list[elemIndex], payload)).length) {
          if (log) console.log('Add one patient impossible: elements are the same', payload)
          return state
        }
        finalState.list[elemIndex] = payload
      } else {
        finalState.list.push(payload)
      }
      saveToSession(finalState, KEY_PATIENTS)
      return finalState
    }
    case 'addOneLive': {
      const elemIndex = state.list.findIndex(e => e.id === payload.id)
      if (elemIndex !== -1) {
        // this is an update.
        if (log) console.log('Add one patient from live impossible: patient already exist', payload)
        return state
      }
      const finalState = { ...state }
      finalState.list.push(payload)

      saveToSession(finalState, KEY_PATIENTS)
      return finalState
    }
    case 'fillList': {
      if (!Object.keys(diff(state.list, payload)).length) {
        if (log) console.log('Patients are the same, doing nothing')
        return state
      }
      const finalState = { ...state, list: payload }
      saveToSession(finalState, KEY_PATIENTS)
      return finalState
    }
    case 'setSummary': {
      const finalState = { ...state }
      const userIndex = finalState.list.findIndex(el => el.infos.email === payload.email)
      if (userIndex === -1) {
        console.log('Error in patient reducer: patient ', payload.email, ' not found')
        return state
      }
      const complaintIndex = finalState.list[userIndex].complaints.findIndex(
        el => el.id === payload.summaryId
      )
      if (complaintIndex === -1) {
        console.log('Error in patient reducer: patient ', payload.email, ' not found')
        return state
      }
      finalState.list[userIndex].complaints[complaintIndex].summary = payload.summary
      saveToSession(finalState, KEY_PATIENTS)
      return finalState
    }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
