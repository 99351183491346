import { textPrimary } from 'styles/Colors'

export default ({ style, color = textPrimary }) => (
  <svg style={style} width="17.737" height="17.001" viewBox="0 0 17.737 17.001">
    <path
      d="M8.75,17.612V21.04a.554.554,0,0,0,1,.328l2.005-2.729Z"
      transform="translate(-2.285 -4.592)"
      fill={color}
    />
    <path
      d="M17.506.1a.555.555,0,0,0-.578-.04L.3,8.748A.555.555,0,0,0,.375,9.764L5,11.344l9.846-8.419L7.225,12.1l7.748,2.648a.567.567,0,0,0,.179.03.554.554,0,0,0,.548-.472L17.733.636A.555.555,0,0,0,17.506.1Z"
      transform="translate(-0.002 0.003)"
      fill={color}
    />
  </svg>
)
