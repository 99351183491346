import { formatAll } from 'services/Patients/formatter'
import { Net } from '../Tools'
import { formatSummary, getPatientFromSummary } from './formatter'

export const getSummary = async (
  { token, isNew, noLive = false },
  summaryId,
  { summariesDispatch, patientsDispatch } = {},
  log = true
) => {
  if (!summaryId) return false
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/GetSummary`,
    token,
    { id: summaryId },
    log
  )
  if (log) console.log('Summary details res: ', res)
  if (typeof res === 'number' || !res) {
    return false
  }
  const formattedSummary = formatSummary(res)
  if (log) console.log('Formatted Summary: ', formattedSummary)
  if (isNew) {
    const formattedPatient = getPatientFromSummary(res)
    formattedPatient.fromLive = true
    // eslint-disable-next-line no-unused-expressions
    patientsDispatch?.({
      type: noLive ? 'addOne' : 'addOneLive',
      payload: formattedPatient,
      log,
    })
    // eslint-disable-next-line no-unused-expressions
    summariesDispatch?.({
      type: 'addOne',
      payload: formattedSummary,
      log,
    })
    return [formattedSummary, formattedPatient]
  }
  // eslint-disable-next-line no-unused-expressions
  summariesDispatch?.({
    type: 'update',
    payload: {
      summaryId,
      data: formattedSummary,
    },
    log,
  })
  return true
}

export const getPatientSummaries = async (
  { token },
  { patientId },
  { summariesDispatch, appointmentsDispatch },
  log = false
) => {
  const res = await Net.get(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/ListRegistrations`,
    token,
    { patient_account_id: patientId, without_old_closed_summaries: false },
    log
  )
  if (typeof res === 'number') return false
  if (log) console.log('Get Summaries Res: ', res)
  // const formattedSummaries = multiple(formatSummary, res, { id: patientId });
  const [patients, summaries, appointments] = formatAll(res)
  if (log) console.log('Formatted Summaries: ', summaries)
  // eslint-disable-next-line no-unused-expressions
  summariesDispatch?.({
    type: 'addBatch',
    payload: summaries,
    log,
  })
  // eslint-disable-next-line no-unused-expressions
  appointmentsDispatch?.({
    type: 'addBatch',
    payload: appointments,
    log,
  })
  return true
}

export const update = async ({ token }, summaryId, params, summariesDispatch, log = true) => {
  const res = await Net.patch(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/UpdateSummary`,
    token,
    { ...params, id: summaryId },
    log
  )
  if (typeof res === 'number') return false
  const formattedSummary = formatSummary(res)
  // eslint-disable-next-line no-unused-expressions
  summariesDispatch?.({
    type: 'update',
    payload: {
      summaryId,
      data: formattedSummary,
    },
    log,
  })
  return true
}

export const terminate = async ({ token }, summaryId, summariesDispatch, log = false) => {
  const res = await Net.post(
    `${process.env.REACT_APP_CLINIC_SERVICE_URL}/CloseSummary`,
    token,
    { id: summaryId },
    log
  )
  if (typeof res === 'number') return false
  const formattedSummary = formatSummary(res)
  if (log) console.log('Terminated summary: ', summaryId, res)
  // eslint-disable-next-line no-unused-expressions
  summariesDispatch?.({
    type: 'update',
    payload: {
      summaryId,
      data: formattedSummary,
    },
  })
  return true
}
