/* eslint-disable no-useless-escape */
import { memo, useCallback, useState } from 'react'
import { Form as FormikForm, Formik, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { GS } from 'back-end-api'

import { hp } from 'styles/Sizes'
import Separator from 'components/common/Separator'
import TextInput from 'components/common/inputs/Text'
import DefaultBtn from 'components/common/buttons/Default'
import Typography from 'components/common/Typography'
import { usernameUsedInPassword } from 'components/auth/reset/PasswordForm/Tools'
import { useTranslation } from 'services/Translation'
import { getQueryStringArgs } from 'utils/functions'
import styled from 'styled-components'
import { ROUTES } from 'Constants'

const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
`

interface FormValues {
  email: string
  oldPassword: string
  newPassword: string
  passwordConfirm: string
}

const defaultValues: FormValues = {
  email: '',
  oldPassword: '',
  newPassword: '',
  passwordConfirm: '',
}

const texts = (getText: (text: string, key: string) => string) => (key: string) =>
  ({
    emailLabel: getText('Email', 'Auth_SetPassword'),
    tmpPasswordLabel: getText('Temporary Password', 'Auth_SetPassword'),
    newPasswordLabel: getText('New Password', 'Auth_SetPassword'),
    confirmPasswordLabel: getText('Confirm Password', 'Auth_SetPassword'),
    btnLabel: getText('Setup password', 'Auth_SetPassword'),
    errorEmail: getText('Please enter a valid email address', 'Auth_SetPassword'),
    error: getText('An error occurred, please try again.', 'Auth_SetPassword'),
    errorContainsEmail: getText(
      'Password may not contain parts of your email address',
      'Auth_SetPassword'
    ),
    oldPassErrorShort: getText('Too short', 'Auth_SetPassword'),
    oldPassErrorMatch: getText('Invalid password', 'Auth_SetPassword'),
    oldPassErrorReq: getText('This field is required', 'Auth_SetPassword'),
    newPassErrorShort: getText('Too short', 'Auth_SetPassword'),
    newPassErrorMatch: getText('Invalid password', 'Auth_SetPassword'),
    newPassErrorReq: getText('This field is required', 'Auth_SetPassword'),
    confirmPassErrorNoMatch: getText('Passwords do not match', 'Auth_SetPassword'),
    confirmPassErrorReq: getText('This field is required', 'Auth_SetPassword'),
  }[key])

const SetPassword = () => {
  const { t } = useTranslation(texts)
  const history = useHistory()
  const [error, setError] = useState('')
  const { username: email, oldPassword } = getQueryStringArgs() || {}
  const initialValues = { ...defaultValues, ...{ email, oldPassword } }

  const schema = Yup.object().shape({
    email: Yup.string()
      .min(6)
      .required()
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Okta email
        t('errorEmail')
      ),
    oldPassword: Yup.string()
      .min(8, t('oldPassErrorShort'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, t('oldPassErrorMatch'))
      .required(t('oldPassErrorReq')),
    newPassword: Yup.string()
      .min(8, t('newPassErrorShort'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, t('newPassErrorMatch'))
      .required(t('newPassErrorReq')),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword')], t('confirmPassErrorNoMatch'))
      .required(t('confirmPassErrorReq')),
  })

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (usernameUsedInPassword(values.email, values.newPassword)) {
        setError(t('errorContainsEmail'))
        return
      }
      const res = await GS.changeExpiredPassword({
        email: values.email,
        old_password: values.oldPassword,
        new_password: values.newPassword,
      })
      if (res.error) setError(t('error'))
      else {
        history.push(ROUTES.LOGIN())
        setError('')
      }
    },
    [history, t]
  )

  return (
    <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, errors, submitCount }) => {
        return (
          <Form>
            <Field
              name="email"
              type="email"
              label={t('emailLabel')}
              component={TextInput}
              fullWidth
            />
            <Separator size={hp(2)} />
            {!!(submitCount && errors.email) && (
              <Typography error text={errors.email} size={undefined} />
            )}
            <Separator size={hp(2)} />
            <Field
              name="oldPassword"
              type="password"
              label={t('tmpPasswordLabel')}
              component={TextInput}
              fullWidth
            />
            <Separator size={hp(2)} />
            {!!(submitCount && errors.oldPassword) && (
              <Typography error text={errors.oldPassword} size={undefined} />
            )}
            <Separator size={hp(2)} />
            <Field
              name="newPassword"
              type="password"
              label={t('newPasswordLabel')}
              component={TextInput}
              fullWidth
              mayBeVisible
            />
            <Separator size={hp(2)} />
            {!!(submitCount && errors.newPassword) && (
              <Typography error text={errors.newPassword} size={undefined} />
            )}
            <Separator size={hp(2)} />
            <Field
              name="passwordConfirm"
              type="password"
              label={t('confirmPasswordLabel')}
              component={TextInput}
              fullWidth
              mayBeVisible
            />
            <Separator size={hp(2)} />
            {!!(submitCount && errors.passwordConfirm) && (
              <Typography error text={errors.passwordConfirm} size={undefined} />
            )}
            <Separator size={hp(2)} />
            {error && <Typography text={error} center error size={undefined} />}
            <Separator size={hp(3.7) - 25} />
            <DefaultBtn
              style={{ width: '50%' }}
              label={t('btnLabel')}
              disabled={isSubmitting || Object.keys(errors).length !== 0}
              type="submit"
              nextIcon={undefined}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

SetPassword.whyDidYouRender = true

export default memo(SetPassword)
