export default ({ className = '', color = '#1C165B', size = 10, style = {} }) => (
  <svg className={className} width="9.74" height="9.74" viewBox="0 0 9.74 9.74" style={style}>
    <path
      d="M9.235.335a.381.381,0,0,0-.024-.1.012.012,0,0,1,0,0h0A.392.392,0,0,0,9.159.15L9.143.132A.393.393,0,0,0,9.07.065h0A.379.379,0,0,0,8.98.022L8.957.015A.384.384,0,0,0,8.855,0H6.16a.385.385,0,0,0,0,.77H7.926l-2.3,2.3a3.467,3.467,0,1,0,.544.545l2.3-2.3V3.08a.385.385,0,1,0,.77,0V.385c0-.008,0-.016,0-.024s0-.017,0-.026ZM3.465,8.47a2.7,2.7,0,1,1,2.7-2.7A2.7,2.7,0,0,1,3.465,8.47Zm0,0"
      transform="translate(0.25 0.25)"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
    />
  </svg>
)
