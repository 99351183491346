import styled from 'styled-components'

import { greySecondary, textPrimary, background, transparency, greyPrimary } from 'styles/Colors'

export const Container = styled.div`
  position: relative;
`
export const DayLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 64px;
`

export const TabLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${background};
`

export const TopShadow = styled(({ topExtraSafe, ...props }) => <div {...props} />)`
  overflow: hidden;
  height: 300px;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 123;
  pointer-events: none;
  ${({ topExtraSafe }) =>
    topExtraSafe
      ? `
      &:before {
      content: ' ';
      position: absolute;
      background: #fcfcfc;
      width: 100%;
      height: 300px;
      top: -300px;
      left: 0;
      z-index: 1;
    }`
      : ''}
`

export const DayTitle = styled.div`
  color: ${({ disabled, selected }) =>
    disabled ? textPrimary + transparency[30] : selected ? 'white' : greyPrimary};
  font-size: 18px;
  font-family: ${({ selected }) => (selected ? 'coresanscr65' : 'coresanscr45')};
  font-weight: bold;
  width: calc(100% / 7);
  padding: 15px 0 5px 0;
  text-align: center;
  height: 50px;
`
export const DayNumber = styled(({ ...props }) => <div {...props} />)`
  position: relative;
  background: #fcfcfc;
  z-index: 1;
  width: calc(100% / 7);
  height: 20px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'coresanscr45';
  color: ${textPrimary};
  cursor: 'pointer';
  transition: color 300ms ease-in;
`
export const EventGrid = styled(({ ...props }) => <div {...props} />)`
  width: calc(100% + 4px);
  max-height: calc(9 * 70px);
  display: grid;
  grid-template-columns: 64px repeat(7, 1fr);
  grid-template-rows: repeat(24, 180px);
  grid-auto-flow: column;
  overflow: auto;
  position: relative;
  margin-top: -12px;
  & > div:nth-child(n + ${({ rows }) => rows + 1}) {
    margin-top: 12px;
  }
`
export const Event = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Time = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${greySecondary};
  width: 60px;
`
export const Text = styled.div`
  position: absolute;
  font-family: coresanscr65;
`
