import { memo, useState, useCallback } from 'react'
import { useTranslation } from 'services/Translation'
import Typography from 'components/common/Typography'
import TextInputComposed from 'components/common/inputs/Text/Composed'
import DefaultBtn from 'components/common/buttons/Default/Component'
import Separator from 'components/common/Separator'
import { getClaims } from 'services/Authentication'
import { textPrimary } from 'styles/Colors'
import SecondaryBtn from 'components/common/buttons/Secondary'
import { CS } from 'back-end-api'
import TextInput from 'components/common/inputs/Text'
import { hp } from 'styles/Sizes'
import { ModalContainer } from '../../Common'
import { AdviceStatus, FooterAdvice } from '../common'

const texts = (getText: (key: string, category: string) => string) => (key: string) =>
  ({
    btnLabelClose: getText('Close', 'Patient_Details_Modal_Advice'),
    newAdviceTemplateTitle: getText('Create new advice template', 'Patient_Details_Modal_Advice'),
    textCreateTemplate: getText(
      "You can create a new template right here & it'll be saved in the database as you would be able to use it in future uses",
      'Patient_Details_Modal_Advice'
    ),
    templateLabel: getText('Title', 'Patient_Details_Modal_Advice'),
    adviceInputPlaceholder: getText('Write here', 'Patient_Details_Modal_Advice'),
    newAdviceInputPlaceholder: getText(
      'Please write your advice over here....',
      'Patient_Details_Modal_Advice'
    ),
    btnLabelCreateNewTemplate: getText('Create', 'Patient_Details_Modal_Advice'),
    btnLabelBack: getText('Go Back', 'Patient_Details_Modal_Advice'),
  }[key])

interface CreateAdviceTemplateProps {
  onClose: () => void
  setStatus: (status: AdviceStatus) => void
  formatTemplateText: (text: string) => string
  advice: string
  setAdvice: (advice: string) => void
  title: string
  setTitle: (title: string) => void
}

const CreateAdviceTemplate = ({
  onClose,
  setStatus,
  formatTemplateText,
  advice,
  setAdvice,
  title,
  setTitle,
}: CreateAdviceTemplateProps) => {
  const { t } = useTranslation(texts)

  const claims = getClaims()
  const res = CS.useAdviceTemplatesList({ doctor_account_id: claims?.sub ?? '' })
  const templateTitleAlreadyUsed = res.data?.some(te => te.title === title)

  const [creatingTemplate, setCreatingTemplate] = useState(false)

  const createTemplate = useCallback(async () => {
    setCreatingTemplate(true)
    await CS.createAdviceTemplate({ text: advice, title })
    setAdvice(formatTemplateText(advice))
    setStatus(AdviceStatus.WriteAdvice)
    setCreatingTemplate(false)
  }, [title, advice, formatTemplateText, setAdvice, setStatus])

  const closeCreateTemplateModal = useCallback(() => {
    setAdvice('')
    setTitle('')
    setStatus(AdviceStatus.WriteAdvice)
  }, [setAdvice, setTitle, setStatus])

  return (
    <>
      <ModalContainer label={t('btnLabelClose')} onClose={onClose}>
        <div className="flex flex-col text-lg py-3 mb-3">
          <Typography
            type="title"
            className="font-csc65"
            text={t('newAdviceTemplateTitle')}
            color={textPrimary}
            size={18}
          />
          <Typography
            className="mt-3 font-csc45 text-medium"
            text={t('textCreateTemplate')}
            color={textPrimary}
            size={15}
          />
        </div>
        <>
          <div className="flex justify-between items-center border-b border-grey-100 py-6">
            <div className="border-r border-grey-100 pr-6 mr-6">
              <Typography
                type="title"
                className="font-csc65"
                text={t('templateLabel')}
                color={textPrimary}
                size={18}
              />
            </div>
            <TextInput
              noBorder
              className="font-csc65"
              noPadding
              inputStyle={{ color: textPrimary, fontSize: '1.125rem' }}
              placeholderStyle={{ fontSize: '1.125rem' }}
              placeholder={t('adviceInputPlaceholder')}
              field={{
                value: title,
                onChange: ({ target: { value } }: any) => setTitle(value),
              }}
              style={undefined}
              label={undefined}
              inputRef={undefined}
              containerStyle={undefined}
            />
          </div>
          <Separator size={hp(3)} />
          <TextInputComposed
            height={350}
            hideToolbar
            noPadding
            placeholder={t('newAdviceInputPlaceholder')}
            field={{
              value: advice,
              onChange: setAdvice,
            }}
            data-cy="inputAdvice"
          />
        </>
      </ModalContainer>
      <FooterAdvice>
        <div className="w-full flex justify-center align-center border-t border-grey-100 pt-12">
          <>
            <DefaultBtn
              label={t('btnLabelCreateNewTemplate')}
              onClick={createTemplate}
              color={textPrimary}
              bold
              reversedColors
              type="submit"
              className="font-csc65"
              disabled={
                !advice ||
                advice === '<p><br></p>' ||
                !title ||
                creatingTemplate ||
                templateTitleAlreadyUsed
              }
              style={{ width: '9.375rem' }}
              nextIcon={undefined}
            />
            <Separator type="horizontal" size={hp(3)} />
            <SecondaryBtn
              label={t('btnLabelBack')}
              onClick={closeCreateTemplateModal}
              type="submit"
              className="font-csc65"
              style={{ width: '9.375rem' }}
              color={textPrimary}
              disabled={creatingTemplate}
              background={undefined}
              innerRef={undefined}
            />
          </>
        </div>
      </FooterAdvice>
    </>
  )
}

CreateAdviceTemplate.whyDidYouRender = true

export default memo(CreateAdviceTemplate)
