import { memo } from 'react'

import { Container, Overlay, Inner } from './Styles'
import { overlayVariants, contentVariants } from './Animations'

const Modal = ({ visible, children, columnWidth = '48% 52%' }) => {
  return (
    <Container clickable={visible} columnWidth={columnWidth}>
      <Overlay
        initial="initial"
        animate={visible ? 'visible' : 'initial'}
        variants={overlayVariants}
      />
      <Inner initial="initial" animate={visible ? 'visible' : 'initial'} variants={contentVariants}>
        {children}
      </Inner>
    </Container>
  )
}

Modal.whyDidYouRender = true

export default memo(Modal)
