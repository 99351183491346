import styled from 'styled-components'

import { primary, transparency } from 'styles/Colors'

export const Container = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 1.5px 3px 9px 0 ${primary + transparency[10]};
  background-color: white;
  cursor: pointer;
`
