import styled from 'styled-components'

import { textPrimary, error as errorColor, textTertiary, green } from 'styles/Colors'

export const Title = styled(
  ({ bold, hidden, center, color, size, underline, italic, rtl, ...props }) => (
    <div {...props} dir={rtl ? 'rtl' : 'ltr'} />
  )
)`
  display: inline-block;
  transition: all 300ms;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  color: ${({ color }) => color || textPrimary};
  font-size: ${({ size }) => size || 24 * 0.8}px;
  font-style: ${({ italic }) => (italic ? 'italic' : 'inherit')};
  line-height: ${({ size }) => size || 30 * 0.8}px;
  min-height: ${({ size }) => size + 2 || 30 * 0.8}px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  text-align: ${({ center }) => (center ? 'center' : 'initial')};
  width: ${({ center }) => (center ? '100%' : 'auto')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'initial')};
`
export const Text = styled(
  ({ bold, hidden, error, success, center, color, size, underline, italic, rtl, ...props }) => (
    <div {...props} dir={rtl ? 'rtl' : 'ltr'} />
  )
)`
  transition: all 300ms;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  font-size: ${({ size }) => size || 18 * 0.8}px;
  font-style: ${({ italic }) => (italic ? 'italic' : 'inherit')};
  line-height: ${({ size }) => size || 16}px;
  min-height: ${({ size }) => size + 2 || 25 * 0.8}px;
  color: ${({ error, success, color }) =>
    error ? errorColor : success ? green : color || textPrimary};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  text-align: ${({ center }) => (center ? 'center' : 'initial')};
  width: ${({ center }) => (center ? '100%' : 'auto')};
  display: ${({ error }) => (error ? 'flex' : 'inline-block')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'initial')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const Subtext = styled(
  ({ bold, hidden, error, center, size, underline, italic, rtl, ...props }) => (
    <div {...props} dir={rtl ? 'rtl' : 'ltr'} />
  )
)`
  transition: all 300ms;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  font-size: ${({ size }) => size || 14 * 0.8}px;
  font-style: ${({ italic }) => (italic ? 'italic' : 'inherit')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  line-height: ${({ size }) => size || 16 * 0.8}px;
  min-height: ${({ size }) => size + 2 || 16 * 0.8}px;
  color: ${({ error }) => (error ? errorColor : textTertiary)};
  text-align: ${({ center }) => (center ? 'center' : 'initial')};
  width: ${({ center }) => (center ? '100%' : 'auto')};
  display: ${({ error }) => (error ? 'flex' : 'inline-block')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'initial')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const CrossContainer = styled.div`
  margin-right: ${12 * 0.8}px;
`
