import { GS, GS_m, Response } from 'back-end-api'
import jwtDecode from 'jwt-decode'
import { fromUnixTime, addMilliseconds } from 'date-fns'
import { saveToLocal, removeFromLocal, getFromLocalOrDefault } from '../Tools'

const TOKENS_LOCAL_STORAGE_KEY = 'TOKENS_LOCAL_STORAGE_KEY'

interface TokensStorage {
  accessToken: string
  refreshToken: string
}

export interface Claims {
  sub: string
  exp: number
  role: string
}

const storeTokens = (tokens: TokensStorage): void => {
  saveToLocal(tokens, TOKENS_LOCAL_STORAGE_KEY)
}

export const eraseTokens = (): void => {
  removeFromLocal(TOKENS_LOCAL_STORAGE_KEY)
}

export const getAccessToken = (): string | undefined => {
  const tokens: TokensStorage | undefined = getFromLocalOrDefault(
    undefined,
    TOKENS_LOCAL_STORAGE_KEY
  )
  return tokens?.accessToken
}

const getRefreshToken = (): string | undefined => {
  const tokens: TokensStorage | undefined = getFromLocalOrDefault(
    undefined,
    TOKENS_LOCAL_STORAGE_KEY
  )
  return tokens?.refreshToken
}

export const isTokenExpiringWithin = (token: string, ms: number): boolean => {
  const decodedToken: { exp: number } = jwtDecode(token)
  return fromUnixTime(decodedToken.exp) < addMilliseconds(new Date(), ms)
}

export const getAccessTokenAndClaims = () => {
  const accessToken = getAccessToken()
  if (accessToken) {
    const claims = jwtDecode<Claims>(accessToken)
    return { token: accessToken, claims }
  }

  return {}
}

export const getClaims = (): Claims | undefined => {
  const { claims } = getAccessTokenAndClaims()
  return claims
}

export const login = async (
  request: GS_m.EndpointsLoginRequest
): Promise<Response<GS_m.EndpointsLoginResponse>> => {
  return GS.login(request)
}

export const logout = async (request: GS_m.EndpointsLogoutRequest): Promise<void> => {
  eraseTokens()
  await GS.logout(request)
}

export const multiFactorAuth = async (
  request: GS_m.EndpointsMultiFactorAuthRequest
): Promise<Response<GS_m.EndpointsMultiFactorAuthResponse>> => {
  const res = await GS.multiFactorAuth(request)

  if (res.data) {
    storeTokens({ accessToken: res.data.access_token, refreshToken: res.data.refresh_token })
  }

  return res
}

export const refresh = async (): Promise<string | null> => {
  const refreshToken = getRefreshToken()

  if (refreshToken && !isTokenExpiringWithin(refreshToken, 1000 * 60)) {
    const res = await GS.refresh({ refresh_token: refreshToken })
    if (res.data?.access_token && res.data?.refresh_token) {
      storeTokens({ accessToken: res.data.access_token, refreshToken: res.data.refresh_token })
      return res.data.access_token
    }
  }

  return null
}
