import styled from 'styled-components'

export const DeleteBtn = styled(({ disabled, ...props }) => <div {...props} />)`
  width: 20px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`
