import { memo, useCallback, useState } from 'react'
import { Formik, Form, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { hp } from 'styles/Sizes'
import TextInput from 'components/common/inputs/Text'
import DefaultBtn from 'components/common/buttons/Default'
import Separator from 'components/common/Separator/Component'
import Typography from 'components/common/Typography/Component'
import { useTranslation } from 'services/Translation'
import { GS } from 'back-end-api'

import { usernameUsedInPassword } from './Tools'

const texts = (getText: (text: string, key: string) => string) => (key: string) =>
  ({
    passwordLabel: getText('New password', 'Auth_Reset_Password_Form'),
    passwordConfirmLabel: getText('Repeat new Password', 'Auth_Reset_Password_Form'),
    hint: getText(
      'Password needs to be at least 8 characters long and contain  uppercase letters, lowercase letters and numbers.',
      'Auth_Reset_Password_Form'
    ),
    btnLabel: getText('Next', 'Auth_Reset_Password_Form'),

    errorSent: getText('Could not send OTP', 'Auth_Reset_Password_Form'),
    errorMin: getText('Too short', 'Auth_Reset_Password_Form'),
    errorMatch: getText('Invalid password', 'Auth_Reset_Password_Form'),
    errorReq: getText('This field is required', 'Auth_Reset_Password_Form'),
    errorOneOf: getText('Passwords do not match', 'Auth_Reset_Password_Form'),
    errorContainsEmail: getText(
      'Password may not contain parts of your email address',
      'Auth_Reset_Password_Form'
    ),
  }[key])

interface PasswordFormData {
  password: string
  passwordConfirm: string
}

const initialValues: PasswordFormData = {
  password: '',
  passwordConfirm: '',
}

interface PasswordFormProps {
  onStepChange: () => void
  email: string
  setPassword: (password: string) => void
  extraError: string
  resetExtraError: () => void
}

const PasswordForm = ({
  onStepChange,
  email,
  setPassword,
  extraError,
  resetExtraError,
}: PasswordFormProps) => {
  const { t, lang } = useTranslation(texts)
  const [error, setError] = useState('')

  const schema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('errorMin'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, t('errorMatch'))
      .required(t('errorReq')),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password')], t('errorOneOf'))
      .required(t('errorReq')),
  })

  const handleSubmit = useCallback(
    async (values: PasswordFormData, { setValues }: FormikHelpers<PasswordFormData>) => {
      resetExtraError()
      if (usernameUsedInPassword(email, values.password)) {
        setError(t('errorContainsEmail'))
        return
      }
      setPassword(values.password)
      const res = await GS.sendOneTimePassword({ destination: email, recipient_language_tag: lang })
      if (res?.error) {
        setError(t('errorSent'))
        return
      }

      setError('')
      setValues(initialValues)
      onStepChange()
    },
    [email, onStepChange, resetExtraError, setPassword, t, lang]
  )

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ isSubmitting, errors, submitCount }) => {
        return (
          <Form>
            <Field
              name="password"
              type="password"
              label={t('passwordLabel')}
              component={TextInput}
            />
            <Separator size={hp(1)} />
            <Typography
              text={errors.password}
              hidden={!submitCount}
              error
              center
              size={undefined}
            />
            <Separator size={hp(3.6) - 25} />
            <Field
              name="passwordConfirm"
              type="password"
              label={t('passwordConfirmLabel')}
              component={TextInput}
            />
            <Separator size={hp(1)} />
            <Typography
              text={errors.passwordConfirm || error || extraError}
              hidden={!submitCount && !extraError}
              error
              center
              size={undefined}
            />
            <Separator size={hp(4.5) - 25} />
            <Typography text={t('hint')} center size={undefined} />
            <Separator size={hp(5)} />
            <DefaultBtn
              label={t('btnLabel')}
              type="submit"
              disabled={isSubmitting || Object.keys(errors).length !== 0}
              style={{ width: '50%', margin: '0 auto' }}
              withNext
              reversedColors
              nextIcon={undefined}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

PasswordForm.whyDidYouRender = true

export default memo(PasswordForm)
