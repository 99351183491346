import { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

const Element = ({ name, action, time, link }) => {
  const history = useHistory()

  const handleLink = useCallback(() => {
    history.push(link)
  }, [link, history])

  return (
    <div
      className="pt-8 pb-9 text-blue-900 border-t border-grey-100"
      style={{ fontSize: '0.9375rem', cursor: link ? 'pointer' : 'normal' }}
      onClick={link ? handleLink : undefined}
      tabIndex={0}
      role="button"
      onKeyDown={link ? handleLink : undefined}
    >
      <div className="flex justify-between">
        {name && (
          <div className="font-csc65 w-52 truncate" style={{ marginBottom: '0.8125rem' }}>
            {name}
          </div>
        )}
        <div
          className="text-xs font-csc55 pl-2 text-right text-grey-200"
          style={{ minWidth: '5.1rem' }}
        >
          {`${time}`}
        </div>
      </div>
      <div className="font-csc45">{action}</div>
    </div>
  )
}

Element.whyDidYouRender = true

export default memo(Element)
