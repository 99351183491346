import { error } from 'styles/Colors'

export default ({ color = error, size = 25, style }) => (
  <svg viewBox="0 0 280.47 280.47" width={size} height={size} style={style}>
    <path
      fill={color}
      d="M94.48,189A94.49,94.49,0,1,1,181.16,56.82h0A94.53,94.53,0,0,1,94.48,189Zm0-169a74.42,74.42,0,1,0,68.34,44.8h0A74.49,74.49,0,0,0,94.48,20Z"
    />
    <path
      fill={color}
      d="M94.48,280.47a10,10,0,0,1-10-10V179a10,10,0,0,1,20,0v91.5A10,10,0,0,1,94.48,280.47Z"
    />
    <path fill={color} d="M129.27,234.72H60.83a10,10,0,0,1,0-20h68.44a10,10,0,0,1,0,20Z" />
  </svg>
)
