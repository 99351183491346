/* eslint-disable no-nested-ternary */
import { memo, useState, useCallback, useEffect } from 'react'
import Month from 'components/common/Month'
import addMonths from 'date-fns/addMonths'
import { useMiscDispatch, useMiscState } from 'services/Misc'
import { useRegistrations } from 'services/Registrations'
import subMonths from 'date-fns/subMonths'
import { primary } from 'styles/Colors'
import Header from './Header'

const DateSelector = ({ expanded, onExpand }) => {
  const {
    data: { appointments },
  } = useRegistrations()

  const {
    scheduleDate: { dateSelected },
  } = useMiscState()

  const dateSelectedOrNow = new Date(dateSelected) || new Date()
  const [date, setDate] = useState(dateSelectedOrNow)
  const miscDispatch = useMiscDispatch()
  const currDate = new Date()
  const [monthView, setMonthView] = useState({
    month: date.getMonth(),
    year: date.getFullYear(),
  })

  useEffect(() => {
    miscDispatch({
      type: 'scheduleDate',
      payload: { dateSelected: date },
    })
  }, [date, miscDispatch])

  const handleNavClick = useCallback(
    direction => {
      const newMonth = monthView.month + direction
      if (direction === 1) setDate(addMonths(date, 1))
      if (direction === -1) setDate(subMonths(date, 1))
      setMonthView({
        month: newMonth === 12 ? 0 : newMonth === -1 ? 11 : newMonth,
        year:
          newMonth === 12
            ? monthView.year + 1
            : newMonth === -1
            ? monthView.year - 1
            : monthView.year,
      })
    },
    [monthView]
  )

  return (
    <>
      <Header
        date={date}
        setDate={handleNavClick}
        expanded={expanded}
        onExpand={onExpand}
        canExpand
      />
      <div className="flex justify-center">
        <div className="mx-14 w-full">
          <Month
            style={{ height: '15rem' }}
            data-cy="schedule_page_calendar"
            className="pt-8"
            currDate={currDate}
            monthView={monthView}
            events={appointments}
            selectedDate={date}
            calendarType={expanded ? null : 'week'}
            mini
            color={primary}
            onDateChange={setDate}
            disableBefore={false}
            appointmentDuration={15}
          />
        </div>
      </div>
    </>
  )
}

DateSelector.whyDidYouRender = true

export default memo(DateSelector)
