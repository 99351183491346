import { memo } from 'react'

import ExpandBtn from 'components/common/buttons/Expand'
import { useTranslation } from 'services/Translation'
import texts from './Texts'

const Header = ({ canExpand = true, onExpand, expanded }) => {
  const { t } = useTranslation(texts)

  return (
    <div className="px-14" style={{ zIndex: 20 }}>
      <div className="h-12 w-full flex items-center justify-between relative z-20 border-b border-grey-100">
        <div className="font-csc65 text-lg">{t('title')}</div>
        <ExpandBtn
          canExpand={canExpand}
          onExpand={onExpand}
          expanded={expanded}
          reverseArrow
          style={{ fontSize: '12px' }}
        />
      </div>
    </div>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
