import styled from 'styled-components'
import { motion } from 'framer-motion'

import { textPrimary, textSecondary, purpleSecondary } from 'styles/Colors'

export const Container = styled(({ small, ...props }) => <div {...props} />)`
  grid-area: messages;
  position: relative;
  width: 100%;
  height: calc(100% - 15vh);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
export const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
  padding: 5% 0% 2%;
`
export const TypingIndicator = styled(motion.div)`
  position: absolute;
  bottom: 3px;
  left: 12%;
  width: 100%;
  font-size: 12px;
  color: ${textPrimary};
`
export const Message = styled(({ fromSelf, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: ${({ fromSelf }) => (fromSelf ? 'flex-end' : 'flex-start')};
  margin-bottom: 12px;
  & > div:first-child {
    background-color: ${({ fromSelf }) => (fromSelf ? '#e8e8e8' : purpleSecondary)};
    color: ${textPrimary};
    ${({ fromSelf }) =>
      fromSelf
        ? `
      border-top-left-radius: 15px;
    `
        : `
      border-top-right-radius: 15px;
    `}
  }
  & > div:last-child {
    ${({ fromSelf }) =>
      fromSelf
        ? `
      margin-right: 70%;
      transform: translate(100%, 0);
    `
        : `
      margin-left: 70%;
      transform: translate(-100%, 0);
    `}
  }
`
export const Content = styled.div`
  width: 70%;
  height: auto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 12px 16px;
  margin-bottom: 3px;
  &:hover {
    & + div {
      height: 16px;
    }
  }
`
export const Time = styled.div`
  height: 0;
  transition: all 100ms ease-in;
  overflow: hidden;
  color: ${textSecondary};
  font-size: 12px;
  font-weight: bold;
`

export const Bar = styled(motion.div)`
  width: 80%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: white;
  border: solid 0.5px #e8e8e8;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  bottom: 38px;
  left: 50%;
  transform: translate(-50%, 0);
`
export const ExtraBtn = styled(motion.div)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
export const SendBtn = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
