export default getText => key =>
  ({
    title: getText('Consultation selection', 'Patient_Details_Tabs_ConfirmFinish'),
    textSelect: getText('You have selected ', 'Patient_Details_Tabs_ConfirmFinish'),
    tentative: getText('Tentative', 'Patient_Details_Tabs_ConfirmFinish'),
    definitive: getText('Definitive', 'Patient_Details_Tabs_ConfirmFinish'),
    consultation: getText(' consultation', 'Patient_Details_Tabs_ConfirmFinish'),
    disclaimerText: getText(
      'Please keep in mind that definitive consultation is not changeable later, if you want you can set to tentative consultation to make a change later.',
      'Patient_Details_Tabs_ConfirmFinish'
    ),
    finished: getText('Consultation finished!', 'Patient_Details_Tabs_ConfirmFinish'),
    finishBtnLabel: getText('Finish consultation', 'Patient_Details_Tabs_ConfirmFinish'),
    cancelBtnLabel: getText('Cancel', 'Patient_Details_Tabs_ConfirmFinish'),
  }[key])
