export const topShadowVariants = {
  show: {
    top: '-3rem',
  },
  hide: {
    top: '-9rem',
  },
}
export const bottomShadowVariants = {
  show: {
    bottom: '-3rem',
  },
  hide: {
    bottom: '-9rem',
  },
}
