import styled from 'styled-components'

export const Container = styled(({ disabled, color, innerRef, ...props }) => (
  // eslint-disable-next-line react/button-has-type
  <button ref={innerRef} {...props} />
))`
  color: ${({ color }) => color};
`

export const NextIcon = styled.div`
  margin-top: 2px;
  transform: scale(0.8);
`
export const Label = styled(({ mini, size, italic, ...props }) => <div {...props} />)`
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-size: ${({ mini, size }) => (mini ? `${12 * 0.8}px` : size)};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  color: ${({ color }) => color};
  text-decoration: underline;
`
