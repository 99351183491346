import styled from 'styled-components'

import { hp } from 'styles/Sizes'
import {
  primary,
  primaryTransparency,
  textPrimary,
  transparency,
  greySecondary,
} from 'styles/Colors'

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${greySecondary};
`

export const Container = styled(({ small, mini, ...props }) => <div {...props} />)`
  position: relative;
  height: ${({ small, mini }) => (small || mini ? 'auto' : `${hp(48.8)}px`)};
  width: 100%;
  padding-top: ${({ mini }) => mini && '0.625rem'};
  color: #1c165b;
  font-family: coresanscr55;
`
export const DayLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ mini }) => mini && primary};
  background-color: ${({ mini }) => mini && primaryTransparency};
  padding: ${({ mini }) => mini && '2.5px 5px'};
  border-radius: ${({ mini }) => mini && '50px'};
`
export const DayTitle = styled(({ small, mini, ...props }) => <div {...props} />)`
  font-size: ${({ small, mini }) => (small ? '0.9375rem' : mini ? '0.625rem' : '18px')};
`
export const NumbersGrid = styled(({ small, mini, ...props }) => <div {...props} />)`
  display: grid;
  grid-template-columns: repeat(7, ${({ small, mini }) => (small ? 25 : mini ? 20 : 24)}px);
  grid-template-rows: repeat(6, ${({ small, mini }) => (small ? 25 : mini ? 19 : 24)}px);
  justify-content: space-between;
  text-align: center;
  grid-row-gap: ${({ small, mini }) => (small ? '0.6875rem' : mini ? '0.6rem' : `${hp(4)}px`)};
`
export const Number = styled(({ disabled, color, small, mini, ...props }) => <div {...props} />)`
  font-size: ${({ mini }) => mini && '10px'};
  position: relative;
  color: ${({ disabled, color }) =>
    disabled ? textPrimary + transparency[30] : color !== 'transparent' ? 'white' : textPrimary};
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  transition: color 300ms ease-in;
  &::before {
    content: ' ';
    position: absolute;
    width: ${({ small, mini }) => (small || mini ? 22 : 52)}px;
    height: ${({ small, mini }) => (small || mini ? 22 : 52)}px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: ${({ color }) => color};
    transition: background-color 300ms ease-in;
    z-index: -1;
  }
  &::after {
    content: ' ';
    position: absolute;
    width: ${({ small, mini }) => (small || mini ? 28 : 60)}px;
    height: ${({ small, mini }) => (small ? 28 : mini ? 28 : 60)}px;
    top: ${({ small, mini }) => (small ? -3 : mini ? 0 : -19)}px;
    left: ${({ small, mini }) => (small ? -3 : mini ? 0 : -19)}px;
    border-radius: 50px;
    background-color: ${({ color, small, mini }) =>
      small || mini ? 'transparent' : color + transparency[30]};
    transition: background-color 300ms ease-in;
    z-index: -2;
  }
`
export const Event = styled(({ color, ...props }) => <div {...props} />)`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 3px;
  height: 3px;
  border-radius: 50px;
  background-color: ${({ color }) => color};
`
