import { memo, useMemo, useEffect, useState, useRef } from 'react'
import isSameDay from 'date-fns/isSameDay'
import { hp } from 'styles/Sizes'
import Separator from 'components/common/Separator'
import { useMiscDispatch } from 'services/Misc'
import {
  setAppointmentSideBar,
  AppointmentSideBar,
  appointmentSideBarDefault,
} from 'services/Misc/appointmentSideBar'
import {
  getAvailableTimes,
  getTimeOffsForDay,
  splitAvailableTimes,
} from 'components/common/Month/Tools'
import { useDragSelect } from 'components/common/dragSelect'
import Grid from './Grid'
import { Container, TitleSection } from './Styles'

const uniqueTimeSlots = (value: any, index: any, self: any) =>
  self.findIndex((v: any) => v.id === value.id) === index

interface TimeSlotsProps {
  currDate: Date
  timeOffs: any[]
  scheduledAppointments: any[]
}

const TimeSlots = ({ currDate, timeOffs, scheduledAppointments }: TimeSlotsProps) => {
  const elementsContainerRef = useRef<any>(null)
  const miscDispatch = useMiscDispatch()
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<any[]>([])
  const dragSelect = useDragSelect()

  const timeSlots = useMemo(() => {
    const appointmentsForDay = scheduledAppointments?.filter(
      appointment => appointment.time && isSameDay(new Date(appointment.time), currDate)
    )
    const timesOffForDay = getTimeOffsForDay(currDate, timeOffs).filter(uniqueTimeSlots)
    const eventsForDay = [...appointmentsForDay, ...timesOffForDay]

    const availableTimes = getAvailableTimes(eventsForDay, currDate)
    const extendedAvailableTimes = splitAvailableTimes(availableTimes)

    const sorted = [
      ...new Set(
        [...extendedAvailableTimes, ...eventsForDay].sort((a, b) => {
          const aTime = new Date(a.time)
          const bTime = new Date(b.time)
          if (aTime.getHours() < bTime.getHours()) return -1
          if (aTime.getHours() > bTime.getHours()) return 1
          if (aTime.getMinutes() < bTime.getMinutes()) return -1
          return 1
        })
      ),
    ]

    const withIndex = sorted.map((item, idx) => ({ ...item, index: idx }))
    return withIndex
  }, [currDate, timeOffs, scheduledAppointments])

  useEffect(() => {
    dragSelect.setSettings({ area: elementsContainerRef.current })

    const id = dragSelect.subscribe('callback', (e: any) => {
      const selectedItems: any[] = []
      e.items.forEach((item: any) => {
        const index = item.getAttribute('data-cy').split('_')[1]
        selectedItems.push(Number(index))
      })

      let selected = timeSlots.filter((_, idx) => selectedItems.includes(idx))

      // If there are multiple time slots selected:
      //  . filter out all appointments as they can't be blocked
      //  . Only keep all the blocked or all the unblocked time slots depending on the first one
      if (selected.length > 1) {
        selected = selected.filter(timeSlot => {
          return timeSlot?.patientId === undefined && timeSlot.doctorId === selected[0].doctorId
        })
      }

      setSelectedTimeSlots(selected)
    })

    return () => {
      dragSelect.unsubscribe('callback', undefined, id)
    }
  })

  useEffect(() => {
    if (selectedTimeSlots.length === 0) {
      setAppointmentSideBar(miscDispatch, appointmentSideBarDefault)
      return
    }
    if (selectedTimeSlots.length === 1 && selectedTimeSlots[0]?.patientId) {
      setAppointmentSideBar(miscDispatch, {
        type: AppointmentSideBar.AppointmentDetails,
        appointmentDetailsSideBarProps: { registrationId: selectedTimeSlots[0].patientId },
      })
      return
    }
    setAppointmentSideBar(miscDispatch, {
      type: AppointmentSideBar.EventManager,
      eventManagerSideBarProps: { timeSlots: selectedTimeSlots },
    })
  }, [selectedTimeSlots, miscDispatch])

  const morningTimes = timeSlots?.filter(slot => new Date(slot.time).getHours() < 12)
  const afternoonTimes = timeSlots?.filter(
    slot => new Date(slot.time).getHours() >= 12 && new Date(slot.time).getHours() < 14
  )
  const eveningTimes = timeSlots?.filter(slot => new Date(slot.time).getHours() >= 14)

  return (
    <Container containerRef={elementsContainerRef}>
      <Separator size={hp(1.5)} />
      <TitleSection className="w-3/12 py-1 pr-3">
        Mornings
        <div className="border-l border-blue-500 font-csc45 pl-3 ml-4 text-blue-900">
          00:00 AM to 12:00 AM
        </div>
      </TitleSection>
      <Separator size={hp(1.5)} />
      <Grid timeSlots={morningTimes} selectedTimeSlots={selectedTimeSlots} />
      <Separator size={hp(1.5)} />
      <TitleSection className="w-3/12 py-1 pr-3">
        Afternoons
        <div className="border-l border-blue-500 font-csc45 pl-3 ml-4 text-blue-900">
          12:00 AM to 2:00 PM
        </div>
      </TitleSection>
      <Separator size={hp(1.5)} />
      <Grid timeSlots={afternoonTimes} selectedTimeSlots={selectedTimeSlots} />
      <Separator size={hp(1.5)} />
      <TitleSection className="w-3/12 py-1 pr-3">
        Evenings
        <div className="border-l border-blue-500 font-csc45 pl-3 ml-4 text-blue-900">
          2:00 PM to 12:00 PM
        </div>
      </TitleSection>
      <Separator size={hp(1.5)} />
      <Grid timeSlots={eveningTimes} selectedTimeSlots={selectedTimeSlots} />
    </Container>
  )
}

TimeSlots.whyDidYouRender = true

export default memo(TimeSlots)
