export default ({ className, color = '#1c165b' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    className={className}
  >
    <text
      id="ID"
      transform="translate(0 13)"
      fill={color}
      fontSize="14"
      fontFamily="CoreSansCR-65Bold, Core Sans CR"
      fontWeight="700"
    >
      <tspan x="0" y="0">
        ID
      </tspan>
    </text>
  </svg>
)
