import { memo } from 'react'

import Help from './Help'
import { Container, Title, Version } from './Styles'

const Header = ({ noHelp }) => {
  return (
    <Container>
      <Title>
        medvice.io<Version>V.1.2</Version>
      </Title>
      <Help visible={!noHelp} />
    </Container>
  )
}

Header.whyDidYouRender = true

export default memo(Header)
