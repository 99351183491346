import styled from 'styled-components'
import { motion } from 'framer-motion'

import { textTertiary } from 'styles/Colors'

export const LengthCounter = styled(motion.div)`
  //position: fixed;
  //bottom: 0;
  //right: 0;
  //background-color: white;
  //width: 25%;
  //height: 0;
  box-shadow: 0px -18px 20px 0 white;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  color: ${textTertiary};
`
