import { diff } from 'deep-object-diff'

import { getUpdatedList, saveToSession, getStatus } from '../Tools'

const KEY_SUMMARIES = process.env.REACT_APP_KEY_SUMMARIES

export default (state, { type, payload, log = false }) => {
  switch (type) {
    case 'addBatch': {
      const newList = getUpdatedList(state.list, payload)
      if (log) console.log('AddBatch newList: ', newList, payload)
      if (!newList.length || !Object.keys(diff(state.list, newList)).length) {
        if (log) console.log('AddBatch No new list: ', state)
        return state
      }
      const finalState = { ...state, list: [...newList] }
      saveToSession(finalState, KEY_SUMMARIES)
      return finalState
    }
    case 'addOne': {
      const elemIndex = state.list.findIndex(e => e.id === payload.id)
      const finalState = { ...state }
      if (elemIndex !== -1) {
        // this is an update.
        if (!Object.keys(diff(state.list[elemIndex], payload)).length) {
          if (log) console.log('Add one Summary impossible: elements are the same', payload)
          return state
        }
        finalState.list[elemIndex] = {
          ...finalState.list[elemIndex],
          payload,
        }
      } else {
        finalState.list.push(payload)
      }
      saveToSession(finalState, KEY_SUMMARIES)
      return finalState
    }
    case 'update': {
      const { summaryId, data } = payload
      const index = state.list.findIndex(el => el.id === summaryId)
      if (index === -1) {
        if (log) console.log('SummaryId ', summaryId, ' Not found.\nLIST: ', state.list)
        return state
      }
      const previousSummary = state.list[index]
      if (log) console.log('previousSummary ', state.list[index])
      const newSummary = {
        ...previousSummary,
        ...data,
        ...(!data.appointmentStatus
          ? {
              appointmentIsImmediate: previousSummary.appointmentIsImmediate,
              appointmentStatus: previousSummary.appointmentStatus,
            }
          : {}),
      }
      newSummary.formattedStatus = getStatus(newSummary)
      if (log) console.log('newSummary ', newSummary)

      if (!Object.keys(diff(previousSummary, newSummary)).length) {
        if (log) console.log('Summaries are the same, doing nothing')
        return state
      }
      if (log) {
        console.log(
          'Summaries differs: ',
          diff(previousSummary, newSummary),
          previousSummary,
          newSummary
        )
      }
      const finalState = { ...state }
      finalState.list?.splice(index, 1, newSummary)
      saveToSession(finalState, KEY_SUMMARIES)
      return finalState
    }
    case 'cancel': {
      const elIndex = state.list.findIndex(el => el.id === payload)
      if (elIndex === -1) {
        if (log) console.log('Summary Cancel error: not found', payload, state.list)
        return state
      }
      const finalState = { ...state }
      finalState.list[elIndex] = { ...finalState.list[elIndex], cancelled: true }
      saveToSession(finalState, KEY_SUMMARIES)
      return finalState
    }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
