import { memo, useState, useCallback } from 'react'

import EyeClose from './assets/EyeClose'
import EyeOpen from './assets/EyeOpen'
import { Container, Label, InputContainer, Input, EyeContainer } from './Styles'
import { labelVariants } from './Animations'

const TextInput = ({
  type = 'text',
  name = '',
  placeholder = '',
  fullWidth = false,
  mayBeVisible = false,
  multiline = false,
  noBorder = false,
  noPadding = false,
  withCard = false,
  rtl = false,
  onEnter = undefined,
  withMask = false,
  style,
  field,
  label,
  inputRef,
  inputStyle,
  containerStyle,
  placeholderStyle,
  ...props
}) => {
  const [currentType, setCurrentType] = useState(type)
  const [isVisible, setIsVisible] = useState(false)
  const [focusing, setFocusing] = useState(false)

  const handleEyeClick = useCallback(() => {
    setCurrentType(isVisible ? 'password' : 'text')
    setIsVisible(!isVisible)
  }, [isVisible])
  const handleFocus = useCallback(
    newState => () => {
      setFocusing(newState)
    },
    []
  )
  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') onEnter?.()
    },
    [onEnter]
  )

  return (
    <Container noPadding={noPadding} style={containerStyle} withCard={withCard}>
      {label && (
        <Label animate={focusing || field.value ? 'small' : 'large'} variants={labelVariants}>
          {label}
        </Label>
      )}
      <InputContainer style={style} noBorder={noBorder} noPadding={noPadding} multiline={multiline}>
        <Input
          innerRef={inputRef}
          multiline={multiline}
          rtl={rtl}
          type={currentType}
          name={name}
          placeholder={placeholder}
          {...field}
          fullWidth={fullWidth}
          onFocus={handleFocus(true)}
          onBlur={handleFocus(false)}
          style={inputStyle}
          placeholderStyle={placeholderStyle}
          onKeyPress={handleKeyPress}
          withMask={withMask}
          className="font-csc45"
          {...props}
        />
        {mayBeVisible && (
          <EyeContainer onClick={handleEyeClick}>
            {isVisible ? <EyeClose /> : <EyeOpen />}
          </EyeContainer>
        )}
      </InputContainer>
    </Container>
  )
}

TextInput.whyDidYouRender = true

export default memo(TextInput)
