import { memo } from 'react'
import { CS_m } from 'back-end-api'

import CameraOn from 'assets/icons/communication/CameraOn'

import { useMiscDispatch } from 'services/Misc'
import { setAppointmentPickedUp, useAudioVisualsState } from 'services/Misc/audioVisuals'
import { green } from 'styles/Colors'

interface TimeCellProps {
  appointment: CS_m.ModelsAppointment
}

const PickUp = ({ appointment }: TimeCellProps) => {
  const miscDispatch = useMiscDispatch()
  const audioVisualState = useAudioVisualsState()

  const onPickup = () => {
    setAppointmentPickedUp(miscDispatch, audioVisualState, appointment)
  }

  return (
    <div role="presentation" onClick={onPickup} onKeyDown={onPickup}>
      <CameraOn style={undefined} color={green} />
    </div>
  )
}

PickUp.whyDidYouRender = true

export default memo(PickUp)
