export const STEPS = {
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  OTP: 'OTP',
  FINAL: 'FINAL',
}

export const Subtitles = {
  EMAIL: 'emailSubtitle',
  PASSWORD: 'passwordSubtitle',
  OTP: ['otpSubtitle1', 'otpSubtitle2'],
  FINAL: 'finalSubtitle',
}
